import styled from 'styled-components';
import { Container } from '../../gridSystem';

const EditorialMosaicCardContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const EditorialMosaicCardWrap = styled.div`
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
`;

export {
  EditorialMosaicCardContainer, EditorialMosaicCardWrap
};
