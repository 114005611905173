import styled from 'styled-components';

const VideoCardItemContainer = styled.div`
  position: relative;
  width: 232px;
  height: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  margin: auto;
  
  transition: all 125ms ease-in;
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    scale: 1.015;
  }

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }
`;

const Span = styled.span.attrs(({
  customClass
}) => ({
  className: `${customClass}`
}))``;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: ${props => props.theme.colors.primaryBlack};
  min-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  
  display: flex;
  align-items: center;
`;

const Image = styled.div`
  height: 126px;
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
`;

const CourseDetails = styled.div`
  padding: 20px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px ${props => props.theme.colors.lighterGrey};
  background-color: ${props => props.theme.colors.white};
  margin-top: -10px;
  border-radius: 4px;
  position: relative;
  height: 67px;
  margin-top: -10px;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.gray};
  min-height: 32px;
  margin-bottom: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const VideoPlay = styled.div`
  position: absolute;
  top: -17px;
  right: 18px;
  width: 34px;
  height: 34px;
  background-color: ${props => props.theme.colors.salem};
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: white;
  }
`;

export {
  VideoCardItemContainer,
  Image,
  CourseDetails,
  Title,
  VideoPlay,
  Span,
  Description
};
