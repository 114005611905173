import styled, { css } from 'styled-components';
import { NavBarHeight } from '../../../const';

const NavBarContainer = styled.header.attrs(() => ({
  className: 'header header-wrapper'
}))`
  position: fixed;
  width: 100%;
  height: ${NavBarHeight}px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.primaryColor};
  padding: 0 2rem;
  z-index: ${props => props.theme.zIndex.navBar};
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  background: ${props => props.theme.colors.white};
  border-top: 5px solid ${props => props.theme.colors.primaryColor};

  .first-nav-row {
    border-bottom: 2px solid ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 2px;
    @media ${props => props.theme.device.tablet} {
      padding: 2px 20px;
    }
    /* @media ${props => props.theme.device.mobile} {
      padding: 2px 15px;
    } */
  }

  .second-nav-row {
    height: 59px;
    padding: 10px 0 20px 10%;
  }

  &.header-wrapper {
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.theme.zIndex.headerWrapper};
    margin-left: auto;
  }

  /* @media ${props => props.theme.device.tablet} {
    padding: 0 30px;
    height: 117px;
    div {
      div {
        div {
          .close-modal {
            outline: none;
            animation: fadein 1s;
            background-color: white;
            border: none;
            display: block;
            padding: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            z-index: ${props => props.theme.zIndex.closeModal};

            &::after {
              content: '';
              position: absolute;
              transform: rotate(-45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }

            &::before {
              content: ' ';
              position: absolute;
              transform: rotate(45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }
          }
        }
      }
    }
  } */

  @media ${props => props.theme.device.tablet} {
    min-height: 110px;
    padding: 0 20px;
    div {
      padding: 0;
      div {
        div {
          //margin-right: 0px;
          //margin-left: 0px;
        }
      }
    }
  }
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  

  .logout {
    margin-left: 10px;
    cursor: pointer;
  }

  /* @media ${props => props.theme.device.tablet} {
    .logout {
      display: none;
    }
  } */

  @media ${props => props.theme.device.laptop} {
    &.logout {
      margin-left: 9px;
    }
  }
 /*  @media ${props => props.theme.device.tablet} {
    position: fixed;
    right: 20px;
  } */
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  min-width: 44px;
  max-width: 70px;
  z-index: 100;
  height: 60px;
  align-items: center;
  padding: 10px;
  padding-top: 15px;
  text-align: center;
  
  @media ${props => props.theme.device.mobileL} {
    min-width: 35px;
  }

    span:first-child, span.icon-notifiche{
      font-size:20px;
      font-weight: bold;
    }

    @media ${props => props.theme.device.tablet} {
      height: 40px; 
      justify-content: center;

      &:hover {
        background: transparent;
      }

      span:first-child {
        margin-bottom: 0px;
        font-size: 20px;
      }

      span.icon-notifiche {
        font-size: 20px;
        font-weight: bold;
      }
      .notification-badge {
        right: 17px;
        top: 3px;
      }
    }

    align-items: center;
    color: ${props => props.theme.colors.primaryColor}; 
    z-index: 90;
    height: 100%;
    font-weight: bold;
  
  ${props =>
    props.notificationOpen &&
    css`
      color: ${props => props.theme.colors.primaryColor};
      align-items: center;
      z-index: 90;
      height: 100%;
    `};

  ${props =>
    props.menuOpen &&
    css`
  color: ${props => props.theme.colors.primaryColor};
  align-items: center;
  z-index: 90;
  height: 100%;

   span:first-child {
    scale: 1.1;
  }; 
  background: opacity: 0.4;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green));
  
  `}

  :first-child {
    margin-right: 0px;
  }

  .bell-notification {
    position: relative;
  
   
  }

  .notification-badge {
    background-color: #009CA6;
    position: absolute;
    top: 4px;
    right: 12px;
    padding: 5px 10x;
    border-radius: 50%;
    width: 7px;
    height: 7px;

    /* @media ${props => props.theme.device.tablet} {
      top: 7px;
      right: 19px;
    } */

  }


  span:first-child {
    margin-bottom: 5px;
  }

  .label {
    font-size: 11px;
  }
`;

const BackHomeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  min-width: 44px;
  max-width: 70px;
  z-index: 100;
  height: 60px;
  align-items: center;
  padding: 10px;
  padding-top: 15px;
  text-align: center;
  
  img {
    height: 35px;
  }
  
  @media ${props => props.theme.device.mobileL} {
    min-width: 35px;
  }

    span:first-child, span.icon-notifiche{
      font-size:20px;
      font-weight: bold;
    }

    @media ${props => props.theme.device.tablet} {
      height: 40px; 
      justify-content: center;

      &:hover {
        background: transparent;
      }

      span:first-child {
        margin-bottom: 0px;
        font-size: 20px;
      }

      span.icon-notifiche {
        font-size: 20px;
        font-weight: bold;
      }
      .notification-badge {
        right: 17px;
        top: 3px;
      }
    }

    color: ${props => props.theme.colors.primaryColor}; 
    font-weight: bold;
    align-items: center;
    z-index: 90;
    height: 100%;
    span:first-child {
      scale: 1.1;
    };
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green));
  } 
  
  ${props =>
    props.notificationOpen &&
    css`
      color: ${props => props.theme.colors.primaryColor};
      align-items: center;
      z-index: 90;
      height: 100%;
    `};

  ${props =>
    props.menuOpen &&
    css`
  color:${props => props.theme.colors.primaryColor};
  align-items: center;
  z-index: 90;
  height: 100%;s

   span:first-child {
    scale: 1.1;
  }; 
  background: opacity: 0.4;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green));
  
  `}

  :first-child {
    margin-right: 0px;
  }

  .bell-notification {
    position: relative;
  
   
  }

  .notification-badge {
    background-color: #009CA6;
    position: absolute;
    top: 4px;
    right: 12px;
    padding: 5px 10x;
    border-radius: 50%;
    width: 7px;
    height: 7px;

    /* @media ${props => props.theme.device.tablet} {
      top: 7px;
      right: 19px;
    } */

  }


  span:first-child {
    margin-bottom: 5px;
  }

  .label {
    font-size: 11px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 60px;
  padding: 0;
  position: relative;
`;

const SearchContainer = styled.div.attrs(() => ({
  className: 'search-container'
}))`
  width: 300px;
  display: flex;

  @media ${props => props.theme.device.tablet} {
    position: relative;
    width: 80%;
    left: 0;
    margin-right: 19px;
    top: unset;
  }

  input {
    border: none;
  }
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const MenuElement = styled.span`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.mustard, 0.7)};
  }

  span {
    margin-right: 10px;
  }
`;

const SidebarMenu = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 150px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  padding: 10px;
  display: none;
  box-shadow: 1px 6px 9px 5px rgba(0, 0, 0, 0.28);

  transition: ${props => props.theme.constants.transitionBase};
  ${props =>
    props.isOpen &&
    css`
      display: block;
    `};

  ${MenuElement} {
    width: 100%;
    &:not(:last-child) {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(22, 74, 2, 0.4);
    }
  }
`;

const Notifications = styled.div`
  position: absolute;
  max-width: 380px;
  min-width: 380px;
  max-height: 300px;
  top: calc(100% + 10px);
  right: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.primaryColor};
  box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  transition: ${props => props.theme.constants.transitionBase};
  overflow: auto;

  @media ${props => props.theme.device.tablet} {
    &.notification-menu-mobile {
      background-color: ${props => props.theme.colors.primaryColor};
      max-height: 100%;

      &.open {
        position: fixed;
        display: initial;
        top: 60px;
        left: 0;
        border: none;
        margin-bottom: 0;
        height: calc(100vh - 60px);
        min-height: 100%;
        min-width: 100%;
        //overflow-y: auto;
      }
    }
  }

  @media ${props => props.theme.device.tablet} {
    &.notification-menu-mobile {
      &.open {
        top: 110px;
      }
    }
  }
`;

const NotificationMenu = styled.div`
  position: absolute;
  min-width: 380px;
  height: 30px;
  top: 60px;
  right: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.gray};
  background-color: ${props => props.theme.colors.accentGreen};
  color: ${props => props.theme.colors.white};
  box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }

  @media ${props => props.theme.device.tablet} {
    min-width: 375px;
    right: 5px;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 24px;
`;

const IconMenu = styled.div`
  cursor: pointer;
`;

const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: white;
  

 /*  @media ${props => props.theme.device.tablet} {
    margin-right: 10px;
  } */
`;

const NotificationHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  background-color: ${props => props.theme.colors.prune};
  border-radius: 4px 4px 0px 0px;
  color: ${props => props.theme.colors.primaryColor};
  padding: 16px 20px;

  @media ${props => props.theme.device.tablet} {
    height: 50px;
    border-radius: 0;
    padding: 25px !important;
  }
`;

const NotificationDescription = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.prune};
  }

  @media ${props => props.theme.device.tablet} {
    min-height: 60px;
    padding: 5px !important;
  }
`;

const NotificationContent = styled.div`
  border-top: 1px solid ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  max-height: 760px;
  overflow-y: scroll;

  & > ${NotificationDescription}:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.white};
  }

  @media ${props => props.theme.device.tablet} {
    padding: 10px !important;
  }
`;

const NotificationLabel = styled.div`
  width: 100%;
  margin-left: 10px;
  min-height: 16px;
  margin-bottom: 5px;
  max-height: 65px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  div:first-child {
    font-weight: bold;
    margin-bottom: 5px;
  }

  div:last-child {
    color: white;
  }
`;

const NotificationDate = styled.div`
  margin-left: 10px;
  min-height: 16px;
  color: rgba(255, 255, 255, 0.7);
`;

const InfoDescription = styled.div`
  width: calc(100% - 10px);
  ${({ notRead }) =>
    !notRead &&
    css`
      margin-left: 10px;
    `}

  ${props => props.theme.device.tablet} {
    ${({ notRead }) =>
      !notRead &&
      css`
        margin-left: 20px;
      `}
  }
`;

const SeeAll = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  white-space: nowrap;
  padding: 15px 40px;
  transition: ${props => props.theme.constants.transitionBase};
  text-align: end;
  border-top: 1px solid ${props => props.theme.colors.white};
  width: 100%;

  span {
    margin-left: 10px;
    transition: ${props => props.theme.constants.transitionBase};
  }

  :active {
    background: ${props => props.theme.colors.lightGreen};
  }

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.8)};
    background: #268900;
    span {
      right: 15px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    padding-top: 10px !important;
    padding-right: 25px !important;
  }
`;

const IconWrapperPeople = styled.div`
  background-color: ${props => props.theme.colors.black};
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: ${props => props.theme.colors.doveGray};
  }

  @media ${props => props.theme.device.tablet} {
    height: 52px;
    margin-right: 5px;
  }
`;

const NameContainer = styled.div`
  width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
  top: 0;
  color: black;

  ::after {
    width: 1px;
    height: 33px;
    opacity: 0.4;
    background: rgba(0, 54, 57, 0.7);
    content: '';
  }

  @media ${props => props.theme.device.tablet} {
    position: relative;

    ::after {
      display: none;
    }
  }
`;

const CircleName = styled.div`
  background-color: ${props => props.theme.colors.primaryColor};
  border-radius: 50%;
  height: 38px;
  text-align: center;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media ${props => props.theme.device.tablet} {
    height: 35px;
    width: 35px;
    margin-right: 0;
  }

  .initials {
    line-height: 1;
    color: white;
    margin-bottom: -6px;
  }
`;

const MobileIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media ${props => props.theme.device.mobileL} {
    justify-content: flex-end;
  }
`;

const NameContainerClickable = styled.div`
  width: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LogoMobileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const LogoContainerDesktop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const NoResultContainer = styled.div`
  margin-bottom: 20px;
`;

export {
  NavBarContainer,
  IconWrapper,
  LoginWrapper,
  ContentWrapper,
  MenuElement,
  SearchContainer,
  SidebarMenu,
  Text,
  Notifications,
  NotificationHeader,
  Icon,
  NotificationLabel,
  NotificationDescription,
  NotificationDate,
  NotificationContent,
  SeeAll,
  Status,
  IconMenu,
  NotificationMenu,
  InfoDescription,
  IconWrapperPeople,
  CircleName,
  NameContainer,
  MobileIconContainer,
  NameContainerClickable,
  LogoMobileContainer,
  NoResultContainer,
  LogoContainerDesktop,
  BackHomeWrapper
};
