import { createAsyncActionType } from './index';

export const PRODUCTS_SERVICES_LIST_GET = createAsyncActionType('productslservices', 'PRODUCTS_SERVICES_LIST_GET');
export const SELECTED_PRODUCTS_SERVICES_GET = createAsyncActionType('productslservices', 'SELECTED_PRODUCTS_SERVICES_GET');
export const SET_SELECTED_PRODUCTS_SERVICES = '@@productslservices/SET_SELECTED_PRODUCTS_SERVICES';
export const PRODUCTS_SERVICES_COURSES_GET = createAsyncActionType('productslservices', 'PRODUCTS_SERVICES_COURSES_GET');

export const PRODUCTS_SERVICES_NODE_ACTION = createAsyncActionType('productslservices', 'PRODUCTS_SERVICES_NODE_ACTION');
export const PRODUCTS_SERVICES_COMMENTS_GET = createAsyncActionType('productslservices', 'PRODUCTS_SERVICES_COMMENTS_GET');
export const PRODUCTS_SERVICES_COMMENTS_SET = createAsyncActionType('productslservices', 'PRODUCTS_SERVICES_COMMENTS_SET');

export const SET_FILTERS_PRODUCTS_SERVICES = '@@productslservices/SET_FILTERS_PRODUCTS_SERVICES';
