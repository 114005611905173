import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorialMosaicCardContainer, EditorialMosaicCardWrap
} from './style';

import EditorialCard from '../EditorialCard';

const EditorialMosaicCard = ({
  content
}) => {
  const getCardType = (items, index) => {
    const types = [
      EditorialCard.TYPE.LARGE, EditorialCard.TYPE.SMALL, EditorialCard.TYPE.SMALL, EditorialCard.TYPE.LARGE
    ];
    const isLast = items.length === index + 1;
    const isOdd = (index + 1) % 2;
    if (isOdd && isLast) return null;
    return types[index % 4];
  };

  return (
    <EditorialMosaicCardContainer className="p-0">
      <EditorialMosaicCardWrap>
        {(content || []).map(({ data: card }, i) => (
          <EditorialCard
            key={`EditorialMosaicCard-${i}`}
            type={getCardType(content, i)}
            title={card?.article ? card?.article[0].data.title : null}
            text={card?.article ? card?.article[0].data.body : null}
            image={card?.image ? card.image.url : null}
          />
        ))}
      </EditorialMosaicCardWrap>
    </EditorialMosaicCardContainer>
  );
};

EditorialMosaicCard.propTypes = {
  content: PropTypes.array
};

export default EditorialMosaicCard;
