import { createAsyncActionType } from './index';

export const LIFE_ISP_GET = createAsyncActionType('life_isp', 'LIFE_ISP_GET');
export const SELECTED_LIFE_ISP_GET = createAsyncActionType('life_isp', 'SELECTED_LIFE_ISP_GET');
export const SET_SELECTED_LIFE_ISP = '@@life_isp/SET_SELECTED_LIFE_ISP';
export const SET_SELECTED_FILTERS_LIFE_ISP = '@@life_isp/SET_SELECTED_FILTERS_LIFE_ISP';
export const LIFE_ISP_COURSES_GET = createAsyncActionType('life_isp', 'LIFE_ISP_COURSES_GET');

export const LIFE_ISP_NODE_ACTION = createAsyncActionType('life_isp', 'LIFE_ISP_NODE_ACTION');
export const LIFE_ISP_COMMENTS_GET = createAsyncActionType('life_isp', 'LIFE_ISP_COMMENTS_GET');
export const LIFE_ISP_COMMENTS_SET = createAsyncActionType('life_isp', 'LIFE_ISP_COMMENTS_SET');
