import { createAsyncActionType } from './index';

export const COURSE_DETAILS_GET = '@@course/COURSE_DETAILS_GET';
export const COURSE_DETAILS_SET = '@@course/COURSE_DETAILS_SET';
export const COURSE_FAVORITE_GET = createAsyncActionType('course', 'COURSE_FAVORITE_GET');

export const COURSE_COMMENTS_GET = createAsyncActionType('course', 'COURSE_COMMENTS_GET');
export const COURSE_COMMENTS_SET = createAsyncActionType('course', 'COURSE_COMMENTS_SET');

export const RECENT_COURSES_GET = createAsyncActionType(
  'course',
  'RECENT_COURSES_GET'
);

export const COURSES_TABLE_CONTENTS_GET = createAsyncActionType(
  'course',
  'COURSES_TABLE_CONTENTS_GET'
);

export const PILLS_RESET = '@@course/PILLS_RESET';
