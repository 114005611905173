import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container } from '../../../ui/gridSystem';
import { AUTH_VALIDATE_OTP } from '../../redux/actions';
import LogoSrc from '../../../ui/assets/img/STCLogo.png';
import {
  AuthContainer,
  Background,
  FormContainer,
  InputWrapper,
  Subtitle,
  Title,
  LogoContainer
} from './styles';
import { Button, CustomInput, Logo } from '../../../ui/atoms';
import heroImage from '../../../ui/assets/img/HPPrincipale.jpg';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import Gill from '../../../ui/typography/gill';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { TitleContainer } from '../Auth/styles';

const initialAuthForm = newForm([
  newFormField({
    field: 'otp',
    required: true,
    type: RegexpType.PIN_OTP,
    valid: true
  })
]);

// Possible messages
// 'INVALID_CODE';
// 'OTP_ERR_EXPIRATION_CODE';
// 'OTP_ERR_CODE_ALREADY_EXIST';
// 'OTP_ERR_SERVER';
// 'MSG_GENERATED_CODE';
const getFullMsg = msg => {
  let fullMsg = false;
  switch (msg) {
    case 'INVALID_CODE':
      fullMsg = 'Errore: il codice inserito non è valido';
      break;
    case 'OTP_ERR_EXPIRATION_CODE':
      fullMsg = 'Errore: il codice inserito è scaduto. Un nnuovo codice è stato generato.';
      break;
    default:
      fullMsg = msg;
      break;
  }
  return fullMsg;
};

const AuthOTP = ({
  validateOTP, vocabulary, message, messageCounter
}) => {
  const [authForm, setAuthForm] = useState(initialAuthForm);
  const [msg, setMsg] = useState();

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    if (!validator(type, newValue, required)) setMsg(true);
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };
  useEffect(() => {
    if (message) {
      setMsg(getFullMsg(message));

      if (getFullMsg(message)) {
        setAuthForm({
          ...authForm,
          otp: {
            ...authForm.otp,
            valid: false
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, messageCounter]);

  return (
    <AuthContainer>
      <Background img={heroImage} />

      <Gill>
        <TitleContainer>
          <Title>
            <Gill type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.authTitle, 'authTitle')}
            </Gill>
          </Title>
          <Subtitle>
            <Gill type="descriptionHome" vocabulary="authDescription">
              {getValueVocabulary(
                vocabulary.authDescription,
                'authDescription'
              )}
            </Gill>
          </Subtitle>
        </TitleContainer>
      </Gill>
      <Container>
        <FormContainer>
          <LogoContainer>
            <Logo src={LogoSrc} size={Logo.SIZE.LARGE} />
          </LogoContainer>
          <InputWrapper>
            <label htmlFor="OTP">
              {getValueVocabulary(
                vocabulary.authDescriptionOTP,
                'authDescriptionOTP'
              )}
            </label>
            <CustomInput
              placeholder="OTP"
              name="OTP"
              type="number"
              onBlur={newValue => handleOnChange('otp', newValue)}
              value={authForm?.otp?.value}
              valid={authForm?.otp?.valid}
              error={msg}
            />
          </InputWrapper>
          <InputWrapper>
            <Button
              size={Button.SIZE.FULL}
              btnText="Accedi"
              disabled={!isValidForm(authForm)}
              onClick={() => validateOTP(authForm.otp.value)}
              height={40}
            />
          </InputWrapper>
        </FormContainer>
      </Container>
    </AuthContainer>
  );
};

AuthOTP.propTypes = {
  vocabulary: PropTypes.object,
  message: PropTypes.string,
  messageCounter: PropTypes.number,
  // HOC (connect, dispatch)
  validateOTP: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { message, messageCounter } = state.app.otp;
    return {
      vocabulary,
      message,
      messageCounter
    };
  },
  dispatch => ({
    validateOTP: otp => dispatch({ type: AUTH_VALIDATE_OTP._REQUEST, otp })
  })
)(AuthOTP);
