import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TypefaceInterface = PropTypes.shape({
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  fontWeight: PropTypes.oneOf([400, 500, 700, 900, 'normal', 'bold', 'light']),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
});

const defaultConfig = {
  color: 'unset',
  fontFamily: 'gill',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  size: 12,
  textAlign: 'unset',
  textTransform: 'unset'
};

export const TypefaceStyled = styled.div.attrs(({ vocabulary }) => ({
  'data-vocabulary': vocabulary
}))`
  ${props => `
    color: ${props.theme.colors[props.config.color]};
    font-family: ${props.theme.fontset[props.config.fontFamily]};
    font-size: ${props.config.size + 2}px;
    font-style: ${props.config.fontStyle};
    font-weight: ${props.config.fontWeight};
    letter-spacing: ${props.config.letterSpacing};
    line-height: ${props.config.lineHeight};
    text-align: ${props.config.textAlign};
    text-transform: ${props.config.textTransform};
    width: 100%;
    transition: all 250ms ease-in;
  `};
`;

const Typeface = ({
  configuration, children, style, vocabulary, className
}) => <TypefaceStyled className={className} style={style} config={{ ...defaultConfig, ...configuration }} vocabulary={vocabulary}>{children}</TypefaceStyled>;

Typeface.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  configuration: TypefaceInterface
};

export default Typeface;
