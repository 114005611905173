import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col } from '../../../ui/gridSystem';
import {
  NewsDetailContainer, NewsDetailHeader, Pdf, Icon, NewsDetailBody,
  Date, ColumnDocuments, Documents, RowHeader, RowDate, Title, Share
} from './style';
import {GET_NEWS_DETAIL, SET_VIEW_NEWS, SHARE_NEWS} from '../../redux/actions/news';
import { BackButton } from '../../../ui/atoms';
import Arial from '../../../ui/typography/arial';
import dateFormat from '../../../utils/date-format';
import {
  SwitcherEditorialContent
} from '../../../ui/components';
import { MODAL_OPEN } from '../../redux/actions';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import routes from "../../../routes";

const NewsDetail = ({
  news_id, getNewsDetail,
  news: {
    title,
    subtitle,
    file_download,
    creation_date,
    cover,
    page_items
  },
  requestedURL,
  shareModal,
  shareNews,
  setViewNews
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsDetail(news_id);
    setViewNews(news_id);
  }, [getNewsDetail, news_id, setViewNews]);

  return (
    <NewsDetailContainer>
      <NewsDetailHeader img={cover?.url}>
        <Container className="p-0">
          <RowDate>
            {requestedURL?.pathname === '/integration-agenda' ? (
                <BackButton url={`${routes.integrationagenda.path}${requestedURL?.search}`} />
            ) : (
                <BackButton url={routes.news.path} />
            )}
            <Date>
              <Icon className="icon-clock" />
              {creation_date && <Arial type="newsDateItalic">{dateFormat(creation_date, 'news')}</Arial>}
            </Date>
          </RowDate>
          <RowHeader>
            <Col md={9}>
              {title && <Title><Arial type="newsDetailTitle">{title}</Arial></Title>}
              {subtitle && <Arial type="newsDetailDescription">{subtitle}</Arial>}
            </Col>
            <ColumnDocuments>
              <Documents>
                {(file_download || []).map(file => (
                  <Pdf href={file?.url} target="_blank" download key={file?.url}>
                    <Icon className="icon-document" />
                    <Arial type="newsAttachment">{file?.filename}</Arial>
                  </Pdf>
                ))}
                <Share onClick={() => shareModal({ onClickConfirm: (value) => shareNews({ receiver_user_id: value, node_id: news_id, configuration_type: 'share_news' }) })}>
                  <Icon className="icon-share" />
                  <Arial type="newsAttachment">condividi</Arial>
                </Share>
              </Documents>
            </ColumnDocuments>
          </RowHeader>
        </Container>
      </NewsDetailHeader>
      { page_items && (
        <NewsDetailBody className="p-0">
          <SwitcherEditorialContent items={page_items} />
        </NewsDetailBody>
      )}
      <ShareModal />
    </NewsDetailContainer>
  );
};

NewsDetail.propTypes = {
  news_id: PropTypes.string.isRequired,
  // HOC (connect, state)
  news: PropTypes.object,

  // HOC (connect, dispatch)
  getNewsDetail: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  shareNews: PropTypes.func.isRequired,
  setViewNews: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { selected_news: news } = state.news;
    const { requestedURL } = state.app;
    return { news, requestedURL };
  },
  dispatch => ({
    getNewsDetail: news_id => dispatch({ type: GET_NEWS_DETAIL._REQUEST, news_id }),
    shareModal: (data) => dispatch({ type: MODAL_OPEN, id: ShareModal.id, data }),
    shareNews: (data) => dispatch({ type: SHARE_NEWS._REQUEST, data }),
    setViewNews: (nid) => dispatch({ type: SET_VIEW_NEWS._REQUEST, nid })
  })
)(NewsDetail);
