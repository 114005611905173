import React, { } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  TeacherBoxContainer, TeacherHeader, TeacherDetails, TeacherPhoto, Text, TeacherName,
  CircleNameContainer, CircleName
} from './style';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';


const getInitials = (name) => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
};

const TeacherBox = ({ mediaIsPhone, tutor, user_type }) => (
  <TeacherBoxContainer style={{ width: mediaIsPhone ? 'auto' : '' }}>
    <TeacherHeader>
      <Arial type="author">{user_type === 'student' ? 'Tutor' : 'Tutee'}</Arial>
    </TeacherHeader>
    {(tutor || []).map((teacher) => (
      <TeacherDetails>
        {
        teacher.picture ? <TeacherPhoto img={teacher.picture} /> : (
          <CircleNameContainer>
            <CircleName className="initials">
              <Arial>{getInitials(`${teacher.name} ${teacher.surname}`)}</Arial>
            </CircleName>
          </CircleNameContainer>
        )}

        <Text>
          <TeacherName>
            <Arial className="name" type="author">{teacher.name} {teacher.surname}</Arial>

          </TeacherName>
          <Arial type="newsDescription" configuration={{ color: 'gray' }}>{teacher.job}</Arial>
        </Text>
      </TeacherDetails>
    ))
      }


  </TeacherBoxContainer>
);

TeacherBox.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired

  // HOC (connect, state)

  // HOC (connect, dispatch)
};


export default connect(
  state => {
    const { data: { user_type } } = state.user;
    return {
      user_type
    };
  }
)(withMediaQueries(TeacherBox));
