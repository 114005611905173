import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import smartworking from '../../api/smartworking';
import { calcPagination } from '../../../utils/pagination';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import {
  RUBRIC_CHANGE_COMMENTS_GET, RUBRIC_CHANGE_COMMENTS_SET,
  RUBRIC_CHANGE_LIST_GET,
  RUBRIC_CHANGE_NODE_ACTION, RUBRIC_CHANGE_PILL_SET_STATS,
  RUBRIC_CHANGE_PILLS_GET, RUBRIC_CHANGE_SHARE_PILL_COLLECTION,
  SELECTED_RUBRIC_CHANGE_GET
} from '../actions/rubricchange';
import routes from '../../../routes';


function* rubricChangeListGet({
  itemPerPage = 24, filters, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { rubricChange: { count: totalCount, items = [] } = {} } = yield select(state => state.rubricchange);
    const pagination = calcPagination(subset ? undefined : { count: items.length, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { category_name: 'RubricaDelCambiamento', ...filters, ...pagination };
      const { data: { data: response } } = yield call(smartworking.getSmartWorkingList, complete_filters);
      yield put({ type: RUBRIC_CHANGE_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > rubric_change > rubricChangeListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* rubricChangeListGetWatch() {
  yield takeLatest(RUBRIC_CHANGE_LIST_GET._REQUEST, rubricChangeListGet);
}

function* setSelectedRubricChange({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedRubricChange } = yield select(state => state.rubricchange);
    if (Object.keys(selectedRubricChange).length <= 0 && pagination && !pagination.isLastPage) {
      const filters = { category_name: 'RubricaDelCambiamento', id, ...pagination };
      const { data: { data: response } } = yield call(smartworking.getSmartWorkingList, filters);
      yield put({ type: SELECTED_RUBRIC_CHANGE_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > rubric change > setSelectedRubricChange', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedRubricChangeWatch() {
  yield takeLatest(SELECTED_RUBRIC_CHANGE_GET._REQUEST, setSelectedRubricChange);
}

function* rubricChangePillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response = {} } = {} } = yield call(smartworking.getSmartWorkingPills, { collectionId });
      yield put({ type: RUBRIC_CHANGE_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > rubric change > rubricChangePillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* rubricChangePillsGetWatch() {
  yield takeLatest(RUBRIC_CHANGE_PILLS_GET._REQUEST, rubricChangePillsGet);
}

function* rubricChangeNodeAction({ nid, action_type, node_type = 'pill_collection' }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(smartworking.smartWorkingNodeAction, payload);
      yield put({ type: RUBRIC_CHANGE_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: RUBRIC_CHANGE_LIST_GET._REQUEST });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selectedRubricChange } = yield select(state => state.rubricchange);
            if (selectedRubricChange.id) yield put({ type: RUBRIC_CHANGE_PILLS_GET._REQUEST, collectionId: selectedRubricChange.id, loader: false });
          } else {
            yield put({ type: RUBRIC_CHANGE_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > rubric change > rubricChangeNodeAction', err);
      yield put(push(routes.home.path));
    }
  }
}

function* rubricChangeNodeActionWatch() {
  yield takeLatest(RUBRIC_CHANGE_NODE_ACTION._REQUEST, rubricChangeNodeAction);
}

function* rubricChangeCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(smartworking.getSmartWorkingComments, { id });
      yield put({ type: RUBRIC_CHANGE_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > rubric change > rubricChangeCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* rubricChangeCommentsGetWatch() {
  yield takeLatest(RUBRIC_CHANGE_COMMENTS_GET._REQUEST, rubricChangeCommentsGet);
}

function* rubricChangeCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(smartworking.setSmartWorkingComments, payload);
    yield put({ type: RUBRIC_CHANGE_COMMENTS_SET._SUCCESS });
    yield put({ type: RUBRIC_CHANGE_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > rubric change > rubricChangeCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* rubricChangeCommentsSetWatch() {
  yield takeLatest(RUBRIC_CHANGE_COMMENTS_SET._REQUEST, rubricChangeCommentsSet);
}


function* rubricChangePillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(smartworking.setStatsSmartWorkingPill, params);
    yield put({ type: RUBRIC_CHANGE_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_RUBRIC_CHANGE_GET._REQUEST, id: collectionId });
    yield put({ type: RUBRIC_CHANGE_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > rubric change > rubricChangePillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* rubricChangePillStatsSetWatch() {
  yield takeLatest(RUBRIC_CHANGE_PILL_SET_STATS._REQUEST, rubricChangePillStatsSet);
}

function* rubricChangeSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(smartworking.smartWorkingSharePillCollection, data);
      yield put({ type: RUBRIC_CHANGE_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > rubric change > rubricChangeSharePillCollection', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* rubricChangeSharePillCollectionWatch() {
  yield takeLatest(RUBRIC_CHANGE_SHARE_PILL_COLLECTION._REQUEST, rubricChangeSharePillCollection);
}

export default [
  rubricChangeListGetWatch(),
  setSelectedRubricChangeWatch(),
  rubricChangePillsGetWatch(),
  rubricChangeNodeActionWatch(),
  rubricChangeCommentsGetWatch(),
  rubricChangeCommentsSetWatch(),
  rubricChangePillStatsSetWatch(),
  rubricChangeSharePillCollectionWatch()
];
