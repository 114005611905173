import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, push } from 'redux-first-history';

import { BackButtonContainer, Span } from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Gill from '../../typography/gill';

const BackButton = ({
  pushUrl, url, vocabulary, color, currentPath, onClick
}) => {
  const [linkUrl, setLinkUrl] = useState('/');
  useEffect(() => {
    const paths_array = localStorage.getItem('paths_array') || '';
    const new_paths_array = paths_array ? paths_array.split(';') : [];
    const new_path = localStorage.getItem('new_path');

    // if last item of new_paths_array = new_path means that a redirect to same route has occurred ==> we should redirect to second-last route (new_paths_array[0])
    const fallback = new_paths_array[new_paths_array.length - 1] === new_path ? new_paths_array[0] : new_path;

    // if even fallback === currentPath redirect to home
    if (fallback === currentPath) setLinkUrl('/');
    else setLinkUrl(url || fallback || '/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  const handleOnClick = () => {
    if (onClick && typeof onClick === 'function') onClick();
    pushUrl(linkUrl);
  };

  return (
    <BackButtonContainer>
      <div onClick={handleOnClick}>
        <Span color={color}><Gill type="backButton" vocabulary="action_back">{`< ${getValueVocabulary(vocabulary.action_back, 'action_back')}`}</Gill></Span>
      </div>
    </BackButtonContainer>
  );
};

BackButton.propTypes = {
  vocabulary: PropTypes.object,
  currentPath: PropTypes.string,
  onClick: PropTypes.func
};

export default connect(
  state => {
    const { vocabulary, currentPath } = state.app;
    return {
      vocabulary,
      currentPath
    };
  },
  dispatch => ({
    back: () => dispatch(goBack()),
    pushUrl: (url) => dispatch(push(url))
  })
)(BackButton);
