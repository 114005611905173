import React, { } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import Button from '../Button/index';
import {
  ShareResultsContainer, Text, Title, ButtonContainer
} from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';


const ShareResults = ({ onClick, vocabulary, disabled }) => (
  <ShareResultsContainer>
    <Title><Arial type="author" configuration={{ textTransform: 'none' }} vocabulary="shareResults">{getValueVocabulary(vocabulary.shareResults, 'shareResults')}</Arial></Title>
    <Text>
      <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }} vocabulary="shareResultsDescription">
        {getValueVocabulary(vocabulary.shareResultsDescription, 'shareResultsDescription')}
      </Arial>

    </Text>
    <ButtonContainer>
      <Button
        type={Button.TYPE.THIRD}
        btnText="Survey"
        onClick={onClick}
        disabled={disabled}
      />
    </ButtonContainer>

  </ShareResultsContainer>
);

ShareResults.propTypes = {
  onClick: PropTypes.func
  // HOC (connect, state)

  // HOC (connect, dispatch)
};
export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(ShareResults);
