import { takeLatest, put, call, select } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import stc_connected from '../../api/stc_connected';
import { calcPagination } from '../../../utils/pagination';
import {
  STC_CONNECTED_LIST_GET,
  SELECTED_STC_CONNECTED_GET,
  STC_CONNECTED_NODE_ACTION,
  STC_CONNECTED_COMMENTS_GET,
  STC_CONNECTED_COMMENTS_SET,
  STC_CONNECTED_PILLS_GET,
  STC_CONNECTED_PILL_SET_STATS,
  STC_CONNECTED_SHARE_PILL_COLLECTION
} from '../actions/stc_connected';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* stcConnectedListGet({
  itemPerPage = 24,
  filters: filterList = {},
  loader = true,
  subset = true,
  pathFilter = undefined
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const {
      stcConnected: { total_items: totalCount, items = [] } = {}
    } = yield select(state => state.stc_connected);
    const pagination = calcPagination(
      subset ? undefined : { from: items.length, totalCount },
      itemPerPage
    );
    const { path_contents } = yield select(state => state.app);
    if (path_contents.length && pagination && !pagination.isLastPage) {
      const path =
        pathFilter ||
        (path_contents || []).find(
          item => item.code.toLowerCase() === 'connected'
        ).id;
      const filters = {
        path,
        ...pagination,
        ...filterList
      };
      const {
        data: { data: response }
      } = yield call(stc_connected.getStcConnectedList, filters);
      yield put({ type: STC_CONNECTED_LIST_GET._SUCCESS, response, subset });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_connected > stcConnectedListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* stcConnectedListGetWatch() {
  yield takeLatest(STC_CONNECTED_LIST_GET._REQUEST, stcConnectedListGet);
}

function* setSelectedStcConnected({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedStcConnected } = yield select(state => state.stc_connected);
    const { path_contents } = yield select(state => state.app);
    if (
      path_contents.length &&
      Object.keys(selectedStcConnected).length <= 0 &&
      pagination &&
      !pagination.isLastPage
    ) {
      const path = (path_contents || []).find(item => item.name === 'Connected')
        .id;
      const filters = { path, id, ...pagination };
      const {
        data: { data: response }
      } = yield call(stc_connected.getStcConnectedList, filters);
      yield put({ type: SELECTED_STC_CONNECTED_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_connected > setSelectedStcConnected', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedStcConnectedWatch() {
  yield takeLatest(
    SELECTED_STC_CONNECTED_GET._REQUEST,
    setSelectedStcConnected
  );
}

function* stcConnectedPillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const {
        data: { data: response = {} } = {}
      } = yield call(stc_connected.getStcConnectedPills, { collectionId });
      yield put({ type: STC_CONNECTED_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_connected > stcConnectedPillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcConnectedPillsGetWatch() {
  yield takeLatest(STC_CONNECTED_PILLS_GET._REQUEST, stcConnectedPillsGet);
}

function* stcConnectedNodeAction({
  nid,
  action_type,
  node_type = 'pill_collection'
}) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(stc_connected.stcConnectedNodeAction, payload);
      yield put({ type: STC_CONNECTED_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite': {
          yield put({ type: STC_CONNECTED_LIST_GET._REQUEST });
          yield put({
            type: FAVORITE_LIST_GET._REQUEST,
            itemPerPage: 100,
            loader: false
          });
          break;
        }
        default: {
          if (nid && action_type !== 'favourite') {
            const { selectedStcConnected } = yield select(
              state => state.stc_connected
            );
            if (selectedStcConnected.id)
              yield put({
                type: STC_CONNECTED_PILLS_GET._REQUEST,
                collectionId: selectedStcConnected.id,
                loader: false
              });
          } else {
            yield put({ type: STC_CONNECTED_LIST_GET._REQUEST, loader: false });
          }
          break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > stc_connected > stcConnectedNodeAction', err);
    }
  }
}

function* stcConnectedNodeActionWatch() {
  yield takeLatest(STC_CONNECTED_NODE_ACTION._REQUEST, stcConnectedNodeAction);
}

function* stcConnectedCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const {
        data: comments
      } = yield call(stc_connected.getStcConnectedComments, { id });
      yield put({ type: STC_CONNECTED_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_connected > stcConnectedCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcConnectedCommentsGetWatch() {
  yield takeLatest(
    STC_CONNECTED_COMMENTS_GET._REQUEST,
    stcConnectedCommentsGet
  );
}

function* stcConnectedCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(stc_connected.setStcConnectedComments, payload);
    yield put({ type: STC_CONNECTED_COMMENTS_SET._SUCCESS });
    yield put({ type: STC_CONNECTED_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_connected > stcConnectedCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcConnectedCommentsSetWatch() {
  yield takeLatest(
    STC_CONNECTED_COMMENTS_SET._REQUEST,
    stcConnectedCommentsSet
  );
}

function* stcConnectedPillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(stc_connected.setStatsStcConnectedPill, params);
    yield put({ type: STC_CONNECTED_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_STC_CONNECTED_GET._REQUEST, id: collectionId });
    yield put({ type: STC_CONNECTED_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_connected > stcConnectedPillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcConnectedPillStatsSetWatch() {
  yield takeLatest(
    STC_CONNECTED_PILL_SET_STATS._REQUEST,
    stcConnectedPillStatsSet
  );
}

function* stcConnectedSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(stc_connected.stcConnectedSharePillCollection, data);
      yield put({ type: STC_CONNECTED_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(
        'sagas > stc_connected > stcConnectedSharePillCollection',
        err
      );
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcConnectedSharePillCollectionWatch() {
  yield takeLatest(
    STC_CONNECTED_SHARE_PILL_COLLECTION._REQUEST,
    stcConnectedSharePillCollection
  );
}

export default [
  stcConnectedListGetWatch(),
  setSelectedStcConnectedWatch(),
  stcConnectedPillsGetWatch(),
  stcConnectedNodeActionWatch(),
  stcConnectedCommentsGetWatch(),
  stcConnectedCommentsSetWatch(),
  stcConnectedPillStatsSetWatch(),
  stcConnectedSharePillCollectionWatch()
];
