import moment from 'moment';
import 'moment/locale/it';

const genGraphConfig = (data, state) => {
  moment.locale('it-IT');
  const datasets = [];
  const labels = Object.keys(data)
    .map(key => {
      const month = moment(key).format('MMM');
      return month.charAt(0).toUpperCase() + month.slice(1);
    })
    .reverse();
  const registered = Object.keys(data)
    .map(key => data[key].registered)
    .reverse();
  const in_progress = Object.keys(data)
    .map(key => data[key].in_progress)
    .reverse();
  const completed = Object.keys(data)
    .map(key => data[key].completed)
    .reverse();

  const registeredData = {
    labels: 'Da iniziare',
    backgroundColor: Object.keys(data).map(i => 'rgba(218, 41, 28, 0.7)'),
    data: registered
  };

  const inProgressData = {
    labels: 'In_progress',
    backgroundColor: Object.keys(data).map(i => 'rgba(218, 41, 28, 0.7)'),
    data: in_progress
  };

  const completedData = {
    labels: 'Completato',
    backgroundColor: Object.keys(data).map(i => 'rgba(218, 41, 28, 0.7)'),
    data: completed
  };

  if (state === 'registered') {
    datasets.push(registeredData);
  } else if (state === 'in_progress') {
    datasets.push(inProgressData);
  } else if (state === 'completed') {
    datasets.push(completedData);
  } else {
    datasets.push(registeredData, inProgressData, completedData);
  }

  return {
    labels,
    datasets
  };
};

export default genGraphConfig;
