import { shouldUseMock } from '../../utils/common';
import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import {
  newCourse, getCoursesTableContentsMock, getCoursesMock, getProductCommentsMock, setProductCommentsMock
} from './mock';

export default {
  getCourseDetails: (courseId) => {
    if (shouldUseMock()) {
      return {
        data: {
          code: 200,
          result: 'ok',
          data: newCourse(courseId)
        }
      };
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams({ id: courseId })}`, 'GET');
  },
  // getRecentCourses: () => apiCall("/api/v1/recentcourses", "GET")
  getRecentCourses: () => ({
    code: 200,
    status: 'ok',
    data: {
      courses: [
        {
          id: 1,
          code: 'AABBB1',
          title: 'Il turista enograstronomico',
          description: 'Come fare per raggiungere e soddisfare il cliente.',
          cover_image:
            'http://csiformactions.it/wordpress/wp-content/uploads/2019/06/running-effective-meeting.jpg',
          state: 'in_progress',
          expiry_date: 1546300800
        },
        {
          id: 2,
          code: 'AABBB2',
          title: 'Il business agricolo',
          description: 'Gestire i fattori strategici dell’azienda agricola.',
          cover_image:
            'http://csiformactions.it/wordpress/wp-content/uploads/2019/06/running-effective-meeting.jpg',
          state: 'in_progress',
          expiry_date: 1546300800
        },
        {
          id: 3,
          cover_image:
            'http://csiformactions.it/wordpress/wp-content/uploads/2019/06/running-effective-meeting.jpg',
          title:
            'Obblighi di Adeguata Verifica della Clientela ed Obblighi di conservazione',
          duration: 60,
          state: 'in_progress',
          description: 'Scopri quali sono i principali aspetti e indicatori che incidono sul calcolo del rating.',
          expiry_date: 1546300800
        },
        {
          id: 4,
          cover_image:
            'http://csiformactions.it/wordpress/wp-content/uploads/2019/06/running-effective-meeting.jpg',
          title: 'Obblighi di Segnalazione delle Operazioni Sospette',
          duration: 60,
          state: 'in_progress',
          description: 'Scopri quali sono i principali aspetti e indicatori che incidono sul calcolo del rating.',
          expiry_date: 1546300800
        },
        {
          id: 5,
          cover_image:
            'http://csiformactions.it/wordpress/wp-content/uploads/2019/06/running-effective-meeting.jpg',
          title: 'Test di valutazione',
          duration: 120,
          state: 'in_progress',
          description: 'Scopri quali sono i principali aspetti e indicatori che incidono sul calcolo del rating.',
          expiry_date: 1546300800
        }
      ]
    }
  }),
  getCoursesTableContents: (filters) => {
    if (process.env.REACT_APP_DEPLOY_ENV === 'LOCAL' && Number(process.env.REACT_APP_ENABLE_MOCK) === 1) {
      return getCoursesTableContentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}course${parseQueryParams(filters)}`, 'GET');
  },
  getFavoriteCourses: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}favourite?node_type=course`, 'GET');
  },
  getCourseComments: ({ id }) => {
    if (shouldUseMock()) {
      return getProductCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setCourseComments: (body) => {
    if (shouldUseMock()) {
      return setProductCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comment`, 'POST', body);
  }
};
