import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getSmarWorkingMock,
  smartWorkingNodeActionMock,
  getSmartWorkingCommentsMock,
  setSmartWorkingCommentsMock,
  getSmartWorkingPillsMock,
  setStatsSmartWorkingPillMock
} from './mock';

export default {
  getStcAllList: (filters) => {
    if (shouldUseMock()) {
      return getSmarWorkingMock();
    }
    return apiCall(`${baseUrl}${extendUrl}pill/collection${parseQueryParams(filters)}`, 'GET');
  },
  getStcAllPills: ({ collectionId }) => {
    if (shouldUseMock()) {
      return getSmartWorkingPillsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}pill/collection/${collectionId}/content`, 'GET');
  },
  stcAllNodeAction: (payload) => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getStcAllComments: ({ id }) => {
    if (shouldUseMock()) {
      return getSmartWorkingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setStcAllComments: (body) => {
    if (shouldUseMock()) {
      return setSmartWorkingCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  },
  setStatsStcAllPill: ({ collectionId, pillId, body }) => {
    if (shouldUseMock()) {
      return setStatsSmartWorkingPillMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/stats/${collectionId}/collection/${pillId}/pill`, 'POST', body);
  },
  stcAllSharePillCollection: (payload) => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/share`, 'POST', payload);
  }
};
