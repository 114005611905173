import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 100vh;
    margin-bottom: -293px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 63%;
    top: -31px;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

export const Slot = styled.div`
margin-bottom: 10px;
width: 50%;
`;

export const SlotWrapper = styled.div`
padding-left: 10px;
margin-top: -25px;

@media ${props => props.theme.device.laptop} {
 margin-top: unset;
}
`;

export const SlotContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
flex-wrap: wrap;
justify-content: flex-start;
margin-top: 10px;
margin-bottom: 20px;
max-height: 100px;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;

export const Time = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const FooterWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  bottom: 35px;

  & > button {
    height: 40px;
    width: 180px;
  }

  @media ${props => props.theme.device.tablet} {
    position: absolute;
    right: 46px;
    top: 56%;
    width: auto;
  }

  @media  ${props => props.theme.device.laptop} {
    position: absolute;
    right: 46px;
    bottom: 100%;
    width: auto;
  }
`;

export const OverviewContainer = styled.div``;

export const BoxesContainer = styled.div`
  
  width:100%;

  :first-child {
    margin-right: 8px;
    margin-left: 0px;
    display: flex;

    

    @media ${props => props.theme.device.tablet} {
      .teacher-box{
        width:300px;
      }

    }

    > div:first-child {
      margin-right: 11px;
      
    }
  }
`;

export const Text = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
`;

export const SkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;

  & > div {
    margin-right: 10px;
    color: ${props => props.theme.colors.veryLightPink};
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const IconWrapper = styled.span`
line-height: 100%;
vertical-align: middle;
display: inline-flex;
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 180px;
    height: 40px;
  }
`;

export const InfoContainer = styled.div`
width: 380px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 8px 20px 0 rgba(50,50,50,0.01);
    background-color: #222222;
    color: #ffffff;
    padding: 15px 20px 30px 20px;
    margin-bottom: 20px;


    @media ${props => props.theme.device.mobileL} {
     width: auto;
     margin-left: 0px;
    }

    

    
`;

export const InfoTitle = styled.div`
  position: relative;
    padding-bottom: 14px;

    &:before {
    content: '';
    position: absolute;
    width: 112%;
    height: 1.5px;
    background-color: rgba(63,63,63,0.7);
    left: -20px;
    bottom: 0;
    }
`;

export const InfoDescription = styled.div`
    margin-top: 26px;
    color: ${props => props.theme.colors.brownGrey};
`;
