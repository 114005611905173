import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import routes from '../../../routes';

import { Container, Row } from '../../gridSystem';


import {
  GuidebookSectionContainer, GuidebookSectionTitle, Title, GuidebookItemsRow,
  ColumnGuidebook
} from './style';
import Arial from '../../typography/arial';
import { GUIDEBOOK_GET } from '../../../app/redux/actions/guidebook';
import { SeeAll } from '../../atoms';
import GuidebookCard from '../GuidebookCard';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const GuidebookSection = ({
  getGuidebook, pushUrl, guidebookItems, title, vocabulary
}) => {
  useEffect(() => {
    getGuidebook();
  }, [getGuidebook]);

  return (
    <GuidebookSectionContainer>
      <Container className="p-0">
        <Row>
          <GuidebookSectionTitle lg={12} sm={12}>
            <Title>
              <Arial type="sectionTitle">{title}</Arial>
              <SeeAll
                onClick={() => pushUrl(`${routes.guidebook.path}`)}
                label={getValueVocabulary(vocabulary.showAll, 'showAll')}
              />
            </Title>
          </GuidebookSectionTitle>
          <GuidebookItemsRow>
            {guidebookItems.slice(0, 3).map((guidebook, index) => (
              <ColumnGuidebook md={4} key={`${guidebook?.title}-${index}`}>
                <GuidebookCard guidebook={guidebook} />
              </ColumnGuidebook>
            ))}
          </GuidebookItemsRow>
        </Row>
      </Container>
    </GuidebookSectionContainer>
  );
};

GuidebookSection.propTypes = {
  title: PropTypes.string,

  // HOC (connect, state)
  guidebookItems: PropTypes.array,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getGuidebook: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { files: guidebookItems } = state.guidebook;
    const { vocabulary } = state.app;
    return {
      guidebookItems,
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getGuidebook: () => dispatch({ type: GUIDEBOOK_GET._REQUEST, section: true })
  })
)(GuidebookSection);
