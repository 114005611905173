import styled from 'styled-components';

const Container = styled.div.attrs(() => ({
  className: 'promotion-video-wrapper'
}))`
  padding: 45px 0px;
  display: flex;
  align-items: center;

  .section-content {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding-right: 50px;
    padding-left: 50px;

    @media ${props => props.theme.device.tablet} {
      flex-direction: column;
    }
  }

  .section-text,
  .section-video {
    padding-right: 15px;
    padding-left: 15px;
  }

  .section-video {
    min-width: 600px;
  }
`;

const Title = styled.div`
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primaryColor};
  margin-bottom: 24px;
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: normal;
  color:  ${props => props.theme.colors.darkerGrey};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Video = styled.div``;

export {
  Container, Video, Title, Description
};
