import produce from 'immer';
import PropTypes from 'prop-types';
import { NEWS_GET, GET_NEWS_DETAIL, GET_NEWS_FAVORITE, GET_NEWS_HIGHLIGHTED } from '../actions/news';

export const NewsInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.object,
  url_source: PropTypes.string
});

const initialState = {
  news: {
    count: 0,
    filters: {},
    items: []
  },
  selected_news: {
  },
  favorites: {},
  highlighted: {}
};

const newsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case NEWS_GET._SUCCESS: {
      draft.news = action.response;
      const { response } = action;
      const { from, items } = response;
      const list = (!from || from <= 0) ? items : [...state.news.items, ...items];
      draft.products = {
        ...response,
        items: list
      };
      break;
    }
    case GET_NEWS_DETAIL._SUCCESS: {
      draft.selected_news = action.response;
      break;
    }
    case GET_NEWS_FAVORITE._SUCCESS: {
      const { response } = action;
      draft.favorites = response;
      break;
    }
    case GET_NEWS_HIGHLIGHTED._SUCCESS: {
      const { response } = action;
      draft.highlighted = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default newsReducer;
