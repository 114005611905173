import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from '../../../utils/date-format';
import {
  NewsCardContainer, Image,
  NewsCardDetails, Title, Date
  // HeartIcon, Description
} from './style';

import { NewsInterface } from '../../../app/redux/reducers/news';
import Arial from '../../typography/arial';

const NewsCard = ({
  onClick, type, news: {
    cover, title, creation_date, subtitle
  }, isAgenda, isHighlighted, isMargin
}) => {
  //const { video } = media[0].data;
  const { url: image } = cover || {};

  // const emptyIcon = 'icon-heart-empty';
  // const filledIcon = 'icon-preferiti';
  // const [icon, setIcon] = useState(emptyIcon);

  // const addToFavorites = () => {
  //   /* @todo integration */
  //   const activeIcon = icon === emptyIcon ? filledIcon : emptyIcon;
  //   setIcon(activeIcon);
  // };

  return (
    <NewsCardContainer type={type} isAgenda={isAgenda} isHighlighted={isHighlighted} isMargin={isMargin}>
      <Image type={type} onClick={onClick} src={image}>
        {/* {video && (
        <VideoIcon>
          <span className="icon-video"><span className="path1" /><span className="path2" /></span>
        </VideoIcon>
        )} */}
      </Image>
      {/*<HeartIcon onClick={() => addToFavorites()}>*/}
      {/*  <span className={icon} />*/}
      {/*</HeartIcon>*/}
      <NewsCardDetails onClick={onClick}>
        <Date><Arial type="newsDate">{dateFormat(creation_date, 'news')}</Arial></Date>
        <Title><Arial type="newsTitle">{title}</Arial></Title>
        {/*<Arial type="newsDescription"><Description>{subtitle}</Description></Arial>*/}
      </NewsCardDetails>
    </NewsCardContainer>
  );
};

NewsCard.TYPE = {
  LIGHT: 'light',
  WHITE: 'white'
};

NewsCard.propTypes = {
  isAgenda: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isMargin: PropTypes.bool,
  onClick: PropTypes.func,
  news: NewsInterface,
  type: PropTypes.oneOf(Object.values(NewsCard.TYPE))
};

NewsCard.defaultProps = {
  type: NewsCard.TYPE.LIGHT
};

export default NewsCard;
