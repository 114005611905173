import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    align-items: flex-start;

    & > h2 {
      width: 100%;
    }
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.primaryBlack};
`;

const ImageContainer = styled.div`
  margin-right: 15px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.primaryBlack};
  padding-bottom: 5px;
`;

const Info = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${props => props.theme.colors.gray};
  width: 100%;
`;

const InfoCertificate = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${props => props.theme.colors.gray};
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const Certificate = styled.div``;

const User = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;

export const DownloadWrap = styled.div`
  float: right;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  
  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ContentProfile = styled.div`
  order: 1;
  width: 40%;

  @media ${props => props.theme.device.tablet} {
    width: 60%;
    margin-bottom: 15px;
    order: 1;
  }
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
`;

const ContentCertificate = styled.div`
  font-size: 14px;
  width: 30%;
  order: 2;
  padding-left: 10px;
  width: 40%;
  
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 15px;
    padding: 0;
    order: 4;
  }

  @media ${props => props.theme.device.mobileL} {
    order: 2;
  }
`;

const ContentActivity = styled.div`
  font-size: 14px;
  width: 25%;
  padding-left: 40px;
  order: 3;
  @media ${props => props.theme.device.tablet} {
    width: 20%;
    margin-bottom: 15px;
    padding: 0;
    order: 2;
  }

  @media ${props => props.theme.device.mobileL} {
    order: 3;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ContentTime = styled.div`
  display: flex;
`;

const ProgressContainer = styled.div`
  order: 4;
  flex-grow: 1;
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    order: 3;
  }

  @media ${props => props.theme.device.mobileL} {
    order: 4;
  }
`;

const Icon = styled.span.attrs(({
  customClass
}) => ({
  className: `${customClass}`
}))`
  margin-right: 0.3em;
`;

export {
  Container,
  Name,
  ImageContainer,
  Title,
  Info,
  User,
  Content,
  ContentProfile,
  ContentInfo,
  ContentCertificate,
  ContentActivity,
  ContentTime,
  ProgressContainer,
  Icon,
  Certificate,
  InfoCertificate
};
