import styled from 'styled-components';

const MenuContainer = styled.div.attrs(({ customClass }) => ({
  className: `menu ${customClass}`
}))`
  border: 1px solid ${props => props.theme.colors.athensGray};

  .container-fluid {
    padding: 0;
  }

  &.menu-mobile {
    display: none;
  }

  @media ${props => props.theme.device.tablet} {
    &.menu-desktop {
      display: none;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &.menu-mobile {
      background-color: ${props => props.theme.colors.primaryRed};
      &.open {
        display: initial;
        position: fixed;
        top: 110px;
        left: 0;
        border: none;
        margin-bottom: 0;
        height: calc(100vh - 60px);
        width: 100%;
        overflow-y: auto;

        .items-list {
          flex-direction: column;
          align-items: left;

          .items-list-item {
            text-align: left;
            font-size: 16px;
            color: ${props => props.theme.colors.white};
            padding: 20px 26px;
            width: 100%;
            background-color: ${props => props.theme.colors.mineShaft};

            &:hover,
            &:active {
              color: ${props => props.theme.colors.white};
            }

            &.active {
              border-bottom: none;
              color: ${props => props.theme.colors.white};
            }

            &.items-list-item-mobile {
              display: flex;
              align-items: center;
              text-transform: uppercase;
              background-color: ${props => props.theme.colors.primaryRed};

              .icon {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }

 /*  @media ${props => props.theme.device.mobileL} {
    &.menu-mobile {
      &.open {
        top: 116px;
      }
    }
  } */
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colors.white};

  .items-list-item {
    flex: 1 1 auto;
    text-align: center;
    text-transform: uppercase;
    padding: 18px 0;
    cursor: pointer;

    color: ${props => props.theme.colors.silverGrey};
    &:hover,
    &:active {
      color: ${props => props.theme.colors.silverGrey};
    }

    &.active {
      border-bottom: 3px solid ${props => props.theme.colors.primaryColor};
      color: ${props => props.theme.colors.primaryColor};
    }

    &.items-list-item-mobile {
      display: none;
    }
  }
`;

const Span = styled.span``;

export { MenuContainer, ItemList, Span };
