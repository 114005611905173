import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getCollectionMock, getCollectionsMock, getCoursesMock, getCoursesMock2, getPillsMock, getWelcomeModalMock
} from './mock';

export default {
  getCollections: (filters) => {
    if (shouldUseMock()) {
      return getCollectionsMock(12);
    }
    return apiCall(`${baseUrl}${extendUrl}collection${parseQueryParams(filters)}`, 'GET');
  },
  getCollection: (filters) => {
    if (shouldUseMock()) {
      return getCollectionMock(filters);
    }
    return apiCall(`${baseUrl}${extendUrl}course${parseQueryParams({ id: filters.collectionId })}`, 'GET');
  },
  getCourse: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock2(filters);
    }
    return apiCall(`${baseUrl}${extendUrl}collection${parseQueryParams(filters)}`, 'GET');
  },
  getCourses: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}course${parseQueryParams(filters)}`, 'GET');
  },
  getVideoPills: (filters) => {
    if (shouldUseMock()) {
      return getPillsMock();
    }

    return apiCall(`${baseUrl}pill${parseQueryParams(filters)}`, 'GET');
  },
  getWelcomeModal: () => {
    if (shouldUseMock()) {
      return getWelcomeModalMock();
    }

    return apiCall(`${baseUrl}welcome`, 'GET');
  }
};
