import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Footer, NavBar, Scroller, SubNavBar } from '../index';
import { PageContainer } from './styles';
import { LinearLoader } from '../Loader';

const GlobalLayout = ({ children, isLoading, isLogged }) =>
  isLogged ? (
    <>
      <NavBar />
      <SubNavBar />
      {isLoading && <LinearLoader />}
      <PageContainer id="anchor">{children}</PageContainer>
      <Scroller />
      <Footer />
    </>
  ) : (
    <>
      {isLoading && <LinearLoader isAuth />}
      <div id="anchor">{children}</div>
    </>
  );

GlobalLayout.propTypes = {
  children: PropTypes.node,

  // HOC (connect, state)
  isLoading: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired
};

export default connect(state => ({
  isLoading: state.app.loader.isSpinning,
  isLogged: state.app.authState.isLogged
}))(GlobalLayout);
