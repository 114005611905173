import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleHeader = styled.div`
  & > div:last-child {
    margin-top: 27px;
    text-decoration: underline;
    cursor: pointer;

    @media ${props => props.theme.device.mobileL} {
      margin-bottom: 20px;
    }
  }
`;

export const Filters = styled.div`
  min-width: 250px;

  & > div:first-child {
    margin-bottom: 10px;
  }
`;

export const Type = styled.div`
  margin-left: 20px;

  ${({ notRead }) =>
    !notRead &&
    css`
      margin-left: 30px;
    `}

  @media ${props => props.theme.device.tablet} {
    ${({ notRead }) =>
      !notRead &&
      css`
        margin-left: 20px;
      `}
  }
`;

export const ReadAll = styled.div``;

export const Description = styled.div`
  padding: 5px 0;
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.biscuit50};
  display: flex;
  align-items: center;
`;

export const NotificationWrapper = styled.div`
  padding-bottom: 100px;
  padding-top: 80px;
  color: ${({ theme }) => theme.colors.black};
`;
