import { createAsyncActionType } from './index';

export const SEARCH_LIST_GET = createAsyncActionType(
  'search',
  'SEARCH_LIST_GET'
);

export const SEARCH_COLLEAGUE = createAsyncActionType(
  'search',
  'SEARCH_COLLEAGUE'
);

export const SEARCH_WORD_SET = '@@search/SEARCH_WORD_SET';

export const SEARCH_COACHEE = createAsyncActionType('search', 'SEARCH_COACHEE');

export const SEARCH_STC_COURSES_LIST_GET = createAsyncActionType(
  'search',
  'SEARCH_STC_COURSES_LIST_GET'
);
