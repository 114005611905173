import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Container } from '../../../ui/gridSystem';
import {
  NewsContainer, Title, ResultContainer, CardContainer, RowFilters,
  ColumnFilter
} from './style';
import { NEWS_GET } from '../../redux/actions/news';
import { NewsInterface } from '../../redux/reducers/news';
import routes from '../../../routes';
import NewsCard from '../../../ui/components/NewsCard';
import Arial from '../../../ui/typography/arial';
import { CustomSelect, NoResult } from '../../../ui/atoms';
import labels from '../../../utils/labels';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { parseQueryParams } from '../../../utils/queryParams';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {SeeMore} from "../LifeISP/style";

const News = ({
  getNews, news, pushUrl, filters, vocabulary, location, mediaIsTablet, mediaIsLaptop,
  total_items
}) => {
  const { categories, type, months } = queryString.parse(location.search);
  const [activeFilters, setActiveFilters] = useState({ categories, type, months });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getNews({ categories, type, months });
  }, [categories, getNews, months, type]);

  useEffect(() => {
    pushUrl(`${routes.news.path}${parseQueryParams(activeFilters)}`);
  }, [activeFilters, pushUrl]);

  const setFilters = (key, option) => {
    const { value } = option || { value: null };
    const filter = {
      [key]: value
    };
    if (!filter[key]) delete activeFilters[key];
    setActiveFilters({ ...activeFilters, ...filter[key] ? filter : null });
  };

  const onClickSeeMore = () => {
    getNews({ categories, type, months }, false);
  };

  return (
    <NewsContainer>
      <Container className="p-0">
        <RowFilters>
          <Title md={12}>
            <Arial type="newsTitle" vocabulary="newsFilterLabel">{getValueVocabulary(vocabulary.newsFilterLabel, 'newsFilterLabel')}</Arial>
          </Title>
          {Object.keys(filters).map((k, i) => {
            const options = filters[k].map(({
              id, name, key, value
            }) => ({ value: id || key, label: name || value }));
            // eslint-disable-next-line eqeqeq
            const selectedOption = options.find(option => option.value == activeFilters[k]);
            return (
              <ColumnFilter key={i} md={3}>
                <CustomSelect
                  isClearable
                  options={options}
                  selected={selectedOption}
                  onChange={newValue => setFilters(k, newValue)}
                  placeholder={labels[k]}
                />
              </ColumnFilter>
            );
          })}
        </RowFilters>
        <ResultContainer>
          {news.length > 0 ? (news.map((newsDetail) => (
            <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={newsDetail.id}>
              <NewsCard
                onClick={() => pushUrl(`${routes.news.path}/${newsDetail.id}`)}
                news={newsDetail}
                isMargin={mediaIsTablet}
              />
            </CardContainer>
          ))) : (<NoResult />)}
        </ResultContainer>
        {news.length < total_items && <SeeMore onClick={onClickSeeMore}>Mostra altri</SeeMore>}
      </Container>
    </NewsContainer>
  );
};

News.propTypes = {
  // HOC (connect, state)
  news: PropTypes.arrayOf(NewsInterface),
  filters: PropTypes.object,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getNews: PropTypes.func.isRequired,
  location: PropTypes.object,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { news: { items: news, count: total_items } } = state.news;
    const { news: { filters } } = state.news;
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      news,
      total_items,
      filters,
      vocabulary,
      location
    };
  },
  dispatch => ({
    getNews: (filters, subset = true) => dispatch({
      type: NEWS_GET._REQUEST, carousel: false, itemPerPage: 12, filters, subset
    }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(News));
