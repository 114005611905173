import styled, { keyframes } from 'styled-components';

const InfoContainer = styled.div.attrs(() => ({
  className: 'info-container'
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    padding: 0px;
  }

  @media ${props => props.theme.device.mobileL} {
    padding-right: 20px;
    margin-bottom: 15px;
  }

  .state {
    width: auto;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color:  ${props => props.theme.colors.darkerGrey};
    text-transform: uppercase;
    padding: 2px 5px;

    @media ${props => props.theme.device.tablet} {
      display: none;
    }
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 15px;
`;

const SeeAll = styled.div`
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  white-space: nowrap;
  padding-right: 20px;
  transition: ${props => props.theme.constants.transitionBase};

  span {
    position: absolute;
    right: 0;
    margin-top: -8px;
    top: 50%;
    transition: ${props => props.theme.constants.transitionBase};
  }

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.primaryColor, 0.8)};
    span {
      right: -5px;
    }
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
`;

const Subtitle = styled.div`
  color:  ${props => props.theme.colors.darkerGrey};
  width: 70%;

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 5px;
  }
`;

const courseContainerAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
  }
`;

const CourseContainer = styled.div`
  animation: ${courseContainerAnimation}.3s;
  position: relative;
  width: 100%;

  @media ${props => props.theme.device.laptop} {
    padding: 0 30px 0 30px;
  }

  @media ${props => props.theme.device.tabletL} {
      padding: 0 25px 0 25px;
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0;
  }

  ${InfoContainer} {
    .state {
      background-color: rgba(60,60,60, 0.2);
      color: ${props => props.theme.colors.white};
      letter-spacing: normal;
    }
  }

  ${Title} {
    color: ${props => props.theme.colors.black};
  }

  ${Subtitle} {
    color: ${props => props.theme.colors.white};
  }
`;

const Icon = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    margin-left: auto;
  }
`;

export {
  CourseContainer,
  InfoContainer,
  HeaderContent,
  Title,
  Subtitle,
  Icon,
  SubtitleContent,
  SeeAll
};
