import produce from 'immer';
import PropTypes from 'prop-types';
import { LOCATION_CHANGE } from 'redux-first-history';
import {
  CERTIFICATION_COURSE_GET,
  CERTIFICATION_TABLE_CONTENTS_GET
} from '../actions/certification';
import routes from '../../../routes';


export const TableCertificationInterface = PropTypes.shape({
  title: PropTypes.string.isRequired,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  description: PropTypes.string,
  assigned_date: PropTypes.number.isRequired,
  expiration_date: PropTypes.number,
  renewed_date: PropTypes.number,
  download_url: PropTypes.string
});

export const TableCertificationsInterface = PropTypes.arrayOf(TableCertificationInterface);

export const CertificationsTableContents = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  certifications: TableCertificationsInterface
});

const initialState = {
  certification_table_contents: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    certifications: []
  },
  course_certificate: undefined
};


const certificationReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload.pathname !== routes.lessons.path) {
        draft.course_certificate = initialState.course_certificate;
      }

      break;
    }

    case CERTIFICATION_TABLE_CONTENTS_GET._SUCCESS: {
      draft.certification_table_contents = action.response;

      const { from, certifications } = action.response;
      const data = (!from || from <= 0) ? certifications : [...state.certification_table_contents.certifications, ...certifications];

      draft.certification_table_contents = {
        ...action.response,
        certifications: data
      };

      break;
    }

    case CERTIFICATION_COURSE_GET._SUCCESS: {
      const { total_items, certifications = [] } = action.response;

      if (total_items && certifications.length) {
        draft.course_certificate = certifications[0];
      }

      break;
    }

    default:
      return state;
  }
  return draft;
});

export default certificationReducer;
