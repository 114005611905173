import { createAsyncActionType } from './index';

export const RUBRIC_CHANGE_LIST_GET = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_LIST_GET');
export const SET_SELECTED_RUBRIC_CHANGE = '@@rubricchange/SET_SELECTED_RUBRIC_CHANGE';
export const SELECTED_RUBRIC_CHANGE_GET = createAsyncActionType('rubricchange', 'SELECTED_RUBRIC_CHANGE_GET');
export const RUBRIC_CHANGE_PILLS_GET = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_PILLS_GET');
export const RUBRIC_CHANGE_NODE_ACTION = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_NODE_ACTION');
export const RUBRIC_CHANGE_COMMENTS_GET = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_COMMENTS_GET');
export const RUBRIC_CHANGE_COMMENTS_SET = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_COMMENTS_SET');
export const RUBRIC_CHANGE_PILL_SET_STATS = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_PILL_SET_STATS');
export const RUBRIC_CHANGE_SHARE_PILL_COLLECTION = createAsyncActionType('rubricchange', 'RUBRIC_CHANGE_SHARE_PILL_COLLECTION');
