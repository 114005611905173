import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  CompulsaryTrainingContainer, CardContainer, ObbligatoryFilterContainer,
  ObbligatoryFilterLabel, ObbligatoryCustomSelectContainer, ObbligatoryFilterPillsContainer
} from './style';
import {
  ResultContainer
} from '../News/style';
import routes from '../../../routes';
import {
  CustomSelect, FilterPill, GeneralCard, Tabs, NoResult
} from '../../../ui/atoms';
import Arial from '../../../ui/typography/arial';
import {
  COMPULSARY_TRAINING_LIST_GET,
  COMPULSARY_TRAINING_NODE_ACTION,
  SET_SELECTED_COMPULSARY_TRAINING, SET_SELECTED_FILTERS_COMPULSARY_TRAINING
} from '../../redux/actions/compulsarytraining';
import { getTagColor } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { Background } from '../Procedures/style';
import ProceduresDefaultCover from '../../../ui/assets/img/formazione.jpg';
import { TabsContainer, HeaderContainer } from '../LifeISP/style';
import { withMediaQueries } from '../../hoc/withMediaQueries';

const CompulsaryTraining = ({
  courses, categories = [], getCompulsaryTraining,
  setSelectedCompulsaryTraining, setAsFavoriteCompulsaryTraining,
  pushUrl, vocabulary, heroImage, mediaIsTablet, mediaIsLaptop, total_items,
  setSelectedFitlersCompulsaryTraining, selected_filters
}) => {
  const [course, setCourse] = useState(courses);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(selected_filters).length <= 0) {
      getCompulsaryTraining({}, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompulsaryTraining]);

  useEffect(() => {
    setCourse(courses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const handleOnClickCard = (item) => {
    setSelectedCompulsaryTraining(item);
    pushUrl(`${routes.compulsarytraining.path}/${item.id}`);
  };

  const defaultTabs = [
    { label: 'Tutte le categorie', value: 'all' },
    { label: 'In scadenza', value: 'expiring' },
    { label: 'Da iniziare', value: 'registered' },
    { label: 'In corso', value: 'in_progress' },
    { label: 'Completati', value: 'completed' }
  ];

  const [activeTab, setActiveTab] = useState(selected_filters?.activeTab || defaultTabs[0]);
  const [selectedFilters, setSelectedFilters] = useState(selected_filters?.selectedFilters || []);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
    setSelectedFitlersCompulsaryTraining({ activeTab: tab });
  };

  const onChangeFilter = (newFilters) => {
    if (newFilters.length) {
      const filter = { category_id: [...newFilters.map(item => item.value)].toString().replace(/,/g, ';') };
      getCompulsaryTraining(filter, true);
    } else {
      getCompulsaryTraining({}, true);
    }
    setSelectedFilters(newFilters);
    setSelectedFitlersCompulsaryTraining({ activeTab, selectedFilters: newFilters });
  };

  const removeFilter = (item) => {
    const newFilters = (selectedFilters || []).filter(filter => filter.value !== item);
    const filter = { category_id: [...newFilters.map(filter => filter.value)].toString().replace(/,/g, ';') };
    getCompulsaryTraining(filter, true);
    setSelectedFilters(newFilters);
    setSelectedFitlersCompulsaryTraining({ activeTab, selectedFilters: newFilters });
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
      </HeaderContainer>
      <CompulsaryTrainingContainer>
        <Container className="p-0">
          <TabsContainer>
            <Tabs tabs={[...defaultTabs]} onChange={onChangeTab} selected={activeTab} />
          </TabsContainer>
          <>
            <ObbligatoryFilterLabel><Arial type="filterLabel" vocabulary="obbligatoryFilterLabel">{getValueVocabulary(vocabulary.obbligatoryFilterLabel, 'obbligatoryFilterLabel')}</Arial></ObbligatoryFilterLabel>
            <ObbligatoryFilterContainer>
              <ObbligatoryCustomSelectContainer>
                <CustomSelect options={[...categories.map(({ id: value, name: label }) => ({ label, value }))]} onChange={newValue => onChangeFilter(newValue || [])} selected={selectedFilters} placeholder="Seleziona" isMulti />
              </ObbligatoryCustomSelectContainer>
              <ObbligatoryFilterPillsContainer>
                {(selectedFilters || []).map(filter => <FilterPill key={`filter-${filter.value}`} onRemove={removeFilter} value={filter.value} label={filter.label} />)}
              </ObbligatoryFilterPillsContainer>
            </ObbligatoryFilterContainer>
          </>
          {(activeTab.value === 'all')
        && (
          <>
            {(course.length > 0
              ? (
                <Container className="p-0">
                  {/*<Title><Arial type="sectionTitle">Formazione obbligatoria da fare</Arial></Title>*/}
                  <ResultContainer>
                    {(course.map((obbligatory) => (
                      <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                        <GeneralCard
                          item={obbligatory}
                          onClickCard={() => handleOnClickCard(obbligatory)}
                          onClickFavorite={() => setAsFavoriteCompulsaryTraining(obbligatory.id)}
                          colorTag={getTagColor(categories, obbligatory.category_id)}
                          type={GeneralCard.TYPE.OBBLIGATORY}
                        />
                      </CardContainer>
                    )))}
                  </ResultContainer>
                  {/*{course.length < total_items && <SeeMore onClick={onClickSeeMore}>Mostra altri</SeeMore>}*/}
                </Container>
              ) : (<NoResult />))
}
          </>
        )}
          {(activeTab.value === 'expiring')
            && (
              <>
                {(course.filter(filter => (filter?.expiration_status === 'expiring' || filter?.expiration_status === 'expired') && filter.status !== 'completed') || []).length > 0 ? (
                  <>
                    {/*<Title>
                      <Arial type="editorialImageTitle">In scadenza</Arial>
                    </Title>*/}
                    <ResultContainer>
                      {(course.filter(filter => (filter?.expiration_status === 'expiring' || filter?.expiration_status === 'expired') && filter.status !== 'completed') || []).length > 0
                        ? (course.filter(filter => (filter?.expiration_status === 'expiring' || filter?.expiration_status === 'expired') && filter.status !== 'completed').map((obbligatory) => (
                          <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                            <GeneralCard
                              item={obbligatory}
                              onClickCard={() => handleOnClickCard(obbligatory)}
                              onClickFavorite={() => setAsFavoriteCompulsaryTraining(obbligatory.id)}
                              colorTag={getTagColor(categories, obbligatory.category_id)}
                              type={GeneralCard.TYPE.OBBLIGATORY}
                            />
                          </CardContainer>
                        ))) : (<></>)}
                    </ResultContainer>
                  </>
                ) : (<NoResult />)
              }
              </>
            )
            }
        </Container>
        {(activeTab.value === 'registered')
        && (
          <>
            {(course.filter(filter => (filter.status === 'registered') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired')) || []).length > 0
              ? (
                <Container className="p-0">
                  {/*<Title><Arial type="sectionTitle">Formazione obbligatoria da fare</Arial></Title>*/}
                  <ResultContainer>
                    {(course.filter(filter => (filter.status === 'registered') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired')) || []).length > 0
                      ? (course.filter(filter => ((filter.status === 'registered') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired'))).map((obbligatory) => (
                        <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                          <GeneralCard
                            item={obbligatory}
                            onClickCard={() => handleOnClickCard(obbligatory)}
                            onClickFavorite={() => setAsFavoriteCompulsaryTraining(obbligatory.id)}
                            colorTag={getTagColor(categories, obbligatory.category_id)}
                            type={GeneralCard.TYPE.OBBLIGATORY}
                          />
                        </CardContainer>
                      ))) : (<></>)}
                  </ResultContainer>
                </Container>
              ) : (<NoResult />)
}
          </>
        )}
        {(activeTab.value === 'in_progress')
        && (
          <>
            {(course.filter(filter => (filter.status === 'in_progress') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired')) || []).length > 0
              ? (
                <Container className="p-0">
                  {/*<Title><Arial type="sectionTitle">Formazione obbligatoria da fare</Arial></Title>*/}
                  <ResultContainer>
                    {(course.filter(filter => (filter.status === 'in_progress') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired')) || []).length > 0
                      ? (course.filter(filter => ((filter.status === 'in_progress') && (filter?.expiration_status !== 'expiring' && filter?.expiration_status !== 'expired'))).map((obbligatory) => (
                        <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                          <GeneralCard
                            item={obbligatory}
                            onClickCard={() => handleOnClickCard(obbligatory)}
                            onClickFavorite={() => setAsFavoriteCompulsaryTraining(obbligatory.id)}
                            colorTag={getTagColor(categories, obbligatory.category_id)}
                            type={GeneralCard.TYPE.OBBLIGATORY}
                          />
                        </CardContainer>
                      ))) : (<></>)}
                  </ResultContainer>
                </Container>
              ) : (<NoResult />)
            }
          </>
        )}
        {(activeTab.value === 'completed')
        && (
          <>
            {(course.filter(filter => filter.status === 'completed') || []).length > 0 ? (
              <Container className="p-0">
                {/*<Title><Arial type="sectionTitle">Formazione obbligatoria completati</Arial></Title>*/}
                <ResultContainer>
                  {(course.filter(filter => filter.status === 'completed') || []).length > 0 && (course.filter(filter => filter.status === 'completed').map((obbligatory) => (
                    <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                      <GeneralCard
                        item={obbligatory}
                        onClickCard={() => handleOnClickCard(obbligatory)}
                        onClickFavorite={() => setAsFavoriteCompulsaryTraining(obbligatory.id)}
                        colorTag={getTagColor(categories, obbligatory.category_id)}
                        type={GeneralCard.TYPE.OBBLIGATORY}
                      />
                    </CardContainer>
                  )))}
                </ResultContainer>
              </Container>
            ) : (<NoResult />)
}
          </>
        )}
      </CompulsaryTrainingContainer>
    </>
  );
};

CompulsaryTraining.propTypes = {
  // MediaQueries
  mediaIsTablet: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  courses: PropTypes.array,
  categories: PropTypes.array,
  selected_filters: PropTypes.object,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getCompulsaryTraining: PropTypes.func.isRequired,
  setSelectedCompulsaryTraining: PropTypes.func.isRequired,
  setAsFavoriteCompulsaryTraining: PropTypes.func.isRequired,
  setSelectedFitlersCompulsaryTraining: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { compulsary_training: { courses = [], filters: { categories = [] } = {}, total_items }, selected_filters } = state.compulsarytraining;
    const { vocabulary } = state.app;
    return {
      courses,
      selected_filters,
      total_items,
      categories,
      vocabulary
    };
  },
  dispatch => ({
    setSelectedCompulsaryTraining: (selected) => dispatch({ type: SET_SELECTED_COMPULSARY_TRAINING, selected }),
    setSelectedFitlersCompulsaryTraining: (filters) => dispatch({ type: SET_SELECTED_FILTERS_COMPULSARY_TRAINING, filters }),
    getCompulsaryTraining: (filters, subset = false) => dispatch({
      type: COMPULSARY_TRAINING_LIST_GET._REQUEST, filters, itemPerPage: 12, subset
    }),
    setAsFavoriteCompulsaryTraining: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(CompulsaryTraining));
