import styled from 'styled-components';

export const ScrollerContainer = styled.div`
  background-color: ${props => props.theme.colors.biscuit50};
  color: ${props => props.theme.colors.primaryColor};
  /* border-top: 5px solid ${props => props.theme.colors.primaryColor}; */
  height: 80px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fontset.arial};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.22;
  text-transform: uppercase;

  > div {
    div,
    span {
      cursor: pointer;
      transition: ${props => props.theme.constants.transitionBase};
    }
    &:hover {
      div,
      span {
        color: ${props =>
          props.theme.mixin.rgba(props.theme.colors.primaryColor, 0.5)};
      }
    }
  }
`;

export const Text = styled.label`
  > div {
    display: inline;
  }
`;

export const Icon = styled.span`
  margin-left: 5px;
  font-size: 16px;
  color: primaryColor;
`;
