import styled from 'styled-components';
import { Col } from '../../gridSystem';

export const EditorialImageLeftContainer = styled.div`
    padding: 50px 0;
    position: relative;
    &:before {
        content: '';
        background-color: ${props => props.theme.colors.black05};
        width: 100vw;
        position: absolute;
        height: 100%;
        left: 50%;
        top: 0;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    @media ${props => props.theme.device.tablet} {
        padding: 50px 10px;
    }
    
    ul {
      list-style: initial;
    }
`;

export const ColumnImage = styled(Col)`
    padding-right: 60px;
    @media ${props => props.theme.device.tablet} {
        padding-right: 20px;
        padding-bottom: 20px;
    }
`;

export const Columntext = styled(Col)`
    justify-content: center;
    display: flex;
    flex-direction: column;
    > div {
        &:first-child {
            margin-bottom: 20px;
        }
    }
`;

export const Image = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 270px;
    position: relative;
    transition: ${props => props.theme.constants.transitionBase};
    cursor: ${props => (props.withVideo ? 'pointer' : '')};

    &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        transform: ${props => (props.withVideo ? 'scale(1.03)' : '')}
    }
`;

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.3)};
`;

export const IconVideo = styled.div`
    font-size: 60px;
    cursor: pointer;
`;
