import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button';
import {
  QuestionBoxContainer, ButtonContainer, QuestionContainer, Icon, VoidIcon, IconContainer, QuestionWrap
} from './style';
import { Container } from '../../gridSystem';
import Arial from '../../typography/arial';
import { isValidForm } from '../../../utils/form';


const QuestionBox = ({
  question, form, handleOnSubmit, showOtherQuestions
}) => {
  const [actualForm, setActualForm] = useState({});
  const [actualQuestions, setActualQuestions] = useState([]);

  useEffect(() => {
    setActualForm(form);
  }, [form]);

  useEffect(() => {
    setActualQuestions(question);
  }, [question]);

  return (
    <QuestionBoxContainer>
      <Container>
        <QuestionContainer>
          <IconContainer>
            {(actualQuestions || []).map((item, i) => (
              <QuestionWrap>
                {actualForm[item]?.valid && actualForm[item]?.value ? <Icon className="icon-completato" /> : <VoidIcon />}
                <Arial type="description14">{`Domanda ${i + 1}`}</Arial>
              </QuestionWrap>
            ))}
          </IconContainer>
        </QuestionContainer>

        <ButtonContainer>
          <Button
            type={Button.TYPE.PRIMARY}
            btnText="Invia Survey"
            height={40}
            size={340}
            onClick={handleOnSubmit}
            disabled={showOtherQuestions ? !isValidForm(actualForm) : !actualForm[question[0]]?.value}
          />
        </ButtonContainer>
      </Container>
    </QuestionBoxContainer>
  );
};

QuestionBox.propTypes = {
  question: PropTypes.array,
  form: PropTypes.object,
  handleOnSubmit: PropTypes.func,
  showOtherQuestions: PropTypes.func
};
export default QuestionBox;
