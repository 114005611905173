import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getCollectionMock, getCoursesMock, getCoursesMock2, getLessonMOCK, collectionNodeActionMock,
  getCourseMock, getCourseContentMock, getCompulsaryTrainingCommentsMock, setCompulsaryTrainingCommentsMock
} from './mock';

export default {
  getCollections: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock(Math.min(24, filters.take));
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getCollection: (filters) => {
    if (shouldUseMock()) {
      return getCollectionMock(filters);
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getCourses: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getCourse: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock2(filters);
    }
    return apiCall(`${baseUrl}${extendUrl}collection${parseQueryParams(filters)}`, 'GET');
  },
  getLessons: (filters) => {
    if (shouldUseMock()) {
      return getLessonMOCK();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/content${parseQueryParams(filters)}`, 'GET');
  },
  getCollectionList: (filters) => {
    if (shouldUseMock()) {
      return getCourseMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getCollectionCourses: (filters) => {
    if (shouldUseMock()) {
      return getCourseContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/content${parseQueryParams(filters)}`, 'GET');
  },
  collectionNodeAction: (payload) => {
    if (shouldUseMock()) {
      return collectionNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getCollectionComments: ({ id }) => {
    if (shouldUseMock()) {
      return getCompulsaryTrainingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setCollectionComments: (body) => {
    if (shouldUseMock()) {
      return setCompulsaryTrainingCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  }
};
