import {
  delay, takeLatest, put, call, select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  CERTIFICATION_COURSE_GET,
  CERTIFICATION_TABLE_CONTENTS_GET
} from '../actions/certification';

import certification from '../../api/certification';
import { calcPagination } from '../../../utils/pagination';

function* tableCertificationListGet({ itemPerPage = 10, filters = {}, newSet = false }) {
  yield delay(500);

  try {
    yield put({ type: LOADER_OPEN });

    const { total_items: totalCount, count, from } = yield select(state => state.certification.certification_table_contents || {});
    const pagination = newSet ? calcPagination({}, itemPerPage) : calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      filters = {
        ...pagination,
        title: filters.search,
        showExpired: filters.expired ? 1 : 0
      };

      const { data: { data: response } = {} } = yield call(certification.getCertificationTableContents, filters);
      yield put({ type: CERTIFICATION_TABLE_CONTENTS_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > certification > tableCertificationListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* tableCertificationListGetWatch() {
  yield takeLatest(CERTIFICATION_TABLE_CONTENTS_GET._REQUEST, tableCertificationListGet);
}

function* getCourseCertificate({ courseId }) {
  try {
    yield put({ type: LOADER_OPEN });

    if (!courseId) return;

    const filters = {
      courseid: courseId
    };

    const { data: { data: response } = {} } = yield call(certification.getCertificationTableContents, filters);
    yield put({ type: CERTIFICATION_COURSE_GET._SUCCESS, response });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > certification > getCourseCertificate', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCourseCertificateWatch() {
  yield takeLatest(CERTIFICATION_COURSE_GET._REQUEST, getCourseCertificate);
}

export default [
  tableCertificationListGetWatch(),
  getCourseCertificateWatch()
];
