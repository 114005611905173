import styled from 'styled-components';

export const CourseContainer = styled.div.attrs(() => ({
  className: 'recent-course-item'
}))`
    position: relative;
    padding: 15px 0px;
    margin: 0px 20px;
    border-top: 1px solid ${props => props.theme.colors.hazeGray};
`;


export const Title = styled.div`
  color: ${props => props.theme.colors.white};
  margin-bottom: 15px;
  width: 80%;

  @media ${props => props.theme.device.mobileL} {
    width: 60%;
  }
`;

export const Date = styled.div`
  color: ${props => props.theme.colors.greyMedium};
  margin-bottom: 10px;
`;

export const Duration = styled.div`
  color: ${props => props.theme.colors.greyMedium};

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > div {
    margin-right: 6px;
  }
`;

export const CourseStatusContainer = styled.div.attrs(() => ({
  className: 'course-status'
}))`
  position: absolute;
  top: 15px;
  right: 0px;
`;

export const SpanEmpty = styled.div`
  @media ${props => props.theme.device.mobileL} {
    height: 21px;
  }
`;

export const Span = styled.div.attrs(({
  customClass
}) => ({
  className: `${customClass}`
}))``;
