import {
  takeLatest, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { AGENDA_GET, APPOINTMENT_DELETE, APPOINTMENT_INSERT, APPOINTMENT_RETRIEVE } from '../actions/agenda';
import agenda from '../../api/agenda';

function* getAgenda({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(agenda.getAgenda, filters);
    yield put({ type: AGENDA_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: AGENDA_GET._ERROR, error });
    console.log('sagas > agenda > getAgenda', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getAgendaWatch() {
  yield takeLatest(AGENDA_GET._REQUEST, getAgenda);
}

function* insertAppointment({ params }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(agenda.insertAppointment, {...params.insert, body: ''});
    yield put({ type: APPOINTMENT_INSERT._SUCCESS, response: { id: response.appointment_id, ...params.insert } });
    yield put({ type: APPOINTMENT_RETRIEVE._REQUEST, params: params.retrieve });
  } catch (error) {
    yield put({ type: APPOINTMENT_INSERT._ERROR, error });
    console.log('sagas > agenda > insertAppointment', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* insertAppointmentWatch() {
  yield takeLatest(APPOINTMENT_INSERT._REQUEST, insertAppointment);
}

function* deleteAppointment({ params }) {
  try {
    const { data: { data: response } = {} } = yield call(agenda.deleteAppointment, params.delete);
    yield put({ type: APPOINTMENT_DELETE._SUCCESS, response });
    yield put({ type: APPOINTMENT_RETRIEVE._REQUEST, params: params.retrieve });
  } catch (error) {
    yield put({ type: APPOINTMENT_DELETE._ERROR, error });
    console.log('sagas > agenda > deleteAppointment', error);
  }
}

function* deleteAppointmentWatch() {
  yield takeLatest(APPOINTMENT_DELETE._REQUEST, deleteAppointment);
}


function* retrieveAppointment({ params }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(agenda.retrieveAppointment, params);
    yield put({ type: APPOINTMENT_RETRIEVE._SUCCESS, response });
  } catch (error) {
    yield put({ type: APPOINTMENT_RETRIEVE._ERROR, error });
    console.log('sagas > agenda > retrieveAppointment', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* retrieveAppointmentWatch() {
  yield takeLatest(APPOINTMENT_RETRIEVE._REQUEST, retrieveAppointment);
}

export default [
  getAgendaWatch(),
  insertAppointmentWatch(),
  deleteAppointmentWatch(),
  retrieveAppointmentWatch()
];
