import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  MODAL_OPEN,
  SIGNUP_FORM_GET,
  SIGNUP_FORM_SUBMIT
} from '../../../redux/actions';
import {
  FlexWrapper,
  InputWrapper,
  PrivacyWrapper,
  QuestionWrapper,
  SignupContainer
} from './style';
import RadioButton from '../../../../ui/atoms/RadioButton';
import {
  Button,
  Checkbox,
  CustomInput,
  CustomSelect
} from '../../../../ui/atoms';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import { ButtonsWrapper } from '../style';
import comuni from '../../../../ui/assets/json/comuni_230101.json';
import ContractModal from '../../../../ui/components/Modals/ContractModal';

const getTypeByAnswerTypeId = ({
  question_type,
  answer_type,
  answer_style,
  answer_api
}) => {
  //domanda a risposta singola
  if (question_type === '1') {
    if (answer_type === '1') {
      if (answer_style === '3' && answer_api === 'regioni') {
        return 'cities';
      }
      return 'radio';
    }
    if (answer_type === '2') {
      if (answer_style === '2' || answer_style === '5') return 'radio_open';
      return 'open';
    }
  }
  // domanda a risposta multipla
  if (question_type === '2') {
    if (answer_type === '1') return 'checkbox';
    if (answer_type === '2') return 'checkbox_open';
  }
  return 'open';
};

const getInputTypeByAnswerStyle = style => {
  if (style === '4' || style === '5') return 'number';
  return 'text';
};

const SignupForm = ({
  vocabulary,
  getForm,
  questionList,
  goBack,
  formSubmit,
  path,
  contract,
  contract_title,
  contractModal
}) => {
  const [form, setForm] = useState([]);
  const [givenAnswers, setGivenAnswers] = useState({});
  const [requiredAnswers, setRequiredAnswers] = useState(0);
  const [counter, setCounter] = useState(0);
  const [checkPrivacy, setCheckPrivacy] = useState(false);

  useEffect(() => {
    if (path) getForm(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (questionList?.length > 0) {
      setForm(questionList);
      setCounter(counter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionList]);

  const handleOnChange = ({
    qid,
    aid,
    text,
    parents,
    type = 'open',
    required
  }) => {
    const newAnswers = { ...givenAnswers };
    if ((type === 'cities' || type === 'open') && !text) {
      newAnswers[qid] = {
        ids: [],
        text,
        parents,
        required
      };
    } else if (type === 'checkbox' || type === 'checkbox_open') {
      if (!newAnswers[qid]?.ids) {
        newAnswers[qid] = {
          ids: [aid],
          parents,
          required,
          answers: {}
        };
      } else {
        const aidIndex = newAnswers[qid].ids.findIndex(id => id === aid);
        if (aidIndex < 0) newAnswers[qid].ids.push(aid);
        else if (type === 'checkbox' || text === '') {
          newAnswers[qid].ids.splice(aidIndex, 1);
        }
      }
      newAnswers[qid].answers[aid] = text;
    } else {
      newAnswers[qid] = {
        ids: [aid],
        text,
        parents,
        required
      };
    }
    // if radioAnswer is changing, check if it is parent of some other question,
    // if so, delete the children answers
    if (type === 'radio' || type === 'radio_open') {
      Object.keys(newAnswers).forEach(k => {
        if (newAnswers[k]?.parents?.includes(qid)) delete newAnswers[k];
      });
    }
    // update given answers
    setGivenAnswers(newAnswers);
    setCounter(counter + 1);
  };

  const inputIsDisabled = ({ qid, aid, answer_text }) => {
    if (!answer_text) return false;
    if (givenAnswers[qid]?.ids?.includes(aid)) return false;
    return true;
  };

  const getGivenanswerIds = aid => {
    const allIds = [];
    Object.values(givenAnswers).forEach(ga => {
      allIds.push(...ga?.ids);
    });
    if (allIds.includes(aid)) return true;
    return false;
  };

  const renderQuestions = (questions, parents = []) => {
    if (questions?.length <= 0) return null;
    return (questions || []).map(
      ({
        question_id: qid,
        question_text: qtext,
        answers,
        question_type,
        question_mandatory: required,
        question_attention: warning
      }) => (
        <div key={`question-${qid}`}>
          <QuestionWrapper key={`question-${qid}`}>
            <p className={required ? 'required' : ''}>{qtext}</p>
            {warning && <p className="warning">{warning}</p>}
            {(answers || []).map(
              ({
                answer_id,
                answer_text,
                answer_type,
                answer_style,
                answer_api
              }) => {
                const type = getTypeByAnswerTypeId({
                  question_type,
                  answer_type,
                  answer_style,
                  answer_api
                });
                let children;
                switch (type) {
                  case 'radio':
                    children = (
                      <RadioButton
                        name={qid}
                        label={answer_text}
                        onChange={() => handleOnChange({
                          qid,
                          aid: answer_id,
                          parents,
                          type,
                          required
                        })
                        }
                      />
                    );
                    break;
                  case 'checkbox':
                    children = (
                      <Checkbox
                        name={qid}
                        label={answer_text}
                        onChange={() => handleOnChange({
                          qid,
                          aid: answer_id,
                          parents,
                          type,
                          required
                        })
                        }
                      />
                    );
                    break;
                  case 'cities':
                    children = (
                      <InputWrapper>
                        <CustomSelect
                          placeholder="Seleziona la città"
                          isSearchType
                          onChange={opt => handleOnChange({
                            qid,
                            aid: answer_id,
                            text: opt?.label,
                            parents,
                            type,
                            required
                          })
                          }
                          options={comuni}
                          className="signup-select"
                          filterConfig={{ matchFrom: 'start' }}
                        />
                      </InputWrapper>
                    );
                    break;

                  case 'radio_open':
                    children = (
                      <FlexWrapper>
                        <RadioButton
                          name={qid}
                          label={answer_text}
                          onChange={() => handleOnChange({
                            qid,
                            aid: answer_id,
                            parents,
                            type,
                            required
                          })
                          }
                        />
                        <CustomInput
                          onChange={value => handleOnChange({
                            qid,
                            aid: answer_id,
                            text: value,
                            parents,
                            required
                          })
                          }
                          value={
                            givenAnswers[qid]?.id === answer_id && !answer_text
                              ? givenAnswers[qid]?.text
                              : ''
                          }
                          disabled={inputIsDisabled({
                            qid,
                            aid: answer_id,
                            answer_text
                          })}
                          type={getInputTypeByAnswerStyle(answer_style)}
                        />
                      </FlexWrapper>
                    );
                    break;

                  case 'checkbox_open':
                    children = (
                      <FlexWrapper>
                        <label>{answer_text}</label>
                        <CustomInput
                          onChange={value => handleOnChange({
                            qid,
                            aid: answer_id,
                            text: value,
                            parents,
                            type,
                            required
                          })
                          }
                          value={givenAnswers[qid]?.text ?? ''}
                          type={getInputTypeByAnswerStyle(answer_style)}
                        />
                      </FlexWrapper>
                    );
                    break;

                  case 'open':
                  default:
                    children = (
                      <InputWrapper>
                        <CustomInput
                          onChange={value => handleOnChange({
                            qid,
                            aid: answer_id,
                            text: value,
                            parents,
                            required
                          })
                          }
                          value={
                            givenAnswers[qid]?.id === answer_id && !answer_text
                              ? givenAnswers[qid]?.text
                              : ''
                          }
                          type={getInputTypeByAnswerStyle(answer_style)}
                        />
                      </InputWrapper>
                    );
                    break;
                }

                return <div key={`ans-${qid}-${answer_id}`}>{children}</div>;
              }
            )}
          </QuestionWrapper>
          {(answers || []).map(a => {
            if (getGivenanswerIds(a?.answer_id)) {
              return renderQuestions(a?.questions, [...parents, qid]);
            }
            return null;
          })}
        </div>
      )
    );
  };

  useEffect(() => {
    const required = document.querySelectorAll('.required')?.length;
    setRequiredAnswers(required);
  }, [counter]);

  const sendAnswer = () => {
    const formattedAnswers = [];
    Object.keys(givenAnswers).forEach(k => {
      (givenAnswers[k].ids || []).forEach(aid => {
        const textAnswer = givenAnswers[k]?.answers
          ? givenAnswers[k]?.answers[aid]
          : givenAnswers[k]?.text;
        if (textAnswer) {
          formattedAnswers.push({
            question_pid: Number(k),
            answer_pid: Number(aid),
            answer_response: textAnswer
          });
        } else {
          formattedAnswers.push({
            question_pid: Number(k),
            answer_pid: Number(aid),
            answer_response: ''
          });
        }
      });
    });
    formSubmit(formattedAnswers);
  };

  const btnDisabled = () => {
    const givenRequired = Object.values(givenAnswers).filter(
      ga => ga?.required && ga?.ids?.length > 0
    );
    // -1 is required because privacy policy is checked in Button component

    return givenRequired?.length < requiredAnswers - 1;
  };

  return (
    <SignupContainer counter={counter}>
      {form?.length > 0 ? renderQuestions(form) : null}
      {contract && (
        <PrivacyWrapper>
          <Checkbox
            label={(
              <span className="required">
                Ho preso visione della{' '}
                <span
                  onClick={e => {
                    e.preventDefault();
                    contractModal();
                  }}
                  className="link"
                >
                  Privacy Policy
                </span>
              </span>
            )}
            onChange={() => setCheckPrivacy(!checkPrivacy)}
          />
        </PrivacyWrapper>
      )}
      <ButtonsWrapper>
        <Button
          btnText={getValueVocabulary(vocabulary.action_back, 'action_back')}
          type={Button.TYPE.SECONDARY}
          onClick={goBack}
        />
        <Button
          btnText={getValueVocabulary(
            vocabulary.action_primary,
            'action_primary'
          )}
          onClick={sendAnswer}
          disabled={btnDisabled() || !checkPrivacy}
        />
      </ButtonsWrapper>
      <ContractModal contract={contract} contract_title={contract_title} />
    </SignupContainer>
  );
};

SignupForm.propTypes = {
  goBack: PropTypes.func,
  vocabulary: PropTypes.object,
  questionList: PropTypes.array,
  getForm: PropTypes.func,
  formSubmit: PropTypes.func,
  path: PropTypes.string,
  contract: PropTypes.string,
  contract_title: PropTypes.string,
  contractModal: PropTypes.func
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const {
      signupForm: { question_list: questionList, contract, contract_title },
      credentials: { path }
    } = state.app.signup;
    return {
      vocabulary,
      questionList,
      path,
      contract,
      contract_title
    };
  },
  dispatch => ({
    getForm: path => dispatch({ type: SIGNUP_FORM_GET._REQUEST, path }),
    formSubmit: question_list => dispatch({ type: SIGNUP_FORM_SUBMIT._REQUEST, question_list }),
    contractModal: () => dispatch({ type: MODAL_OPEN, id: ContractModal.id })
  })
)(SignupForm);
