import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CommentContainer, CommentsContainer, CommentsWrapper,
  CommentWrapper, TextArea, Timestamp, Text, CommentBody,
  CommentInfo, CommentsHeader
} from './style';

import Gill from '../../typography/gill';
import formatTimestamp from '../../../utils/formatTimestamp';
import { Button, CircleImage } from '../../atoms';

const Comments = ({
  list = [], onSubmit
}) => {
  const [newComment, setNewComment] = useState('');

  const handleOnchangeNewComment = ({ target: { value } }) => {
    setNewComment(value);
  };

  const handleOnSubmit = ({ key } = {}) => {
    const submit = !key || key === 'Enter';
    if (submit && onSubmit) {
      onSubmit(newComment);
      setNewComment('');
    }
  };

  return (
    <CommentsContainer>
      <CommentsHeader>
        <Gill type="newsDate">{list?.length} commenti</Gill>
        <CommentsWrapper>
          <TextArea onKeyPress={handleOnSubmit} onChange={handleOnchangeNewComment} value={newComment.replace(/\r?\n|\r/, '')} placeholder="Scrivi un commento" />
          {newComment
            && <Button btnText="Invia" onClick={handleOnSubmit} />
          }
        </CommentsWrapper>
      </CommentsHeader>
      <CommentContainer>
        {list.map((item = {}, index) => (
          <CommentWrapper key={`comments${index}`}>
            <CircleImage src={item?.user_picture} name={`${item?.field_name} ${item?.field_surname}`} size={40} />
            <CommentBody>
              <CommentInfo>
                <Gill type="newsTitle"><span dangerouslySetInnerHTML={{ __html: `${item?.field_name} ${item?.field_surname}` }} /></Gill>
                <Timestamp>
                  <Gill type="newsDate">{formatTimestamp(item?.created)}</Gill>
                </Timestamp>
              </CommentInfo>
              <Text>
                <Gill type="guidebookCategory"><span dangerouslySetInnerHTML={{ __html: item?.comment_body }} /></Gill>
              </Text>
            </CommentBody>
          </CommentWrapper>
        ))}
      </CommentContainer>
    </CommentsContainer>
  );
};

Comments.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired
};

export default Comments;
