import produce from 'immer';

import {
  RUBRIC_CHANGE_COMMENTS_GET,
  RUBRIC_CHANGE_LIST_GET,
  RUBRIC_CHANGE_PILLS_GET,
  SELECTED_RUBRIC_CHANGE_GET,
  SET_SELECTED_RUBRIC_CHANGE
} from '../actions/rubricchange';

const initialState = {
  rubricChange: {},
  selectedRubricChange: {},
  pills: [],
  comments: []
};

const rubricChangeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case RUBRIC_CHANGE_LIST_GET._SUCCESS: {
      const { response } = action;
      const { from, items } = response;
      const list = (!from || from <= 0) ? items : [...state.rubricChange.items, ...items];
      draft.rubricChange = {
        ...response,
        items: list
      };
      break;
    }

    case RUBRIC_CHANGE_PILLS_GET._SUCCESS: {
      const { response } = action;
      const { pill_items } = response[0];
      draft.pills = pill_items;
      break;
    }

    case SELECTED_RUBRIC_CHANGE_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedRubricChange = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_RUBRIC_CHANGE: {
      const { selected } = action;
      draft.selectedRubricChange = selected;
      break;
    }

    case RUBRIC_CHANGE_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default rubricChangeReducer;
