import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  CompulsaryTrainingContainer, CardContainer, ObbligatoryFilterContainer, ObbligatoryCustomSelect,
  ObbligatoryFilterLabel, ObbligatoryCustomSelectContainer, ObbligatoryFilterPillsContainer
} from './style';
import {
  ResultContainer
} from '../News/style';
import routes from '../../../routes';
import {
  CustomSelect, FilterPill, GeneralCard, Tabs, NoResult
} from '../../../ui/atoms';
import Arial from '../../../ui/typography/arial';
import { getTagColor } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { Background } from '../Procedures/style';
import ProceduresDefaultCover from '../../../ui/assets/img/building.jpg';
import {TabsContainer, HeaderContainer, SeeMore} from '../LifeISP/style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {
  PROCESSES_PROCEDURES_LIST_GET, PROCESSES_PROCEDURES_NODE_ACTION, SET_FILTERS_PROCESSES_PROCEDURES,
  SET_SELECTED_PROCESSES_PROCEDURES
} from '../../redux/actions/processesprocedures';
import theme from '../../../ui/theme';

const ProcessesProcedures = ({
 processes_procedures, getProcessesProcedures,
 setSelectedProcessesProcedures, setAsFavoriteProcessesProcedures,
 pushUrl, vocabulary, heroImage, mediaIsTablet, mediaIsLaptop,
 setSelectedFilters, selected_filters
}) => {
  const [selectedProcedure, setSelectedProcedures] = useState();
  const [parentFilters, setParentFilters] = useState([]);
  const [childFilters, setChildFilters] = useState([]);

  useEffect(() => {
    if (selectedProcedure?.filters?.categories?.length) {
      setParentFilters((selectedProcedure?.filters?.categories || []).map(category => ({
        ...category, value: category.id, label: category.name, isParent: true
      })).filter(category => !category.parent));
    }
  }, [selectedProcedure]);

  const handleOnClickCard = (item) => {
    setSelectedProcessesProcedures(item);
    pushUrl(`${routes.processesProcedures.path}/${item.id}`);
  };

  const defaultTabs = [
    { label: 'Tutti', value: 'all' },
    { label: 'Suggeriti per te', value: 'suggested' }
  ];

  const [tabs, setTabs] = useState([...defaultTabs]);
  const [activeTab, setActiveTab] = useState(selected_filters?.activeTab || defaultTabs[1]);
  const [selectedParentFilter, setSelectedParentFilter] = useState(selected_filters?.selectedParentFilter);
  const [selectedChildFilter, setSelectedChildFilter] = useState(selected_filters?.selectedChildFilter || []);
  const [getProcessesProceduresFilter, setGetProcessesProceduresFilter] = useState({});

  const onChangeTab = (tab) => {
    setSelectedParentFilter(null);
    setSelectedChildFilter([]);
    setChildFilters([]);
    setActiveTab(tab);
    setGetProcessesProceduresFilter({});
    setSelectedFilters({ activeTab: tab, reload: true });
  };

  useEffect(() => {
    if (selectedParentFilter) {
      const filter = { category_id: [...childFilters, selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';'), company_cluster: activeTab.value };
      selectedChildFilter.length > 0 ? setSelectedFilters({ activeTab, selectedParentFilter, selectedChildFilter, reload: false }) : setSelectedFilters({ activeTab, selectedParentFilter, reload: false });
      setGetProcessesProceduresFilter(filter);
    } else {
      setChildFilters([]);
      setSelectedFilters({ activeTab, reload: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParentFilter]);

  useEffect(() => {
    if(selectedParentFilter){
      const childFiltersList = (processes_procedures?.not_suggested?.filters?.categories || processes_procedures?.suggested?.filters?.categories || processes_procedures?.all?.filters?.categories || [])
          .map(category => ({
            ...category, value: category.id, label: category.name, isParent: false
          }))
          .filter(category => Number(category?.parent) === Number(selectedParentFilter?.value));

      if(childFiltersList.length > 0){
        if(selectedChildFilter.length > 0){
          const filter = { category_id: [...selectedChildFilter, selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';'), company_cluster: activeTab.value };
          setGetProcessesProceduresFilter(filter);
          setSelectedFilters({ activeTab, selectedParentFilter, selectedChildFilter, reload: false });
          getProcessesProcedures(filter);
        } else {
          const filter = { category_id: [...childFiltersList, selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';'), company_cluster: activeTab.value };
          setGetProcessesProceduresFilter(filter);
          setSelectedFilters({ activeTab, selectedParentFilter, reload: false });
          setChildFilters(childFiltersList);
          getProcessesProcedures(filter);
        }
      } else {
        const child = selectedChildFilter?.length > 0 ? selectedChildFilter : childFiltersList;
        const filter = { category_id: [...child, selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';'), company_cluster: activeTab.value };
        setGetProcessesProceduresFilter(filter);
        setSelectedFilters({ activeTab, selectedParentFilter, selectedChildFilter, reload: false });
        setChildFilters(childFiltersList);
        getProcessesProcedures(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChildFilter]);

  const onChangeParentFilter = (newFilter) => {
    setSelectedParentFilter(newFilter);
    setSelectedChildFilter([]);
    if(!newFilter){
      setGetProcessesProceduresFilter({});
      getProcessesProcedures({company_cluster: activeTab.value});
    }
  };

  const onChangeChildFilter = (newFilter) => {
    setSelectedChildFilter(newFilter);
  };

  const removeFilter = (item) => {
    const newFilters = (selectedChildFilter || []).filter(filter => filter.value !== item);
    setSelectedChildFilter(newFilters);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const others = processes_procedures?.not_suggested?.courses || [];

    if(others.length > 0) {
      setTabs([
        ...defaultTabs,
        { label: 'Guarda anche', value: 'not_suggested' }
      ]);
    }
    const childFilters = (selectedProcedure?.filters?.categories || [])
        .map(category => ({
          ...category, value: category.id, label: category.name, isParent: false
        }))
        .filter(category => Number(category?.parent) === Number(selectedParentFilter?.value));
    setChildFilters(childFilters);
    setSelectedProcedures(processes_procedures[activeTab.value] || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes_procedures]);

  const onClickSeeMore = () => {
    getProcessesProcedures({...getProcessesProceduresFilter, company_cluster: activeTab.value}, false);
  };

  useEffect(() => {
    if(selected_filters?.reload){
      if(activeTab.value === 'all'){
        getProcessesProcedures({});
      } else {
        getProcessesProcedures({company_cluster: 'suggested'});
        getProcessesProcedures({company_cluster: 'not_suggested'});
      }
      setSelectedFilters({activeTab: selected_filters?.activeTab || defaultTabs[1], reload: false});
      setSelectedProcedures(processes_procedures[selected_filters?.activeTab?.value] || {});
    } else {
      let filter = {};
      const childFiltersList = (processes_procedures?.all?.filters?.categories || [])
          .map(category => ({
            ...category, value: category.id, label: category.name, isParent: false
          }))
          .filter(category => Number(category?.parent) === Number(selectedParentFilter?.value));
      setChildFilters(childFiltersList);
      if (selectedParentFilter && selectedChildFilter.length > 0){
        filter = { category_id: [...(selectedChildFilter || []), selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';')};
      } else {
        filter = { category_id: [...(childFiltersList || []), selectedParentFilter].map(filter => filter?.value).toString().replace(/,/g, ';')};
      }
      setGetProcessesProceduresFilter(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeTab]);

  return (
      <>
        <HeaderContainer>
          <Background img={heroImage || ProceduresDefaultCover}/>
        </HeaderContainer>
        <CompulsaryTrainingContainer>
          <Container className="p-0">
            <TabsContainer>
              <Tabs tabs={[...tabs]} onChange={onChangeTab} selected={activeTab} color={theme().colors.primaryRed}/>
            </TabsContainer>
            <>
              <ObbligatoryFilterLabel><Arial type="filterLabel" vocabulary="obbligatoryFilterLabel">{getValueVocabulary(vocabulary.obbligatoryFilterLabel, 'obbligatoryFilterLabel')}</Arial></ObbligatoryFilterLabel>
              <ObbligatoryFilterContainer>
                <ObbligatoryCustomSelectContainer>
                  <ObbligatoryCustomSelect>
                    <CustomSelect options={[...parentFilters]} onChange={newValue => onChangeParentFilter(newValue)} selected={selectedParentFilter} placeholder="Tutti" isClearable />
                  </ObbligatoryCustomSelect>
                  {childFilters.length > 0
                  && (
                      <ObbligatoryCustomSelect>
                        <CustomSelect options={[...childFilters]} onChange={newValue => onChangeChildFilter(newValue || [])} selected={selectedChildFilter} placeholder="Seleziona" isMulti />
                      </ObbligatoryCustomSelect>
                  )}
                </ObbligatoryCustomSelectContainer>
                <ObbligatoryFilterPillsContainer>
                  {selectedChildFilter.length > 0 && (selectedChildFilter || []).filter(filter => !filter.isParent).map(filter => <FilterPill key={`filter-${filter.value}`} onRemove={removeFilter} value={filter.value} label={filter.label} />)}
                </ObbligatoryFilterPillsContainer>
              </ObbligatoryFilterContainer>
              <ResultContainer>
                {selectedProcedure?.courses?.length > 0
                    ? selectedProcedure?.courses.map((obbligatory) => (
                        <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={`obbligatory-${obbligatory.id}`}>
                          <GeneralCard
                              item={obbligatory}
                              onClickCard={() => handleOnClickCard(obbligatory)}
                              onClickFavorite={() => setAsFavoriteProcessesProcedures(obbligatory.id)}
                              colorTag={getTagColor(selectedProcedure?.filters?.categories, obbligatory.category_id)}
                              type={GeneralCard.TYPE.COSACAMBIA}
                          />
                        </CardContainer>
                    )) : (<NoResult text='Presto disponibile una selezione di contenuti pensati per te. Nel frattempo, puoi visitare la sezione "Guarda anche" per scoprire altri contenuti sui ruoli BPER' />)}
              </ResultContainer>
              {selectedProcedure?.courses?.length < selectedProcedure?.total_items && <SeeMore onClick={onClickSeeMore}>Mostra altri</SeeMore>}
            </>
          </Container>

        </CompulsaryTrainingContainer>
      </>
  );
};

ProcessesProcedures.propTypes = {
  // MediaQueries
  mediaIsTablet: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  processes_procedures: PropTypes.object,
  selected_filters: PropTypes.object,

  // HOC (connect, dispatch)
  setSelectedProcessesProcedures: PropTypes.func.isRequired,
  getProcessesProcedures: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setAsFavoriteProcessesProcedures: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
    state => {
      const { processes_procedures, selected_filters } = state.processesprocedures;
      const { vocabulary } = state.app;
      return {
        processes_procedures,
        selected_filters,
        vocabulary
      };
    },
    dispatch => ({
      setSelectedProcessesProcedures: (selected) => dispatch({ type: SET_SELECTED_PROCESSES_PROCEDURES, selected }),
      getProcessesProcedures: (filters, subset = true) => dispatch({
        type: PROCESSES_PROCEDURES_LIST_GET._REQUEST, filters, itemPerPage: 12, subset
      }),
      setSelectedFilters: (filters) => dispatch({
        type: SET_FILTERS_PROCESSES_PROCEDURES, filters
      }),
      setAsFavoriteProcessesProcedures: (nid) => dispatch({
        type: PROCESSES_PROCEDURES_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
      }),
      pushUrl: url => dispatch(push(url))
    })
)(withMediaQueries(ProcessesProcedures));
