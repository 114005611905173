import React from 'react';
import PropTypes from 'prop-types';

import {
  GuidebookCardContainer, Guidebook, Date, Title, Description,
  IconAttachment, GuidebookCardContent
} from './style';

import Arial from '../../typography/arial';
import dateFormat from '../../../utils/date-format';

const GuidebookCard = ({
  guidebook: {
    data: {
      title, date, description, file_download, file
    }
  }
}) => {
  const { url } = file_download ? file_download[0] : file ? file[0] : {};

  return (
    <GuidebookCardContainer>
      <Guidebook href={url} target="_blank" download />
      <IconAttachment>
        <span className="icon-right" />
      </IconAttachment>
      <GuidebookCardContent>
        {date && <Date><Arial type="guidebookCardDate">{dateFormat(date, 'news')}</Arial></Date>}
        {title && <Title><Arial type="guidebookCardAttachment">{title}</Arial></Title>}
        {description && <Description><Arial type="guidebookCardText">{description}</Arial></Description>}
      </GuidebookCardContent>
      <IconAttachment position="right" icon="download">
        <span className="icon-download" />
      </IconAttachment>
    </GuidebookCardContainer>
  );
};

GuidebookCard.propTypes = {
  guidebook: PropTypes.object
};

export default GuidebookCard;
