import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getSmarWorkingMock,
  smartWorkingNodeActionMock,
  getSmartWorkingCommentsMock,
  setSmartWorkingCommentsMock,
  getSmartWorkingPillsMock,
  setStatsSmartWorkingPillMock
} from './mock';

export default {
  getSmartWorkingList: (filters) => {
    if (shouldUseMock()) {
      return getSmarWorkingMock();
    }
    return apiCall(`${baseUrl}${extendUrl}pill/collection${parseQueryParams(filters)}`, 'GET');
  },
  getSmartWorkingPills: ({ collectionId }) => {
    if (shouldUseMock()) {
      return getSmartWorkingPillsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}pill/collection/${collectionId}/content`, 'GET');
  },
  smartWorkingNodeAction: (payload) => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getSmartWorkingComments: ({ id }) => {
    if (shouldUseMock()) {
      return getSmartWorkingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setSmartWorkingComments: (body) => {
    if (shouldUseMock()) {
      return setSmartWorkingCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  },
  setStatsSmartWorkingPill: ({ collectionId, pillId, body }) => {
    if (shouldUseMock()) {
      return setStatsSmartWorkingPillMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/stats/${collectionId}/collection/${pillId}/pill`, 'POST', body);
  },
  smartWorkingSharePillCollection: (payload) => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/share`, 'POST', payload);
  }
};
