import React, { Component } from 'react';

import { Menu, Table, TableCard } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import { ClassroomsContainer, Title } from './style';

class Classrooms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = [];

    return (
      <Container>
        <Menu customClass="menu-desktop" />
        <ClassroomsContainer>
          <Title>Aule</Title>
          <Table type="classrooms" data={data} />
          <TableCard type="classrooms" data={data} />
        </ClassroomsContainer>
      </Container>
    );
  }
}

Classrooms.propTypes = {};

export default Classrooms;
