import { all } from 'redux-saga/effects';
import appSagas from './app';
import authSagas from './auth';
import certificationSagas from './certification';
import collectionsSagas from './collections';
import courseSagas from './course';
import homeSagas from './home';
import lessonSagas from './lesson';
import searchSagas from './search';
import statisticsSagas from './statistics';
import userSagas from './user';
import newsSagas from './news';
import guidebookSagas from './guidebook';
import productSagas from './product';
import editorialSagas from './editorial';
import notificationSagas from './notification';
import smartworkingSagas from './smartworking';
import compulsarytrainingSagas from './compulsarytraining';
import favorite from './favorite';
import mostviewed from './mostviewed';
import life_ispSagas from './life_isp';
import rubricchangeSagas from './rubricchange';
import agendaSagas from './agenda';
import tojSagas from './toj';
import surveySagas from './survey';
import whatChangeSagas from './what_change';
import roletrainingSagas from './roletraining';
import processesproceduresSagas from './processesprocedures';
import coachcoacheeSagas from './coachcoachee';
import productsservicesSagas from './productsservices';
import stcAllSagas from './stc_all';
import stcConnectedSagas from './stc_connected';
import stcImprovedSagas from './stc_improved';
import stcCourseSagas from './stc_course';

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...authSagas,
    ...certificationSagas,
    ...collectionsSagas,
    ...courseSagas,
    ...homeSagas,
    ...lessonSagas,
    ...statisticsSagas,
    ...searchSagas,
    ...userSagas,
    ...newsSagas,
    ...guidebookSagas,
    ...productSagas,
    ...editorialSagas,
    ...notificationSagas,
    ...smartworkingSagas,
    ...compulsarytrainingSagas,
    ...favorite,
    ...mostviewed,
    ...life_ispSagas,
    ...rubricchangeSagas,
    ...agendaSagas,
    ...tojSagas,
    ...surveySagas,
    ...whatChangeSagas,
    ...roletrainingSagas,
    ...processesproceduresSagas,
    ...coachcoacheeSagas,
    ...productsservicesSagas,
    ...stcAllSagas,
    ...stcConnectedSagas,
    ...stcImprovedSagas,
    ...stcCourseSagas
  ]);
}
