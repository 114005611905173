import produce from 'immer';
import { AGENDA_GET, APPOINTMENT_DELETE, APPOINTMENT_INSERT, APPOINTMENT_RETRIEVE } from '../actions/agenda';

const initialState = {
  agenda: {},
};

const agendaReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case AGENDA_GET._SUCCESS: {
      const { response } = action;
      draft.agenda = { ...state.agenda, ...response };
      break;
    }
    case APPOINTMENT_INSERT._SUCCESS: {
      const { response } = action;
      draft.agenda.appointments = [
        ...state.agenda?.appointments,
        response
      ];
      break;
    }
    case APPOINTMENT_DELETE._SUCCESS: {
      const { response } = action;
      draft.appointments = {
        ...state.appointments,
        response
      };
      break;
    }
    case APPOINTMENT_RETRIEVE._SUCCESS: {
      const { response } = action;
      draft.agenda.appointments = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default agendaReducer;
