import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import smartworking from '../../api/smartworking';
import { calcPagination } from '../../../utils/pagination';
import {
  SMARTWORKING_LIST_GET, SELECTED_SMARTWORKING_GET, SMARTWORKING_NODE_ACTION,
  SMARTWORKING_COMMENTS_GET, SMARTWORKING_COMMENTS_SET, SMARTWORKING_PILLS_GET,
  SMARTWORKING_PILL_SET_STATS, SMARTWORKING_SHARE_PILL_COLLECTION
} from '../actions/smartworking';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';


function* smartWorkingListGet({ itemPerPage = 24, loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { smartWorking: { count: totalCount, items = [] } = {} } = yield select(state => state.smartworking);
    const pagination = calcPagination({ count: items.length, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const filters = { category_name: 'Smartworking', ...pagination };
      const { data: { data: response } } = yield call(smartworking.getSmartWorkingList, filters);
      yield put({ type: SMARTWORKING_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > smartworking > smartWorkingsGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* smartWorkingListGetWatch() {
  yield takeLatest(SMARTWORKING_LIST_GET._REQUEST, smartWorkingListGet);
}

function* setSelectedSmartWorking({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedSmartWorking } = yield select(state => state.smartworking);
    if (Object.keys(selectedSmartWorking).length <= 0 && pagination && !pagination.isLastPage) {
      const filters = { category_name: 'Smartworking', id, ...pagination };
      const { data: { data: response } } = yield call(smartworking.getSmartWorkingList, filters);
      yield put({ type: SELECTED_SMARTWORKING_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > smartworking > setSelectedSmartWorking', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedSmartWatch() {
  yield takeLatest(SELECTED_SMARTWORKING_GET._REQUEST, setSelectedSmartWorking);
}

function* smartWorkingPillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response = {} } = {} } = yield call(smartworking.getSmartWorkingPills, { collectionId });
      yield put({ type: SMARTWORKING_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > smartworking > smartWorkingPillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* smartWorkingPillsGetWatch() {
  yield takeLatest(SMARTWORKING_PILLS_GET._REQUEST, smartWorkingPillsGet);
}

function* smartWorkingNodeAction({ nid, action_type, node_type = 'pill_collection' }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(smartworking.smartWorkingNodeAction, payload);
      yield put({ type: SMARTWORKING_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: SMARTWORKING_LIST_GET._REQUEST });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selectedSmartWorking } = yield select(state => state.smartworking);
            if (selectedSmartWorking.id) yield put({ type: SMARTWORKING_PILLS_GET._REQUEST, collectionId: selectedSmartWorking.id, loader: false });
          } else {
            yield put({ type: SMARTWORKING_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > smartworking > smartWorkingNodeAction', err);
    }
  }
}

function* smartWorkingNodeActionWatch() {
  yield takeLatest(SMARTWORKING_NODE_ACTION._REQUEST, smartWorkingNodeAction);
}

function* smartWorkingCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(smartworking.getSmartWorkingComments, { id });
      yield put({ type: SMARTWORKING_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
    // eslint-disable-next-line no-console
      console.log('sagas > smartworking > smartWorkingCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* smartWorkingCommentsGetWatch() {
  yield takeLatest(SMARTWORKING_COMMENTS_GET._REQUEST, smartWorkingCommentsGet);
}

function* smartWorkingCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(smartworking.setSmartWorkingComments, payload);
    yield put({ type: SMARTWORKING_COMMENTS_SET._SUCCESS });
    yield put({ type: SMARTWORKING_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > smartworking > smartWorkingCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* smartWorkingCommentsSetWatch() {
  yield takeLatest(SMARTWORKING_COMMENTS_SET._REQUEST, smartWorkingCommentsSet);
}


function* smartWorkingPillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(smartworking.setStatsSmartWorkingPill, params);
    yield put({ type: SMARTWORKING_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_SMARTWORKING_GET._REQUEST, id: collectionId });
    yield put({ type: SMARTWORKING_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > smartworking > smartWorkingPillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* smartWorkingPillStatsWatch() {
  yield takeLatest(SMARTWORKING_PILL_SET_STATS._REQUEST, smartWorkingPillStatsSet);
}

function* smartWorkingSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(smartworking.smartWorkingSharePillCollection, data);
      yield put({ type: SMARTWORKING_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > smartworking > smartWorkingSharePillCollection', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* smartWorkingSharePillCollectionWatch() {
  yield takeLatest(SMARTWORKING_SHARE_PILL_COLLECTION._REQUEST, smartWorkingSharePillCollection);
}

export default [
  smartWorkingListGetWatch(),
  setSelectedSmartWatch(),
  smartWorkingPillsGetWatch(),
  smartWorkingNodeActionWatch(),
  smartWorkingCommentsGetWatch(),
  smartWorkingCommentsSetWatch(),
  smartWorkingPillStatsWatch(),
  smartWorkingSharePillCollectionWatch()
];
