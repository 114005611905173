import React from 'react';
import PropTypes from 'prop-types';

import DefaultCover from '../../assets/img/img-card.jpg';

import {
  VideoCardItemContainer, Image,
  CourseDetails, Title, VideoPlay, Span
} from './style';

import { CourseStatus } from '../../atoms';
import Roboto from '../../typography/roboto';
import { PillInterface } from '../../../app/redux/reducers/home';

const VideoCardItem = ({ onClick, pill: { cover, title, status } }) => (
  <VideoCardItemContainer onClick={onClick}>
    <Image src={cover || DefaultCover} />
    {Boolean(status) && (
      <CourseStatus>
        <CourseStatus state={status} />
      </CourseStatus>
    )}
    <CourseDetails>
      <VideoPlay>
        <Span customClass="icon-play" />
      </VideoPlay>
      <Title><Roboto type="cardTitle">{title}</Roboto></Title>
    </CourseDetails>
  </VideoCardItemContainer>
);

VideoCardItem.propTypes = {
  onClick: PropTypes.func,
  pill: PillInterface.isRequired
};

export default VideoCardItem;
