import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { GET_COACH_COACHEE } from '../actions/coachcoachee';

import coachCoachee from '../../api/coachcoachee';

function* coachCoacheeGet() {
  try {
    const { coach_coachee_list } = yield select(state => state.coachcoachee);
    if (coach_coachee_list.length > 0) {
      yield put({ type: GET_COACH_COACHEE._SUCCESS, coach_coachee: coach_coachee_list });
    } else {
      const { data: { data: coach_coachee = [] } = {} } = yield call(coachCoachee.getCoachCoachee);
      yield put({ type: GET_COACH_COACHEE._SUCCESS, coach_coachee });
    }
  } catch (error) {
    yield put({ type: GET_COACH_COACHEE._ERROR, error });
    console.log('sagas > coachee > searchCoachee', error);
  }
}

function* coachCoacheeGetWatch() {
  yield takeLatest(GET_COACH_COACHEE._REQUEST, coachCoacheeGet);
}


export default [
  coachCoacheeGetWatch()
];
