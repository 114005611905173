import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  CompulsaryTrainingCoursesContainer, CompulsaryTrainingCourseContainer,
  CompulsaryTrainingCourseTitle, CompulsaryTrainingCourseCover, Iframe,
  MainSection, CompulsaryTrainingCourseElementDetails, Tag, TagContainer,
  TagWrapper, VideoContainer, CompulsaryTrainingCourseElementCounter, Frame
} from './style';
import { BackButton } from '../../../ui/atoms';
import routes from '../../../routes';
import {
  Comments, CourseDetailHeader, CourseDetailInfo, CourseDetailPill, GuideboookCard
} from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import {
  COMPULSARY_TRAINING_COMMENTS_GET,
  COMPULSARY_TRAINING_COMMENTS_SET, COMPULSARY_TRAINING_COURSES_GET, COMPULSARY_TRAINING_NODE_ACTION,
  SELECTED_COMPULSARY_TRAINING_GET
} from '../../redux/actions/compulsarytraining';
import { CourseElementCompleted, IconCompleted } from '../Collection/style';
import { getTagColor } from '../../../utils/common';
import { PdfContainer } from '../LifeISPDetails/style';
import { GuidebookItemColumn, GuidebookListColumn, RowGuidebook } from '../Guidebook/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const courseElement = ({
  cover, title, blocked, stats
} = {}, onClick, isActive = false, index) => (
  <CompulsaryTrainingCourseContainer onClick={onClick} active={isActive} blocked={blocked}>
    <CompulsaryTrainingCourseElementCounter>
      {index + 1}
    </CompulsaryTrainingCourseElementCounter>
    <CompulsaryTrainingCourseCover cover={cover} />
    <CompulsaryTrainingCourseElementDetails>
      <CompulsaryTrainingCourseTitle title={title} type="collectionCourseTitle">{title}</CompulsaryTrainingCourseTitle>
    </CompulsaryTrainingCourseElementDetails>
    <CourseElementCompleted>
      {stats?.status === 'completed' && <IconCompleted className="icon-completato" />}
    </CourseElementCompleted>
  </CompulsaryTrainingCourseContainer>
);

const CompulsaryTrainingCourses = ({
  collectionId, scormId, selected_compulsary_training,
  scorms, comments, getSelectedCompulsaryTraining,
  getCompulsaryTrainingCourses, setCompulsaryTrainingComments,
  getCompulsaryTrainingComments, setLike, setDislike, setViews,
  shareModal, pushUrl, requestedURL, isLoadingData, categories, vocabulary
}) => {
  const [scorm, setScorm] = useState({});
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setViews(collectionId);
    getSelectedCompulsaryTraining(collectionId);
    getCompulsaryTrainingCourses({ collectionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, setScormFullscreen]);

  useEffect(() => {
    if (scormId) getCompulsaryTrainingComments(scormId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scormId]);
  useEffect(() => {
    if (!isLoadingData) {
      if ((scorms?.length && !scormId) || (scormId && scorms?.length && !scorms?.find(({ node_scorm_id } = {}) => node_scorm_id?.toString() === scormId?.toString()))) {
        const selectedScorm = scorms?.find(({ stats } = {}) => !(stats.status === 'completed'));
        selectedScorm ? pushUrl(`${routes.compulsarytraining.path}/${collectionId}/${selectedScorm?.node_scorm_id}`) : pushUrl(`${routes.compulsarytraining.path}/${collectionId}/${scorms[0]?.node_scorm_id}`);
        setScorm(selectedScorm || scorms[0]);
      } else if (scorms?.length && scormId) {
        let selectedScorm = scorms?.find(({ node_scorm_id, stats } = {}) => node_scorm_id?.toString() === scormId?.toString());
        selectedScorm = selectedScorm || scorms?.filter(({ stats } = {}) => !(stats.status === 'completed'));
        setScorm(selectedScorm);
      }
    }
    setScormFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scorms, scormId, isLoadingData]);

  //const percentage = ((scorms.filter(item => item.stats.status === 'completed').length / scorms.length) * 100).toFixed(0);

  const openCourse = ({ node_scorm_id, blocked }) => {
    if (!blocked) {
      pushUrl(`${routes.compulsarytraining.path}/${collectionId}/${node_scorm_id}`);
      getCompulsaryTrainingCourses({ collectionId });
    }
  };

  const handleNewCommentOnSubmit = (value) => {
    setCompulsaryTrainingComments({
      id: scorm?.node_scorm_id,
      subject: scorm?.title,
      comment_body: value
    });
  };

  const onClickNext = () => {
    getSelectedCompulsaryTraining(collectionId, true);
    getCompulsaryTrainingCourses({ collectionId });
    pushUrl(`${routes.compulsarytraining.path}/${collectionId}`);
  };

  const windowOpen = (uri) => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.compulsarytraining.path}/${collectionId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  return (
    <CompulsaryTrainingCoursesContainer>
      <Container className="p-0">
        {requestedURL?.pathname === '/integration-agenda' ? (
          <BackButton url={`${routes.integrationagenda.path}${requestedURL?.search}`} />
        ) : (
          <BackButton url={routes.compulsarytraining.path} />
          )}
        <CourseDetailHeader
          title={selected_compulsary_training?.title}
          category={selected_compulsary_training?.category}
          percentage={selected_compulsary_training?.percentage}
          categoryColor={getTagColor(categories, selected_compulsary_training?.category_id)}
          eyeIcon={scorms.length <= 1}
          views={selected_compulsary_training?.views}
          durationPill={selected_compulsary_training?.duration}
        />
        <MainSection>
          <Row>
            <Col sm={12} md={scorms.length > 1 ? 8 : 12} lg={scorms.length > 1 ? 9 : 12}>
              {scorm ? (
                <>
                  <VideoContainer>
                    {scormFullscreen
                      ? (<Frame><Arial type="editorialCardText">Aperto in modalità fullscreen</Arial></Frame>)
                      : (
                        <Iframe
                          // frameHeight={frameHeight}
                          title={scorm?.title}
                          src={scorm?.iframe_uri}
                          id="scorm_frame"
                        />
                      )
                    }
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnSource={scorm?.iframe_uri}
                    btnOnClick={() => windowOpen(scorm?.iframe_uri)}
                    source={scorm}
                    title={scorm?.title}
                    description={scorm?.description}
                    onClickLike={() => setLike(scorm.node_scorm_id)}
                    onClickDislike={() => setDislike(scorm.node_scorm_id)}

                    btnTextNext="Registra completamento"
                    btnSourceNext={scorms.length > 0}
                    btnOnClickNext={onClickNext}
                  />
                  {scorm?.files?.length > 0 && scorm?.stats?.status === 'completed'
                  && (
                    <PdfContainer>
                      <Arial type="newsTitle" vocabulary="pdfScorm">{getValueVocabulary(vocabulary.pdfScorm, 'pdfScorm')}</Arial>
                      <GuidebookListColumn md={12} sm={12} lg={12}>
                        <RowGuidebook>
                          {(scorm?.files || []).map((singleMaterials, i) => (
                            <GuidebookItemColumn key={i}>
                              <GuideboookCard guidebook={singleMaterials} />
                            </GuidebookItemColumn>
                          ))}
                        </RowGuidebook>
                      </GuidebookListColumn>
                    </PdfContainer>
                  )
                  }
                  {scorm?.tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {scorm?.tag.map(t => <Tag key={t.id}><Arial type="tagText">{t.label}</Arial></Tag>)}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments list={comments} onSubmit={handleNewCommentOnSubmit} />
                </>
              ) : null}
            </Col>
            {scorms.length > 1 && (
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={scorms.length}
                durationPill={selected_compulsary_training?.duration}
                views={selected_compulsary_training?.views}
              >
                {scorms.map((c, index) => <React.Fragment key={`scorm-${c.id}`}>{courseElement(c, () => openCourse(c), c.id?.toString() === scormId?.toString(), index)}</React.Fragment>)}
              </CourseDetailPill>
            </Col>
            )}
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </CompulsaryTrainingCoursesContainer>
  );
};

CompulsaryTrainingCourses.propTypes = {
  collectionId: PropTypes.string.isRequired,
  scormId: PropTypes.string,

  // HOC (connect, state)
  selected_compulsary_training: PropTypes.object,
  scorms: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,
  isLoadingData: PropTypes.bool,

  // HOC (connect, dispatch)
  getSelectedCompulsaryTraining: PropTypes.func.isRequired,
  getCompulsaryTrainingCourses: PropTypes.func.isRequired,
  setCompulsaryTrainingComments: PropTypes.func.isRequired,
  getCompulsaryTrainingComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setViews: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      selected_compulsary_training, scorms, comments, isLoadingData, compulsary_training: { filters: { categories = [] } = {} } = {}
    } = state.compulsarytraining;
    const { requestedURL, vocabulary } = state.app;
    return {
      selected_compulsary_training, scorms, comments, requestedURL, isLoadingData, categories, vocabulary
    };
  },
  dispatch => ({
    getSelectedCompulsaryTraining: (id, refresh = false) => dispatch({ type: SELECTED_COMPULSARY_TRAINING_GET._REQUEST, id, refresh }),
    getCompulsaryTrainingCourses: ({ collectionId }) => dispatch({ type: COMPULSARY_TRAINING_COURSES_GET._REQUEST, collectionId }),
    setCompulsaryTrainingComments: (params) => dispatch({ type: COMPULSARY_TRAINING_COMMENTS_SET._REQUEST, ...params }),
    getCompulsaryTrainingComments: (id) => dispatch({ type: COMPULSARY_TRAINING_COMMENTS_GET._REQUEST, id }),
    setLike: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'like', node_type: 'scorm'
    }),
    setDislike: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'dislike', node_type: 'scorm'
    }),
    setViews: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'views', node_type: 'course'
    }),
    shareModal: () => dispatch({ type: MODAL_OPEN, id: ShareModal.id }),
    pushUrl: url => dispatch(push(url))
  })
)(CompulsaryTrainingCourses);
