import styled from 'styled-components';

const BodyContainer = styled.div`
  width: 100%;
  margin: 20px auto;

  @media ${props => props.theme.device.tablet} {
    padding: 0px 10px;
  }

  ul {
    list-style: initial;
  }
`;

const BodyItem = styled.div`
  width: 100%;
`;

export { BodyContainer, BodyItem };
