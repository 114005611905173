import styled from 'styled-components';
import { Col } from '../../../ui/gridSystem';
import BackgroundImg from '../../../ui/assets/img/immagine-sfondo.png';

import {
  CustomSelectContainer,
  FilterContainer,
  FilterLabel,
  FilterPillsContainer
} from '../Procedures/style';

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 420px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 50%;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
`;

export const MaskContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: ${props => props.theme.colors.transparent};
  position: relative;

  @media ${props => props.theme.device.tablet} {
    padding-top: 20px;
  }
`;

export const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const TabsContainer = styled.div`
  margin-top: -80px;
  margin-bottom: 80px;
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: -2px;
  width: 100vw;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  z-index: 2;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.primary.white};
  margin-top: 100px;
  margin-bottom: 25px;
  margin-left: 100px;

  @media ${props => props.theme.device.mobileL} {
    margin-left: 40px;
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.primary.white};
  width: 630px;
  margin-left: 100px;

  @media ${props => props.theme.device.mobileL} {
    width: auto;
    margin-left: 40px;
  }
`;

export const ProductsFilterContainer = styled(FilterContainer)``;

export const ProductsFilterLabel = styled(FilterLabel)``;

export const ProductsCustomSelectContainer = styled(CustomSelectContainer)``;

export const ProductsFilterPillsContainer = styled(FilterPillsContainer)``;
