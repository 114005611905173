import {
  takeLatest, put, call, select, takeEvery
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
// eslint-disable-next-line import/no-cycle
import productsservices from '../../api/productsservices';
import {
  PRODUCTS_SERVICES_COMMENTS_GET,
  PRODUCTS_SERVICES_COMMENTS_SET,
  PRODUCTS_SERVICES_COURSES_GET,
  PRODUCTS_SERVICES_LIST_GET,
  PRODUCTS_SERVICES_NODE_ACTION,
  SELECTED_PRODUCTS_SERVICES_GET
} from '../actions/productsservices';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* productsServicesListGet({
  itemPerPage = 24,
  filters = {},
  subset = false,
  loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const {
      products_services: {
        [filters.company_cluster]: {
          total_items: totalCount,
          courses = []
        } = {}
      } = {}
    } = yield select(state => state.productsservices);
    const pagination = calcPagination(
      subset ? undefined : { from: courses.length, totalCount },
      itemPerPage
    );
    if (pagination && !pagination.isLastPage) {
      const complete_filters = {
        type: 'prodotti_servizi',
        ...filters,
        ...pagination,
        order_code: 'asc'
      };
      const {
        data: { data: response }
      } = yield call(
        productsservices.getProductsServicesList,
        complete_filters
      );
      yield put({
        type: PRODUCTS_SERVICES_LIST_GET._SUCCESS,
        response,
        processType: filters.company_cluster || 'all'
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > productsservices > productsServicesListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* productsServicesListGetWatch() {
  yield takeEvery(PRODUCTS_SERVICES_LIST_GET._REQUEST, productsServicesListGet);
}

function* setSelectedProductsServices({ id, itemPerPage = 1, refresh = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_products_services } = yield select(
      state => state.productsservices
    );
    if ((
      selected_products_services
      && Object.keys(selected_products_services).length <= 0
      && pagination
      && !pagination.isLastPage
    ) || refresh) {
      const complete_filters = { type: 'prodotti_servizi', id, ...pagination };
      const {
        data: { data: response }
      } = yield call(
        productsservices.getProductsServicesList,
        complete_filters
      );
      yield put({ type: SELECTED_PRODUCTS_SERVICES_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > productsservices > setSelectedProductsServices', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedProductsServicesWatch() {
  yield takeLatest(
    SELECTED_PRODUCTS_SERVICES_GET._REQUEST,
    setSelectedProductsServices
  );
}

function* productsServicesCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(
        productsservices.getProductsServicesCourses,
        {
          course_id: collectionId
        }
      );
      console.log('scorms', scorms);
      yield put({ type: PRODUCTS_SERVICES_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > productsservices > productsServicesCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* productsServicesCoursesGetWatch() {
  yield takeLatest(
    PRODUCTS_SERVICES_COURSES_GET._REQUEST,
    productsServicesCoursesGet
  );
}

function* productsServicesNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(productsservices.productsServicesNodeAction, payload);
      yield put({ type: PRODUCTS_SERVICES_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite': {
          yield put({
            type: PRODUCTS_SERVICES_LIST_GET._REQUEST,
            loader: false
          });
          yield put({
            type: FAVORITE_LIST_GET._REQUEST,
            itemPerPage: 100,
            loader: false
          });
          break;
        }
        case 'views': {
          yield put({
            type: PRODUCTS_SERVICES_LIST_GET._REQUEST,
            loader: false
          });
          break;
        }
        default: {
          if (nid && action_type !== 'favourite') {
            const { selected_products_services } = yield select(
              state => state.productsservices
            );
            if (selected_products_services.id) {
              yield put({
                type: PRODUCTS_SERVICES_COURSES_GET._REQUEST,
                collectionId: selected_products_services.id,
                loader: false
              });
            }
          } else {
            yield put({
              type: PRODUCTS_SERVICES_LIST_GET._REQUEST,
              loader: false
            });
          }
          break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > productsservices > productsServicesNodeAction', err);
    }
  }
}

function* productsServicesNodeActionWatch() {
  yield takeLatest(
    PRODUCTS_SERVICES_NODE_ACTION._REQUEST,
    productsServicesNodeAction
  );
}

function* productsServicesCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const {
        data: comments
      } = yield call(productsservices.getProductsServicesComments, { id });
      yield put({ type: PRODUCTS_SERVICES_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(
        'sagas > productsservices > productsServicesCommentsGet',
        err
      );
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* productsServicesCommentsGetWatch() {
  yield takeLatest(
    PRODUCTS_SERVICES_COMMENTS_GET._REQUEST,
    productsServicesCommentsGet
  );
}

function* productsServicesCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(productsservices.setProductsServicesComments, payload);
    yield put({ type: PRODUCTS_SERVICES_COMMENTS_SET._SUCCESS });
    yield put({ type: PRODUCTS_SERVICES_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > productsservices > productsServicesCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* productsServicesCommentsSetWatch() {
  yield takeLatest(
    PRODUCTS_SERVICES_COMMENTS_SET._REQUEST,
    productsServicesCommentsSet
  );
}

export default [
  productsServicesListGetWatch(),
  setSelectedProductsServicesWatch(),
  productsServicesCoursesGetWatch(),
  productsServicesNodeActionWatch(),
  productsServicesCommentsGetWatch(),
  productsServicesCommentsSetWatch()
];
