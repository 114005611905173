import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  productNodeActionMock,
  getProductCommentsMock,
  setProductCommentsMock,
  getCourseContentMock,
  getWhatChangeMock
} from './mock';

export default {
  getWhatChangeList: (filters) => {
    if (shouldUseMock()) {
      return getWhatChangeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getWhatChangeCourses: (filters) => {
    if (shouldUseMock()) {
      return getCourseContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/content${parseQueryParams(filters)}`, 'GET');
  },
  whatChangeNodeAction: (payload) => {
    if (shouldUseMock()) {
      return productNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getWhatChangeComments: ({ id }) => {
    if (shouldUseMock()) {
      return getProductCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setWhatChangeComments: (body) => {
    if (shouldUseMock()) {
      return setProductCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  }
};
