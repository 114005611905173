import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components, createFilter } from 'react-select';
import makeAnimated from 'react-select/animated';
import { SelectContainer } from './style';
import theme from '../../theme';
import Checkbox from '../Checkbox';

const CustomSelect = ({
  options = [],
  onChange,
  onBlur,
  selected,
  disabled,
  placeholder = 'Seleziona...',
  styles = {},
  readonly = false,
  formatOptionLabel,
  className,
  isSearchType = false,
  isClearable = false,
  isMulti = false,
  filterConfig = {}
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      color: theme().colors.black,
      backgroundColor: state.isSelected
        ? theme().colors.biscuit
        : theme().colors.biscuit50,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      lineHeight: '20px',
      minHeight: 40,
      height: 'auto',
      cursor: 'pointer',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${
          state.isSelected ? theme().colors.black : theme().colors.brownGrey
        }`
      },
      '&:hover': {
        color: theme().colors.white,
        backgroundColor: theme().colors.primaryRed
      },
      ...styles?.option
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      height: 40,
      borderRadius: 6,
      boxShadow: 'none',
      backgroundColor: !state.isDisabled
        ? theme().colors.biscuit50
        : theme().colors.biscuit50,
      cursor: !state.isDisabled ? 'pointer' : 'not-allowed',
      border: 'none',
      overflow: 'hidden',
      '&:hover': {
        border: 'none',
        boxShadow: 'none'
      },
      ...styles?.controls
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: theme().colors.black,
      borderRadius: 6,
      border: `solid 1px ${theme().colors.brownGrey}`,
      boxShadow: 'none',
      marginTop: 10,
      overflow: 'hidden',
      height: 'auto',
      ...styles?.menu
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      ...styles?.menuList
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.isFocused ? 'rotate(180deg)' : null,
      ...styles?.dropdownIndicator,
      '&:after': {
        left: !state.isFocused ? 0 : 'unset',
        right: !state.isFocused ? 'unset' : 0
      }
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: theme().colors.black,
      '&:hover': {
        color: theme().colors.black
      },
      ...styles?.indicatorsContainer
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
      ...styles?.indicatorSeparator
    })
  };

  const [selectedOption, setSelectedOption] = useState(selected);

  const onchangeSelected = value => {
    setSelectedOption(value);
    if (onChange) onChange(value);
  };

  const handleOnBlur = e => {
    e.preventDefault();
    if (onBlur) onBlur(selectedOption);
  };

  useEffect(() => {
    setSelectedOption(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const [searchInput, setSearchInput] = useState('');
  let customProps = {};
  if (isSearchType) {
    customProps = {
      menuIsOpen: searchInput.length >= 3,
      isClearable: true
    };
  }

  let selectComponents = {};
  const animatedComponents = makeAnimated();
  const Option = props => (
    <components.Option {...props}>
      <Checkbox checked={props.isSelected} />
      {props.label}
    </components.Option>
  );

  if (isMulti) {
    selectComponents = {
      animatedComponents,
      Option
    };
  }

  return (
    <SelectContainer readonly={readonly} isSearchType={isSearchType}>
      <Select
        className={className}
        styles={customStyles}
        value={selectedOption}
        onChange={onchangeSelected}
        onBlur={handleOnBlur}
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        onInputChange={value => setSearchInput(value)}
        noOptionsMessage={() => <>Nessun risultato</>}
        isClearable={isClearable}
        isMulti={isMulti}
        hideSelectedOptions={false}
        components={{ ...selectComponents }}
        controlShouldRenderValue={!isMulti}
        filterOption={createFilter(filterConfig)}
        {...customProps}
      />
    </SelectContainer>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array
  ]),
  styles: PropTypes.object,
  customProps: PropTypes.object,
  filterConfig: PropTypes.object
};

export default CustomSelect;
