import styled from 'styled-components';

const BrandGroupContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    padding-top: 20px;
  }
`;


export { BrandGroupContainer };
