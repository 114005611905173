import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import { AUTH_LOGIN, MODAL_OPEN } from '../../redux/actions';
import LogoSrc from '../../../ui/assets/img/STCLogo.png';
import {
  AuthContainer,
  Background,
  FormContainer,
  InputWrapper,
  Subtitle,
  Title,
  LogoContainer,
  ResetPassword,
  ShowHidePasswordIcon,
  TitleContainer
} from './styles';
import { Button, CustomInput, Logo } from '../../../ui/atoms';
import ResetPasswordModal from '../../../ui/components/Modals/ResetPasswordModal';
import heroImage from '../../../ui/assets/img/HPPrincipale.jpg';
import {
  getValues,
  isValidForm,
  newForm,
  newFormField
} from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import Gill from '../../../ui/typography/gill';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import routes from '../../../routes';

const initialAuthForm = newForm([
  newFormField({
    field: 'username',
    required: true,
    type: RegexpType.EMAIL,
    valid: true
  }),
  newFormField({
    field: 'password',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  })
]);

const Auth = ({
  startLogin, vocabulary, resetPasswordModal, pushUrl
}) => {
  sessionStorage.setItem('SSO', false);
  sessionStorage.removeItem('isLogout');
  const [authForm, setAuthForm] = useState(initialAuthForm);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid:
          typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true
      }
    });
  };

  const handleShowPassword = n => {
    const elem = document.querySelector(`input.${n}`);
    const icon = document.querySelector(`span.icon-visualizzazioni.${n}`);
    if (elem && elem.getAttribute('type') === 'password') {
      elem.setAttribute('type', 'text');
      icon.classList.add('visible');
    } else {
      elem.setAttribute('type', 'password');
      icon.classList.remove('visible');
    }
  };

  return (
    <AuthContainer>
      <Background img={heroImage} />
      <Gill>
        <TitleContainer>
          <Title>
            <Gill type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.authTitle, 'authTitle')}
            </Gill>
          </Title>
          <Subtitle>
            <Gill type="descriptionHome" vocabulary="authDescription">
              {getValueVocabulary(
                vocabulary.authDescription,
                'authDescription'
              )}
            </Gill>
          </Subtitle>
        </TitleContainer>
      </Gill>
      <Container>
        <FormContainer>
          <LogoContainer>
            <Logo src={LogoSrc} size={Logo.SIZE.LARGE} />
          </LogoContainer>
          <InputWrapper>
            <CustomInput
              placeholder="Mail aziendale"
              type="email"
              onBlur={newValue => handleOnChange('username', newValue)}
              value={authForm?.username?.value}
              valid={authForm?.username?.valid}
            />
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              className="password"
              placeholder="Password"
              onChange={newValue => handleOnChange('password', newValue)}
              type="password"
              value={authForm?.password?.value}
              valid={authForm?.password?.valid}
              error={getValueVocabulary(
                vocabulary.passwordDescription,
                'passwordDescription'
              )}
            />
            <ShowHidePasswordIcon
              onClick={() => handleShowPassword('password')}
              className="icon-visualizzazioni password"
            />
          </InputWrapper>
          <InputWrapper>
            <ResetPassword onClick={resetPasswordModal}>
              <Gill
                type="newsTitle"
                style={{ cursor: 'pointer', width: 'fit-content' }}
              >
                Password dimenticata?
              </Gill>
            </ResetPassword>
          </InputWrapper>
          <InputWrapper>
            <Button
              size={Button.SIZE.FULL}
              btnText="Accedi"
              disabled={!isValidForm(authForm)}
              onClick={() => startLogin(getValues(authForm))}
              height={40}
            />
          </InputWrapper>
          <p style={{ textAlign: 'center' }}>oppure</p>
          <InputWrapper style={{ marginTop: 0 }}>
            <Button
              size={Button.SIZE.FULL}
              btnText="Registrati"
              type={Button.TYPE.SECONDARY}
              onClick={() => pushUrl(routes.signup.path)}
              height={40}
            />
          </InputWrapper>
        </FormContainer>
        <ResetPasswordModal />
      </Container>
    </AuthContainer>
  );
};

Auth.propTypes = {
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  startLogin: PropTypes.func.isRequired,
  pushUrl: PropTypes.func
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    startLogin: params => dispatch({ type: AUTH_LOGIN._REQUEST, params }),
    resetPasswordModal: () => dispatch({ type: MODAL_OPEN, id: ResetPasswordModal.id }),
    pushUrl: url => dispatch(push(url))
  })
)(Auth);
