import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import stc_improved from '../../api/stc_improved';
import { calcPagination } from '../../../utils/pagination';
import {
  STC_IMPROVED_LIST_GET, SELECTED_STC_IMPROVED_GET, STC_IMPROVED_NODE_ACTION,
  STC_IMPROVED_COMMENTS_GET, STC_IMPROVED_COMMENTS_SET, STC_IMPROVED_PILLS_GET,
  STC_IMPROVED_PILL_SET_STATS, STC_IMPROVED_SHARE_PILL_COLLECTION
} from '../actions/stc_improved';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';


function* stcImprovedListGet({
  itemPerPage = 24, filters: filterList = {}, loader = true, subset = true, pathFilter
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { stcImproved: { total_items: totalCount, items = [] } = {} } = yield select(state => state.stc_improved);
    const pagination = calcPagination(subset ? undefined : { from: items.length, totalCount }, itemPerPage);
    const { path_contents = [] } = yield select(state => state.app);
    if (path_contents.length && pagination && !pagination.isLastPage) {
      const path = pathFilter || (path_contents || []).find(item => item.code.toLowerCase() === 'improved').id;
      const filters = {
        path, ...pagination, ...filterList
      };
      const { data: { data: response } } = yield call(stc_improved.getStcImprovedList, filters);
      yield put({ type: STC_IMPROVED_LIST_GET._SUCCESS, response, subset });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_improved > stcImprovedListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* stcImprovedListGetWatch() {
  yield takeLatest(STC_IMPROVED_LIST_GET._REQUEST, stcImprovedListGet);
}

function* setSelectedStcImproved({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedStcImproved } = yield select(state => state.stc_improved);
    const { path_contents } = yield select(state => state.app);
    if (path_contents.length && Object.keys(selectedStcImproved).length <= 0 && pagination && !pagination.isLastPage) {
      const path = (path_contents || []).filter(item => item.name === 'Improved').id;
      const filters = { path, id, ...pagination };
      const { data: { data: response } } = yield call(stc_improved.getStcImprovedList, filters);
      yield put({ type: SELECTED_STC_IMPROVED_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_improved > setSelectedStcImproved', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedStcImprovedWatch() {
  yield takeLatest(SELECTED_STC_IMPROVED_GET._REQUEST, setSelectedStcImproved);
}

function* stcImprovedPillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response = {} } = {} } = yield call(stc_improved.getStcImprovedPills, { collectionId });
      yield put({ type: STC_IMPROVED_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_improved > stcImprovedPillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcImprovedPillsGetWatch() {
  yield takeLatest(STC_IMPROVED_PILLS_GET._REQUEST, stcImprovedPillsGet);
}

function* stcImprovedNodeAction({ nid, action_type, node_type = 'pill_collection' }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(stc_improved.stcImprovedNodeAction, payload);
      yield put({ type: STC_IMPROVED_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: STC_IMPROVED_LIST_GET._REQUEST });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selectedStcImproved } = yield select(state => state.stc_improved);
            if (selectedStcImproved.id) yield put({ type: STC_IMPROVED_PILLS_GET._REQUEST, collectionId: selectedStcImproved.id, loader: false });
          } else {
            yield put({ type: STC_IMPROVED_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > stc_improved > stcImprovedNodeAction', err);
    }
  }
}

function* stcImprovedNodeActionWatch() {
  yield takeLatest(STC_IMPROVED_NODE_ACTION._REQUEST, stcImprovedNodeAction);
}

function* stcImprovedCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(stc_improved.getStcImprovedComments, { id });
      yield put({ type: STC_IMPROVED_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_improved > stcImprovedCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcImprovedCommentsGetWatch() {
  yield takeLatest(STC_IMPROVED_COMMENTS_GET._REQUEST, stcImprovedCommentsGet);
}

function* stcImprovedCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(stc_improved.setStcImprovedComments, payload);
    yield put({ type: STC_IMPROVED_COMMENTS_SET._SUCCESS });
    yield put({ type: STC_IMPROVED_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_improved > stcImprovedCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcImprovedCommentsSetWatch() {
  yield takeLatest(STC_IMPROVED_COMMENTS_SET._REQUEST, stcImprovedCommentsSet);
}


function* stcImprovedPillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(stc_improved.setStatsStcImprovedPill, params);
    yield put({ type: STC_IMPROVED_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_STC_IMPROVED_GET._REQUEST, id: collectionId });
    yield put({ type: STC_IMPROVED_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_improved > stcImprovedPillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcImprovedPillStatsSetWatch() {
  yield takeLatest(STC_IMPROVED_PILL_SET_STATS._REQUEST, stcImprovedPillStatsSet);
}

function* stcImprovedSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(stc_improved.stcImprovedSharePillCollection, data);
      yield put({ type: STC_IMPROVED_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_improved > stcImprovedSharePillCollection', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcImprovedSharePillCollectionWatch() {
  yield takeLatest(STC_IMPROVED_SHARE_PILL_COLLECTION._REQUEST, stcImprovedSharePillCollection);
}

export default [
  stcImprovedListGetWatch(),
  setSelectedStcImprovedWatch(),
  stcImprovedPillsGetWatch(),
  stcImprovedNodeActionWatch(),
  stcImprovedCommentsGetWatch(),
  stcImprovedCommentsSetWatch(),
  stcImprovedPillStatsSetWatch(),
  stcImprovedSharePillCollectionWatch()
];
