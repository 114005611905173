import produce from 'immer';

import {
  SELECTED_STC_CONNECTED_GET,
  STC_CONNECTED_LIST_GET,
  SET_SELECTED_STC_CONNECTED, STC_CONNECTED_COMMENTS_GET, STC_CONNECTED_PILLS_GET
} from '../actions/stc_connected';

const initialState = {
  stcConnected: {},
  selectedStcConnected: {},
  pills: [],
  comments: [],
  testLink: ''
};

const stcConnectedReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case STC_CONNECTED_LIST_GET._SUCCESS: {
      const { response, subset } = action;
      const { items: pills } = response;
      const items = pills || [];
      const list = subset || !state.stcConnected.items || state.stcConnected.items <= 0 ? items : [...state.stcConnected.items, ...items];
      draft.stcConnected = {
        ...response,
        items: list
      };
      break;
    }

    case STC_CONNECTED_PILLS_GET._REQUEST: {
      draft.pills = initialState.pills;
      break;
    }

    case STC_CONNECTED_PILLS_GET._SUCCESS: {
      const { response } = action;
      const { pill_items, link, previous_pill_collection, next_pill_collection } = response[0];
      const uri = link ? link.uri : '';
      draft.pills = pill_items.reduce((acc, val, index, array) => {
        if (!array[index - 1]) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        if (array[index - 1] && (acc[index - 1].stats.status === 'completed')) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        return [...acc, {
          ...val, blocked: false, index, last_item: index === array.length - 1
        }];
      }, []);
      draft.testLink = uri;
      draft.prevCourse = previous_pill_collection;
      draft.nextCourse = next_pill_collection;
      break;
    }

    case SELECTED_STC_CONNECTED_GET._REQUEST: {
      draft.selectedStcConnected = initialState.selectedStcConnected;
      break;
    }

    case SELECTED_STC_CONNECTED_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedStcConnected = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_STC_CONNECTED: {
      const { selected } = action;
      draft.selectedStcConnected = selected;
      break;
    }

    case STC_CONNECTED_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default stcConnectedReducer;
