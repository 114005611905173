import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  CourseCardContainer, Image, CourseDetails, Duration,
  Title, CourseDetailHeader, CourseContent, CourseDetailsFooter, Detail, Play
} from './style';

import Arial from '../../typography/arial';
import { PRODUCTS_NODE_ACTION } from '../../../app/redux/actions/product';

const ProductCardItem = ({
  product, onClick, setAsFavorite
}) => {
  const {
    id, title, cover: { url }, duration, stats: { like = '0', views = '0', comments = '0' }, user_actions: { favourite = false }
  } = product;

  const [isFavorite, setIsFavorite] = useState(favourite);

  useEffect(() => {
    setIsFavorite(favourite);
  }, [favourite, product]);

  const handleOnclickFavorite = (e) => {
    setAsFavorite(id);
    setIsFavorite(!isFavorite);
    e.stopPropagation();
  };

  return (
    <CourseCardContainer onClick={onClick}>
      <Image src={url} />
      <CourseContent right>
        <span onClick={handleOnclickFavorite} className={isFavorite ? 'icon-preferiti' : 'icon-heart-empty'} />
      </CourseContent>

      <CourseDetails>
        <CourseDetailHeader>
          <Title><Arial type="productCardTitle">{title}</Arial></Title>
          <Duration>
            <Play><span className="icon-play" /></Play>
            <Arial type="productCardDetail">{duration}&nbsp;min</Arial>
          </Duration>
        </CourseDetailHeader>
        <CourseDetailsFooter>
          <Detail>
            <span className="icon-visualizzazioni" />
            <Arial type="productCardDetail">{views}</Arial>
          </Detail>
          <Detail>
            <span className="icon-commenti" />
            <Arial type="productCardDetail">{comments}</Arial>
          </Detail>
          <Detail>
            <span className="icon-mipiace" />
            <Arial type="productCardDetail">{like}</Arial>
          </Detail>
        </CourseDetailsFooter>
      </CourseDetails>
    </CourseCardContainer>
  );
};

ProductCardItem.propTypes = {
  product: PropTypes.object,
  onClick: PropTypes.func,

  // HOC (connect, dispatch)
  setAsFavorite: PropTypes.func.isRequired
};

export default connect(
  () => ({}),
  dispatch => ({
    setAsFavorite: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    })
  })
)(ProductCardItem);
