import styled from 'styled-components';

const NoResultContainer = styled.div`
  padding: 20px 0;
  text-align: center;
  width: 100%;
  color: black;
`;


export { NoResultContainer };
