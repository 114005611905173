import styled from 'styled-components';

const GuidebookCardContainer = styled.div`
  position: relative;
  height: 81px;
  padding: 10px 0 15px;
  background-color: rgba(0,0,0,0.3);
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
  }
`;

const Guidebook = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Date = styled.div`
  color: ${props => props.theme.colors.brownGrey};
`;

const Title = styled.div`
  margin: 5px 0;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Description = styled.div`
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconAttachment = styled.div`
  margin: 0 30px 0 24px;
  font-size: 28px;
  margin-left: ${props => (props.position === 'right' ? 'auto' : '')};
  span {
    font-size: ${props => (props.icon === 'download' ? '14px' : '28px')};
  }
`;

const GuidebookCardContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 60px;
`;

export {
  GuidebookCardContainer, Guidebook, Date, Title, Description, IconAttachment, GuidebookCardContent
};
