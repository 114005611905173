import {
  takeLatest, takeEvery, put, call
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from '../actions';

import survey from '../../api/survey';
import { SET_INSERT_SURVEY, SURVEY_CONTENT_GET } from '../actions/survey';
import { ErrorModal } from '../../../ui/components/Modals';
import routes from '../../../routes';

function* getSurveyContent({ id, survey_type }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(survey.getSurveyContent, { id, survey_type });
    yield put({ type: SURVEY_CONTENT_GET._SUCCESS, response, survey_type });
  } catch (error) {
    yield put({ type: SURVEY_CONTENT_GET._ERROR, error });
    console.log('sagas > survey > getSurveyContent', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSurveyContentWatch() {
  yield takeEvery(SURVEY_CONTENT_GET._REQUEST, getSurveyContent);
}

function* setInsertSurvey({
  tojId, form = {}, mandatory_survey_id, additional_survey_id, title
}) {
  try {
    const formKeys = Object.keys(form);
    const mandatoryOnly = formKeys.filter(key => !form[key].mandatory).length <= 0;
    let body = {};
    if (mandatoryOnly) {
      const mandatorySurvey = formKeys.filter(key => form[key].mandatory);
      body = {
        toj_id: tojId,
        survey_id: null,
        survey_title: null,
        first_mandatory_answer: Array.isArray(form[mandatorySurvey[0]].value) ? form[mandatorySurvey[0]].value.toString().replace(/,/g, ';') : form[mandatorySurvey[0]].value,
        second_mandatory_answer: Array.isArray(form[mandatorySurvey[1]].value) ? form[mandatorySurvey[1]].value.toString().replace(/,/g, ';') : form[mandatorySurvey[1]].value
      };
    } else {
      const mandatorySurvey = formKeys.filter(key => form[key].mandatory);
      const additionalSurvey = formKeys.filter(key => !form[key].mandatory);
      body = {
        toj_id: tojId,
        survey_id: additional_survey_id,
        survey_title: title,
        first_mandatory_answer: Array.isArray(form[mandatorySurvey[0]].value) ? form[mandatorySurvey[0]].value.toString().replace(/,/g, ';') : form[mandatorySurvey[0]].value,
        second_mandatory_answer: Array.isArray(form[mandatorySurvey[1]].value) ? form[mandatorySurvey[1]].value.toString().replace(/,/g, ';') : form[mandatorySurvey[1]].value,
        questions: additionalSurvey.reduce((acc, key) => ([
          ...acc,
          {
            question_code: form[key].survey.data.question_id,
            question: form[key].survey.data.question,
            answers: Array.isArray(form[key].value) ? form[key].value.toString().replace(/,/g, ';') : form[key].value
          }
        ]), [])
      };
    }
    console.log('setInsertSurvey ', body);
    yield call(survey.setInsertSurvey, body);
    yield put({ type: SET_INSERT_SURVEY._SUCCESS });
    yield put(push(`${routes.toj.path}/${tojId}`));
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ErrorModal.id, data: { title: 'Errore', message: err?.response?.data.action_result.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > survey > setInsertSurvey', err);
  }
}

function* setInsertSurveyWatch() {
  yield takeLatest(SET_INSERT_SURVEY._REQUEST, setInsertSurvey);
}

export default [
  getSurveyContentWatch(),
  setInsertSurveyWatch()
];
