import styled from 'styled-components';
import { Col } from '../../../ui/gridSystem';
import { FilterLabel } from '../Procedures/style';

export const CompulsaryTrainingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
`;

export const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const TabsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    text-align: center;
  }
`;

export const ObbligatoryFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const ObbligatoryFilterLabel = styled(FilterLabel)`
  color: ${props => props.theme.colors.black};
`;

export const ObbligatoryCustomSelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const ObbligatoryCustomSelect = styled.div`
  width: 280px;
  margin-right: 17px;
  margin-bottom: 50px;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 10px;
  }
`;

export const ObbligatoryFilterPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 297px);
  padding-top: 5px;

  > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const Title = styled.div`
  margin: 20px 0px;

  @media ${props => props.theme.device.tablet} {
    & > div {
      text-align: center;
    }
  }
`;
