import React from 'react';
import PropTypes from 'prop-types';

import { NoResultContainer } from './style';
import Gill from '../../typography/gill';

const NoResult = ({ text }) => (
  <NoResultContainer>
    <Gill type="editorialImageTitle">{text} </Gill>
  </NoResultContainer>
);

NoResult.propTypes = {
  text: PropTypes.string

};

NoResult.defaultProps = {
  text: 'Nessun risultato'
};

export default NoResult;
