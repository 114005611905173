import styled from 'styled-components';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0;

  &:checked ~ span {
    border: 1px solid ${props => props.theme.colors.brownGrey};
  }

  &:checked ~ span:after {
    display: block;
    background-color: ${props => props.theme.colors.primaryColor};
  }
`;

const CustomRadio = styled.span`
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.brownGrey};
  border-radius: 100%;

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 55%;
    left: 54%;
    transform: translate(-54%, -54%);
    width: 9px;
    height: 9px;
    margin: auto;
    border-radius: 100%;
    display: block;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${props => props.theme.colors.black};
  margin: 0;
  padding-left: 1.3rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  padding-left: 30px;
  cursor: pointer;

  @media ${props => props.theme.device.mobileL} {
    margin-top: 10px;
  }
`;

export { Label, Input, CustomRadio };
