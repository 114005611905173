import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isValidForm, newForm, newFormField } from '../../../../utils/form';
import { Button, CustomInput, CustomSelect } from '../../../../ui/atoms';
import { InputsGrid, InputWrapper } from './style';
import { RegexpType, validator } from '../../../../utils/validator';
import { ButtonsWrapper } from '../style';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import {
  SAVE_CREDENTIALS,
  SIGNUP_PATHS_GET,
  SIGNUP_CHECK_EMAIL
} from '../../../redux/actions';
import { ShowHidePasswordIcon } from '../../Auth/styles';
import routes from '../../../../routes';
import 'react-datepicker/dist/react-datepicker.css';

const initialForm = newForm([
  newFormField({
    field: 'username',
    required: true,
    type: RegexpType.EMAIL,
    valid: true
  }),
  newFormField({
    field: 'name',
    required: true,
    type: RegexpType.STRING,
    valid: true
  }),
  newFormField({
    field: 'surname',
    required: true,
    type: RegexpType.STRING,
    valid: true
  }),
  newFormField({
    field: 'password',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'confirmPassword',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'path',
    required: true,
    type: RegexpType.STRING,
    valid: true
  }),
  newFormField({
    field: 'birthDate',
    required: false,
    type: RegexpType.DATE,
    valid: true
  }),
  newFormField({
    field: 'gender',
    required: true,
    type: RegexpType.STRING,
    valid: true
  })
]);

const genderOptions = [
  { id: 'man', label: 'Maschile' },
  { id: 'woman', label: 'Femminile' },
  { id: 'other', label: 'Altro' },
  { id: 'not_answer', label: 'Preferisco non rispondere' }
];

const SignupCredentials = ({
  vocabulary,
  credentials,
  saveCredentials,
  getPaths,
  paths,
  checkEmail,
  mailIsInvalid,
  pushUrl
}) => {
  const [credentialsForm, setCredentialsForm] = useState(initialForm);
  const [errPwd, setErrPwd] = useState();
  const [errEmail, setErrEmail] = useState();
  const [pathsOptions, setPathsOptions] = useState([]);

  useEffect(() => {
    getPaths();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paths?.length > 0) {
      const opts = paths.map(p => ({ id: p.termid, label: p.name }));
      setPathsOptions(opts);
    }
  }, [paths]);

  useEffect(() => {
    if (Object.keys(credentials)?.length > 0) {
      // refill form if turn back from step 2
      const newCredentailsForm = { ...credentialsForm };
      Object.keys(credentials).forEach(c => {
        newCredentailsForm[c] = {
          ...newCredentailsForm[c],
          value: credentials[c]
        };
      });
      newCredentailsForm.confirmPassword.value = credentials.password;
      setCredentialsForm(newCredentailsForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = credentialsForm[field];
    setCredentialsForm({
      ...credentialsForm,
      [field]: {
        ...credentialsForm[field],
        value: newValue,
        valid:
          typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true
      }
    });
  };

  const confirmCredentials = () => {
    saveCredentials({
      username: credentialsForm.username.value,
      name: credentialsForm.name.value,
      surname: credentialsForm.surname.value,
      password: credentialsForm.password.value,
      path: credentialsForm.path.value,
      birthDate: credentialsForm.birthDate.value,
      gender: credentialsForm.gender.value
    });
    checkEmail(credentialsForm?.username?.value);
  };

  const handleShowPassword = n => {
    const elem = document.querySelector(`input.${n}`);
    const icon = document.querySelector(`span.icon-visualizzazioni.${n}`);
    if (elem && elem.getAttribute('type') === 'password') {
      elem.setAttribute('type', 'text');
      icon.classList.add('visible');
    } else {
      elem.setAttribute('type', 'password');
      icon.classList.remove('visible');
    }
  };

  const checkPasswords = () => {
    if (
      !credentialsForm.password.value
      || !credentialsForm.confirmPassword.value
    ) {
      return;
    }
    if (
      credentialsForm.password.value !== credentialsForm.confirmPassword.value
    ) {
      setCredentialsForm({
        ...credentialsForm,
        confirmPassword: {
          ...credentialsForm.confirmPassword,
          valid: false
        }
      });
      setErrPwd('Le password devono coincidere');
    }
  };

  useEffect(() => {
    if (mailIsInvalid !== 0) {
      setCredentialsForm({
        ...credentialsForm,
        username: {
          ...credentialsForm.username,
          valid: false
        }
      });
      setErrEmail('Email non valida');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailIsInvalid]);

  return (
    <InputsGrid>
      <InputWrapper>
        <CustomInput
          placeholder="Nome"
          type="text"
          onBlur={newValue => handleOnChange('name', newValue)}
          value={credentialsForm?.name?.value}
          valid={credentialsForm?.name?.valid}
          autoComplete="new-password"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          placeholder="Cognome"
          type="text"
          onBlur={newValue => handleOnChange('surname', newValue)}
          value={credentialsForm?.surname?.value}
          valid={credentialsForm?.surname?.valid}
          autoComplete="new-password"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomSelect
          placeholder="Seleziona il percorso"
          onChange={opt => handleOnChange('path', opt.label)}
          options={pathsOptions}
          className="signup-select"
          selected={pathsOptions.find(opt => opt.label === credentialsForm.path.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          placeholder="Mail aziendale"
          type="email"
          onBlur={newValue => {
            handleOnChange('username', newValue);
            if (!validator(RegexpType.EMAIL, newValue, true)) setErrEmail(true);
          }}
          value={credentialsForm?.username?.value}
          valid={credentialsForm?.username?.valid}
          autoComplete="new-password"
          error={errEmail}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          className="password"
          placeholder="Password"
          onChange={newValue => handleOnChange('password', newValue)}
          type="password"
          value={credentialsForm?.password?.value}
          valid={credentialsForm?.password?.valid}
          autoComplete="new-password"
          onBlur={checkPasswords}
          error={getValueVocabulary(
            vocabulary.passwordErrorMessage,
            'passwordErrorMessage'
          )}
        />
        <ShowHidePasswordIcon
          onClick={() => handleShowPassword('password')}
          className="icon-visualizzazioni password"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          className="confirm-password"
          placeholder="Conferma password"
          onChange={newValue => {
            handleOnChange('confirmPassword', newValue);
          }}
          type="password"
          value={credentialsForm?.confirmPassword?.value}
          valid={credentialsForm?.confirmPassword?.valid}
          autoComplete="new-password"
          onBlur={checkPasswords}
          error={errPwd}
        />
        <ShowHidePasswordIcon
          onClick={() => handleShowPassword('confirm-password')}
          className="icon-visualizzazioni confirm-password"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomSelect
          placeholder="Genere"
          onChange={opt => handleOnChange('gender', opt.id)}
          options={genderOptions}
          className="signup-select"
          selected={genderOptions.find(opt => opt.id === credentialsForm.gender.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <DatePicker
          onChange={date => {
            const formattedDate = moment(date)?.unix();
            handleOnChange('birthDate', formattedDate);
          }}
          selected={credentialsForm?.birthDate?.value * 1000}
          placeholderText="Data di nascita (DD/MM/YYYY)"
          dateFormat="dd/MM/yyyy"
          locale="it"
          maxDate={new Date()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </InputWrapper>
      <ButtonsWrapper>
        <Button
          btnText={getValueVocabulary(vocabulary.action_back, 'action_back')}
          type={Button.TYPE.SECONDARY}
          onClick={() => pushUrl(routes.auth.path)}
        />
        <Button
          btnText={getValueVocabulary(vocabulary.action_next, 'action_next')}
          onClick={confirmCredentials}
          disabled={!isValidForm(credentialsForm)}
        />
      </ButtonsWrapper>
    </InputsGrid>
  );
};

SignupCredentials.propTypes = {
  vocabulary: PropTypes.object,
  credentials: PropTypes.object,
  saveCredentials: PropTypes.func,
  getPaths: PropTypes.func,
  paths: PropTypes.array,
  checkEmail: PropTypes.func,
  mailIsInvalid: PropTypes.number,
  pushUrl: PropTypes.func
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { credentials, paths, mailIsInvalid } = state.app.signup;
    return {
      vocabulary,
      credentials,
      paths,
      mailIsInvalid
    };
  },
  dispatch => ({
    getPaths: () => dispatch({ type: SIGNUP_PATHS_GET._REQUEST }),
    saveCredentials: credentials => dispatch({ type: SAVE_CREDENTIALS, credentials }),
    checkEmail: email => dispatch({ type: SIGNUP_CHECK_EMAIL._REQUEST, email }),
    pushUrl: url => dispatch(push(url))
  })
)(SignupCredentials);
