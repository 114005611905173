import styled from 'styled-components';

export const ShareModalContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 200px;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.black};
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -26px;
    width: calc(100% + 52px);
    bottom: 0;
  }
`;

export const Colleague = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.black};
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px;
  transition: ${props => props.theme.constants.transitionBase};
  
  &:hover {
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.gray, 0.3)};
  }
  
  >div:not(:first-child) {
    margin-left: 10px;
  }

  label {
    span {
      top: -8px;
    }
  }
`;

export const ColleaguesContainer = styled.div`
  overflow-y: auto;
  height: 300px;
  margin-top: 10px;
  
  ${Colleague}:not(:last-child) {
    border-bottom: 2px solid ${props => props.theme.colors.black07};
  }
`;

export const Invite = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.black};
  border-radius: 12px;
  margin-right: 7px;
  padding: 4px 6px;
  ${Colleague}:not(:last-child) {
    padding: 0;
  }
`;

export const InvitationsContainer = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  min-height: 40px;
  width: 100%;
  margin: 8px;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  
  width: 100%;
  button {
    max-width: 200px;
  }
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    background: ${props => props.theme.colors.primary.white};
  }
`;

export const IconClose = styled.span`
  top: 20px!important;
  right: 20px!important;
  z-index:100;
`;

export const ColleagueHeader = styled.div`
  display: flex;
  color: ${props => props.theme.colors.black};
  height: 58px;
  align-items: center;
  

  >div {
    padding-left: 46px;
  }
`;

export const Email = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  
  @media ${props => props.theme.device.mobileL} {
    max-width: 120px;
    overflow-wrap: break-word;
  }
`;

export const Collaborate = styled.div`
  @media ${props => props.theme.device.mobileL} {
    max-width: 87px;
    overflow-wrap: break-word;
  }
`;
