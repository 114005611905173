import React from 'react';
import PropTypes from 'prop-types';

import { BodyContainer, BodyItem } from './style';

const Body = ({
  content
}) => (
  <BodyContainer>
    <BodyItem dangerouslySetInnerHTML={{ __html: content }} />
  </BodyContainer>
);

Body.propTypes = {
  content: PropTypes.string
};

export default Body;
