import React, { } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  AgendaTimelineContainer, TimeLineContainer, TimelineItemContainer, TimelineItemContent, Description
} from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';


const AgendaTimeline = ({ timeline, vocabulary }) => (
  <AgendaTimelineContainer timeline={timeline}>
    <Arial vocabulary="agendaList">{getValueVocabulary(vocabulary.agendaList, 'agendaList')}</Arial>
    {timeline?.length > 0 && (
      <TimeLineContainer>
        {timeline?.map((item) => (
          <TimelineItemContainer>
            <TimelineItemContent>
              <Arial>{item?.data?.title}</Arial>
              {item?.data?.body && (
                <Description><Arial type="welcomeModalSubtitle"><span dangerouslySetInnerHTML={{ __html: item?.data.body }} /></Arial></Description>
              )}
            </TimelineItemContent>
          </TimelineItemContainer>
        ))}
      </TimeLineContainer>
)}
  </AgendaTimelineContainer>
);

AgendaTimeline.propTypes = {
  timeline: PropTypes.array
  // HOC (connect, state)

  // HOC (connect, dispatch)
};


export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  }

)(AgendaTimeline);
