import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';

import { getStatisticsMock } from './mock';
import { shouldUseMock } from '../../utils/common';

const baseurl = '/stats/';

export default {
  getStatistics: (statType, filters) => {
    if (shouldUseMock()) {
      return new Promise(res => {
        setTimeout(() => {
          res(getStatisticsMock(statType));
        }, Math.floor(Math.random() * (4000 - 500) + 500));
      });
    }

    return apiCall(`${baseUrl}${extendUrl}user${baseurl}${statType}${parseQueryParams(filters)}`, 'GET');
  }
};
