import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from '../../gridSystem';
import {
  EditorialAboutContainer, EditorialAboutBox,
  EditorialAboutBoxTitle, EditorialAboutRow, EditorialAboutImage,
  Text
} from './style';
import Arial from '../../typography/arial';

const EditorialAbout = ({ content: { subtitle, title }, image }) => (
  <EditorialAboutContainer>
    <EditorialAboutImage img={image} />
    <Container className="p-0">
      <EditorialAboutRow className="no-gutters">
        <Col sm={12} md={6}>
          <EditorialAboutBox>
            <EditorialAboutBoxTitle>
              <Arial type="EditorialAboutTitle">{title}</Arial>
            </EditorialAboutBoxTitle>
            <Text type="EditorialAboutText">
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
            </Text>
          </EditorialAboutBox>
        </Col>
      </EditorialAboutRow>
    </Container>
  </EditorialAboutContainer>
);

EditorialAbout.propTypes = {
  content: PropTypes.object,
  image: PropTypes.string
};

export default EditorialAbout;
