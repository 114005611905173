import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import { getNewsMock, getNewsItemMock, compulsaryTrainingNodeActionMock } from './mock';

export default {
  getNews: (filters) => {
    if (shouldUseMock()) {
      return getNewsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}news${parseQueryParams(filters)}`, 'GET');
  },

  getNewsItem: (news_id) => {
    if (shouldUseMock()) {
      return getNewsItemMock(news_id);
    }
    return apiCall(`${baseUrl}${extendUrl}news/${news_id}/item`, 'GET');
  },

  getFavoriteNews: (filters) => {
    if (shouldUseMock()) {
      return getNewsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}favourite?node_type=news`, 'GET');
  },
  shareNews: (payload) => {
    if (shouldUseMock()) {
      return getNewsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/share`, 'POST', payload);
  },
  setViewNews: (payload) => {
    if (shouldUseMock()) {
      return compulsaryTrainingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  }
};
