import produce from 'immer';

import {
  COURSE_GET, COLLECTIONS_GET, LESSONS_GET, COLLECTION_LIST_GET, COLLECTION_COURSES_GET, SELECTED_COLLECTION_GET, SET_SELECTED_COLLECTION, COLLECTION_COMMENTS_GET
} from '../actions/collections';
import { COURSE_DETAILS_SET, COURSE_COMMENTS_GET } from '../actions/course';

const initialState = {
  lessons: [],
  filters: {},
  collection: {},
  selected_collection: {},
  scorms: [],
  comments: []
};

const collectionsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case COLLECTIONS_GET._SUCCESS: {
      const { subset = false, data } = action;
      if (!(subset && state.courses?.length)) {
        draft = {
          ...state,
          ...data
        };
      }
      break;
    }

    case COURSE_DETAILS_SET: {
      draft.collection = action.collection;
      break;
    }

    case COURSE_GET._SUCCESS: {
      const { collection = [] } = action;

      draft.collection = collection;
      break;
    }

    case LESSONS_GET._SUCCESS: {
      const { scorms = [] } = action.response;
      draft.lessons = scorms;
      break;
    }

    case COURSE_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case COLLECTION_LIST_GET._SUCCESS: {
      const { response } = action;
      const { from, items } = response;
      const list = (!from || from <= 0) ? items : [...state.collection.items, ...items];
      draft.collection = {
        ...response,
        items: list
      };
      break;
    }

    case COLLECTION_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      break;
    }

    case SELECTED_COLLECTION_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_collection = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_COLLECTION: {
      const { selected } = action;
      draft.selected_collection = selected;
      break;
    }

    case COLLECTION_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default collectionsReducer;
