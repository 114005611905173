import styled from 'styled-components';

const AccessStatisticContainer = styled.div`
  height: 91px;
  border-radius: 2.4px;
  box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.biscuit50};
  padding: 14px 15px 15px 20px;
  margin-bottom: 10px;
  width: calc(33.3% - 5px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${props => props.theme.device.mobileL} {
    padding-left: 15px;
    width: 100%;
  }

  .content {
    @media ${props => props.theme.device.mobileL} {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: space-between;
    }
  }
`;

const RowContainer = styled.div`
  width: 100%;
`;

const Span = styled.span.attrs(({ customClass }) => ({
  className: `${customClass}`
}))``;

const Title = styled.div`
  margin-bottom: 2px;
  color: ${props => props.theme.colors.black};
  white-space: nowrap;
`;

const Description = styled.span`
  color: ${props => props.theme.colors.black};
  white-space: nowrap;
`;

const Icon = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  & span {
    font-size: 22px;
    color: ${props => props.theme.colors.black};
  }
`;

const InfoAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export {
  AccessStatisticContainer,
  Title,
  Description,
  Icon,
  InfoAccessContainer,
  Span,
  RowContainer
};
