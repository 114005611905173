import React, { useState } from 'react';

import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer, Background, HeaderWrapper,
  Time, Title, FooterWrapper, OverviewContainer,
  Text, ButtonContainer
} from './style';
import ProceduresDefaultCover from '../../../ui/assets/img/agenda.jpg';
import Gill from '../../../ui/typography/gill';
import {
  BackButton, Button, Tabs
} from '../../../ui/atoms';
import { TabsContainer } from '../CompulsaryTraining/style';
import { Comments, GuideboookCard, TeacherBox } from '../../../ui/components';
import { GuidebookItemColumn, GuidebookListColumn, RowGuidebook } from '../Guidebook/style';
import InfoCourse from '../../../ui/atoms/InfoCourse';
import AgendaTimeline from '../../../ui/components/AgendaTimeline';

const IntegrationAgendaDetails = ({
  heroImage
}) => {
  const defaultTabs = [
    { label: 'Overview', value: 'overview' },
    { label: 'Materiali', value: 'materials' },
    { label: 'Commenti', value: 'comments' }
  ];

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const guidebookList = [{
    data: {
      title: 'Primo PDF di esempio',
      category: 'Manuale 1',
      description: 'Descrizione del primo PDF di esempio',
      date: 1598889015,
      file_download: [
        {
          filename: 'dummy_0.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/dummy_0.pdf'
        }
      ]
    }
  },
  {
    data: {
      title: 'Secondo PDF',
      category: 'Nuova funzionalità',
      description: 'Descrizione',
      date: 1598889025,
      file_download: [
        {
          filename: 'sample_1.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/sample_1.pdf'
        }
      ]
    }
  },
  {
    data: {
      title: 'Secondo PDF',
      category: 'Nuova funzionalità',
      description: 'Descrizione',
      date: 1598889025,
      file_download: [
        {
          filename: 'sample_1.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/sample_1.pdf'
        }
      ]
    }
  },
  {
    data: {
      title: 'Secondo PDF',
      category: 'Nuova funzionalità',
      description: 'Descrizione',
      date: 1598889025,
      file_download: [
        {
          filename: 'sample_1.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/sample_1.pdf'
        }
      ]
    }
  },
  {
    data: {
      title: 'Secondo PDF',
      category: 'Nuova funzionalità',
      description: 'Descrizione',
      date: 1598889025,
      file_download: [
        {
          filename: 'sample_1.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/sample_1.pdf'
        }
      ]
    }
  },
  {
    data: {
      title: 'Secondo PDF',
      category: 'Nuova funzionalità',
      description: 'Descrizione',
      date: 1598889025,
      file_download: [
        {
          filename: 'sample_1.pdf',
          url: 'http://ubi.local:8080/sites/default/files/2020-08/sample_1.pdf'
        }
      ]
    }
  }];

  const comments = [
    {
      name: 'Student1',
      field_name: 'Andrea',
      field_surname: 'Cattaneo',
      user_picture: '/sites/default/files/pictures/2020-08/picture_08272020_082406.jpg',
      subject: 'Goodbye World',
      comment_body: '<p>comment which you want write</p>\n',
      cid: '2',
      created: '2020-08-28T17:07:19+0200',
      changed: '2020-08-28T17:07:19+0200'
    },
    {
      name: 'admin',
      field_name: 'Giandonato',
      field_surname: 'Greco',
      user_picture: '',
      subject: 'Commento test 1',
      comment_body: '<p>Contenuto del commento</p>',
      cid: '1',
      created: '2020-08-28T16:27:45+0200',
      changed: '2020-08-28T16:27:45+0200'
    }
  ];

  const timelineData = [
    {
      title: '10:30',
      body: 'Welcome e OnBoarding'
    },
    {
      title: '10:45',
      body: 'Introduzione alla filiale - Overview sulla filiale'
    }, {
      title: '11:45',
      body: 'Principali prodotti e servizi: caratteristiche, processo di vendita e modello di servizio'
    },
    {
      title: '12:15',
      body: 'Operatività - Le principali attività'
    }
  ];


  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
        <Container>
          <HeaderWrapper>
            <BackButton />
            <Time><Gill type="productCardDetail">Ore 10:30 - 12:30</Gill></Time>
            <Gill type="newsDetailTitle">Normativa, metodi, strumenti e procedure</Gill>
          </HeaderWrapper>
          <FooterWrapper>
            <Gill type="productCardDetail">Aula virtuale | 2h</Gill>
            <Button btnText="Partecipa" />
          </FooterWrapper>
        </Container>
      </HeaderContainer>
      <Container>
        <TabsContainer>
          <Tabs tabs={[...defaultTabs]} onChange={onChangeTab} selected={activeTab} tabType={Tabs.TYPE.PRIMARY} />
        </TabsContainer>
        {activeTab.value === 'overview' && (
          <OverviewContainer>
            <Row>
              <Col lg={8} md={6} sm={12}>
                <Text>
                  <Gill type="tab">Il mondo intorno a noi evolve, ma lo stile della casa, il nostro essere resta lo stesso.<br />L’adozione di una serie di comportamenti che ci contraddistingue nel modo di fare banca, permette una maggiore soddisfazione dei colleghi e dei clienti.
                  </Gill>
                </Text>
                <Text>
                  <Title><Gill type="collectionCardTitle">Obiettivi</Gill></Title>
                  <Gill type="tab">L'obiettivo è illustrare le attività quotidiane , cogliere le principali caratteristiche dello "stile della casa", dell’offerta commerciale e delle modalità di esecuzione del lavoro in filiale. Per ogni prodotto e servizio verrà illustrata anche l’operatività connessa ad essi, in termini di processi e procedure.</Gill>
                </Text>
                <AgendaTimeline timeline={timelineData} />
                {/*<Gill type="collectionCardTitle">Skill</Gill>
                <SkillsContainer>
                  <FilterPill label="Empowerment" />
                  <FilterPill label="Equity" />
                  <FilterPill label="Management" />
                </SkillsContainer>*/}
                <InfoCourse />
                <ButtonContainer>
                  <Button btnText="Partecipa" />
                </ButtonContainer>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <TeacherBox />
              </Col>
            </Row>

          </OverviewContainer>
        )}
        {activeTab.value === 'materials' && (
          <>
            <GuidebookListColumn md={9} sm={12} lg={12}>
              <RowGuidebook>
                {(guidebookList || []).map((singleGuidebook, i) => (
                  <GuidebookItemColumn key={i}>
                    <GuideboookCard guidebook={singleGuidebook} />
                  </GuidebookItemColumn>
                ))}
              </RowGuidebook>
            </GuidebookListColumn>
          </>
        )}
        {activeTab.value === 'comments' && (
          <Comments list={comments} />
        )}
      </Container>
    </>
  );
};


IntegrationAgendaDetails.propTypes = {
  // HOC (connect, dispatch)

  // HOC (connect, state)
};

export default IntegrationAgendaDetails;
