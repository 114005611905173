import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  Image, EditorialImageTopContainer, CardText, Title,
  Overlay, IconVideo
} from './style';
import { VideoModal } from '../Modals';
import { MODAL_OPEN } from '../../../app/redux/actions';

const EditorialImageTop = ({ content: { article, file }, openVideoModal }) => {
  const openVideo = () => {
    openVideoModal();
  };
  return (
    <EditorialImageTopContainer>
      {file?.image && (
      <Image src={file?.image?.url}>
        {file?.video && (
        <Overlay onClick={() => openVideo()}>
          <IconVideo>
            <span className="icon-video"><span className="path1" /><span className="path2" /></span>
          </IconVideo>
        </Overlay>
      )}
      </Image>
        )}
      {(article?.title || article?.body)
        && (
          <CardText>
            <Title>
              {article?.title && <Arial>{article?.title}</Arial>}
            </Title>
            {article?.body && <div dangerouslySetInnerHTML={{ __html: article?.body }} />}
          </CardText>
        )}
      {file?.video && <VideoModal videoUrl={file?.video} />}
    </EditorialImageTopContainer>
  );
};

EditorialImageTop.propTypes = {
  content: PropTypes.object
};

export default connect(
  () => ({}),
  dispatch => ({
    openVideoModal: () => dispatch({ type: MODAL_OPEN, id: VideoModal.id })
  })
)(EditorialImageTop);
