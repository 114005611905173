import produce from 'immer';
import PropTypes from 'prop-types';
import {
  RECENT_COURSES_GET,
  COURSES_TABLE_CONTENTS_GET,
  COURSE_FAVORITE_GET
} from '../actions/course';

export const TableCourseInterface = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  subscriptiondate: PropTypes.number,
  deadline: PropTypes.number,
  completiondate: PropTypes.number,
  credits: PropTypes.number,
  time: PropTypes.number,
  score: PropTypes.number
});

export const TableCoursesInterface = PropTypes.arrayOf(TableCourseInterface);

export const CoursesTableContents = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  courses: TableCoursesInterface
});

const initialState = {
  lessons: [],
  course_details: {},
  recent_courses: [],
  selectedLesson: {},
  courses_table_contents: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    courses: []
  },

  courses: {},
  favorites: {}
};

const dashboardReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case RECENT_COURSES_GET._SUCCESS:
      draft.recent_courses = action.response;
      break;

    case COURSES_TABLE_CONTENTS_GET._SUCCESS: {
      const { from, courses } = action.response;
      const data = (!from || from <= 0) ? courses : [...state.courses_table_contents.courses, ...courses];
      draft.courses_table_contents = {
        ...action.response,
        courses: data
      };
    }
      break;
    case COURSE_FAVORITE_GET._SUCCESS: {
      const { response } = action;
      draft.favorites = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default dashboardReducer;
