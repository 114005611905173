import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Redirect } from 'react-router-dom';

import { CourseActivityContainer } from './style';

import { Activity } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';

import { LESSON_CONTENTS_GET } from '../../redux/actions/lesson';
import { LessonScormInterface } from '../../redux/reducers/lesson';
import { selectCurrentLesson, selectCurrentPill, selectCurrentScorm } from '../../redux/selectors';
import routes from '../../../routes';
import { PillInterface } from '../../redux/reducers/home';

const CourseActivity = ({
  lessonId, pillId, scorm, pill
}) => {
  if (lessonId
     && (
       !(scorm && scorm.node_scorm_id)
       || (scorm && (scorm.blocked === 1 || scorm.status === 'completed'))
     )
  ) {
    return <Redirect to={`${routes.lessons.path}/${lessonId}`} />;
  }

  if (pillId && !(pill && pill.id)) return <Redirect to={routes.home.path} />;

  return (
    <Container className="p-0">
      <CourseActivityContainer>
        <Activity activity={scorm || pill} />
      </CourseActivityContainer>
    </Container>
  );
};

CourseActivity.propTypes = {

  // App.js via Route
  lessonId: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  pillId: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  scormId: PropTypes.string,

  // HOC (connect, state)
  pill: PillInterface,
  scorm: LessonScormInterface
};

export default connect(
  (state, { lessonId, scormId, pillId }) => {
    // const { lessons } = state.lesson;

    const lesson = { ...selectCurrentLesson(state, lessonId), scormId };
    const scorm = selectCurrentScorm(state, lesson);
    const pill = selectCurrentPill(state, pillId);

    return {
      scorm,
      pill
    };
  },
  dispatch => ({
    getLessonContents: (lessonId) => dispatch({ type: LESSON_CONTENTS_GET._REQUEST, lessonId, reload: false }),
    pushUrl: url => dispatch(push(url))
  })
)(CourseActivity);
