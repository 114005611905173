import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  Background, HeaderContainer, SmartWorkingContainer, ResultContainer,
  CardContainer
  // SeeMore
} from './style';
import { GeneralCard } from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/procedures.jpg';
import {
  SET_SELECTED_SMARTWORKING,
  SMARTWORKING_LIST_GET,
  SMARTWORKING_NODE_ACTION
} from '../../redux/actions/smartworking';
import routes from '../../../routes';


const SmartWorking = ({
  heroImage, smartWorking, getSmartWorking, pushUrl,
  setSelectedSmartWorking, setAsFavorite
}) => {
  const { items: list = [] } = smartWorking;

  const handleOnClickCard = (item) => {
    setSelectedSmartWorking(item);
    pushUrl(`${routes.smartWorking.path}/${item.id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSmartWorking();
  }, [getSmartWorking]);

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
      </HeaderContainer>
      <SmartWorkingContainer>
        <Container className="p-0">
          <ResultContainer>
            {
              list.map(item => (
                <CardContainer key={`smartworking-${item.id}`}>
                  <GeneralCard item={item} onClickFavorite={() => setAsFavorite(item.id)} onClickCard={() => handleOnClickCard(item)} type={GeneralCard.TYPE.SMARTWORKING} />
                </CardContainer>
              ))
            }
          </ResultContainer>
          {/*{list.length > 0 && (*/}
          {/*<SeeMore>*/}
          {/*  <Arial type="scrollUp">Mostra Altri</Arial>*/}
          {/*</SeeMore>*/}
          {/*)}*/}
        </Container>
      </SmartWorkingContainer>
    </>
  );
};

SmartWorking.propTypes = {
  // HOC (connect, state)
  heroImage: PropTypes.string,
  smartWorking: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getSmartWorking: PropTypes.func.isRequired,
  setSelectedSmartWorking: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { smartWorking } = state.smartworking;
    return {
      heroImage,
      smartWorking
    };
  },
  dispatch => ({
    getSmartWorking: () => dispatch({ type: SMARTWORKING_LIST_GET._REQUEST, itemPerPage: 24 }),
    setSelectedSmartWorking: (selected) => dispatch({ type: SET_SELECTED_SMARTWORKING, selected }),
    setAsFavorite: (nid) => dispatch({ type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'favourite' }),
    pushUrl: url => dispatch(push(url))
  })
)(SmartWorking);
