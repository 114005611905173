export const getLabelByKey = key => {
  switch (key) {
    case 'connected':
      return 'Connected';
    case 'improved':
      return 'Improved';
    case 'gg8-connected':
      return 'GG8-Connected';
    case 'gg8-improved':
      return 'GG8-Improved';
    case 'extra':
      return 'Extra';
    case 'total':
      return 'Tutti';

    default:
      return key;
  }
};
