import { takeLatest, put, call, select } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import stc_course from '../../api/stc_course';
import { calcPagination } from '../../../utils/pagination';
import {
  STC_COURSE_LIST_GET,
  SELECTED_STC_COURSE_GET,
  STC_COURSE_NODE_ACTION,
  STC_COURSE_COMMENTS_GET,
  STC_COURSE_COMMENTS_SET,
  STC_COURSE_PILLS_GET,
  STC_COURSE_PILL_SET_STATS,
  STC_COURSE_SHARE_PILL_COLLECTION,
  PATH_DETAILS_GET
} from '../actions/stc_course';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* stcCourseListGet({
  itemPerPage = 24,
  filters: filterList = {},
  loader = true,
  subset = true,
  pathFilter
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const {
      stcCourse: { total_items: totalCount, items = [] } = {}
    } = yield select(state => state.stc_course);
    const pagination = calcPagination(
      subset ? undefined : { from: items.length, totalCount },
      itemPerPage
    );
    if (pagination && !pagination.isLastPage) {
      const path = pathFilter;
      const filters = {
        path,
        ...pagination,
        ...filterList
      };
      const {
        data: { data: response }
      } = yield call(stc_course.getStcCourseList, filters);
      yield put({ type: STC_COURSE_LIST_GET._SUCCESS, response, subset });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_course > stcCourseListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* stcCourseListGetWatch() {
  yield takeLatest(STC_COURSE_LIST_GET._REQUEST, stcCourseListGet);
}

function* setSelectedStcCourse({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedStcCourse } = yield select(state => state.stc_course);
    const { path_contents } = yield select(state => state.app);
    if (
      path_contents.length &&
      Object.keys(selectedStcCourse).length <= 0 &&
      pagination &&
      !pagination.isLastPage
    ) {
      const path = (path_contents || []).filter(item => item.name === 'Course')
        .id;
      const filters = { path, id, ...pagination };
      const {
        data: { data: response }
      } = yield call(stc_course.getStcCourseList, filters);
      yield put({ type: SELECTED_STC_COURSE_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_course > setSelectedStcCourse', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedStcCourseWatch() {
  yield takeLatest(SELECTED_STC_COURSE_GET._REQUEST, setSelectedStcCourse);
}

function* stcCoursePillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const {
        data: { data: response = {} } = {}
      } = yield call(stc_course.getStcCoursePills, { collectionId });
      yield put({ type: STC_COURSE_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_course > stcCoursePillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcCoursePillsGetWatch() {
  yield takeLatest(STC_COURSE_PILLS_GET._REQUEST, stcCoursePillsGet);
}

function* stcCourseNodeAction({
  nid,
  action_type,
  node_type = 'pill_collection'
}) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(stc_course.stcCourseNodeAction, payload);
      yield put({ type: STC_COURSE_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite': {
          yield put({ type: STC_COURSE_LIST_GET._REQUEST });
          yield put({
            type: FAVORITE_LIST_GET._REQUEST,
            itemPerPage: 100,
            loader: false
          });
          break;
        }
        default: {
          if (nid && action_type !== 'favourite') {
            const { selectedStcCourse } = yield select(
              state => state.stc_course
            );
            if (selectedStcCourse.id)
              yield put({
                type: STC_COURSE_PILLS_GET._REQUEST,
                collectionId: selectedStcCourse.id,
                loader: false
              });
          } else {
            yield put({ type: STC_COURSE_LIST_GET._REQUEST, loader: false });
          }
          break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > stc_course > stcCourseNodeAction', err);
    }
  }
}

function* stcCourseNodeActionWatch() {
  yield takeLatest(STC_COURSE_NODE_ACTION._REQUEST, stcCourseNodeAction);
}

function* stcCourseCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(stc_course.getStcCourseComments, {
        id
      });
      yield put({ type: STC_COURSE_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_course > stcCourseCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcCourseCommentsGetWatch() {
  yield takeLatest(STC_COURSE_COMMENTS_GET._REQUEST, stcCourseCommentsGet);
}

function* stcCourseCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(stc_course.setStcCourseComments, payload);
    yield put({ type: STC_COURSE_COMMENTS_SET._SUCCESS });
    yield put({ type: STC_COURSE_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_course > stcCourseCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcCourseCommentsSetWatch() {
  yield takeLatest(STC_COURSE_COMMENTS_SET._REQUEST, stcCourseCommentsSet);
}

function* stcCoursePillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(stc_course.setStatsStcCoursePill, params);
    yield put({ type: STC_COURSE_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_STC_COURSE_GET._REQUEST, id: collectionId });
    yield put({ type: STC_COURSE_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_course > stcCoursePillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcCoursePillStatsSetWatch() {
  yield takeLatest(STC_COURSE_PILL_SET_STATS._REQUEST, stcCoursePillStatsSet);
}

function* stcCourseSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(stc_course.stcCourseSharePillCollection, data);
      yield put({ type: STC_COURSE_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_course > stcCourseSharePillCollection', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcCourseSharePillCollectionWatch() {
  yield takeLatest(
    STC_COURSE_SHARE_PILL_COLLECTION._REQUEST,
    stcCourseSharePillCollection
  );
}

function* pathDetailsGet({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data }
    } = yield call(stc_course.pathDetailsGet, id);
    yield put({ type: PATH_DETAILS_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_course > pathDetailsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* pathDetailsGetWatch() {
  yield takeLatest(PATH_DETAILS_GET._REQUEST, pathDetailsGet);
}

export default [
  stcCourseListGetWatch(),
  setSelectedStcCourseWatch(),
  stcCoursePillsGetWatch(),
  stcCourseNodeActionWatch(),
  stcCourseCommentsGetWatch(),
  stcCourseCommentsSetWatch(),
  stcCoursePillStatsSetWatch(),
  stcCourseSharePillCollectionWatch(),
  pathDetailsGetWatch()
];
