import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import routes from '../../../routes';
import edtorial from '../../api/edtorial';
import { EDITORIAL_GET, EDITORIAL_GET_BY_ALIAS } from '../actions/editorial';

function* getEditorialPagebyAlias({ alias }) {
  try {
    yield put({ type: LOADER_OPEN });
    const editorial = yield select(state => state.editorial);
    if (!(alias in editorial)) {
      const { data: { data: { key, page_items: items } = {} } = {} } = yield call(edtorial.getEdiorialbyAlias, alias);
      if (!items) yield put(push(`${routes.home}`));
      yield put({ type: EDITORIAL_GET._SUCCESS, items, key });
    }
  } catch (error) {
    yield put({ type: EDITORIAL_GET._ERROR, error });
    console.log('sagas > editorial > getEditorialPagebyAlias', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getEditorialPage() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(edtorial.getEdiorial);
    yield put({ type: EDITORIAL_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: EDITORIAL_GET._ERROR, error });
    console.log('sagas > editorial > getEditorialPage', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* EditorialGetWatch() {
  yield takeLatest(EDITORIAL_GET._REQUEST, getEditorialPage);
}

function* EditorialGetbyAliasWatch() {
  yield takeLatest(EDITORIAL_GET_BY_ALIAS._REQUEST, getEditorialPagebyAlias);
}

export default [
  EditorialGetWatch(),
  EditorialGetbyAliasWatch()
];
