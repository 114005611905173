import styled from 'styled-components';
import { Col, Container, Row } from '../../gridSystem';

const EditorialGridContainer = styled(Container)`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const LightBox = styled(Col)`
  background-color: ${props => props.theme.colors.white};
  min-height: 300px;
  color: ${props => props.theme.colors.black};
  
  >div {
    padding: 50px 45px;
  }

`;

const ColumnRight = styled(Col)`
  background: linear-gradient(to bottom,#ffa50000 153px,${props => props.theme.colors.black50} 40px);
  padding-top: 153px;
  color: ${props => props.theme.colors.white};
  @media ${props => props.theme.device.tablet} {
    padding-top: 0;
    background: unset;
    background-color: ${props => props.theme.colors.black50}
  }
`;

const BoxText = styled.div`
  padding: 40px;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ColumnLeft = styled(Col)`
    background: linear-gradient(to bottom,#ffa50000 153px,${props => props.theme.colors.black30} 40px);
    @media ${props => props.theme.device.tablet} {
        background: unset;
    }
`;

const ContainerVideo = styled(Col)`
    padding-left: 0;
    @media ${props => props.theme.device.tablet} {
        padding-left: 20px;
        margin: 20px 0;
    }
`;

const BoxTextTitle = styled.div`
    margin-bottom: 20px;
`;

const RowBottom = styled(Row)`
    height: 100%;
`;

const BoxTextDescription = styled.div`
    color: ${props => props.theme.colors.brownGrey};
`;

export {
  EditorialGridContainer, LightBox, ColumnRight, BoxText, ColumnLeft,
  ContainerVideo, BoxTextTitle, RowBottom, BoxTextDescription
};
