import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { parseQueryParams } from '../../utils/queryParams';
import { getLessonMOCK } from './mock';

export default {
  getLesson: (filters) => {
    if (shouldUseMock()) {
      return getLessonMOCK();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/content${parseQueryParams(filters)}`, 'GET');
  }
};
