import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import TimeField from 'react-simple-timefield';


import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer, ModalWrapper
} from '../Modal.style';
import {
  Button, Checkbox, CircleImage, CustomInput, FilterPill
} from '../../../atoms';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import {
  IconWrapper,
  ButtonsContainer, Colleague, ColleaguesContainer,
  ShareModalContainer, Title, IconClose, ColleagueHeader, Email, Collaborate, Flexer, WrapperRow, InvitationsContainer, Invite, ErrorDates, ErrorMsg, WrapperInput, WrapperTimePicker, IconInput
} from './style';

import Arial from '../../../typography/arial';
import { isValidForm, newForm, newFormField } from '../../../../utils/form';
import { RegexpType, validator } from '../../../../utils/validator';
import { GET_COACH_COACHEE } from '../../../../app/redux/actions/coachcoachee';

const modalId = 'PlanMeetModal';

const initialForm = newForm([
  newFormField({
    field: 'user', required: false, type: RegexpType.STRING, valid: true
  }),
  newFormField({
    field: 'title', required: true, type: RegexpType.STRING, valid: true
  }),
  newFormField({
    field: 'date', required: true, type: RegexpType.STRING, valid: true
  }),
  newFormField({
    field: 'start_hour', required: true, type: RegexpType.HOUR, valid: true
  }),
  newFormField({
    field: 'end_hour', required: true, type: RegexpType.HOUR, valid: true
  }),
  newFormField({
    field: 'url', required: false, type: RegexpType.URL, valid: true
  })
]);

const initialState = {
  user: '', title: '', start_hour: '', end_hour: '', invitations: [], date: '', url: ''
};

const PlanMeetModal = ({
  coachees, getCoachee, onClose, modalData, userID, vocabulary, coacheeReset
}) => {
  const [form, setForm] = useState(initialForm);
  const [event, setEvent] = useState(initialState);
  const [timeInvalid, setTimeInvalid] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [filteredCoachees, setFilteredCoachees] = useState([]);
  /* const [toggleAll, setToggleAll] = useState(false)*/
  useEffect(() => {
    setForm({
      ...form,
      date: {
        ...form.date,
        value: modalData.selectedDate
      }
    });
    setEvent({ ...event, date: modalData.selectedDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData])

  useEffect(() => {
    if (coachees.length === 0) getCoachee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoachee]);

  const handleOnClose = () => {
    setForm(initialForm);
    setEvent(initialState);
    coacheeReset();
    setSendNotification(false);
    //setToggleAll(false)
    onClose();
  };

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = form[field];
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value: newValue,
        valid: typeof newValue !== 'object' ? validator(type, newValue, required) : true
      }
    });
    setEvent({
      ...event,
      [field]: newValue
    });
  };

  useEffect(() => {
    const { start_hour, end_hour } = event;
    if (start_hour && end_hour) {
      const start = start_hour.replace(':', '');
      const end = end_hour.replace(':', '');
      setTimeInvalid(Number(end) <= Number(start));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const isChecked = ({ id }) => !!event.invitations.find(({ id: ID }) => ID.toString() === id?.toString());

  const coacheeToggle = (coachee = {}) => {
    if (isChecked(coachee)) {
      setEvent({ ...event, invitations: event.invitations.filter(({ id }) => id?.toString() !== coachee?.id?.toString()) });
    } else {
      setEvent({ ...event, invitations: [...event.invitations, coachee] });
    }
  };

  const handleOnConfirm = () => {
    const receiver_user_ids = event.invitations.map(invitation => invitation.id).toString().replace(/,/g, ';');
    const date = new Date(modalData.selectedDate * 1000);
    const start = event.start_hour.split(':');
    const end = event.end_hour.split(':');

    if (modalData?.onClickConfirm) {
      modalData.onClickConfirm({
        receiver_user_ids,
        title: event.title,
        url: event.url,
        start_date: moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(start[0]), parseInt(start[1]))).unix(),
        end_date: moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(end[0]), parseInt(end[1]))).unix(),
        send_notification: sendNotification
      });
    }
    handleOnClose();
  };

  const checkSubmitDisabled = () => {
    const datesInvalid = () => {
      const start = event.start_hour.replace(':', '');
      const end = event.end_hour.replace(':', '');
      return Number(end) <= Number(start);
    };

    return !isValidForm(form) || event.invitations.length === 0 || datesInvalid();
  };

  /* const handleToggleAll = () => {
    setEvent({ ...event, invitations: toggleAll ? [] : (coachees || []).filter(coachee => coachee.id !== userID) })
    setToggleAll(!toggleAll)
  }
 */
  const handleSearchStudent = (value) => {
    if (value.length >= 3) {
      setFilteredCoachees(coachees.filter(v =>
          `${v.name} ${v.surname}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || v.mail.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    } else {
      setFilteredCoachees([]);
    }
    handleOnChange('user', value);
  };

  const onClickIconClose = (invite) => {
    setEvent({ ...event, invitations: event.invitations.filter(({ id }) => id?.toString() !== invite?.id?.toString()) });
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <IconClose className="icon-close" onClick={handleOnClose} />
            <ShareModalContainer>
              <Title><Arial type="sectionTitle">Pianifica un evento</Arial></Title>
              <WrapperRow>
                <CustomInput
                  placeholder="Titolo appuntamento"
                  onChange={newValue => handleOnChange('title', newValue)}
                  value={form?.title?.value}
                  valid={form?.title?.valid}
                />
              </WrapperRow>
              <Flexer>
                <CustomInput
                  disabled
                  value={moment(new Date(modalData.selectedDate * 1000))?.format('dddd DD MMMM')}
                  icon={<IconInput className="icon-calendar" />}
                />
                <WrapperTimePicker>
                  <TimeField
                    value={form?.start_hour?.value}
                    onChange={(event, value) => handleOnChange('start_hour', value)}
                    placeholder="Ora di inizio"
                  />
                  <IconWrapper><IconInput className="icon-clock" /></IconWrapper>
                </WrapperTimePicker>
                <WrapperTimePicker>

                  <TimeField
                    value={form?.end_hour?.value}
                    onChange={(event, value) => handleOnChange('end_hour', value)}
                  />
                  <IconWrapper><IconInput className="icon-clock" /></IconWrapper>
                </WrapperTimePicker>

              </Flexer>
              {timeInvalid && <ErrorDates><ErrorMsg>Orario di fine minore dell'orario di inizio</ErrorMsg></ErrorDates>}
              <WrapperRow>
                <CustomInput
                  placeholder="Incolla il link a Google Meet (facoltativo)"
                  onChange={newValue => handleOnChange('url', newValue)}
                  value={form?.url?.value}
                  valid={form?.url?.valid}
                  icon={<IconInput className="icon-mappamondo" />}
                />
              </WrapperRow>
              <WrapperRow>
                <WrapperInput>
                  <CustomInput
                    placeholder="Cerca utenti..."
                    onChange={newValue => handleSearchStudent(newValue)}
                    value={form?.user?.value}
                    valid={form?.user?.valid}
                    icon={<IconInput className="icon-search" />}
                  />
                </WrapperInput>
              </WrapperRow>
              <ColleaguesContainer>
                {filteredCoachees.length > 0
                  && (
                    <ColleagueHeader>
                      <Arial type="quoteDetail">Utente</Arial>
                      <Arial type="quoteDetail">Email id</Arial>
                    </ColleagueHeader>
                  )
                }
                {(filteredCoachees || []).map(coachee => (
                  <Colleague onClick={() => coacheeToggle(coachee)} key={coachee?.id}>
                    <Checkbox checked={isChecked(coachee)} name={coachee.mail} />
                    <CircleImage src={coachee?.picture} name={`${coachee?.name} ${coachee?.surname}`} />
                    <Arial><Collaborate>{coachee?.name}&nbsp;{coachee?.surname}</Collaborate></Arial>
                    <Arial type="newsDescription">
                      <Email>{coachee?.mail}</Email>
                    </Arial>
                  </Colleague>
                ))}
              </ColleaguesContainer>
              <InvitationsContainer>
                {(event.invitations || []).map(invite => (
                  <Invite key={invite?.id}>
                    <FilterPill
                      label={`${invite?.name} ${invite?.surname}`}
                      value={invite}
                      onRemove={() => onClickIconClose(invite)}
                    />
                  </Invite>
                ))}
              </InvitationsContainer>
              <ButtonsContainer>
                <Button type={Button.TYPE.SECONDARY} onClick={handleOnClose} btnText="Annulla" />
                <Button disabled={checkSubmitDisabled()} onClick={() => handleOnConfirm()} btnText="Pianifica Meeting" />
              </ButtonsContainer>
            </ShareModalContainer>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

PlanMeetModal.propTypes = {
  // HOC (connect, state)
  coachees: PropTypes.arrayOf(PropTypes.object),
  modalData: PropTypes.object,
  userID: PropTypes.number,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  getCoachee: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  coacheeReset: PropTypes.func.isRequired
};

PlanMeetModal.id = modalId;

export default connect(
  state => {
    const { coach_coachee_list: coachees = [] } = state.coachcoachee;
    const { data: modalData = {} } = state.modal;
    const { data: { id: userID } = {} } = state.user;
    const { vocabulary } = state.app;
    return {
      coachees, modalData, userID, vocabulary
    };
  },
  dispatch => ({
    getCoachee: () => dispatch({ type: GET_COACH_COACHEE._REQUEST }),
    coacheeReset: () => dispatch({ type: GET_COACH_COACHEE._SUCCESS, coachees: [] }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(PlanMeetModal);
