const baseSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  lazyLoad: true,
  arrows: true,
  centerPadding: 12
};

export const Settings = {
  ...baseSettings,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        ...baseSettings,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 50
      }
    },
    {
      breakpoint: 1280,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 10
      }
    },
    {
      breakpoint: 1024,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 10
      }
    },
    {
      breakpoint: 995,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true
      }
    },
    {
      breakpoint: 950,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        centerPadding: 20
      }
    },
    {
      breakpoint: 660,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        centerPadding: 60,
        dots: true
      }
    },
    {
      breakpoint: 645,
      settings: {
        ...baseSettings,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 10,
        dots: true
      }
    },
    {
      breakpoint: 500,
      settings: {
        ...baseSettings,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 1,
        dots: true
      }
    },
    {
      breakpoint: 420,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        centerPadding: 40,
        dots: true
      }
    },
    {
      breakpoint: 380,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        centerPadding: 20,
        dots: true
      }
    }
  ]
};

export const SettingsAgenda = {
  ...baseSettings,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 50
      }
    },
    {
      breakpoint: 1024,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        centerPadding: 100
      }
    },
    {
      breakpoint: 995,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true
      }
    },
    {
      breakpoint: 950,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true
      }
    },
    {
      breakpoint: 770,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true
      }
    },
    {
      breakpoint: 660,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 180
      }
    },
    {
      breakpoint: 645,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 160
      }
    },
    {
      breakpoint: 605,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 130
      }
    },
    {
      breakpoint: 545,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 20
      }
    }
  ]
};
