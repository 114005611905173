import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import compulsarytraining from '../../api/compulsarytraining';
import {
  COMPULSARY_TRAINING_COMMENTS_GET, COMPULSARY_TRAINING_COMMENTS_SET,
  COMPULSARY_TRAINING_COURSES_GET, COMPULSARY_TRAINING_LIST_GET,
  COMPULSARY_TRAINING_NODE_ACTION, SELECTED_COMPULSARY_TRAINING_GET
} from '../actions/compulsarytraining';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';


function* compulsaryTrainingListGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { compulsary_training: { total_items: totalCount, courses = [] } = {} } = yield select(state => state.compulsarytraining);
    const pagination = calcPagination(subset ? undefined : { from: courses.length , totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'formazione_obbligatoria', ...filters, ...pagination };
      const { data: { data: response } } = yield call(compulsarytraining.getCompulsaryTrainingList, complete_filters);
      yield put({ type: COMPULSARY_TRAINING_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > compulsarytraining > compulsaryTrainingListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* compulsaryTrainingListGetWatch() {
  yield takeLatest(COMPULSARY_TRAINING_LIST_GET._REQUEST, compulsaryTrainingListGet);
}

function* setSelectedCompulsaryTraining({ id, itemPerPage = 1, refresh = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_compulsary_training } = yield select(state => state.compulsarytraining);
    if ((selected_compulsary_training && Object.keys(selected_compulsary_training).length <= 0 && pagination && !pagination.isLastPage) || refresh) {
      const complete_filters = { type: 'formazione_obbligatoria', id, ...pagination };
      const { data: { data: response } } = yield call(compulsarytraining.getCompulsaryTrainingList, complete_filters);
      yield put({ type: SELECTED_COMPULSARY_TRAINING_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > compulsarytraining > setSelectedCompulsaryTraining', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedCompulsaryTrainingWatch() {
  yield takeLatest(SELECTED_COMPULSARY_TRAINING_GET._REQUEST, setSelectedCompulsaryTraining);
}

function* compulsaryTrainingCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(compulsarytraining.getCompulsaryTraningCourses, { course_id: collectionId });
      yield put({ type: COMPULSARY_TRAINING_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > compulsarytraining > compulsaryTrainingCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* compulsaryTrainingCoursesGetWatch() {
  yield takeLatest(COMPULSARY_TRAINING_COURSES_GET._REQUEST, compulsaryTrainingCoursesGet);
}

function* compulsaryTrainingNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(compulsarytraining.compulsaryTrainingNodeAction, payload);
      yield put({ type: COMPULSARY_TRAINING_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: COMPULSARY_TRAINING_LIST_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: COMPULSARY_TRAINING_LIST_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_compulsary_training } = yield select(state => state.compulsarytraining);
            if (selected_compulsary_training.id) yield put({ type: COMPULSARY_TRAINING_COURSES_GET._REQUEST, collectionId: selected_compulsary_training.id, loader: false });
          } else {
            yield put({ type: COMPULSARY_TRAINING_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > compulsarytraining > compulsaryTrainingNodeAction', err);
    }
  }
}

function* compulsaryTrainingNodeActionWatch() {
  yield takeLatest(COMPULSARY_TRAINING_NODE_ACTION._REQUEST, compulsaryTrainingNodeAction);
}

function* compulsaryTrainingCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(compulsarytraining.getCompulsaryTrainingComments, { id });
      yield put({ type: COMPULSARY_TRAINING_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > compulsarytraining > compulsaryTrainingCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* compulsaryTrainingCommentsGetWatch() {
  yield takeLatest(COMPULSARY_TRAINING_COMMENTS_GET._REQUEST, compulsaryTrainingCommentsGet);
}

function* compulsaryTrainingCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(compulsarytraining.setCompulsaryTrainingComments, payload);
    yield put({ type: COMPULSARY_TRAINING_COMMENTS_SET._SUCCESS });
    yield put({ type: COMPULSARY_TRAINING_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > compulsarytraining > compulsaryTrainingCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* compulsaryTrainingCommentsSetWatch() {
  yield takeLatest(COMPULSARY_TRAINING_COMMENTS_SET._REQUEST, compulsaryTrainingCommentsSet);
}

export default [
  compulsaryTrainingListGetWatch(),
  setSelectedCompulsaryTrainingWatch(),
  compulsaryTrainingCoursesGetWatch(),
  compulsaryTrainingNodeActionWatch(),
  compulsaryTrainingCommentsGetWatch(),
  compulsaryTrainingCommentsSetWatch()
];
