import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  GeneralCardContainer,
  ProgressBar,
  Tag,
  AdditionalInfo,
  Favorite,
  Icon,
  Completed,
  InformationContainer,
  Title,
  CardDetails,
  Banner,
  Icons,
  AdditionalInfoContainer
} from './style';

import { IconCompleted } from '../../../app/pages/Collection/style';

import Gill from '../../typography/gill';
import Gothic from '../../../ui/typography/gothic';

const componentVisibleDefault = {
  progress: false,
  tag: false,
  addInfo: false,
  favorite: false,
  completed: false,
  banner: false,
  contents: false,
  duration: false
};

const GeneralCard = ({
  item,
  type,
  colorTag,
  onClickCard,
  onClickFavorite,
  isAgenda
}) => {
  const [data, setData] = useState(item);
  const [isFavorite, setIsFavorite] = useState(false);
  const [componentVisible, setComponentVisible] = useState(
    componentVisibleDefault
  );
  useEffect(() => {
    const { user_actions: { favourite = false } = {} } = data;
    setIsFavorite(favourite || data.favorite);
  }, [data]);

  // eslint-disable-next-line consistent-return
  const getBanner = (status, expiration_status) => {
    if (status === 'completed') return 'completato';

    if (expiration_status === 'expiring' && status !== 'completed')
      return 'in scadenza';
    if (expiration_status === 'expired' && status !== 'completed')
      return 'scaduto';

    if (
      status === 'registered' &&
      expiration_status !== 'expiring' &&
      expiration_status !== 'expired'
    )
      return 'da iniziare';
    if (
      status === 'in_progress' &&
      expiration_status !== 'expiring' &&
      expiration_status !== 'expired'
    )
      return 'in corso';
  };

  const getAddInfoObbligatory = (
    status,
    completion_date,
    expiration_status,
    soft_expiration_date
  ) => {
    if (status === 'completed')
      return `Completato il ${moment(new Date(completion_date * 1000)).format(
        'DD/MM/YYYY'
      )}`;

    return `Scadenza il ${moment(new Date(soft_expiration_date * 1000)).format(
      'DD/MM/YYYY'
    )}`;
  };

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (type) {
      case GeneralCard.TYPE.PRODUCT: {
        const {
          title,
          duration,
          category: tag,
          cover,
          user_actions: { favourite: favorite = false } = {},
          percentage,
          status,
          scorm_amount
        } = item;
        setData({
          title,
          duration: `${duration} Min totali`,
          tag,
          cover: cover || '',
          favorite,
          percentage,
          contents:
            scorm_amount > 1
              ? `${scorm_amount} oggetti formativi`
              : `${scorm_amount} oggetto formativo`
        });
        setComponentVisible({
          ...componentVisibleDefault,
          favorite: true,
          addInfo: true,
          tag: !!tag,
          duration: true,
          progress: status !== 'completed' && percentage > 0,
          completed: status === 'completed',
          contents: scorm_amount > 0
        });
        break;
      }
      case GeneralCard.TYPE.SMARTWORKING: {
        const {
          title,
          category,
          cover,
          status,
          duration,
          user_actions: { favourite: favorite = false } = {}
        } = item;
        setData({
          title,
          cover: cover ? cover?.url : '',
          addInfo: category,
          duration: `${duration} Min totali`,
          favorite
        });
        setComponentVisible({
          ...componentVisibleDefault,
          duration: true,
          completed: status === 'completed',
          favorite: true
        });
        break;
      }
      case GeneralCard.TYPE.OBBLIGATORY: {
        const {
          title,
          duration,
          category: tag,
          cover,
          status,
          expiration_status,
          completion_date,
          soft_expiration_date,
          scorm_amount
        } = item;
        getBanner(status, expiration_status);
        setData({
          title,
          duration: `${duration} Min totali`,
          addInfo: getAddInfoObbligatory(
            status,
            completion_date,
            expiration_status,
            soft_expiration_date
          ),
          banner: getBanner(status, expiration_status),
          tag,
          cover: cover || '',
          contents:
            scorm_amount > 1
              ? `${scorm_amount} oggetti formativi`
              : `${scorm_amount} oggetto formativo`
        });
        setComponentVisible({
          ...componentVisibleDefault,
          tag: true,
          duration: true,
          addInfo: true,
          banner: true,
          contents: scorm_amount > 0
        });
        break;
      }
      case GeneralCard.TYPE.COSACAMBIA: {
        const {
          title,
          duration,
          category: tag,
          cover,
          user_actions: { favourite: favorite = false } = {},
          percentage,
          status
        } = item;
        setData({
          title,
          duration: `${duration} Min totali`,
          tag,
          cover: cover || '',
          favorite,
          percentage
        });
        setComponentVisible({
          ...componentVisibleDefault,
          favorite: !!onClickFavorite,
          addInfo: true,
          tag: !!tag,
          duration: true,
          progress: status !== 'completed' && percentage > 0,
          completed: status === 'completed'
        });
        break;
      }
      case GeneralCard.TYPE.ROLETRAINING: {
        const {
          title,
          duration,
          category: tag,
          cover,
          percentage,
          status
        } = item;
        setData({
          title,
          duration: `${duration} Min totali`,
          tag,
          cover: cover || '',
          percentage
        });
        setComponentVisible({
          ...componentVisibleDefault,
          addInfo: true,
          tag: !!tag,
          duration: true,
          progress: status !== 'completed' && percentage > 0,
          completed: status === 'completed'
        });
        break;
      }
      case GeneralCard.TYPE.COLLECTION:
      default: {
        const {
          title,
          duration,
          category,
          cover,
          status,
          percentage,
          user_actions: { favourite: favorite = false },
          scorm_amount
        } = item;
        setData({
          title,
          cover,
          percentage,
          duration: `${duration} Min totali`,
          tag: category,
          contents:
            scorm_amount > 1
              ? `${scorm_amount} oggetti formativi`
              : `${scorm_amount} oggetto formativo`,
          favorite
        });
        setComponentVisible({
          ...componentVisibleDefault,
          progress: status !== 'completed' && percentage > 0,
          completed: status === 'completed',
          tag: !!category,
          duration: true,
          favorite: true,
          contents: scorm_amount > 0
        });
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, type]);

  const handleOnclickFavorite = e => {
    if (onClickFavorite) onClickFavorite(data.id);
    setIsFavorite(!isFavorite);
    e.stopPropagation();
  };

  const handleOnClickCard = () => {
    if (onClickCard) onClickCard();
  };

  return (
    <GeneralCardContainer
      onClick={handleOnClickCard}
      cover={data?.cover}
      isAgenda={isAgenda}
    >
      {componentVisible.progress && (
        <ProgressBar percentage={data.percentage} />
      )}
      <AdditionalInfoContainer>
        {componentVisible.tag && (
          <Tag tagType={type} color={colorTag}>
            <Gill type="generalCardTag">{data.tag}</Gill>
          </Tag>
        )}
        {componentVisible.addInfo && (
          <AdditionalInfo>
            <Gill type="addInfoGeneralCard">{data.addInfo}</Gill>
          </AdditionalInfo>
        )}
      </AdditionalInfoContainer>
      <Icons>
        {componentVisible.completed && (
          <Completed>
            <IconCompleted className="icon-completato" />
          </Completed>
        )}
        {componentVisible.favorite && (
          <Favorite right>
            <Icon
              onClick={handleOnclickFavorite}
              isFavorite={isFavorite}
              className={isFavorite ? 'icon-preferiti' : 'icon-heart-empty'}
            />
          </Favorite>
        )}
      </Icons>
      <InformationContainer>
        <Title>
          <Gothic
            type="collectionCardTitle"
            configuration={{ fontWeight: 900 }}
          >
            {data.title}
          </Gothic>
        </Title>
        {componentVisible.contents && (
          <CardDetails>
            <Gill type="collectionCardFooter">
              <Icon className="icon-icon-video" size={10} />
              {data.contents}
            </Gill>
          </CardDetails>
        )}
        {/* {componentVisible.duration && (
          <CardDetails>
            <Gill type="collectionCardFooter"><Icon className="icon-clock" size={10} />{data.duration}</Gill>
          </CardDetails>
        )} */}
      </InformationContainer>
      {componentVisible.banner && (
        <Banner status={data.banner}>
          <Gill type="bannerGeneralCard">{data.banner}</Gill>
        </Banner>
      )}
    </GeneralCardContainer>
  );
};

GeneralCard.TYPE = {
  COLLECTION: 'COLLECTION',
  PRODUCT: 'PRODUCT',
  SMARTWORKING: 'SMARTWORKING',
  OBBLIGATORY: 'OBBLIGATORY',
  COSACAMBIA: 'COSACAMBIA',
  ROLETRAINING: 'ROLETRAINING'
};

GeneralCard.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string,
  colorTag: PropTypes.string,
  onClickCard: PropTypes.func,
  onClickFavorite: PropTypes.func
};

GeneralCard.defaultProps = {
  type: GeneralCard.TYPE.COLLECTION
};

export default GeneralCard;
