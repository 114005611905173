import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer, ModalWrapper
} from '../Modal.style';
import {
  Button, CustomInput
} from '../../../atoms';
import { AUTH_RESET_PASSWORD, MODAL_CLOSE } from '../../../../app/redux/actions';
import {
  ButtonsContainer, ResetPasswordModalContainer, Title, IconClose
} from './style';
import {
  getValues, isValidForm, newForm, newFormField
} from '../../../../utils/form';
import { RegexpType, validator } from '../../../../utils/validator';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';


const modalId = 'ResetPasswordModal';

const initialForm = newForm([
  newFormField({
    field: 'email', required: true, type: RegexpType.EMAIL, valid: true
  })
]);

const ResetPasswordModal = ({
  onClose, vocabulary, resetPassword
}) => {
  const [form, setForm] = useState(initialForm);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = form[field];
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value: newValue,
        valid: typeof newValue !== 'object' ? validator(type, newValue, required) : true
      }
    });
  };

  const handleOnClose = () => {
    setForm(initialForm);
    onClose();
  };

  const handleOnConfirm = () => {
    resetPassword(getValues(form));
    handleOnClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent} width={580} height="250" heightUnit="px">
            <IconClose className="icon -close" onClick={handleOnClose}>X</IconClose>
            <ResetPasswordModalContainer>
              <Title>{getValueVocabulary(vocabulary.reset_password, 'reset_password')}</Title>
              <CustomInput
                placeholder="Mail aziendale"
                onChange={newValue => handleOnChange('email', newValue)}
                value={form?.email?.value}
                valid={form?.email?.valid}
              />
              <ButtonsContainer>
                <Button type={Button.TYPE.SECONDARY} onClick={handleOnClose} btnText="Annulla" />
                <Button
                  onClick={handleOnConfirm}
                  btnText="Conferma"
                  disabled={!isValidForm(form)}
                />
              </ButtonsContainer>
            </ResetPasswordModalContainer>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  resetPassword: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

ResetPasswordModal.id = modalId;

export default connect(
  state => {
    const { vocabulary } = state.app;
    return { vocabulary };
  },
  dispatch => ({
    resetPassword: (params) => dispatch({ type: AUTH_RESET_PASSWORD._REQUEST, params }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ResetPasswordModal);
