import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import news from '../../api/news';
import { calcPagination } from '../../../utils/pagination';
import {
  NEWS_GET, GET_NEWS_DETAIL, GET_NEWS_FAVORITE, GET_NEWS_HIGHLIGHTED, SHARE_NEWS, SET_VIEW_NEWS
} from '../actions/news';
import routes from '../../../routes';

function* newsGet({
  carousel = false, itemPerPage = 12, filters, title, promoted, subset
}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { news: { count: totalCount, items = [] } = {} } = yield select(state => state.news);
    const pagination = calcPagination(subset || carousel ? undefined : { from: items.length , totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = {
        ...pagination,
        title,
        promoted,
        category_id: filters?.categories,
        date: filters?.months,
        type_id: filters?.type
      };
      const { data: { data: response } = {} } = yield call(news.getNews, complete_filters);
      yield put({ type: NEWS_GET._SUCCESS, response: {...response, from: items.length} });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > news > newsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* NewsGetWatch() {
  yield takeLatest(NEWS_GET._REQUEST, newsGet);
}

function* getNewsDetails({ news_id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(news.getNewsItem, news_id);
    if (!response) yield put(push(`${routes.news.path}`));
    yield put({ type: GET_NEWS_DETAIL._SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_NEWS_DETAIL._ERROR, error });
    console.log('sagas > news > getNewsDetails', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsDetailsWatcher() {
  yield takeLatest(GET_NEWS_DETAIL._REQUEST, getNewsDetails);
}

function* getNewsFavorite({ itemPerPage = 100 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { favorites: { total_items: totalCount, count, from } = {} } = yield select(state => state.news);
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const { data: { data: response } } = yield call(news.getFavoriteNews, pagination);
      yield put({ type: GET_NEWS_FAVORITE._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > news > getNewsFavorite', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsFavoriteWatch() {
  yield takeLatest(GET_NEWS_FAVORITE._REQUEST, getNewsFavorite);
}

function* getNewsHighlighted({
  itemPerPage = 10
}) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = {
        ...pagination,
        promoted: true
      };
      const { data: { data: response } = {} } = yield call(news.getNews, complete_filters);
      yield put({ type: GET_NEWS_HIGHLIGHTED._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > news > getNewsHighlighted', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsHighlightedWatch() {
  yield takeLatest(GET_NEWS_HIGHLIGHTED._REQUEST, getNewsHighlighted);
}

function* shareNews({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(news.shareNews, data);
      yield put({ type: SHARE_NEWS._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > news > shareNews', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* shareNewsWatch() {
  yield takeLatest(SHARE_NEWS._REQUEST, shareNews);
}

function* setViewNews({ nid }) {
  if (nid) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(news.setViewNews, { nid, action_type: 'views', node_type: 'news' });
      yield put({ type: SET_VIEW_NEWS._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > news > setViewNews', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* setViewNewsWatch() {
  yield takeLatest(SET_VIEW_NEWS._REQUEST, setViewNews);
}

export default [
  NewsGetWatch(),
  getNewsDetailsWatcher(),
  getNewsFavoriteWatch(),
  getNewsHighlightedWatch(),
  shareNewsWatch(),
  setViewNewsWatch()
];
