import React from 'react';
import Arial from '../../typography/arial';

import { Mask, Title, Description } from './style';

const ComingSoon = ({ description }) => (
  <Mask>
    <Title>
      <Arial type="comingSoonTitle">Coming soon!</Arial>
    </Title>
    <Description>
      <Arial type="EditorialGridTitle" configuration={{ fontWeight: 'normal' }}>{description}</Arial>
    </Description>
  </Mask>
);

export default ComingSoon;
