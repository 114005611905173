import produce from 'immer';
import {
  COMPULSARY_TRAINING_COMMENTS_GET,
  COMPULSARY_TRAINING_COURSES_GET,
  COMPULSARY_TRAINING_LIST_GET,
  SELECTED_COMPULSARY_TRAINING_GET,
  SET_SELECTED_COMPULSARY_TRAINING, SET_SELECTED_FILTERS_COMPULSARY_TRAINING
} from '../actions/compulsarytraining';
import {LOCATION_CHANGE} from "redux-first-history";
import routes from "../../../routes";

const initialState = {
  compulsary_training: {},
  selected_compulsary_training: {},
  scorms: [],
  comments: [],
  isLoadingData: false,
  selected_filters: {}
};

const compulsaryTrainingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {

    case LOCATION_CHANGE: {
      const {payload} = action;
      if(payload.pathname.indexOf(routes.compulsarytraining.path) < 0){
        draft.selected_filters = initialState.selected_filters;
      }
      break;
    }

    case COMPULSARY_TRAINING_LIST_GET._SUCCESS: {
      const { response } = action;
      const { from, courses } = response;
      const list = (!from || from <= 0) ? courses : [...state.compulsary_training.courses, ...courses];
      draft.compulsary_training = {
        ...response,
        courses: list
      };
      break;
    }

    case COMPULSARY_TRAINING_COURSES_GET._REQUEST: {
      draft.isLoadingData = true;
      break;
    }

    case COMPULSARY_TRAINING_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      draft.isLoadingData = initialState.isLoadingData;
      break;
    }

    case SELECTED_COMPULSARY_TRAINING_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_compulsary_training = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_COMPULSARY_TRAINING: {
      const { selected } = action;
      draft.selected_compulsary_training = selected;
      break;
    }

    case COMPULSARY_TRAINING_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case SET_SELECTED_FILTERS_COMPULSARY_TRAINING: {
      const { filters } = action;
      draft.selected_filters = filters;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default compulsaryTrainingReducer;
