import { push } from 'redux-first-history';
import { getValueVocabulary } from './getValueVocabulary';
import { store } from '../app/redux/store';

export function youtube_parser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export function getPaths(location) {
  const loc = location || window.location;
  const pathname = loc ? loc.pathname || '' : '';
  const paths = pathname.split('/');
  const params = {};
  loc.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = decodeURIComponent(decodeURIComponent(value.toString()));
  });

  return {
    pathname,
    paths,
    params
  };
}

export function shouldUseMock() {
  return (
    process.env.REACT_APP_DEPLOY_ENV === 'LOCAL' &&
    Number(process.env.REACT_APP_ENABLE_MOCK) === 1
  );
}

export function defaultTabsValue(
  vocabulary,
  // extra,
  improved,
  connected
) {
  return [
    {
      label: `${getValueVocabulary(vocabulary.guidebookDescription)}`,
      value: 'all'
    }
    // {
    //   label: `Contenuti extra (${extra})`,
    //   value: 'extra'
    // },
    // {
    //   label: `${getValueVocabulary(
    //     vocabulary.stcImproved
    //   )} (${improved})`,
    //   value: 'improved'
    // },
    // {
    //   label: `${getValueVocabulary(
    //     vocabulary.stcConnected
    //   )} (${connected})`,
    //   value: 'connected'
    // }
    // {
    //   label: `Form. obbligatoria (${obbligatory})`,
    //   value: 'obbligatory'
    // },
    // {
    //   label: `${getValueVocabulary(
    //     vocabulary.roleTrainingTitle
    //   )} (${roleTraining})`,
    //   value: 'roleTraining'
    // },
    // {
    //   label: `${getValueVocabulary(
    //     vocabulary.proceduresTitle
    //   )} (${processesProcedures})`,
    //   value: 'processesProcedures'
    // },
    // {
    //   label: `${getValueVocabulary(
    //     vocabulary.productsServicesTitle
    //   )} (${productsServices})`,
    //   value: 'productsServices'
    // },
    /*{
        label: `${getValueVocabulary(vocabulary.rubricChange, 'rubricChange')} (${rubricChange})`,
        value: 'rubricChange'
      }, */
    // {
    //   label: `${getValueVocabulary(vocabulary.cosaCambia)} (${whatChange})`,
    //   value: 'whatChange'
    // },
    // {
    //   label: `${getValueVocabulary(vocabulary.lifeIspTitle)} (${lifeIsp})`,
    //   value: 'lifeIsp'
    // },
    /*{
      label: `${getValueVocabulary(vocabulary.proceduresTitle)} (${course})`,
      value: 'process'
    },*/
    /*{
      label: `${getValueVocabulary(vocabulary.productsTitle)} (${products})`,
      value: 'products'
    },*/
    // {
    //   label: `${getValueVocabulary(vocabulary.newsTitle)} (${news})`,
    //   value: 'news'
    // }
  ];
}

export function getTagColor(categories = [], category) {
  return categories.find(item => item.id === category)?.color;
}

export const handleClickCourseUrl = route => {
  if (route === '') return;
  const isInternal = route.startsWith('/');
  isInternal ? store.dispatch(push(route)) : window.open(route, '_blank');
};
