import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  EditorialMosaicCard, EditorialBanner, EditorialQuote, EditorialAbout, EditorialGrid
} from '../../../ui/components';
import { EditorialPageContainer } from './style';
import { EDITORIAL_GET } from '../../redux/actions/editorial';

const EditorialPage = ({ getEditorialPage, editorial, editorial: { final_file_articles, title } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getEditorialPage();
  }, [getEditorialPage]);

  const page = Object.keys(editorial).reduce((acc, key) => {
    switch (key) {
      case 'witness':
      case 'subtitle_article':
      case 'central_file':
      case 'central_article_1':
      case 'central_article_2':
        return {
          ...acc,
          [key]: editorial[key][0].data
        };
      case 'cover':
        return {
          ...acc,
          [key]: editorial[key].url
        };
      default:
        return {
          ...acc,
          [key]: editorial[key]
        };
    }
  }, {});

  return (
    <EditorialPageContainer>
      {(title || page?.cover) && <EditorialBanner title={title} image={page?.cover} />}
      {page?.witness && <EditorialQuote content={page?.witness} />}
      {page?.subtitle_article && <EditorialAbout image={page?.central_image?.url} content={page?.subtitle_article} />}
      <EditorialGrid
        file={page?.central_file}
        text={page?.subtitle_article?.body}
        article1={page?.central_article_1}
        article2={page?.central_article_2}
      />
      <EditorialMosaicCard content={final_file_articles} />
    </EditorialPageContainer>
  );
};

EditorialPage.propTypes = {
  // HOC (connect, state)
  editorial: PropTypes.object,
  // HOC (connect, dispatch)
  getEditorialPage: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    editorial: state.editorial
  }),
  dispatch => ({
    getEditorialPage: () => dispatch({ type: EDITORIAL_GET._REQUEST })
  })
)(EditorialPage);
