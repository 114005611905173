import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  CourseContainer, InfoContainer, Title, SeeAll, HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import routes from '../../../routes';
import { Settings } from '../Collections/settings';
import { GeneralCard } from '../../atoms';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { NewsCardContainer } from '../NewsCardCarousel/style';
import { ColumnCarousel } from '../RubricChangeCarousel/style';
import {getTagColor} from "../../../utils/common";
import {
  PROCESSES_PROCEDURES_NODE_ACTION,
  SET_SELECTED_PROCESSES_PROCEDURES
} from "../../../app/redux/actions/processesprocedures";

const ProcessesProceduresCarousel = ({
  setSelectedProcessesProcedures, setAsFavoriteProcessesProcedures,
  vocabulary, pushUrl, list, categories
}) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const defaultSetting = Settings;

  const handleOnClickCard = (item) => {
    setSelectedProcessesProcedures(item);
    pushUrl(`${routes.processesProcedures.path}/${item.id}`);
  };

  return (
    products && products.length && (
      <CourseContainer>
        <Container className="p-0">
          <Row>
            <Col lg={12} sm={12}>
              <InfoContainer>
                <HeaderContent>
                  <Title><Arial type="sectionTitle">{getValueVocabulary(vocabulary.proceduresTitle, 'proceduresTitle')}</Arial></Title>
                  <SeeAll onClick={() => pushUrl(`${routes.processesProcedures.path}`)}>
                    <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                    <span className="icon-arrow-right" />
                  </SeeAll>
                </HeaderContent>
              </InfoContainer>
            </Col>
            <ColumnCarousel sm={12}>
              <CustomCarousel config={{ ...defaultSetting }}>
                {(products || []).slice(0, 12).map(obbligatory => <NewsCardContainer>
                  <GeneralCard
                      item={obbligatory}
                      onClickCard={() => handleOnClickCard(obbligatory)}
                      onClickFavorite={() => setAsFavoriteProcessesProcedures(obbligatory.id)}
                      colorTag={getTagColor(categories, obbligatory.category_id)}
                      type={GeneralCard.TYPE.COSACAMBIA}
                  />
                </NewsCardContainer>)}
              </CustomCarousel>
            </ColumnCarousel>
          </Row>
        </Container>
      </CourseContainer>
    )
  );
};

ProcessesProceduresCarousel.propTypes = {
  list: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,

  // HOC (connect, state)
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  setSelectedProcessesProcedures: PropTypes.func.isRequired,
  setAsFavoriteProcessesProcedures: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    setSelectedProcessesProcedures: (selected) => dispatch({ type: SET_SELECTED_PROCESSES_PROCEDURES, selected }),
    setAsFavoriteProcessesProcedures: (nid) => dispatch({
      type: PROCESSES_PROCEDURES_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(ProcessesProceduresCarousel);
