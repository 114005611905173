import styled from 'styled-components';

const FootContainer = styled.footer`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.mainColorGradient};
  z-index: ${props => props.theme.zIndex.foot};
  padding: 10px 0;

  height: 125px;
  width: 100%;

  & span {
    margin-left: 0;
  }
`;

const FootSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  /*> div:not(:last-child) {
    margin-right: 15px;
  }*/
  
  > div:first-child {
    margin-left: 20px;
  }

  > div:last-child {
    width: 117px;
  }
  img {
    height: 50px;
    width: auto;
  }

  @media ${props => props.theme.device.mobileL} {
    > div:first-child {
      margin-left: 0;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 20px;
    > div {
      width: fit-content;
    }
  }
`;

export { FootContainer, FootSection };
