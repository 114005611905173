import React from 'react';
import PropTypes from 'prop-types';

import { Container, LineBar, PercentageLine, Percentage } from './style';

import { getStateColor } from '../../../utils/course-state';

const ProgressBar = ({ percentage, hasPercentage, state }) => (
  <Container>
    <LineBar background={getStateColor(state).secondary}>
      <PercentageLine
        percentage={percentage}
        color={getStateColor(state).primary}
      />
    </LineBar>
    {hasPercentage && <Percentage>{percentage || 0}%</Percentage>}
  </Container>
);

ProgressBar.STATE = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  REGISTERED: 'registered'
};

ProgressBar.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasPercentage: PropTypes.bool,
  state: PropTypes.string
};

ProgressBar.defaultProps = {
  percentage: 0,
  hasPercentage: false,
  state: ProgressBar.STATE.COMPLETED
};

export default ProgressBar;
