import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from '../../../ui/gridSystem';
import {
  NewsDetailContainer, Title, NewsDetailHeader, Date,
  Text, NewsDetailAttachment, Pdf, Icon, Subtitle, ContainerMedia
} from './style';
import { BackButton } from '../../../ui/atoms';
import { GET_NEWS_DETAIL } from '../../redux/actions/news';
import Arial from '../../../ui/typography/arial';
import Video from '../../../ui/atoms/Video';
import dateFormat from '../../../utils/date-format';

const NewsDetail = ({
  news_id, getNewsDetail,
  news: {
    title,
    subtitle,
    media,
    file_download,
    overlay_media_text,
    category,
    creation_date,
    body
  }
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsDetail(news_id);
  }, [getNewsDetail, news_id]);

  return (
    <NewsDetailContainer>
      <Container className="p-0">
        <BackButton />
        <NewsDetailHeader>
          <Title>
            <Arial type="newsDetailTitle">{title}</Arial>
            <Subtitle>
              <Arial type="newsDetailDescription">{subtitle}</Arial>
            </Subtitle>
          </Title>
          <Date>
            <Icon className="icon-clock" />
            <Arial type="newsDateItalic">{dateFormat(creation_date, 'news')}</Arial>
          </Date>
        </NewsDetailHeader>
        {(media[0]?.data?.video?.url || media[0]?.data?.image?.url) && (
        <ContainerMedia>
          <Video
            videoUrl={media[0]?.data?.video?.url || null}
            videoTitle={overlay_media_text || null}
            videoSubTitle={null}
            imageUrl={media[0]?.data?.image?.url || null}
          />
        </ContainerMedia>
        )}
        {body && <Text dangerouslySetInnerHTML={{ __html: body }} />}
        <NewsDetailAttachment>
          <Arial type="newsTitle">Download allegati</Arial>
          {(file_download || []).map(file => (
            <Pdf href={file?.url} download target="_blank" key={file?.url}>
              <Icon className="icon-document" />
              <Arial type="newsAttachment">{file?.filename}</Arial>
            </Pdf>
          ))}
          {/*<Share>*/}
          {/*  <Icon className="icon-share" />*/}
          {/*  <Arial type="newsAttachment">condividi</Arial>*/}
          {/*</Share>*/}
        </NewsDetailAttachment>
      </Container>
    </NewsDetailContainer>
  );
};

NewsDetail.propTypes = {
  news_id: PropTypes.string.isRequired,
  // HOC (connect, state)
  news: PropTypes.object,

  // HOC (connect, dispatch)
  getNewsDetail: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { selected_news: news } = state.news;
    return { news };
  },
  dispatch => ({
    getNewsDetail: news_id => dispatch({ type: GET_NEWS_DETAIL._REQUEST, news_id })
  })
)(NewsDetail);
