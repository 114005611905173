import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import { getEditorialMock, getEditorialMockbyAlias } from './mock';

export default {
  getEdiorialbyAlias: (alias) => {
    if (shouldUseMock()) {
      return getEditorialMockbyAlias(alias);
    }
    return apiCall(`${baseUrl}${extendUrl}editorial_page${parseQueryParams(alias)}`, 'GET');
  },
  getEdiorial: () => {
    if (shouldUseMock()) {
      return getEditorialMock();
    }
    return apiCall(`${baseUrl}${extendUrl}editorial_group`, 'GET');
  }
};
