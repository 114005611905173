import styled from 'styled-components';

const Title = styled.div`
  color: ${props => props.theme.colors.white};
  min-height: 54px;
  margin-bottom: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CourseDetailHeader = styled.div`
  padding: 10px;
`;

const Duration = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  font-size: 10px;
  color: ${props => props.theme.colors.gray2};
  margin-right: 6px;
`;

const Time = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${props => props.theme.colors.doveGray};
`;

const CourseCardContainer = styled.div`
  position: relative;
  border-radius: 4px;
  margin: auto;
  width: 280px;
  height: auto;
  
  transition: ${props => props.theme.constants.transitionBase};
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transform: scale(1.03);
  }

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  ${Icon} {
    color:  ${props => props.theme.colors.darkerGrey};
  }

  ${Time} {
    color:  ${props => props.theme.colors.darkerGrey};
  }
`;

const CourseContent = styled.div`
  position: absolute;
  top: 8px;
  right: ${props => (props.right ? 10 : null)}px;
  left: ${props => (props.left ? 10 : null)}px;
  
  span:not(:last-child) {
    margin-right: 6px;
  }
  
  span {
    color: ${props => props.theme.colors.white};
  }
`;

const Image = styled.div`
  height: 132px;
  width: 100%;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
`;

const CourseDetails = styled.div`
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.greyDark};
  border-radius: 0 0 4px 4px;
  margin-top: -10px;
  position: relative;
  width: 100%;
`;

const CourseDetailsFooter = styled.div`
  padding: 10px;
  width: 100%;
  height: 30px;
  border-top: 1px solid ${props => props.theme.colors.doveGray};
  color: ${props => props.theme.colors.greyLighter};
  display: inline-flex;
  align-items: center;
  
  div:not(:last-child){
    border-right: 1px solid ${props => props.theme.colors.doveGray};
    padding-right: 10px;
    margin-right: 10px;
  }
  
  div{
    span {
      margin-right: 4px;
      font-size: 12px;
    }
  }
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
`;

const Play = styled.div`
  width: 16px;
  height: 15px;
  font-size: 11px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.brownGrey};
`;

export {
  CourseCardContainer,
  Image,
  CourseDetails,
  Title,
  CourseDetailHeader,
  Time,
  CourseContent,
  CourseDetailsFooter,
  Duration,
  Detail,
  Play
};
