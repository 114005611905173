import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import localeIt from '@fullcalendar/core/locales/it';

import { connect } from 'react-redux';
import queryString from 'query-string';
import { push } from 'redux-first-history';
import { Col, Row, Container } from '../../../ui/gridSystem';
import {
  BoxContainer,
  Header,
  Body,
  CalendarContainer,
  NoResultContainer,
  WrapperHeader
} from './style';
import Gill from '../../../ui/typography/gill';
import { ProductCardsContainer } from '../../../ui/components';
import {
  AGENDA_GET,
  APPOINTMENT_DELETE,
  APPOINTMENT_INSERT,
  APPOINTMENT_RETRIEVE
} from '../../redux/actions/agenda';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { TOJ_LIST_GET } from '../../redux/actions/toj';
import { parseQueryParams } from '../../../utils/queryParams';
import routes from '../../../routes';
import { NoResult } from '../../../ui/atoms';
import PlanMeetModal from '../../../ui/components/Modals/PlanMeetModal';
import { MODAL_OPEN } from '../../redux/actions';

const IntegrationAgenda = ({
  agenda,
  getAgenda,
  vocabulary,
  mediaIsPhone,
  mediaIsLaptop,
  mediaIsTablet,
  getTojList,
  tojList,
  location,
  pushUrl,
  planMeetModal,
  user_type,
  appointmentInsert,
  appointmentRetrieve,
  appointmentDelete
}) => {
  const { selectedDate } = queryString.parse(location.search);
  const [start, setStart] = useState(
    moment(new Date())
      .startOf('month')
      .unix()
  );
  const [end, setEnd] = useState(
    moment(new Date())
      .endOf('month')
      .unix()
  );
  useEffect(() => {
    getAgenda({ starting_date: start, ending_date: end });
    appointmentRetrieve({ starting_date: start, ending_date: end });
  }, [getAgenda, start, end, appointmentRetrieve]);

  moment.locale('it-IT');
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const fullcalendarRef = useRef(null);

  useEffect(() => {
    const filterItem = (start, end) =>
      start && end
        ? moment(
            selectedDate ? new Date(selectedDate * 1000) : new Date()
          ).isSame(moment(new Date(start)), 'day') ||
          (moment(
            selectedDate ? new Date(selectedDate * 1000) : new Date()
          ).isAfter(moment(new Date(start))) &&
            moment(
              selectedDate ? new Date(selectedDate * 1000) : new Date()
            ).isBefore(moment(new Date(end))))
        : moment(
            selectedDate ? new Date(selectedDate * 1000) : new Date()
          ).isSame(moment(new Date(start)), 'day');

    const selectedEventsList = events.filter(event =>
      filterItem(event.start, event.end)
    );

    setSelectedEvents(selectedEventsList);
    if (!selectedDate)
      pushUrl(
        `${routes.integrationagenda.path}${parseQueryParams({
          selectedDate: moment(new Date()).unix()
        })}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, selectedDate]);

  useEffect(() => {
    if (selectedDate && !mediaIsPhone) {
      const calendarApi = fullcalendarRef.current.getApi();
      calendarApi.gotoDate(new Date(selectedDate * 1000));

      const selectedDateTile = moment(new Date(selectedDate * 1000)).format(
        'YYYY-MM-DD'
      );
      const dayTiles = document.querySelectorAll('.fc-daygrid-day') || [];
      dayTiles.forEach(tile =>
        tile.getAttribute('data-date') === selectedDateTile
          ? tile.classList.add('active-tile')
          : tile.classList.remove('active-tile')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    const formattedToj = (tojList || []).reduce((acc, value) => {
      const tojArray = value.slots.map(slot => ({
        ...value.toj_data,
        slot,
        agenda_type: 'toj',
        tutee_id: value.tutee_id,
        duration: value.duration,
        completed: value.completed
      }));
      return [...acc, ...tojArray];
    }, []);

    const groupEvents = Object.keys(agenda).reduce((acc, key) => {
      const agendaList = agenda[key].map(item => ({
        ...item,
        agenda_type: key
      }));
      return [...acc, ...agendaList];
    }, []);

    const formattedEvents = [...groupEvents, ...formattedToj]
      .filter(
        event =>
          !!event.calendar_date ||
          !!event.soft_expiration_date ||
          !!event.slot ||
          !!event.start_date
      )
      .map(event => {
        const { calendar_date, soft_expiration_date, agenda_type } = event;
        switch (agenda_type) {
          case 'toj':
            return {
              ...event,
              start: new Date(event.slot.startDateTimestamp),
              end: new Date(event.slot.endDateTimestamp)
            };
          case 'pill_collection':
            return {
              ...event,
              agenda_type: event.category,
              start: calendar_date
                ? new Date(calendar_date * 1000)
                : new Date(soft_expiration_date * 1000)
            };
          case 'appointments':
            const link = event.url;
            delete event.url;
            return {
              ...event,
              link,
              start: new Date(event.start_date * 1000),
              end: new Date(event.end_date * 1000)
            };
          default:
            return {
              ...event,
              start: calendar_date
                ? new Date(calendar_date * 1000)
                : new Date(soft_expiration_date * 1000)
            };
        }
      });
    setEvents([...formattedEvents]);

    if (selectedDate && !mediaIsPhone) {
      const selectedDateTile = moment(new Date(selectedDate * 1000)).format(
        'YYYY-MM-DD'
      );
      const dayTiles = document.querySelectorAll('.fc-daygrid-day') || [];
      dayTiles.forEach(tile =>
        tile.getAttribute('data-date') === selectedDateTile
          ? tile.classList.add('active-tile')
          : tile.classList.remove('active-tile')
      );
    }
  }, [agenda, mediaIsPhone, selectedDate, tojList]);

  const onClickDate = calendarDate => {
    const { date } = calendarDate;
    pushUrl(
      `${routes.integrationagenda.path}${parseQueryParams({
        selectedDate: moment(date || calendarDate).unix()
      })}`
    );
  };

  const onClickEvent = eventInfo => {
    const {
      event: { start: date = new Date() }
    } = eventInfo;
    pushUrl(
      `${routes.integrationagenda.path}${parseQueryParams({
        selectedDate: moment(date).unix()
      })}`
    );
  };

  const setStartEnd = fetchInfo => {
    setStart(moment(fetchInfo?.start).unix());
    setEnd(moment(fetchInfo?.end).unix());
    return events;
  };

  const config = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    fixedWeekCount: false,
    locale: localeIt,
    dateClick: onClickDate,
    eventClick: onClickEvent,
    dayMaxEvents: 1,
    ref: fullcalendarRef,
    events: (fetchInfo, successCallback, failureCallback) => {
      const datas = setStartEnd(fetchInfo);
      if (datas?.length) {
        successCallback(datas);
      }
      failureCallback([]);
    }
  };

  const handleDeleteEvent = params => {
    appointmentDelete({
      delete: params,
      retrieve: { starting_date: start, ending_date: end }
    });
  };

  const currentDate = new Date();
  currentDate.setHours('00');
  currentDate.setMinutes('00');
  currentDate.setSeconds('00');

  return (
    <Container>
      <Row>
        <Col lg={mediaIsLaptop ? 8 : 6}>
          <CalendarContainer>
            {mediaIsPhone ? (
              <Calendar
                view="month"
                tileClassName={({ date }) =>
                  events.filter(event =>
                    moment(new Date(date)).isSame(
                      moment(new Date(event.start)),
                      'day'
                    )
                  ).length > 0
                    ? 'active'
                    : ''
                }
                onClickDay={onClickDate}
              />
            ) : (
              <FullCalendar {...config} />
            )}
          </CalendarContainer>
        </Col>
        <Col lg={mediaIsLaptop ? 12 : 6}>
          <BoxContainer>
            <WrapperHeader>
              <Header>
                <Gill type="description18">
                  {moment(new Date(selectedDate * 1000))?.format(
                    'dddd DD MMMM'
                  )}
                </Gill>
              </Header>
            </WrapperHeader>
            <Body>
              {selectedEvents.length > 0 ? (
                <ProductCardsContainer
                  type="agenda"
                  productList={selectedEvents}
                  handleDeleteEvent={handleDeleteEvent}
                />
              ) : (
                <NoResultContainer>
                  <NoResult />
                </NoResultContainer>
              )}
            </Body>
          </BoxContainer>
        </Col>
      </Row>
      <PlanMeetModal />
    </Container>
  );
};

IntegrationAgenda.propTypes = {
  // MediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,

  // HOC (connect, state)
  vocabulary: PropTypes.object,
  agenda: PropTypes.object,
  tojList: PropTypes.array,
  location: PropTypes.object,
  user_type: PropTypes.string,
  appointments: PropTypes.array,

  // HOC (connect, dispatch)
  getAgenda: PropTypes.func.isRequired,
  getTojList: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  planMeetModal: PropTypes.func.isRequired,
  appointmentInsert: PropTypes.func.isRequired,
  appointmentRetrieve: PropTypes.func.isRequired,
  appointmentDelete: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { agenda } = state.agenda;
    const { tojList } = state.toj;
    const { location } = state.router;
    const {
      data: { user_type }
    } = state.user;

    return {
      vocabulary,
      agenda,
      tojList,
      location,
      user_type
    };
  },
  dispatch => ({
    getAgenda: filters => dispatch({ type: AGENDA_GET._REQUEST, filters }),
    getTojList: filters => dispatch({ type: TOJ_LIST_GET._REQUEST, filters }),
    pushUrl: url => dispatch(push(url)),
    planMeetModal: modalData => dispatch({ type: MODAL_OPEN, ...modalData }),
    appointmentInsert: params =>
      dispatch({ type: APPOINTMENT_INSERT._REQUEST, params }),
    appointmentRetrieve: params =>
      dispatch({ type: APPOINTMENT_RETRIEVE._REQUEST, params }),
    appointmentDelete: params =>
      dispatch({ type: APPOINTMENT_DELETE._REQUEST, params })
  })
)(withMediaQueries(IntegrationAgenda));
