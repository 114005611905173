import produce from 'immer';
import { PILLS_RESET } from '../actions/course';

import {
  SELECTED_STC_COURSE_GET,
  STC_COURSE_LIST_GET,
  SET_SELECTED_STC_COURSE,
  STC_COURSE_COMMENTS_GET,
  STC_COURSE_PILLS_GET,
  PATH_DETAILS_GET
} from '../actions/stc_course';

const initialState = {
  stcCourse: {},
  selectedStcCourse: {},
  pills: [],
  comments: [],
  testLink: ''
};

const stcCourseReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case STC_COURSE_LIST_GET._SUCCESS: {
      const { response, subset } = action;
      const { items: pills } = response;
      const items = pills || [];
      const list = subset || !state.stcCourse.items || state.stcCourse.items <= 0
        ? items
        : [...state.stcCourse.items, ...items];
      draft.stcCourse = {
        ...response,
        items: list
      };
      break;
    }

    case STC_COURSE_PILLS_GET._REQUEST: {
      draft.pills = initialState.pills;
      break;
    }

    case STC_COURSE_PILLS_GET._SUCCESS: {
      const { response } = action;
      const {
        pill_items,
        link,
        previous_pill_collection,
        next_pill_collection
      } = response[0];
      const uri = link ? link.uri : '';
      draft.pills = pill_items.reduce((acc, val, index, array) => {
        if (!array[index - 1]) {
          return [
            ...acc,
            {
              ...val,
              blocked: false,
              index,
              last_item: index === array.length - 1
            }
          ];
        }
        if (array[index - 1] && acc[index - 1].stats.status === 'completed') {
          return [
            ...acc,
            {
              ...val,
              blocked: false,
              index,
              last_item: index === array.length - 1
            }
          ];
        }
        return [
          ...acc,
          {
            ...val,
            blocked: true,
            index,
            last_item: index === array.length - 1
          }
        ];
      }, []);
      draft.testLink = uri;
      draft.prevCourse = previous_pill_collection;
      draft.nextCourse = next_pill_collection;
      break;
    }

    case SELECTED_STC_COURSE_GET._REQUEST: {
      draft.selectedStcCourse = initialState.selectedStcCourse;
      break;
    }

    case SELECTED_STC_COURSE_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedStcCourse = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_STC_COURSE: {
      const { selected } = action;
      draft.selectedStcCourse = selected;
      break;
    }

    case STC_COURSE_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case PATH_DETAILS_GET._SUCCESS: {
      draft.pathDetails = action.data;
      break;
    }

    case PILLS_RESET: {
      draft.pills = initialState.pills;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default stcCourseReducer;
