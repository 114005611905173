import {
  apiCall, baseUrl, extendUrl, extendUrlV1
} from './config';
import { shouldUseMock } from '../../utils/common';
import {
  emailCheckMock,
  getLoginMock,
  getLogoutMock,
  signupFormGetMock,
  signupFormSubmitMock,
  signupPathGetMock
} from './mock';

export default {
  login: params => {
    if (shouldUseMock()) {
      if (params?.otp_code) return getLoginMock(true); // set false to getError
      return getLoginMock(false); // set true to skip otp
    }
    return apiCall(`${baseUrl}${extendUrl}login`, 'POST', params);
  },
  resetPassword: params => {
    if (shouldUseMock()) {
      return getLoginMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}user/password_restore`,
      'POST',
      params
    );
  },
  logout: () => {
    if (shouldUseMock()) {
      return getLogoutMock();
    }
    return apiCall(`${baseUrl}${extendUrl}logout`, 'GET');
  },
  reset: params => apiCall(`${baseUrl}${extendUrl}user/password_reset`, 'POST', params),
  signupCheckEmail: email => {
    if (shouldUseMock()) {
      return emailCheckMock();
    }
    return apiCall(`${baseUrl}${extendUrlV1}email_available`, 'POST', {
      mail: email
    });
  },
  signupPathGet: () => {
    if (shouldUseMock()) {
      return signupPathGetMock();
    }
    return apiCall(`${baseUrl}${extendUrlV1}registration_path`, 'GET');
  },
  signupFormGet: pathLabel => {
    if (shouldUseMock()) {
      return signupFormGetMock();
    }
    return apiCall(
      `${baseUrl}${extendUrlV1}registration_module/${pathLabel}`,
      'GET'
    );
  },
  signupFormSubmit: body => {
    if (shouldUseMock()) {
      return signupFormSubmitMock();
    }
    return apiCall(`${baseUrl}${extendUrlV1}user_responses`, 'POST', body);
  }
};
