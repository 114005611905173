import styled from 'styled-components';

const EditorialAboutContainer = styled.div`
`;

const EditorialAboutBox = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  padding: 50px;
  min-height: 312px;
  margin-left: 10px;
  @media ${props => props.theme.device.tablet} {
    margin-left: 0px;
  }
`;

const EditorialAboutBoxTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const EditorialAboutRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -130px;
`;

const EditorialAboutImage = styled.div`
  height: 400px;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.gray};
`;

export {
  EditorialAboutContainer, EditorialAboutBox,
  EditorialAboutBoxTitle, EditorialAboutRow, EditorialAboutImage,
  Text
};
