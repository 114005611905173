import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';
import Gill from '../../typography/gill';

import {
  CourseContainer,
  InfoContainer,
  Title,
  SeeAll,
  HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import routes from '../../../routes';
import { PRODUCTS_LIST_GET } from '../../../app/redux/actions/product';
import { FAVORITE_LIST_GET } from '../../../app/redux/actions/favorite';
import { MOST_VIEWED_LIST_GET } from '../../../app/redux/actions/mostviewed';
import { Settings, SettingsAgenda } from '../Collections/settings';
import { Button, GeneralCard } from '../../atoms';
import { SMARTWORKING_NODE_ACTION } from '../../../app/redux/actions/smartworking';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import AgendaCard from '../../atoms/AgendaCard';
import NewsCard from '../NewsCard';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';
import { BaseSettings as BaseSettingsAgenda } from '../NewsHighlightedCarousel/settings';
import { NewsCardContainer } from '../NewsCardCarousel/style';
import { ColumnCarousel } from '../RubricChangeCarousel/style';
import AppointmentCard from '../../atoms/AppointmentCard';

const ProductCardsContainer = ({
  title = 'Prodotti',
  type,
  pushUrl,
  product,
  favorites,
  mostviewed,
  getProducts,
  getFavorites,
  getMostViewed,
  vocabulary,
  setAsFavoriteSmartWorking,
  productList,
  mediaIsPhone,
  isAgenda,
  handleDeleteEvent
}) => {
  const [products, setProducts] = useState([]);
  const [route, setRoute] = useState(routes.products.path);
  useEffect(() => {
    switch (type) {
      case 'favorites': {
        setRoute(routes.favorites.path);
        if (favorites.length <= 0) getFavorites();
        break;
      }
      case 'most_viewed': {
        setRoute(routes.mostViewed.path);
        if (mostviewed.length <= 0) getMostViewed();
        break;
      }
      default:
        getProducts();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    switch (type) {
      case 'favorites': {
        setProducts(favorites);
        break;
      }
      case 'most_viewed': {
        setProducts(mostviewed);
        break;
      }
      case 'agenda':
      case 'course_toj': {
        setProducts(productList);
        break;
      }
      default: {
        const { products: { items = [] } = {} } = product;
        setProducts(items);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, favorites, mostviewed, productList]);

  const getRedirectPath = ({ path, id }) => {
    pushUrl(`${routes.course.path}/${path}/${id}`);
  };

  const defaultSetting = type === 'agenda' ? SettingsAgenda : Settings;

  const getRenderCard = item => {
    switch (item.agenda_type || type) {
      case 'news':
        return (
          <NewsCard
            key={`product-news-${item.id}`}
            isAgenda={type === 'agenda'}
            news={item}
            onClick={() => pushUrl(`${routes.news.path}/${item.id}`)}
          />
        );
      //case 'agenda':
      //return <AgendaCard agenda={agenda} btnText="Iscritto" btnType={Button.TYPE.THIRD} /* onClick={() => pushUrl(routes.integrationagendadetails.path)} */ />;
      case 'toj':
        return (
          <AgendaCard
            key={`product-toj-${item.id}`}
            agenda={item}
            btnText="Iscritto"
            btnType={Button.TYPE.PRIMARY}
            onClick={() =>
              pushUrl(`${routes.toj.path}/${item.id}?tutee_id=${item.tutee_id}`)
            }
          />
        );
      case 'appointments':
        return (
          <AppointmentCard
            key={`product-appointments-${item.id}`}
            agenda={item}
            btnText="Iscritto"
            btnType={Button.TYPE.PRIMARY}
            handleDelete={handleDeleteEvent}
          />
        );
      default:
        return (
          <GeneralCard
            key={`product-${type}-${item.id}`}
            item={item}
            onClickCard={() => getRedirectPath(item)}
            onClickFavorite={() => setAsFavoriteSmartWorking(item.id)}
            type={GeneralCard.TYPE.SMARTWORKING}
            colorTag={item.category_color}
            isAgenda={type === 'agenda'}
          />
        );
    }
  };

  return products && products.length ? (
    <CourseContainer>
      <Container className="p-0">
        <Row>
          {type !== 'agenda' && type !== 'course_toj' && (
            <Col lg={12} sm={12}>
              <InfoContainer>
                <HeaderContent>
                  <Title>
                    <Gill type="sectionTitle">{title}</Gill>
                  </Title>
                  <SeeAll onClick={() => pushUrl(route)}>
                    <Gill type="newsAttachment" vocabulary="showAll">
                      {getValueVocabulary(vocabulary.showAll, 'showAll')}
                    </Gill>
                    <span className="icon-arrow-right" />
                  </SeeAll>
                </HeaderContent>
              </InfoContainer>
            </Col>
          )}
          <ColumnCarousel sm={12}>
            {mediaIsPhone && type === 'agenda' ? (
              <>
                {(products || []).slice(0, 12).map(item => getRenderCard(item))}
              </>
            ) : (
              <CustomCarousel
                config={
                  isAgenda ? { ...BaseSettingsAgenda } : { ...defaultSetting }
                }
              >
                {(products || []).slice(0, 12).map(item => (
                  <NewsCardContainer key={`nws-${item?.id}`}>
                    {getRenderCard(item)}
                  </NewsCardContainer>
                ))}
              </CustomCarousel>
            )}
          </ColumnCarousel>
        </Row>
      </Container>
    </CourseContainer>
  ) : (
    <></>
  );
};

ProductCardsContainer.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  productList: PropTypes.array,

  // HOC (connect, state)
  product: PropTypes.object,
  favorites: PropTypes.array,
  mostviewed: PropTypes.array,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getFavorites: PropTypes.func.isRequired,
  getMostViewed: PropTypes.func.isRequired,
  setAsFavoriteSmartWorking: PropTypes.func.isRequired,
  handleDeleteEvent: PropTypes.func
};

export default connect(
  state => {
    const { product } = state;
    const { items: favorites = [] } = state.favorite;
    const { items: mostviewed = [] } = state.mostviewed;
    const { vocabulary, path_contents } = state.app;
    return {
      product,
      mostviewed,
      favorites,
      vocabulary,
      path_contents
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getProducts: () =>
      dispatch({ type: PRODUCTS_LIST_GET._REQUEST, itemPerPage: 100 }),
    getFavorites: () =>
      dispatch({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100 }),
    getMostViewed: () =>
      dispatch({ type: MOST_VIEWED_LIST_GET._REQUEST, itemPerPage: 100 }),
    setAsFavoriteSmartWorking: nid =>
      dispatch({
        type: SMARTWORKING_NODE_ACTION._REQUEST,
        nid,
        action_type: 'favourite',
        node_type: 'pill_collection'
      })
  })
)(withMediaQueries(ProductCardsContainer));
