import { createAsyncActionType } from './index';

export const STC_CONNECTED_LIST_GET = createAsyncActionType('stc_connected', 'STC_CONNECTED_LIST_GET');
export const SET_SELECTED_STC_CONNECTED = '@@stc_connected/SET_SELECTED_STC_CONNECTED';
export const SELECTED_STC_CONNECTED_GET = createAsyncActionType('stc_connected', 'SELECTED_STC_CONNECTED_GET');
export const STC_CONNECTED_PILLS_GET = createAsyncActionType('stc_connected', 'STC_CONNECTED_PILLS_GET');
export const STC_CONNECTED_NODE_ACTION = createAsyncActionType('stc_connected', 'STC_CONNECTED_NODE_ACTION');
export const STC_CONNECTED_COMMENTS_GET = createAsyncActionType('stc_connected', 'STC_CONNECTED_COMMENTS_GET');
export const STC_CONNECTED_COMMENTS_SET = createAsyncActionType('stc_connected', 'STC_CONNECTED_COMMENTS_SET');
export const STC_CONNECTED_PILL_SET_STATS = createAsyncActionType('stc_connected', 'STC_CONNECTED_PILL_SET_STATS');
export const STC_CONNECTED_SHARE_PILL_COLLECTION = createAsyncActionType('stc_connected', 'STC_CONNECTED_SHARE_PILL_COLLECTION');
