import styled from 'styled-components';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px ${props => props.theme.colors.lighterGrey};
  background-color: ${props => props.theme.colors.white};
  padding: 20px 30px 4px 30px;
  
  @media ${props => props.theme.device.mobileL} {
    padding: 20px 15px 4px 15px;
  }
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryBlack};
  margin-bottom: 5px;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.gray};
`;

export {
  Container, Title, Label, InformationContainer
};
