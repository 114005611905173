import {
  takeLatest, put, call, select, takeEvery
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
// eslint-disable-next-line import/no-cycle
import processesprocedures from '../../api/processesprocedures';
import {
  PROCESSES_PROCEDURES_COMMENTS_GET, PROCESSES_PROCEDURES_COMMENTS_SET,
  PROCESSES_PROCEDURES_COURSES_GET, PROCESSES_PROCEDURES_LIST_GET,
  PROCESSES_PROCEDURES_NODE_ACTION, SELECTED_PROCESSES_PROCEDURES_GET
} from '../actions/processesprocedures';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* processesProceduresListGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { processes_procedures: { [filters.company_cluster]: { total_items: totalCount, courses = [] } = {} } = {} } = yield select(state => state.processesprocedures);
    const pagination = calcPagination(subset ? undefined : { from: courses.length, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = {
        type: 'processi_procedure', ...filters, ...pagination, order_code: 'asc'
      };
      const { data: { data: response } } = yield call(processesprocedures.getProcessesProceduresList, complete_filters);
      yield put({ type: PROCESSES_PROCEDURES_LIST_GET._SUCCESS, response, processType: filters.company_cluster || 'all' });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > processesprocedures > processesProceduresListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* processesProceduresListGetWatch() {
  yield takeEvery(PROCESSES_PROCEDURES_LIST_GET._REQUEST, processesProceduresListGet);
}

function* setSelectedProcessesProcedures({ id, itemPerPage = 1, refresh = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_processes_procedures } = yield select(state => state.processesprocedures);
    if ((selected_processes_procedures && Object.keys(selected_processes_procedures).length <= 0 && pagination && !pagination.isLastPage) || refresh) {
      const complete_filters = { type: 'processi_procedure', id, ...pagination };
      const { data: { data: response } } = yield call(processesprocedures.getProcessesProceduresList, complete_filters);
      yield put({ type: SELECTED_PROCESSES_PROCEDURES_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > processesprocedures > setSelectedProcessesProcedures', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedProcessesProceduresWatch() {
  yield takeLatest(SELECTED_PROCESSES_PROCEDURES_GET._REQUEST, setSelectedProcessesProcedures);
}

function* processesProceduresCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(processesprocedures.getProcessesProceduresCourses, { course_id: collectionId });
      yield put({ type: PROCESSES_PROCEDURES_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > processesprocedures > processesProceduresCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* processesProceduresCoursesGetWatch() {
  yield takeLatest(PROCESSES_PROCEDURES_COURSES_GET._REQUEST, processesProceduresCoursesGet);
}

function* processesProceduresNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(processesprocedures.processesProceduresNodeAction, payload);
      yield put({ type: PROCESSES_PROCEDURES_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: PROCESSES_PROCEDURES_LIST_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: PROCESSES_PROCEDURES_LIST_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_processes_procedures } = yield select(state => state.processesprocedures);
            if (selected_processes_procedures.id) yield put({ type: PROCESSES_PROCEDURES_COURSES_GET._REQUEST, collectionId: selected_processes_procedures.id, loader: false });
          } else {
            yield put({ type: PROCESSES_PROCEDURES_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > processesprocedures > processesProceduresNodeAction', err);
    }
  }
}

function* processesProceduresNodeActionWatch() {
  yield takeLatest(PROCESSES_PROCEDURES_NODE_ACTION._REQUEST, processesProceduresNodeAction);
}

function* processesProceduresCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(processesprocedures.getProcessesProceduresComments, { id });
      yield put({ type: PROCESSES_PROCEDURES_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > processesprocedures > processesProceduresCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* processesProceduresCommentsGetWatch() {
  yield takeLatest(PROCESSES_PROCEDURES_COMMENTS_GET._REQUEST, processesProceduresCommentsGet);
}

function* processesProceduresCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(processesprocedures.setProcessesProceduresComments, payload);
    yield put({ type: PROCESSES_PROCEDURES_COMMENTS_SET._SUCCESS });
    yield put({ type: PROCESSES_PROCEDURES_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > processesprocedures > processesProceduresCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* processesProceduresCommentsSetWatch() {
  yield takeLatest(PROCESSES_PROCEDURES_COMMENTS_SET._REQUEST, processesProceduresCommentsSet);
}

export default [
  processesProceduresListGetWatch(),
  setSelectedProcessesProceduresWatch(),
  processesProceduresCoursesGetWatch(),
  processesProceduresNodeActionWatch(),
  processesProceduresCommentsGetWatch(),
  processesProceduresCommentsSetWatch()
];
