import produce from 'immer';
import { GET_COACH_COACHEE } from '../actions/coachcoachee';
import {AUTH_LOGOUT} from "../actions";

const initialState = {
  coach_coachee_list: []
};

const coachCoacheeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_COACH_COACHEE._SUCCESS:
      draft.coach_coachee_list = action.coach_coachee;
      break;

    case AUTH_LOGOUT._SUCCESS: {
      draft = initialState;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default coachCoacheeReducer;
