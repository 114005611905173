import routes from '../routes';

export const nameForUrl = (name = '') =>
  name
    ?.toLowerCase()
    ?.trim()
    ?.replace(' ', '_');

export const getUrl = (name = '') => {
  let url = routes.course.path + '/' + nameForUrl(name);
  return url;
};
