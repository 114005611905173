import styled from 'styled-components';

const TeacherBoxContainer = styled.div`
  width: 380px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.01);
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 15px 20px 30px 20px;
  margin-bottom: 20px;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 300px;
    height: auto;
  }
`;
const TeacherHeader = styled.div`
  width: 100%;
  padding: 9px 0 14px 0;
  height: 49px;
  width: 112%;
  position: relative;

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #323232;
    margin-left: -20px;
    position: absolute;
    left: 0;
    top: 49px;
  }
`;
const TeacherDetails = styled.div`
  display: flex;
  margin: 20px 0 20px 0;
  align-items: center;
`;

const TeacherPhoto = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  margin-right: 20px;
`;

const CircleNameContainer = styled.div`
  background-color: #beddb9;
  border-radius: 50%;
  height: 60px;
  text-align: center;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;


const CircleName = styled.div`
line-height: 1;
  color: #258900;
`;

const Text = styled.div`
  div.name {
    margin-bottom: 5px;
  }
`;

const TeacherName = styled.div`
  margin-bottom: 5px;
`;

export {
  TeacherBoxContainer,
  TeacherHeader,
  TeacherDetails,
  TeacherPhoto,
  Text,
  TeacherName,
  CircleNameContainer,
  CircleName
};
