import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../gridSystem';
import { EditorialBannerContainer, EditorialBannerTitle, BackButtonContainer } from './style';
import Arial from '../../typography/arial';
import { BackButton } from '../../atoms';

const EditorialBanner = ({
  title, image
}) => (
  <>
    <BackButtonContainer>
      <Container className="p-0">
        <BackButton />
      </Container>
    </BackButtonContainer>
    <EditorialBannerContainer img={image}>
      <Container className="p-0">
        <EditorialBannerTitle md={{ offset: 1, size: 10 }}>
          <Arial type="newsBannerTitle">{title}</Arial>
        </EditorialBannerTitle>
      </Container>
    </EditorialBannerContainer>
  </>
);

EditorialBanner.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string
};

export default EditorialBanner;
