import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorialCardContainer, EditorialCardDetails, Title,
  Description, EditorialCardImage
} from './style';
import Arial from '../../typography/arial';

const EditorialCard = ({
  onClick, type, title, text, image
}) => (
  <EditorialCardContainer type={type}>
    <EditorialCardImage onClick={onClick} img={image}>
      <EditorialCardDetails>
        <Title><Arial type="editorialCardTitle">{title}</Arial></Title>
        <Arial type="editorialCardText"><Description dangerouslySetInnerHTML={{ __html: text }} /></Arial>
      </EditorialCardDetails>
    </EditorialCardImage>
  </EditorialCardContainer>
);

EditorialCard.TYPE = {
  LARGE: 'large',
  SMALL: 'small',
  FULL: null
};

EditorialCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.oneOf(Object.values(EditorialCard.TYPE))
};

EditorialCard.defaultProps = {
  type: EditorialCard.TYPE.FULL
};

export default EditorialCard;
