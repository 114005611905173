import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import queryString from 'query-string';
import { Container } from '../../../ui/gridSystem';
import { AUTH_RESET } from '../../redux/actions';
import LogoSrc from '../../../ui/assets/img/STCLogo.png';
import {
  AuthContainer,
  Background,
  FormContainer,
  InputWrapper
} from './styles';
import { Button, CustomInput, Logo } from '../../../ui/atoms';
import heroImage from '../../../ui/assets/img/home_page.jpg';
import {
  getValues,
  isValidForm,
  newForm,
  newFormField
} from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import Gill from '../../../ui/typography/gill';
import { LogoContainer, ShowHidePasswordIcon, Subtitle, Title } from '../Auth/styles';

const initialAuthForm = newForm([
  // newFormField({
  //   field: 'username', required: true, type: RegexpType.STRING, valid: true
  // }),
  newFormField({
    field: 'old_password',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'new_password',
    required: true,
    type: RegexpType.RESET_PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'confermapwd',
    required: true,
    type: RegexpType.RESET_PASSWORD,
    valid: true
  })
]);

const AuthReset = ({ username, resetPassword, location }) => {
  const { first_login } = queryString.parse(location.search);
  const [authForm, setAuthForm] = useState(initialAuthForm);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid:
          (typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true)
          && (field === 'confermapwd'
            ? authForm.new_password.value === newValue
            : true)
      }
    });
  };

  const handleShowPassword = (n) => {
    const elem = document.querySelector(`input.${n}`);
    const icon = document.querySelector(`span.icon-visualizzazioni.${n}`);
    if (elem && elem.getAttribute('type') === 'password') {
      elem.setAttribute('type', 'text');
      icon.classList.add('visible');
    } else {
      elem.setAttribute('type', 'password');
      icon.classList.remove('visible');
    }
  };

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => { handleOnChange('username', username); }, [username]);

  return (
    <AuthContainer>
      <Background img={heroImage} />
      <Container>
        <Gill>
          <Title>Bentornato,</Title>
          <Subtitle>Aggiorna la tua password e resta connesso!</Subtitle>
        </Gill>
        <FormContainer>
          <LogoContainer>
            <Logo src={LogoSrc} size={Logo.SIZE.LARGE} />
          </LogoContainer>
          <Gill type="newsTitle">Aggiorna la tue credenziali</Gill>
          {/*<InputWrapper>*/}
          {/*  <CustomInput*/}
          {/*    placeholder="Username"*/}
          {/*    disabled*/}
          {/*    onBlur={newValue => handleOnChange('username', newValue)}*/}
          {/*    value={authForm?.username?.value}*/}
          {/*    valid={authForm?.username?.valid}*/}
          {/*    error="L'username non è valida"*/}
          {/*  />*/}
          {/*</InputWrapper>*/}
          <InputWrapper>
            <CustomInput
              className="oldPassword"
              placeholder={first_login ? 'Password attuale' : 'Password'}
              onChange={newValue => handleOnChange('old_password', newValue)}
              type="password"
              value={authForm?.old_password?.value}
              valid={authForm?.old_password?.valid}
            />
            <ShowHidePasswordIcon onClick={() => handleShowPassword('oldPassword')} className="icon-visualizzazioni oldPassword" />
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              className="newPassword"
              placeholder="Nuova Password"
              onChange={newValue => handleOnChange('new_password', newValue)}
              type="password"
              value={authForm?.new_password?.value}
              valid={authForm?.new_password?.valid}
              error="La password deve contenere almeno 1 carattere speciale, 1 numero, 1 lettera maiuscola, 1 lettera minuscola e deve essere lunga almeno 8 caratteri"
            />
            <ShowHidePasswordIcon onClick={() => handleShowPassword('newPassword')} className="icon-visualizzazioni newPassword" />
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              className="confirmPassword"
              placeholder="Conferma Password"
              onChange={newValue => handleOnChange('confermapwd', newValue)}
              type="password"
              value={authForm?.confermapwd?.value}
              valid={authForm?.confermapwd?.valid}
              error="Le password non sono uguali"
            />
            <ShowHidePasswordIcon onClick={() => handleShowPassword('confirmPassword')} className="icon-visualizzazioni confirmPassword" />
          </InputWrapper>
          <Button
            size={Button.SIZE.FULL}
            btnText="Aggiorna"
            disabled={!isValidForm(authForm)}
            onClick={() => resetPassword(getValues(authForm))}
          />
        </FormContainer>
      </Container>
    </AuthContainer>
  );
};

AuthReset.propTypes = {
  // HOC (connect, state)
  username: PropTypes.string.isRequired,

  // HOC (connect, dispatch)
  resetPassword: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { username } = state.app.authState;
    const { location } = state.router;
    return { username, location };
  },
  dispatch => ({
    resetPassword: params => dispatch({ type: AUTH_RESET._REQUEST, params })
  })
)(AuthReset);
