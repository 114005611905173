import styled, { css } from 'styled-components';

export const FaqContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  padding: 0px 0px 96px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.black};

  @media ${props => props.theme.device.tablet} {
    font-size: 25px;
    text-align: center;
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 60px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Category = styled.div`
  cursor: pointer;
  font-size: 14px;
  line-height: 1.14;
  transition: ${props => props.theme.constants.transitionBase};

  > div:firt &:hover {
    opacity: 0.6;
  }

  ${props =>
    props.isActive &&
    css`
      text-shadow: -0.06ex 0 white, 0.06ex 0 white;
      color: ${props.theme.colors.primaryColor};
    `};
`;

export const CategoriesContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 25%;
  padding-right: 20px;

  @media ${props => props.theme.device.tablet} {
    max-width: 100%;
    padding-bottom: 20px;
    padding-left: 45px;
    padding-right: 0px;
    padding: 0 0 20px 45px;
  }

  ${Category}:not (:last-child) {
    margin-bottom: 20px;
  }
`;

export const AnswerBody = styled.div`
  background: ${props => props.theme.colors.biscuit50};
  color: ${props => props.theme.colors.black};
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 1.29;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
`;

export const Contact = styled.div`
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: 10px;
  text-transform: uppercase;
  transition: ${props => props.theme.constants.transitionBase};
  span {
    margin-right: 6px;
  }
`;

export const AnswerContainer = styled.div`
  overflow: hidden;
  width: 100%;
  ${AnswerBody} {
    max-height: ${props => (props.isOpen ? '100%' : 0)};
    padding: ${props =>
      props.isOpen ? '10px 50px 56px 20px' : '0 50px 0 20px'};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    ${Contact} {
      display: ${props => (props.isOpen ? 'inline-flex' : 'none')};
      opacity: ${props => (props.isOpen ? 1 : 0)};
    }
  }
`;

export const AnswersContainer = styled.div`
  /* border-left: 1px solid ${props => props.theme.colors.black}; */
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  max-width: 75%;
  /* padding-left: 20px; */
  display: flex;
  flex-wrap: wrap;
  box-shadow: 1px 6px 9px 5px rgba(0,0,0,0.28);

  @media ${props => props.theme.device.tablet} {
    border-left: none;
    padding-left: 0px;
  }
`;

export const AnswerText = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors.biscuit25};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  padding: 10px 50px 10px 20px;
  transition: ${props => props.theme.constants.transitionBase};

  /*   &:hover {
    opacity: 0.6;
  } */

  span.icon-grow,
  span.icon-decrease {
    position: absolute;
    font-size: 10px;
    right: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const MobileWrapperBackButton = styled.div`
  @media ${props => props.theme.device.tablet} {
    padding: 0 0 20px 35px;
  }
`;
