import { createAsyncActionType } from './index';

export const PRODUCTS_LIST_GET = createAsyncActionType('products', 'PRODUCTS_LIST_GET');
export const SELECTED_PRODUCT_GET = createAsyncActionType('products', 'SELECTED_PRODUCT_GET');
export const SET_SELECTED_PRODUCT = '@@products/SET_SELECTED_PRODUCT';
export const PRODUCT_COURSES_GET = createAsyncActionType('products', 'PRODUCT_COURSES_GET');

export const PRODUCTS_NODE_ACTION = createAsyncActionType('products', 'PRODUCTS_NODE_ACTION');
export const PRODUCTS_COMMENTS_GET = createAsyncActionType('products', 'PRODUCTS_COMMENTS_GET');
export const PRODUCTS_COMMENTS_SET = createAsyncActionType('products', 'PRODUCTS_COMMENTS_SET');
