import styled, { css } from 'styled-components';

const commonCss = css`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontset.gotham};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.17;
`;
export const TableContainer = styled.div`
  position: relative;
  .rdt_Table {
    background-color: ${({ theme }) => theme.colors.biscuit50};
    ${commonCss};
    padding: 1.5rem;
  }

  .rdt_TableHeadRow {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }

  .rdt_TableCol,
  .rdt_TableCol_Sortable {
    background-color: ${({ theme }) => theme.colors.biscuit50};
    ${commonCss};
    color: ${({ theme }) => theme.colors.black};

    & span {
      opacity: 1;
    }
  }

  .rdt_TableCol {
    text-transform: uppercase;
  }

  .rdt_TableRow {
    border-top: 1px solid ${({ theme }) => theme.colors.white};
    background: ${props => props.theme.colors.biscuit50};

    ${commonCss};

    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};

    &:hover {
      background-color: ${({ theme }) => theme.colors.purpe};
    }

    transition: all 125ms ease-in-out;
  }
`;

export const CellPill = styled.div`
  background-color: ${({ theme }) => theme.colors.brightgreen};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.black};
  padding: 3px 0;
  height: 20px;
  width: 27px;
  text-align: center;
  font-size: 12px;
`;
