import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';
import {
  PRODUCTS_SERVICES_COMMENTS_GET,
  PRODUCTS_SERVICES_COURSES_GET,
  PRODUCTS_SERVICES_LIST_GET,
  SELECTED_PRODUCTS_SERVICES_GET, SET_FILTERS_PRODUCTS_SERVICES,
  SET_SELECTED_PRODUCTS_SERVICES
} from '../actions/productsservices';
import routes from '../../../routes';

const initialState = {
  products_services: {},
  selected_products_services: {},
  scorms: [],
  comments: [],
  isLoadingData: false,
  selected_filters: {
    reload: true
  }
};

const productsServicesReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { payload } = action;
      if (payload.pathname.indexOf(routes.productsServices.path) < 0) {
        draft.selected_filters = initialState.selected_filters;
      }
      break;
    }

    case PRODUCTS_SERVICES_LIST_GET._SUCCESS: {
      const { response, processType } = action;
      const { from, courses } = response;
      const list = (!from || from <= 0) ? courses : [...state.products_services[processType].courses, ...courses];
      draft.products_services = {
        ...state.products_services,
        [processType]: {
          ...response,
          courses: list
        }
      };
      break;
    }

    case PRODUCTS_SERVICES_COURSES_GET._REQUEST: {
      draft.isLoadingData = true;
      break;
    }

    case PRODUCTS_SERVICES_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      draft.isLoadingData = initialState.isLoadingData;
      break;
    }

    case SELECTED_PRODUCTS_SERVICES_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_products_services = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_PRODUCTS_SERVICES: {
      const { selected } = action;
      draft.selected_products_services = selected;
      break;
    }

    case PRODUCTS_SERVICES_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case SET_FILTERS_PRODUCTS_SERVICES: {
      const { filters } = action;
      draft.selected_filters = filters;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default productsServicesReducer;
