import './ui/scss/app.scss';
import '../node_modules/video-react/dist/video-react.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import {
  Router, Route, Switch, Redirect
} from 'react-router-dom';

import './ui/scss/bootstrap/bootstrap-grid.min.css';
import { pdfjs } from 'react-pdf';
import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import theme from './ui/theme';
import routes from './routes';
import { history } from './app/redux/store';

import { GlobalLayout } from './ui/components';

/* Pages */
import {
  Auth,
  AuthReset,
  Dashboard,
  Home,
  Search,
  Faq,
  Guidebook,
  MostViewed,
  Favorites,
  IntegrationAgenda,
  IntegrationAgendaDetails
} from './app/pages';
import { APP_STORE_RELOAD } from './app/redux/actions';
import { ModalsPortal, SuccessModal } from './ui/components/Modals';
import ErrorModal from './ui/components/Modals/ErrorModal';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Signup from './app/pages/Signup';
import AuthOTP from './app/pages/AuthOTP';
import CourseList from './app/pages/CourseList';
import CourseDetails from './app/pages/CourseDetails';


registerLocale('it', it);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const App = ({ appStoreReload, isLogged }) => {
  useEffect(() => {
    appStoreReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const isSSO = false; //JSON.parse(sessionStorage.getItem('SSO'));

  return (
    <ThemeProvider theme={theme('ThemeRed')}>
      <Router history={history}>
        {isLogged ? (
          <GlobalLayout>
            <Switch>
              <Route
                path={routes.dashboard.path}
                exact
                render={props => (
                  <Dashboard {...props} title={routes.dashboard.title} />
                )}
              />
              <Route
                path={routes.home.path}
                exact
                render={props => <Home {...props} title={routes.home.title} />}
              />
              <Route
                path={routes.guidebook.path}
                render={() => <Guidebook />}
                exact
              />
              <Route
                path={routes.favorites.path}
                render={() => <Favorites />}
                exact
              />
              <Route
                path={routes.mostViewed.path}
                render={() => <MostViewed />}
                exact
              />
              <Route
                path={routes.integrationagenda.path}
                render={() => <IntegrationAgenda />}
                exact
              />
              <Route
                path={routes.integrationagendadetails.path}
                render={() => <IntegrationAgendaDetails />}
                exact
              />
              <Route path={routes.faq.path} render={() => <Faq />} exact />
              <Route
                path={`${routes.search.path}`}
                exact
                render={props => (
                  <Search {...props} title={routes.search.title} />
                )}
              />
              <Route
                path={`${routes.course.path}/:courseName`}
                exact
                render={({ match }) => (
                  <CourseList
                    title={routes.course.title}
                    courseName={match.params.courseName}
                  />
                )}
              />
              <Route
                path={`${routes.course.path}/:courseName/:courseId`}
                exact
                render={({ match }) => (
                  <CourseDetails
                    title={routes.course.title}
                    courseName={match.params.courseName}
                    courseId={match.params.courseId}
                  />
                )}
              />
              <Route
                path={`${routes.course.path}/:courseName/:courseId/:pillId`}
                render={({ match }) => (
                  <CourseDetails
                    title={routes.course.title}
                    courseName={match.params.courseName}
                    courseId={match.params.courseId}
                    pillId={match.params.pillId}
                  />
                )}
              />
              <Redirect to={routes.home.path} />
            </Switch>
          </GlobalLayout>
        ) : (
          <GlobalLayout>
            <Switch>
              <Route
                path={routes.resetAuth.path}
                render={() => <AuthReset />}
              />
              <Route path={routes.auth.path} render={() => <Auth />} />
              <Route path={routes.signup.path} render={() => <Signup />} />
              <Route
                path={routes.authOTP.path}
                exact
                render={() => <AuthOTP />}
              />
              <Redirect to={isSSO ? routes.authSSO.path : routes.auth.path} />
            </Switch>
          </GlobalLayout>
        )}
        {/* <Loader /> */}
      </Router>
      <ErrorModal />
      <SuccessModal />
      <ModalsPortal.Target />
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, state)
  isLogged: PropTypes.bool.isRequired,
  isSpinning: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  appStoreReload: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { isLogged } = state.app.authState;
    const { isSpinning } = state.app.loader;
    const { location } = state.router;

    return {
      isLogged,
      isSpinning,
      location
    };
  },
  dispatch => ({
    appStoreReload: () => dispatch({ type: APP_STORE_RELOAD })
  })
)(App);
