import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  Background,
  HeaderContainer,
  STCConnectedContainer,
  ResultContainer,
  CardContainer,
  SeeMore
} from './style';
import { GeneralCard, Tabs } from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/HPConnected.jpg';
import {
  SET_SELECTED_STC_CONNECTED,
  STC_CONNECTED_LIST_GET,
  STC_CONNECTED_NODE_ACTION
} from '../../redux/actions/stc_connected';
import routes from '../../../routes';
import Arial from '../../../ui/typography/arial';
import Gothic from '../../../ui/typography/gothic';
import theme from '../../../ui/theme';
import NoResult from '../../../ui/atoms/NoResult';

const STCConnected = ({
  heroImage,
  stcConnected,
  getSTCConnected,
  pushUrl,
  setSelectedSTCConnected,
  setAsFavorite,
  path_contents
}) => {
  const ls = localStorage;
  const { items: list = [], total_items } = stcConnected;
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(
    Boolean(ls.getItem('activeTabConnected')) &&
      tabs.filter(
        tab => tab.value === JSON.parse(ls.getItem('activeTabConnected')).value
      ).length > 0
      ? JSON.parse(ls.getItem('activeTabConnected'))
      : tabs[0]
  );

  useEffect(() => {
    if (path_contents.length) {
      const selectedPath = [...path_contents].find(
        item => item.code.toLowerCase() === 'connected'
      );
      const selectedPathChildren = selectedPath?.children;
      const options = (selectedPathChildren || []).reduce(
        (acc, val) => [...acc, { value: val.id, label: val.name }],
        []
      );
      setActiveTab(
        Boolean(ls.getItem('activeTabConnected')) &&
          options.filter(
            tab =>
              tab.value === JSON.parse(ls.getItem('activeTabConnected')).value
          ).length > 0
          ? JSON.parse(ls.getItem('activeTabConnected'))
          : options[0]
      );
      setTabs(options);
    }
    // getSTCConnected(true, activeTab?.value !== 'all' ? activeTab?.value : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path_contents]);

  useEffect(() => {
    getSTCConnected(true, activeTab?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleOnClickCard = item => {
    setSelectedSTCConnected(item);
    pushUrl(`${routes.stcConnected.path}/${item.id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSTCConnected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSTCConnected, path_contents]);

  const onClickSeeMore = () => {
    getSTCConnected(false);
  };

  const onChangeTab = tab => {
    setActiveTab(tab);
    ls.setItem('activeTabConnected', JSON.stringify(tab));
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
        <Container className="p-0">
          <Gothic type="sectionDescription">
            Potrai accedere a una serie di contenuti formativi sulle competenze
            umane e tecnologico-digitali utili per la progettazione di attività
            in classe. Inoltre, potrai essere guidato da suggerimenti e principi
            importanti per accompagnare bambini, bambine e adolescenti nello
            sviluppo delle proprie competenze umane e tecnologico-digitali. Una
            volta che avrai fatto l’accesso a questa sezione, potrai scaricare
            l’attestato per il riconoscimento dei crediti formativi.
          </Gothic>
          <Gothic type="sectionDescription" className="credit-line">
            (Credit line foto: Giulia Del Gatto per Save the Children)
          </Gothic>
        </Container>
      </HeaderContainer>
      <STCConnectedContainer>
        <Container className="p-0">
          {tabs.length > 1 && (
            <Tabs
              tabs={[...tabs]}
              onChange={onChangeTab}
              selected={activeTab}
              color={theme().colors.primaryRed}
            />
          )}
          <ResultContainer>
            {list.map(item => (
              <CardContainer key={`stcconnected-${item.id}`}>
                <GeneralCard
                  item={item}
                  onClickFavorite={() => setAsFavorite(item.id)}
                  onClickCard={() => handleOnClickCard(item)}
                  type={GeneralCard.TYPE.SMARTWORKING}
                />
              </CardContainer>
            ))}
          </ResultContainer>
          {list.length <= 0 && <NoResult>Nessun risultato</NoResult>}
          {list.length && list.length < total_items && (
            <SeeMore onClick={onClickSeeMore}>
              <Arial type="scrollUp">Mostra Altri</Arial>
            </SeeMore>
          )}
        </Container>
      </STCConnectedContainer>
    </>
  );
};

STCConnected.propTypes = {
  // HOC (connect, state)
  heroImage: PropTypes.string,
  stcConnected: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getSTCConnected: PropTypes.func.isRequired,
  setSelectedSTCConnected: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { stcConnected } = state.stc_connected;
    const { path_contents } = state.app;
    return {
      heroImage,
      stcConnected,
      path_contents
    };
  },
  dispatch => ({
    getSTCConnected: (subset = true, pathFilter = undefined) =>
      dispatch({
        type: STC_CONNECTED_LIST_GET._REQUEST,
        itemPerPage: 24,
        subset,
        pathFilter
      }),
    setSelectedSTCConnected: selected =>
      dispatch({ type: SET_SELECTED_STC_CONNECTED, selected }),
    setAsFavorite: nid =>
      dispatch({
        type: STC_CONNECTED_NODE_ACTION._REQUEST,
        nid,
        action_type: 'favourite'
      }),
    pushUrl: url => dispatch(push(url))
  })
)(STCConnected);
