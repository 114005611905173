import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { coachCoacheeMock } from './mock';

export default {
  getCoachCoachee: () => {
    if (shouldUseMock()) {
      return coachCoacheeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}users/coach_coachee`, 'GET');
  }
};
