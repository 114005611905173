/* eslint-disable */
import { shouldUseMock } from './common';

export const RegexpType = {
  EMAIL: 'email',
  NUMBER: 'number',
  FLOAT: 'float',
  STRING: 'string',
  ADDRESS: 'address',
  ALPHA_NUMERIC: 'alphaNumeric',
  PIN_OTP: 'pinOtp',
  FISCAL_CODE: 'fiscalCode',
  POSTAL_CODE: 'postalCode',
  EAN: 'ean',
  PASSWORD: 'password',
  RESET_PASSWORD: 'reset_password',
  SPACEONLY: 'space',
  MOBILE_PHONE: 'mobile_phone',
  TELEPHONE: 'telephone',
  DATE: 'date',
  USERNAME: 'username',
  HOUR: 'hour',
  URL: 'url'
};

const RegexpRule = {
  [RegexpType.EMAIL]: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi,
  [RegexpType.NUMBER]: /^\d+$/g,
  [RegexpType.FLOAT]: /(\d+(\.\d+)?)/,
  [RegexpType.STRING]: /^[a-zA-Z0-9!@#$£€%^&*()_+\-=\[\]{};':"\\|,.<>°§\/?èéòàìùç ]*$/,
  [RegexpType.ADDRESS]: /^[a-zA-Z0-9_\/.,èéòàìù'](?:[a-zA-Z0-9_\/.,'\sèéòàìù\-]+)?$/gi,
  [RegexpType.ALPHA_NUMERIC]: /^[a-z A-Z 0-9_.-]*$/gi,
  [RegexpType.PIN_OTP]: /^[0-9]{6,6}$/gi,
  [RegexpType.FISCAL_CODE]: /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i,
  [RegexpType.POSTAL_CODE]: /^[0-9]{5}$/gm,
  [RegexpType.EAN]: /^(\d{13})?$/g,
  [RegexpType.PASSWORD]: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\d)(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\\])[A-Za-z\d !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\\&]{8,}$/,
  [RegexpType.RESET_PASSWORD]: '^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  [RegexpType.SPACEONLY]: /^\s/,
  [RegexpType.MOBILE_PHONE]: /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/,
  [RegexpType.TELEPHONE]: /^([0-9]*\-?\ ?\/?[0-9]*)$/,
  [RegexpType.USERNAME]: /^[a-z0-9 _]*[a-z0-9][a-z0-9 _]$/,
  [RegexpType.HOUR]: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  [RegexpType.URL]: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/
};

export const validator = (
  type = RegexpType.ALPHA_NUMERIC,
  data,
  required = false
) => {
  if (required) {
    if (data !== undefined && data !== '') {
      if (type === RegexpType.DATE) return !!new Date(data).valueOf();
      return new RegExp(RegexpRule[type]).test(data);
    }
    return false;
  } else if (data !== undefined && data !== '') {
    if (type === RegexpType.DATE) return !!new Date(data).valueOf();
    return new RegExp(RegexpRule[type]).test(data);
  }
  return true;
};
