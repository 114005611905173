import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LineBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${props => props.background};
`;

const PercentageLine = styled.span`
  position: absolute;
  top: -1px;
  left: 0;
  width: ${props => (props.percentage > 0 ? `${props.percentage}%` : 0)};
  height: 6px;
  border-radius: 3px;
  background-color: ${props => props.color};
`;

const Percentage = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: ${props => props.theme.colors.black};
  padding-left: 5px;
`;

export {
  Container, LineBar, PercentageLine, Percentage
};
