import produce from 'immer';
import PropTypes from 'prop-types';

import { STATISTICS_RESULTS_SET } from '../actions/statistics';
// stats/type=[courses, activities, performance, scores, recent_courses]

export const STAT_TYPE = {
  COURSES: 'courses',
  ACTIVITIES: 'activities',
  PERFORMANCE: 'performance',
  SCORES: 'scores',
  RECENT_COURSES: 'recent_courses'
};

export const StatisticsAccessInterface = PropTypes.shape({
  // TODO define here the shape
});
export const StatisticsLoginInterface = PropTypes.shape({
  // TODO define here the shape
});

export const StatisticsCoursesInterface = PropTypes.shape({
  statType: PropTypes.string,
  registered: PropTypes.number,
  in_progress: PropTypes.number,
  completed: PropTypes.number,
  total_courses: PropTypes.number
});

export const StatisticsActivitiesInterface = PropTypes.shape({
  data: PropTypes.object,
  statType: PropTypes.string
});

export const StatisticsPerformanceInterface = PropTypes.shape({
  data: PropTypes.array,
  statType: PropTypes.string
});

export const StatisticsScoresInterface = PropTypes.shape({
  statType: PropTypes.string,
  highest_score: PropTypes.number,
  lowest_score: PropTypes.number,
  avarage_score: PropTypes.number,
  recent_score: PropTypes.number
});

export const StatisticsRecentCoursesInterface = PropTypes.shape({
  data: PropTypes.array,
  statType: PropTypes.string
});

const initialState = {
  courses: null,
  activities: null,
  performance: null,
  scores: null,
  recent_courses: null
};

const statisticsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STATISTICS_RESULTS_SET: {
        const { statType, data } = action.response;

        switch (statType) {
          case STAT_TYPE.COURSES:
            draft.courses = {
              ...data
            };
            break;

          case STAT_TYPE.ACTIVITIES:
            draft.activities = action.response;
            break;

          case STAT_TYPE.PERFORMANCE:
            draft.performance = action.response;
            break;

          case STAT_TYPE.SCORES:
            draft.scores = {
              statType,
              ...data
            };
            break;

          case STAT_TYPE.RECENT_COURSES:
            draft.recent_courses = action.response;
            break;

          default:
        }

        break;
      }

      case 'other':
      default:
        return state;
    }
    return draft;
  });

export default statisticsReducer;
