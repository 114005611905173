import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  Category,
  CollectionContainer,
  CourseContainer,
  Description,
  CoursesContainer,
  CoursesHeader,
  CoursesList,
  CourseTitle,
  Cover,
  HeaderContainer,
  HeaderInfoContainer,
  Iframe,
  MainSection,
  CourseElementDetails,
  ProgressContainer,
  ProgressWrapper,
  Tag,
  TagContainer,
  TagWrapper,
  CourseElementCompleted,
  VideoContainer,
  VideoInfo,
  VideoInfoWrapper,
  VideoSocialWrapper,
  IconCompleted,
  ButtonContainer,
  CoursesHeaderLength,
  CoursesHeaderDuration,
  CoursesHeaderClock,
  CourseElementCounter,
  Social,
  Like
  // CourseElementHeart, Like, Social, CourseDuration
} from './style';
import { ProgressBar } from '../../../ui/atoms';
import routes from '../../../routes';
//import { Comments } from '../../../ui/components';
import {
  COLLECTION_NODE_ACTION,
  COLLECTION_COURSES_GET,
  SELECTED_COLLECTION_GET,
  COLLECTION_COMMENTS_SET,
  COLLECTION_COMMENTS_GET
} from '../../redux/actions/collections';
import Arial from '../../../ui/typography/arial';
import { Comments } from '../../../ui/components';
import Button from '../../../ui/atoms/Button';
import { Frame } from '../CompulsaryTrainingCourses/style';

const courseElement = (
  {
    cover, title, duration, blocked, stats
  } = {},
  onClick,
  isActive = false,
  index
) => (
  <CourseContainer onClick={onClick} active={isActive} blocked={blocked}>
    <CourseElementCounter>{index + 1}</CourseElementCounter>
    <Cover cover={cover} />
    <CourseElementDetails>
      <CourseTitle type="collectionCourseTitle" title={title}>{title}</CourseTitle>
      {/*<CourseDuration>{duration} min</CourseDuration>*/}
    </CourseElementDetails>
    <CourseElementCompleted>
      {stats?.status === 'completed' && <IconCompleted className="icon-completato" />}
    </CourseElementCompleted>
    {/*<CourseElementHeart className="icon-heart-empty" />*/}
  </CourseContainer>
);

const Collection = ({
  collectionId,
  scormId,
  selected_collection = {},
  setLike,
  setDislike,
  setViews,
  comments,
  setCollectionComments,
  getSelectedCollection,
  getCollectionCourses,
  pushUrl,
  getCollectionComments,
  scorms
}) => {
  const [scorm, setScorm] = useState({});
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    setViews(collectionId);
    getSelectedCollection(collectionId);
    getCollectionCourses({ collectionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, setScormFullscreen]);

  useEffect(() => {
    if (scormId) getCollectionComments(scormId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scormId]);

  useEffect(() => {
    if (
      (scorms?.length && !scormId)
      || (scormId
        && scorms?.length
        && !scorms?.find(
          ({ node_scorm_id } = {}) => node_scorm_id?.toString() === scormId?.toString()
        ))
    ) {
      const selectedScorm = scorms?.find(
        ({ stats } = {}) => !(stats.status === 'completed')
      );
      pushUrl(routes.home.path);
      // selectedScorm
      //   ? pushUrl(
      //     `${routes.collection.path}/${collectionId}/${selectedScorm?.node_scorm_id}`
      //   )
      //   : pushUrl(
      //     `${routes.collection.path}/${collectionId}/${scorms[0]?.node_scorm_id}`
      //   );
      setScorm(selectedScorm || scorms[0]);
    } else if (scorms?.length && scormId) {
      let selectedScorm = scorms?.find(
        ({ node_scorm_id } = {}) => node_scorm_id?.toString() === scormId?.toString()
      );
      selectedScorm = selectedScorm
        || scorms?.filter(({ stats } = {}) => !(stats.status === 'completed'));
      setScorm(selectedScorm);
    }
    setScormFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scorms, scormId]);

  const { category, duration, title: collectionTitle } = selected_collection;

  const { description, tag, title } = scorm;

  const percentage = (
    (scorms.filter(item => item.stats.status === 'completed').length
      / scorms.length)
    * 100
  ).toFixed(0);

  const openCourse = ({ node_scorm_id, blocked }) => {
    if (!blocked) {
      pushUrl(`${routes.collection.path}/${collectionId}/${node_scorm_id}`);
      getCollectionCourses({ collectionId });
    }
  };

  const handleNewCommentOnSubmit = value => {
    setCollectionComments({
      id: scorm?.node_scorm_id,
      subject: scorm?.title,
      comment_body: value
    });
  };

  // const onClickNext = () => {
  //   getCollectionCourses({ collectionId });
  //   pushUrl(`${routes.collection.path}/${collectionId}`);
  // };

  const windowOpen = uri => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.collection.path}/${collectionId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  return (
    <CollectionContainer>
      <Container className="p-0">
        <HeaderContainer>
          <HeaderInfoContainer>
            {category && (
              <Category>
                <Arial type="categoryPill">{category}</Arial>
              </Category>
            )}
            <Arial type="collectionHeaderTitle">{collectionTitle}</Arial>
          </HeaderInfoContainer>
          <ProgressContainer>
            <ProgressWrapper>
              <Arial type="progressInfo">Completamento</Arial>
              <Arial type="progressInfo">{percentage}%</Arial>
            </ProgressWrapper>
            <ProgressBar percentage={percentage} />
          </ProgressContainer>
        </HeaderContainer>
        <MainSection>
          <Row>
            <Col sm={12} md={8} lg={9}>
              {scorm ? (
                <>
                  <VideoContainer>
                    {scormFullscreen ? (
                      <Frame>
                        <Arial type="editorialCardText">
                          Aperto in modalità fullscreen
                        </Arial>
                      </Frame>
                    ) : (
                      <Iframe
                          // frameHeight={frameHeight}
                          title={scorm?.title}
                          src={scorm?.iframe_uri}
                          id="scorm_frame"
                        />
                    )}
                  </VideoContainer>
                  <VideoInfo>
                    <ButtonContainer>
                      {scorm?.iframe_uri && (
                        <Button
                          btnText="Fullscreen"
                          onClick={() => windowOpen(scorm?.iframe_uri)}
                          height={20}
                        />
                      )}
                    </ButtonContainer>
                    <VideoInfoWrapper>
                      <Arial type="sectionTitle">{title}</Arial>
                      <Social>
                        <span className="icon-visualizzazioni" />
                        {selected_collection?.views}
                      </Social>
                    </VideoInfoWrapper>
                    <Description>
                      <Arial type="description14">{description}</Arial>
                    </Description>
                    <VideoSocialWrapper>
                      <Like>
                        <Social isActive={scorm?.user_actions?.like}>
                          <span
                            className="icon-like"
                            onClick={() => setLike(scorm.node_scorm_id)}
                          />
                          <Arial type="like">{scorm?.stats?.like}</Arial>
                        </Social>
                        <Social isActive={scorm?.user_actions?.dislike}>
                          <span
                            className="icon-nonutile"
                            onClick={() => setDislike(scorm.node_scorm_id)}
                          />
                          <Arial type="like">{scorm?.stats?.dislike}</Arial>
                        </Social>
                      </Like>
                      {/*<Share onClick={shareModal}>*/}
                      {/*  <span className="icon-share" />*/}
                      {/*  <Arial type="newsAttachment">condividi</Arial>*/}
                      {/*</Share>*/}
                    </VideoSocialWrapper>
                  </VideoInfo>
                  {tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {tag.map(t => (
                          <Tag key={t.id}>
                            <Arial type="tagText">{t.label}</Arial>
                          </Tag>
                        ))}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments
                    list={comments}
                    onSubmit={handleNewCommentOnSubmit}
                  />
                </>
              ) : null}
            </Col>
            <Col sm={12} md={4} lg={3}>
              <CoursesContainer>
                <CoursesHeader>
                  <CoursesHeaderLength>
                    <Arial type="coursesHeaderLengthBold">
                      {scorms.length}
                    </Arial>
                    <Arial type="coursesHeaderLength">Oggetti formativi</Arial>
                  </CoursesHeaderLength>
                  <CoursesHeaderDuration>
                    <CoursesHeaderClock className="icon-clock" />
                    <Arial configuration={{ size: 10 }}>
                      {duration}&nbsp;min. totali
                    </Arial>
                  </CoursesHeaderDuration>
                </CoursesHeader>
                <CoursesList>
                  {scorms.map((c, index) => (
                    <React.Fragment key={`scorm-${c.id}`}>
                      {courseElement(
                        c,
                        () => openCourse(c),
                        c.id?.toString() === scormId?.toString(),
                        index
                      )}
                    </React.Fragment>
                  ))}
                </CoursesList>
                {/*{scorms.length > 1*/}
                {/*    && <ButtonContainer><Button btnText="Successivo" onClick={onClickNext} height={20} /></ButtonContainer>*/}
                {/*}*/}
              </CoursesContainer>
            </Col>
          </Row>
        </MainSection>
      </Container>
    </CollectionContainer>
  );
};

Collection.propTypes = {
  collectionId: PropTypes.string.isRequired,
  scormId: PropTypes.string,

  // HOC (connect, state)
  selected_collection: PropTypes.object,
  scorms: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,

  // HOC (connect, dispatch)
  getSelectedCollection: PropTypes.func.isRequired,
  getCollectionCourses: PropTypes.func.isRequired,
  setCollectionComments: PropTypes.func.isRequired,
  getCollectionComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setViews: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      collection,
      comments,
      scorms,
      selected_collection
    } = state.collections;
    return {
      collection,
      comments,
      scorms,
      selected_collection
    };
  },
  dispatch => ({
    getSelectedCollection: id => dispatch({ type: SELECTED_COLLECTION_GET._REQUEST, id }),
    getCollectionCourses: ({ collectionId }) => dispatch({ type: COLLECTION_COURSES_GET._REQUEST, collectionId }),
    setCollectionComments: params => dispatch({ type: COLLECTION_COMMENTS_SET._REQUEST, ...params }),
    getCollectionComments: id => dispatch({ type: COLLECTION_COMMENTS_GET._REQUEST, id }),
    setLike: nid => dispatch({
      type: COLLECTION_NODE_ACTION._REQUEST,
      nid,
      action_type: 'like',
      node_type: 'scorm'
    }),
    setDislike: nid => dispatch({
      type: COLLECTION_NODE_ACTION._REQUEST,
      nid,
      action_type: 'dislike',
      node_type: 'scorm'
    }),
    setViews: nid => dispatch({
      type: COLLECTION_NODE_ACTION._REQUEST,
      nid,
      action_type: 'views',
      node_type: 'course'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(Collection);
