import produce from 'immer';

import {
  SELECTED_STC_ALL_GET,
  STC_ALL_LIST_GET,
  SET_SELECTED_STC_ALL, STC_ALL_COMMENTS_GET, STC_ALL_PILLS_GET
} from '../actions/stc_all';

const initialState = {
  stcAll: {},
  selectedStcAll: {},
  pills: [],
  comments: [],
  testLink: ''
};

const stcAllReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case STC_ALL_LIST_GET._SUCCESS: {
      const { response, subset } = action;
      const { items: pills } = response;
      const items = pills || [];
      const list = subset || !state.stcAll.items || state.stcAll.items <= 0 ? items : [...state.stcAll.items, ...items];
      draft.stcAll = {
        ...response,
        items: list
      };
      break;
    }

    case STC_ALL_PILLS_GET._REQUEST: {
      draft.pills = [];
      break;
    }

    case STC_ALL_PILLS_GET._SUCCESS: {
      const { response } = action;
      const { pill_items, link } = response[0];
      const uri = link ? link.uri : '';
      draft.pills = pill_items.reduce((acc, val, index, array) => {
        if (!array[index - 1]) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        if (array[index - 1] && (acc[index - 1].stats.status === 'completed')) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        return [...acc, {
          ...val, blocked: false, index, last_item: index === array.length - 1
        }];
      }, []);
      draft.testLink = uri;
      break;
    }

    case SELECTED_STC_ALL_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedStcAll = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_STC_ALL: {
      const { selected } = action;
      draft.selectedStcAll = selected;
      break;
    }

    case STC_ALL_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default stcAllReducer;
