import styled from 'styled-components';

const MaintenanceContainer = styled.div`
 max-width: 1440px;
 width: 100%;
 height: 100vh;
 margin: 0 auto;
 z-index: ${props => props.theme.zIndex.absoluteUp};
 display: flex;
 justify-content: center;
 padding-top: 100px;
 padding-left: 25px;
 padding-right: 25px;
`;

const TextContainer = styled.div`
  width: 70%;

  @media ${props => props.theme.device.tablet} {
    width: 80%;
  }

`;

const IconContainer = styled.div`
  width: 30%;

  @media ${props => props.theme.device.tablet} {
    width: 25%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 20%;
  }

`;

const Title = styled.div`
    width: 70%;
    margin-top: 15px;
    margin-bottom: 35px;

  @media ${props => props.theme.device.tablet} {
    width: 80%;
  }

  > div {
    @media ${props => props.theme.device.laptop} {
      font-size: 45px;
    }

    @media ${props => props.theme.device.tablet} {
      font-size: 35px;
    }

    @media ${props => props.theme.device.mobileL} {
      font-size: 18px;
    }
  }
`;

const Description = styled.div`

  > div {

    @media ${props => props.theme.device.tablet} {
      font-size: 24px;
    }

    @media ${props => props.theme.device.mobileL} {
      font-size: 14px;
    }

   
  }
  
`;

export {
  MaintenanceContainer, TextContainer, IconContainer, Title, Description
};
