import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  AppointmentCardContainer, ButtonContainer, AdditionalInfo,
  InformationContainer, Title, Description, PartecipantsContainer,
  IconWrapper
} from './style';
import Arial from '../../typography/arial';
import Button from '../Button';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';



const AppointmentCard = ({
  agenda: {
    title, start_date, end_date, receiver_user, id, link
  }, btnType, vocabulary, user_type, handleDelete,
}) => {
  const [partecipants, setPartecipants] = useState(false);

  const onHandleDelete = () => {
    handleDelete({ delete: id });
  };

  const convertUrlParticipate = (url) => (url.indexOf('http') < 0 ? `https:\\\\${url}` : url);

  const openUrl = () => {
    window.open(convertUrlParticipate(link), '_blank');
  }


  return (
    <AppointmentCardContainer>
      <AdditionalInfo>
        <Arial type="productCardDetail">{moment(new Date(start_date*1000)).format('DD MMMM')} <br /> Ore {moment(new Date(start_date*1000)).format('HH:mm')} - {moment(new Date(end_date*1000)).format('HH:mm')}</Arial>
      </AdditionalInfo>
      {user_type === 'tutor' && (
        <IconWrapper
          onClick={onHandleDelete}
        >
          <span className="icon-delete" />
        </IconWrapper>
      )}
      <InformationContainer>
        <Description
          onMouseEnter={() => setPartecipants(!partecipants)}
          onMouseLeave={() => setPartecipants(!partecipants)}
        >
          {user_type === 'tutor' && (
            <Arial type="addInfoGeneralCard">
              Partecipanti | {receiver_user?.length}
            </Arial>
          )}

          {partecipants && (
            <PartecipantsContainer>
              {receiver_user.map(user => (
                <Arial type="newsDescription">{user.name} {user.surname}</Arial>
              ))
              }
            </PartecipantsContainer>
          )}

        </Description>
        <Title><Arial type="collectionCardTitle">{title}</Arial></Title>
        {link && (
          <ButtonContainer>
            <Button onClick={openUrl} btnText={getValueVocabulary(vocabulary.appointmentButton, 'appointmentButton')} type={btnType} />
          </ButtonContainer>
        )}
      </InformationContainer>
    </AppointmentCardContainer>
  );
}


AppointmentCard.propTypes = {
  btnType: PropTypes.object,
  agenda: PropTypes.object,
  vocabulary: PropTypes.object,
  user_type: PropTypes.string,
  handleDelete: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { data: { user_type } } = state.user;
    return {
      vocabulary,
      user_type
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(AppointmentCard);
