import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import product from '../../api/product';
import {
  PRODUCT_COURSES_GET, PRODUCTS_COMMENTS_GET,
  PRODUCTS_COMMENTS_SET, PRODUCTS_LIST_GET,
  PRODUCTS_NODE_ACTION, SELECTED_PRODUCT_GET
} from '../actions/product';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';


function* productsListGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { products: { total_items: totalCount, count, from } = {} } = yield select(state => state.product);
    const pagination = calcPagination(subset ? undefined : { count, from, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'prodotti', ...filters, ...pagination };
      const { data: { data: response } } = yield call(product.getProductsList, complete_filters);
      yield put({ type: PRODUCTS_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > product > productsListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* productsListGetWatch() {
  yield takeLatest(PRODUCTS_LIST_GET._REQUEST, productsListGet);
}

function* setSelectedProduct({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_product } = yield select(state => state.product);
    if (Object.keys(selected_product).length <= 0 && pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'prodotti', id, ...pagination };
      const { data: { data: response } } = yield call(product.getProductsList, complete_filters);
      yield put({ type: SELECTED_PRODUCT_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > product > setSelectedProduct', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedProductWatch() {
  yield takeLatest(SELECTED_PRODUCT_GET._REQUEST, setSelectedProduct);
}

function* productCoursesGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(product.getProductCourses, { course_id: collectionId });
      yield put({ type: PRODUCT_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > product > productCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* productCoursesGetWatch() {
  yield takeLatest(PRODUCT_COURSES_GET._REQUEST, productCoursesGet);
}

function* productNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(product.productsNodeAction, payload);
      yield put({ type: PRODUCTS_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: PRODUCTS_LIST_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: PRODUCTS_LIST_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_product } = yield select(state => state.product);
            if (selected_product.id) yield put({ type: PRODUCT_COURSES_GET._REQUEST, collectionId: selected_product.id, loader: false });
          } else {
            yield put({ type: PRODUCTS_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > product > productNodeAction', err);
    }
  }
}

function* productNodeActionWatch() {
  yield takeLatest(PRODUCTS_NODE_ACTION._REQUEST, productNodeAction);
}

function* productCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(product.getProductsComments, { id });
      yield put({ type: PRODUCTS_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > product > productCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* productCommentsGetWatch() {
  yield takeLatest(PRODUCTS_COMMENTS_GET._REQUEST, productCommentsGet);
}

function* productCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(product.setProductsComments, payload);
    yield put({ type: PRODUCTS_COMMENTS_SET._SUCCESS });
    yield put({ type: PRODUCTS_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > product > productCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* productCommentsSetWatch() {
  yield takeLatest(PRODUCTS_COMMENTS_SET._REQUEST, productCommentsSet);
}

export default [
  productsListGetWatch(),
  setSelectedProductWatch(),
  productCoursesGetWatch(),
  productNodeActionWatch(),
  productCommentsGetWatch(),
  productCommentsSetWatch()
];
