import React from 'react';
import PropTypes from 'prop-types';
import {
  PillsListContainer,
  PillsHeader,
  PillsHeaderLength,
  PillsList,
  PillsInfo,
  NextPrevCourseContainer
} from './style';
import { Social } from '../CourseDetailInfo/style';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { Button } from '../../atoms';
import routes from '../../../routes';

const CourseDetailPill = ({
  children,
  length,
  views,
  vocabulary,
  isCompleted,
  testLink,
  nextCourse,
  prevCourse,
  courseName,
  pushUrl,
  pillsReset
}) => {
  const handleOnchangeCourse = item => {
    pillsReset();
    pushUrl(`${routes.course.path}/${courseName}/${item.id}`);
  };
  return (
    <PillsListContainer>
      <PillsHeader>
        <PillsHeaderLength>
          <Arial configuration={{ size: 18 }} type="coursesHeaderLengthBold">
            {length}
          </Arial>
          <Arial type="coursesHeaderLength">Oggetti formativi</Arial>
        </PillsHeaderLength>
      </PillsHeader>
      <PillsList>{children}</PillsList>
      {views && (
        <PillsInfo>
          {/* <PillsHeaderDuration>
        <PillsHeaderClock className="icon-clock" />
        <Arial configuration={{ size: 10 }}>{durationPill}&nbsp;min. totali</Arial>
      </PillsHeaderDuration> */}

          <Social>
            <span className="icon-visualizzazioni" />
            <span style={{ fontSize: 10, color: 'black' }}>{views}</span>
          </Social>
        </PillsInfo>
      )}
      {isCompleted && testLink && (
        <Button
          btnText={getValueVocabulary(vocabulary.btnFinalTest, 'btnFinalTest')}
          onClick={() => window.open(testLink, 'blank')}
          height={30}
        />
      )}
      {(prevCourse?.id || nextCourse?.id) && (
        <NextPrevCourseContainer>
          {prevCourse?.id && (
            <Button
              btnText={getValueVocabulary(
                vocabulary.btnPrevCourse,
                'btnPrevCourse'
              )}
              onClick={() => handleOnchangeCourse(prevCourse)}
              height={30}
            />
          )}
          {nextCourse?.id && (
            <Button
              btnText={getValueVocabulary(
                vocabulary.btnNextCourse,
                'btnNextCourse'
              )}
              onClick={() => handleOnchangeCourse(nextCourse)}
              height={30}
            />
          )}
        </NextPrevCourseContainer>
      )}
    </PillsListContainer>
  );
};

CourseDetailPill.propTypes = {
  children: PropTypes.node,
  length: PropTypes.number,
  courseName: PropTypes.string,
  pillsReset: PropTypes.func
};

export default CourseDetailPill;
