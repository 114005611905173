import produce from 'immer';
import PropTypes from 'prop-types';

import { LOCATION_CHANGE } from 'redux-first-history';
import {
  HOME_COLLECTIONS_GET,
  HOME_COURSES_GET,
  HOME_COURSE_LIST_GET,
  HOME_PILLS_GET
} from '../actions/home';
import { CourseStates } from '../../../utils/course-state';
import routes from '../../../routes';

export const CourseInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.string,
  expiry_date: PropTypes.number,
  status: PropTypes.oneOf(
    Object.keys(CourseStates).map(key => CourseStates[key])
  ),
  percentage: PropTypes.number,
  publish: PropTypes.number,
  required: PropTypes.number,
  completion_date: PropTypes.number,
  creation_date: PropTypes.number,
  last_update_date: PropTypes.number
});

export const CoursesInterface = PropTypes.arrayOf(CourseInterface);

export const CoursesDataInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  courses: CoursesInterface
});

export const CollectionInterface = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info_box: PropTypes.string
});

export const CollectionsInterface = PropTypes.arrayOf(CollectionInterface);

export const CollectionsDataInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  collections: CollectionsInterface
});

export const PillInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.string,
  url_source: PropTypes.string
});

export const PillsInterface = PropTypes.arrayOf(PillInterface);

export const PillsDataInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  pills: PillsInterface
});

const initialState = {
  collectionsData: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    collections: []
  },
  coursesData: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    curses: []
  },
  inProgressCoursesData: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    courses: []
  },
  pillsData: {
    total_items: undefined,
    count: undefined,
    from: undefined,
    pills: []
  },
  carousels: {}
};

const homeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        if (action.payload.pathname !== routes.home.path) {
          draft.collectionsData = initialState.collectionsData;
          draft.coursesData = initialState.coursesData;
        }

        break;
      }
      case HOME_COLLECTIONS_GET._SUCCESS: {
        // const { from } = state.collectionsData;
        const { collections, from } = action.response;

        const data =
          !from || from <= 0
            ? collections
            : [...state.collectionsData.collections, ...collections];
        draft.collectionsData = action.response;
        draft.collectionsData.collections = data;

        break;
      }

      case HOME_COURSES_GET._SUCCESS: {
        const { courses, collectionId, status, from } = action.response;

        if (collectionId) {
          // const { from } = state.coursesData[collectionId] || {};

          const data =
            !from || from <= 0
              ? courses
              : [...state.coursesData[collectionId], ...courses];
          draft.coursesData[collectionId] = action.response;
          draft.coursesData[collectionId].courses = data;
        }

        if (status && !collectionId) {
          draft.inProgressCoursesData = {
            ...action.response,
            courses
          };
        }

        break;
      }

      case HOME_PILLS_GET._SUCCESS: {
        const { pills, from } = action.response;

        const data =
          !from || from <= 0 ? pills : [...state.pillsData.pills, ...pills];
        draft.pillsData = action.response;
        draft.pillsData.pills = data;

        break;
      }

      case HOME_COURSE_LIST_GET._SUCCESS: {
        draft.carousels = action.response;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default homeReducer;
