import {
  takeLatest, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import favorite from '../../api/favorite';
import {
  FAVORITE_LIST_GET, FAVORITE_NODE_ACTION
} from '../actions/favorite';


function* favoriteListGet({ itemPerPage = 24, filters = {}, loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { ...filters, ...pagination };
      const { data: { data: response } } = yield call(favorite.getFavoriteList, complete_filters);
      yield put({ type: FAVORITE_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > favorite > favoriteListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* favoriteListGetWatch() {
  yield takeLatest(FAVORITE_LIST_GET._REQUEST, favoriteListGet);
}

function* favoriteNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(favorite.favoriteNodeAction, payload);
      yield put({ type: FAVORITE_NODE_ACTION._SUCCESS });
      yield put({ type: FAVORITE_LIST_GET._REQUEST });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > favorite > favoriteNodeAction', err);
    }
  }
}

function* favoriteNodeActionWatch() {
  yield takeLatest(FAVORITE_NODE_ACTION._REQUEST, favoriteNodeAction);
}

export default [
  favoriteListGetWatch(),
  favoriteNodeActionWatch()
];
