import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { APP_CONFIGURATION_GET, USER_INFO_GET } from '../../redux/actions';

import { Container } from '../../../ui/gridSystem';
import { ProductCardsContainer } from '../../../ui/components';

import {
  CourseInProgressContainer,
  Title,
  Subtitle,
  Background,
  // Text,
  HeaderContainer,
  TitleWrapper,
  BannerContainer,
  Banner,
  DescriptionSection
} from './style';
import HomePageDefaultCover from '../../../ui/assets/img/HPPrincipale.jpg';
import WelcomeModal from '../../../ui/components/Modals/WelcomeModal';
import { FAVORITE_LIST_GET } from '../../redux/actions/favorite';
import { MOST_VIEWED_LIST_GET } from '../../redux/actions/mostviewed';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Gill from '../../../ui/typography/gill';
import STCCarousel from '../../../ui/components/STCCarousel';
import { HOME_COURSE_LIST_GET } from '../../redux/actions/home';

const Home = ({
  getPlatformConf,
  getUserInfo,
  heroImage,
  getFavorites,
  getMostViewed,
  vocabulary,
  favorites,
  mostviewed,
  path_contents,
  pathMapping,
  getCourseList,
  carousels
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserInfo();
    getPlatformConf();
    getMostViewed();
    getFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path_contents]);

  useEffect(() => {
    localStorage.removeItem('activeTabConnected');
    localStorage.removeItem('activeTabImproved');
  }, []);

  useEffect(() => {
    if (pathMapping?.length > 0) {
      const path_ids = pathMapping.map(p => p?.path_tid);
      getCourseList({ path_ids });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathMapping]);

  const getLoginContent = () => (
    <>
      <HeaderContainer>
        <Background img={heroImage || HomePageDefaultCover} />
        <Container className="p-0">
          <CourseInProgressContainer>
            <TitleWrapper>
              <Title>
                <Gill type="titleHome" vocabulary="homeTitle">
                  {/* {getValueVocabulary(vocabulary.homeTitle, 'homeTitle')}, */}
                  Ladies and gentlemen, welcome on board
                </Gill>
              </Title>
              <Subtitle>
                <Gill type="descriptionHome" vocabulary="homeDescription">
                  {/* <Text
                    dangerouslySetInnerHTML={{
                      __html: getValueVocabulary(
                        vocabulary.homeDescription,
                        'homeDescription'
                      )
                    }}
                  /> */}In questa piattaforma e-learning troverete metodi innovativi per arricchire la vostra didattica e sviluppare le competenze trasversali e umane di ragazzi e ragazze, bambini e bambine, creando uno spazio di apprendimento sicuro, inclusivo e non giudicante.<br />
                  Unitevi a noi in un viaggio trasformativo basato su attività esperienziali e pensato sia per scoprire il potenziale interiore e accendere lo sviluppo delle capacità umane, relazionali e creative di bambine e bambini che per favorire e supportare il processo di crescita personale e professionale di ragazzi e ragazze. Siete pronti per partire?
                </Gill>
              </Subtitle>
              <DescriptionSection>
                <Gill type="descriptionHome" vocabulary="homeDescription">
                  <h2>
                    <b>Se sei un/a docente ET3:</b>
                  </h2>
                  <p>
                    In questo viaggio esplorerete luoghi legati all’apprendimento socio-emotivo (SEL) per lo sviluppo delle competenze umane dei vostri studenti e studentesse, supererete nuove frontiere digitali, scoprendo come integrare le attività di tinkering, coding e robotica educativa in tutte le discipline, e acquisire strategie per costruire un ambiente inclusivo e sicuro.<br />
                    Scoprite i due itinerari formativi di diversa intensità:
                  </p>
                </Gill>
                <Gill type="descriptionHome" vocabulary="homeDescription">
                  <h2>
                    <b>
                      Se sei un/a docente Youth Leaders for a Sustainable Future:
                    </b>
                  </h2>
                  <p>
                    All’interno di questo percorso formativo vi accompagneremo nei
                    luoghi di apprendimento delle{' '}
                    <b>
                      competenze trasversali e umane per l’orientamento e
                      l’occupabilità
                    </b>
                    , e le <b>green skills</b>, per promuovere una{' '}
                    <b>mentalità dinamica orientata alla crescita</b>. In tal modo
                    studenti e studentesse potranno coltivare scelte consapevoli
                    sia in ambito scolastico, sia nelle altre sfere della vita,
                    sviluppando la capacità di accogliere le sfide per la ricerca
                    di soluzioni innovative e della propria strada verso l’empowerment.
                  </p>
                </Gill>
              </DescriptionSection>
              <DescriptionSection>
                <Gill type="descriptionHome" vocabulary="homeDescription">
                  <p>Nella sezione <b>Advanced Level (Improved)</b>, esperti ed esperte di Funtasia, Madlab e Scuola di Robotica vi accompagneranno in un percorso formativo di 10 ore! </p>
                  <p>La sezione <b>Entry Level (Connected)</b> vi porterà in un percorso formativo più leggero per acquisire strumenti e modelli di attività pratici per il lavoro nelle vostre classi! </p>
                  <p>Nella nuova sezione "Extra" troverete suggerimenti per portare l’educazione allo sviluppo sostenibile in classe attraverso strumenti digitali e attività innovative. </p>
                </Gill>
                <Gill type="descriptionHome" vocabulary="homeDescription">
                  <p>Nella sezione <b>Improved (Advanced Level)</b> troverete strumenti formativi flessibili e approfondimenti sulle competenze umane, la mentalità dinamica orientata alla crescita e sull'inclusione di genere e sociale.</p>
                  <p>La sezione <b>Connected (Entry Level)</b> vi fornirà un percorso formativo più leggero per acquisire strumenti, attività e strategie per allenare le competenze umane e trasversali dei vostri studenti e delle vostre studentesse.   </p>
                </Gill>
              </DescriptionSection>
            </TitleWrapper>
          </CourseInProgressContainer>
        </Container>
      </HeaderContainer>
      <BannerContainer>
        {(Object.keys(carousels) || []).map(k => (
          <Banner key={`banner-${k}`}>
            <STCCarousel
              pathName={pathMapping.find(p => p?.path_tid === k)?.path_name}
              data={carousels[k]}
            />
          </Banner>
        ))}
        {!!favorites?.length && (
          <Banner>
            <ProductCardsContainer
              title={getValueVocabulary(vocabulary.favourites, 'favourites')}
              type="favorites"
            />
          </Banner>
        )}
        {!!mostviewed?.length && (
          <Banner>
            <ProductCardsContainer
              title={getValueVocabulary(
                vocabulary.mostViewedTitle,
                'mostViewedTitle'
              )}
              type="most_viewed"
            />
          </Banner>
        )}
        <WelcomeModal />
      </BannerContainer>
    </>
  );

  const isLogin = true;

  return <>{isLogin && getLoginContent()}</>;
};

Home.propTypes = {
  // HOC (connect, state)
  heroImage: PropTypes.string,
  favorites: PropTypes.array,
  mostviewed: PropTypes.array,
  vocabulary: PropTypes.object,
  pathMapping: PropTypes.array,
  carousels: PropTypes.object,

  //HOC (connect, dispatch)
  getUserInfo: PropTypes.func.isRequired,
  getPlatformConf: PropTypes.func.isRequired,
  getFavorites: PropTypes.func.isRequired,
  getMostViewed: PropTypes.func.isRequired,
  getCourseList: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      collectionsData,
      inProgressCoursesData,
      coursesData,
      carousels
    } = state.home;
    const { name: userName } = state.user.data;
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { vocabulary, path_contents } = state.app;
    const { items: favorites } = state.favorite;
    const { items: mostviewed } = state.mostviewed;
    const { paths_mapping: pathMapping } = state.app;
    const {
      data: { user_type }
    } = state.user;

    return {
      heroImage,
      collectionsData,
      coursesData,
      inProgressCoursesData,
      userName,
      favorites,
      mostviewed,
      vocabulary,
      user_type,
      path_contents,
      pathMapping,
      carousels
    };
  },
  dispatch => ({
    getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST }),
    getPlatformConf: () => dispatch({ type: APP_CONFIGURATION_GET._REQUEST }),
    getFavorites: () => dispatch({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 12 }),
    getMostViewed: () => dispatch({ type: MOST_VIEWED_LIST_GET._REQUEST, itemPerPage: 12 }),
    getCourseList: ({ path_ids }) => dispatch({
      type: HOME_COURSE_LIST_GET._REQUEST,
      path_ids
    })
  })
)(Home);
