import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';
import Gill from '../../../ui/typography/gill';

import {
  STCCourseContainer,
  InfoContainer,
  Title,
  SeeAll,
  HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import { GeneralCard } from '../../atoms';
import { Settings } from '../Collections/settings';
import {
  SET_SELECTED_STC_COURSE,
  STC_COURSE_NODE_ACTION
} from '../../../app/redux/actions/stc_course';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { getUrl } from '../../../utils/subnavbar';

const STCCarousel = ({
  pushUrl,
  data,
  setSelectedSTCCourse,
  setAsFavorite,
  vocabulary,
  pathName
}) => {
  const { items: list = [] } = data;

  const handleOnClickCard = item => {
    setSelectedSTCCourse(item);
    pushUrl(`${getUrl(pathName)}/${item.id}`);
  };

  return (
    <STCCourseContainer>
      <Container className="p-0">
        <Row>
          <Col lg={12} sm={12}>
            <InfoContainer>
              <HeaderContent>
                <Title>
                  <Gill type="sectionTitle" vocabulary="stcCourse">
                    {pathName}
                  </Gill>
                </Title>
                <SeeAll onClick={() => pushUrl(getUrl(pathName))}>
                  <Gill type="newsAttachment" vocabulary="showAll">
                    {getValueVocabulary(vocabulary.showAll, 'showAll')}
                  </Gill>
                  <span className="icon-arrow-right" />
                </SeeAll>
              </HeaderContent>
            </InfoContainer>
          </Col>
          <Col sm={12}>
            <CustomCarousel config={Settings}>
              {(list || []).map(item => (
                <GeneralCard
                  key={`stc_course-${pathName}-${item.id}`}
                  item={item}
                  onClickFavorite={() => setAsFavorite(item.id)}
                  onClickCard={() => handleOnClickCard(item)}
                  type={GeneralCard.TYPE.SMARTWORKING}
                />
              ))}
            </CustomCarousel>
          </Col>
        </Row>
      </Container>
    </STCCourseContainer>
  );
};

STCCarousel.propTypes = {
  pathName: PropTypes.string,
  data: PropTypes.object.isRequired,
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  setSelectedSTCCourse: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    setAsFavorite: nid =>
      dispatch({
        type: STC_COURSE_NODE_ACTION._REQUEST,
        nid,
        action_type: 'favourite'
      }),
    setSelectedSTCCourse: selected =>
      dispatch({ type: SET_SELECTED_STC_COURSE, selected })
  })
)(STCCarousel);
