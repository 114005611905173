import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Roboto from '../../typography/roboto';

import dateFormat from '../../../utils/date-format';

import { CircleImage, CircularProgress, DownloadLink } from '../../atoms';

import {
  Container, ImageContainer, Title, Info,
  User, Content, ContentProfile, ContentInfo, ContentCertificate,
  ProgressContainer, InfoCertificate, DownloadWrap
} from './style';

import { USER_INFO_GET } from '../../../app/redux/actions';
import { UserDataInterface } from '../../../app/redux/reducers/user';
import { CourseInterface } from '../../../app/redux/reducers/home';
import { CourseStates } from '../../../utils/course-state';
import { CERTIFICATION_COURSE_GET } from '../../../app/redux/actions/certification';
import { TableCertificationInterface } from '../../../app/redux/reducers/certification';

const CourseInfo = ({
  course, course_certificate,

  getUserInfo, getCourseCertificate,
  user: {
    name, surname, picture
  }
}) => {
  useEffect(() => {
    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (course && Number(course.percentage) === 100 && course.status === CourseStates.COMPLETED) {
      getCourseCertificate(course.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const {
    creation_date, last_update_date, percentage
  } = course;

  return (
    <Container>
      <User>
        <ImageContainer>
          <CircleImage src={picture} name={`${name} ${surname}`} />
        </ImageContainer>
      </User>
      <Content>
        <ContentProfile>
          <Title><Roboto type="courseInfoLabel">{name}&nbsp;{surname}</Roboto></Title>
          <ContentInfo>
            <Info className="mb-2"><Roboto type="courseInfoDetails">Data iscrizione al corso: <br /><i>{creation_date ? dateFormat(creation_date, 'short') : '-'}</i></Roboto></Info>
          </ContentInfo>
          <ContentInfo>
            <Info className="mb-2"><Roboto type="courseInfoDetails">Data ultimo accesso al corso: <br /><i>{last_update_date ? dateFormat(last_update_date) : '-'}</i></Roboto></Info>
          </ContentInfo>
        </ContentProfile>
        <ContentCertificate>
          <Title><Roboto type="courseInfoLabel">Certificato</Roboto></Title>
          <InfoCertificate>
            {course_certificate && course_certificate.assigned_date ? (
              <>
                <Info className="mb-2"><Roboto type="courseInfoDetails">Nome certificato: <br /><i>{course_certificate.code} - {course_certificate.title}</i></Roboto></Info>
                {course_certificate.assigned_date ? (
                  <><br />
                    <Info className="mb-2"><Roboto type="courseInfoDetails">Rilasciato il: <br /><i>{dateFormat(course_certificate.assigned_date, 'short')}</i></Roboto></Info>
                  </>
                ) : null}
                <DownloadWrap className="mr-2">
                  <DownloadLink url={course_certificate.download_url} />
                </DownloadWrap>
              </>
            ) : 'Il certificato verrà rilasciato al completamento del corso.'}
          </InfoCertificate>
        </ContentCertificate>
        {/* TODO define what to show
          <ContentActivity>
            <Title><Roboto type="courseInfoLabel">Le mie attività</Roboto></Title>
            <ContentTime>
              <Icon customClass="icon-clock" />
              <Roboto type="courseInfoDetails">
                <Info>{durationFormat(activity)}</Info>
              </Roboto>
            </ContentTime>
          </ContentActivity>
        */}
        <ProgressContainer>
          <CircularProgress percentage={percentage} />
        </ProgressContainer>
      </Content>
    </Container>
  );
};

CourseInfo.propTypes = {
  course: CourseInterface,

  // HOC (connect, state)
  user: UserDataInterface,
  course_certificate: TableCertificationInterface,

  // HOC (connect, dispatch)
  getUserInfo: PropTypes.func.isRequired,
  getCourseCertificate: PropTypes.func.isRequired
};

export default connect(
  state => ({
    user: state.user.data,
    course_certificate: state.certification.course_certificate
  }),
  dispatch => ({
    getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST }),
    getCourseCertificate: (courseId) => dispatch({ type: CERTIFICATION_COURSE_GET._REQUEST, courseId })
  })
)(CourseInfo);
