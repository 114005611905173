import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Container, Title, Label, InformationContainer
} from './style';

import Roboto from '../../typography/roboto';

import { Row } from '../../gridSystem';
import { USER_INFO_GET } from '../../../app/redux/actions';
import { UserDataInterface } from '../../../app/redux/reducers/user';
import dateFormat from '../../../utils/date-format';

const InformationCard = ({
  user: {
    name, surname, username, mail, fiscal_code, created_time
  },
  getUserInfo
}) => {
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <div className="col-lg-12 col-6">
          <InformationContainer>
            <Title><Roboto type="infoTitle" configuration={{ lineHeight: 1.29 }}>Nome</Roboto></Title>
            <Label><Roboto type="description">{name || '-'} {surname}</Roboto></Label>
          </InformationContainer>
        </div>
        <div className="col-lg-4 col-12">
          <InformationContainer>
            <Title><Roboto type="infoTitle" configuration={{ lineHeight: 1.29 }}>Codice Fiscale</Roboto></Title>
            <Label><Roboto type="description">{fiscal_code || username || '-'}</Roboto></Label>
          </InformationContainer>
        </div>
        <div className="col-lg-4 col-6">
          <InformationContainer>
            <Title><Roboto type="infoTitle" configuration={{ lineHeight: 1.29 }}>Email</Roboto></Title>
            <Label><Roboto type="description">{mail || '-' }</Roboto></Label>
          </InformationContainer>
        </div>
        <div className="col-lg-4 col-6">
          <InformationContainer>
            <Title><Roboto type="infoTitle" configuration={{ lineHeight: 1.29 }}>Data iscrizione</Roboto></Title>
            <Label><Roboto type="description">{created_time ? dateFormat(Number(created_time), 'short') : '-'}</Roboto></Label>
          </InformationContainer>
        </div>
      </Row>
    </Container>
  );
};

InformationCard.propTypes = {
  // HOC (connect, state)
  user: UserDataInterface,

  // HOC (connect, dispatch)
  getUserInfo: PropTypes.func.isRequired
};

export default connect(
  state => ({
    user: state.user.data
  }),
  dispatch => ({
    getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST })
  })
)(InformationCard);
