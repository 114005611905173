import styled from 'styled-components';

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px;
  max-width: 700px;
  margin: auto;

  @media ${props => props.theme.device.tablet} {
    max-width: 340px;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  @media ${props => props.theme.device.mobileL} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .signup-select {
    div[class*='-placeholder'] {
      font-family: 'Gill';
      font-style: italic;
      font-weight: 500;
      font-style: italic;
      color: ${props => props.theme.colors.greyDark};
      opacity: 50%;
      text-transform: none;
    }
  }

  .react-datepicker-wrapper {
    input {
      min-width: 100%;
      height: 40px;
      padding: 0.5rem 8px 0;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.border};
      color: ${props => props.theme.colors.black};
      outline: none;

      &::placeholder {
        font-family: 'Gill';
        font-style: italic;
        font-weight: 500;
        font-style: italic;
        color: ${props => props.theme.colors.greyDark};
        opacity: 50%;
        text-transform: none;
      }
    }
  }
  .react-datepicker-popper {
    width: 340px;
    max-width: 100%;

    .react-datepicker {
      width: 100%;
      .react-datepicker__triangle::after {
        border-bottom-color: ${props => props.theme.colors.primaryColor};
      }
      .react-datepicker__navigation-icon {
        top: 8px;
        &::before {
          border-color: ${props => props.theme.colors.white};
        }
      }
      .react-datepicker__header {
        background-color: ${props => props.theme.colors.primaryColor};
        & > div {
          color: ${props => props.theme.colors.white};
        }
        .react-datepicker__day-name {
          color: ${props => props.theme.colors.white};
          width: calc(100% / 7);
          margin: 0;
        }
      }
      select {
        outline: none;
        padding: 2px 5px;
        border-radius: 4px;
        min-width: 100px;
      }
      .react-datepicker__day--selected {
        background-color: ${props => props.theme.colors.primaryColor};
      }
    }
    .react-datepicker__month-container {
      width: 100%;
      .react-datepicker__day {
        width: calc(100% / 7);
        margin: 0;
      }
    }
  }
`;
