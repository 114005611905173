import {
  takeLatest, put, call, select
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_RESET,
  AUTH_RESET_SET,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_OPEN,
  AUTH_RESET_PASSWORD,
  SIGNUP_FORM_GET,
  SIGNUP_PATHS_GET,
  SIGNUP_FORM_SUBMIT,
  SIGNUP_CHECK_EMAIL,
  AUTH_VALIDATE_OTP,
  STORE_CREDENTIALS
} from '../actions';
import auth from '../../api/auth';
import routes from '../../../routes';
import ErrorModal from '../../../ui/components/Modals/ErrorModal';
import { SuccessModal } from '../../../ui/components/Modals';

function* authLogin({ params = {}, sso = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { username, password, forgotPassword } = params;
    const {
      data: {
        data: {
          csrf_token: token,
          force_password_reset: toReset,
          otp_csrf: otpToken
        } = {}
      } = {}
    } = yield call(auth.login, { username, password });
    if (toReset) {
      yield put({ type: AUTH_RESET_SET, params, token });
      forgotPassword
        ? yield put(push(`${routes.resetAuth.path}`))
        : yield put(push(`${routes.resetAuth.path}?first_login=1`));
    } else if (token) {
      console.log(
        'LOGIN - SessStor TMP-X-CSRF-Token',
        sessionStorage.getItem('TMP-X-CSRF-Token')
      );
      if (sessionStorage.getItem('TMP-X-CSRF-Token')) { sessionStorage.removeItem('TMP-X-CSRF-Token'); }
      yield put({ type: AUTH_LOGIN._SUCCESS, token, sso });
    } else {
      yield put(push(`${routes.authOTP.path}`));
      yield put({ type: STORE_CREDENTIALS, params, otpToken });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authLogin', err);
    console.log('SSO ', sso);
    if (sso) { yield put(push(`${routes.authSSO.path}?status=ko&error_code=sso_error`)); }
    if (!sso) {
      yield put({
        type: MODAL_OPEN,
        id: ErrorModal.id,
        data: {
          title: 'Errore di accesso',
          message:
            'Attenzione, le credenziali inserite non sono corrette. Inseriscile nuovamente per accedere'
        }
      });
    }
    yield put({ type: AUTH_LOGIN._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authLoginWatch() {
  yield takeLatest(AUTH_LOGIN._REQUEST, authLogin);
}

function* authReset({ params: { old_password, new_password } = {} }) {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.reset, { old_password, new_password });
    yield put({ type: AUTH_LOGOUT._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authReset', err);
    yield put({ type: AUTH_LOGIN._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authResetWatch() {
  yield takeLatest(AUTH_RESET._REQUEST, authReset);
}

function* authLogout() {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.logout);
    yield put({ type: AUTH_LOGOUT._SUCCESS });
    yield put(push(routes.auth.path));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authLogout', err);
    yield put({
      type: MODAL_OPEN,
      id: ErrorModal.id,
      data: {
        title: 'Errore',
        message: "Attenzione, c'è stato un problema, riprovare più tardi."
      }
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authLogoutWatch() {
  yield takeLatest(AUTH_LOGOUT._REQUEST, authLogout);
}

function* authResetPassword({ params = {}, sso = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { email: username } = params;
    console.log('username', username);
    const {
      data: { data: { status: toReset = 'ok' } = {} } = {}
    } = yield call(auth.resetPassword, { username });
    if (toReset === 'ok') {
      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        data: {
          title: 'Password Reset',
          message:
            'Se la mail è corretta è stata inviato un messaggio contente la nuova password'
        }
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authLogin', err);
    yield put({
      type: MODAL_OPEN,
      id: ErrorModal.id,
      data: {
        title: 'Errore',
        message:
          'Attenzione, le credenziali inserite non sono corrette. Inseriscile nuovamente per resettare'
      }
    });
    yield put({ type: AUTH_RESET_PASSWORD._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authResetPasswordWatch() {
  yield takeLatest(AUTH_RESET_PASSWORD._REQUEST, authResetPassword);
}

function* signupCheckEmail({ email }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = {} } = {} } = yield call(
      auth.signupCheckEmail,
      email
    );
    if (data) yield put({ type: SIGNUP_CHECK_EMAIL._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > signupCheckEmail', err);
    yield put({ type: SIGNUP_CHECK_EMAIL._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupCheckEmailWatch() {
  yield takeLatest(SIGNUP_CHECK_EMAIL._REQUEST, signupCheckEmail);
}

function* signupPathGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = {} } = {} } = yield call(auth.signupPathGet);
    if (data) yield put({ type: SIGNUP_PATHS_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > signupPathGet', err);
    yield put({ type: SIGNUP_PATHS_GET._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupPathGetWatch() {
  yield takeLatest(SIGNUP_PATHS_GET._REQUEST, signupPathGet);
}

function* signupFormGet({ path }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = {} } = {} } = yield call(
      auth.signupFormGet,
      path?.toLowerCase()
    );
    if (data) yield put({ type: SIGNUP_FORM_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > signupFormGet', err);
    yield put({ type: SIGNUP_FORM_GET._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupFormGetWatch() {
  yield takeLatest(SIGNUP_FORM_GET._REQUEST, signupFormGet);
}

function* signupFormSubmit({ question_list }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      signupForm: { registration_module_nid },
      credentials
    } = yield select(state => state.app.signup);
    const user = {
      email: credentials?.username,
      password: credentials?.password,
      name: credentials?.name,
      surname: credentials?.surname,
      gender: credentials?.gender,
      date_of_birth: credentials?.birthDate || 0
    };
    const body = {
      data: {
        user,
        registration_module: {
          nid: Number(registration_module_nid),
          response: question_list
        }
      }
    };
    const response = yield call(auth.signupFormSubmit, body);
    if (response) {
      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        data: {
          title: 'Complimenti',
          message: 'La registrazione è avvenuta con successo.'
        }
      });
      yield put(push(routes.auth.path));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > signupFormSubmit', err);
    yield put({ type: SIGNUP_FORM_SUBMIT._ERROR, response: err });
    yield put({
      type: MODAL_OPEN,
      id: SuccessModal.id,
      data: {
        title: 'Errore',
        message: 'Qualcosa è andato storto, si prega di riprovare più tardi.'
      }
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* signupFormSubmitWatch() {
  yield takeLatest(SIGNUP_FORM_SUBMIT._REQUEST, signupFormSubmit);
}

function* validateOTP({ otp }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { username, otpToken } = yield select(state => state.app.otp);
    const body = {
      username,
      otp_code: otpToken + otp
    };
    const response = yield call(auth.login, body);
    const {
      data: {
        data: { csrf_token: token, force_password_reset: toReset, message } = {}
      } = {}
    } = response;
    const params = { username };
    if (response?.code === 500 && message) {
      yield put({ type: STORE_CREDENTIALS, params, message });
    }
    if (toReset) {
      yield put({ type: AUTH_RESET_SET, params, token });
      yield put(push(`${routes.resetAuth.path}?first_login=1`));
    } else if (token) {
      if (sessionStorage.getItem('TMP-X-CSRF-Token')) { sessionStorage.removeItem('TMP-X-CSRF-Token'); }
      yield put({ type: AUTH_VALIDATE_OTP._SUCCESS, token });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > validateOTP', err);
    yield put({
      type: STORE_CREDENTIALS,
      message: 'Ops, qualcosa è andato storto'
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* validateOTPWatch() {
  yield takeLatest(AUTH_VALIDATE_OTP._REQUEST, validateOTP);
}

export default [
  authLoginWatch(),
  authLogoutWatch(),
  authResetWatch(),
  authResetPasswordWatch(),
  signupCheckEmailWatch(),
  signupPathGetWatch(),
  signupFormGetWatch(),
  signupFormSubmitWatch(),
  validateOTPWatch()
];
