import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from '../../../routes';
import { BaseSettings } from './settings';
import { CustomCarousel, NewsCard } from '../index';
import { Container, Row, Col } from '../../gridSystem';
import {
  NewsHighlightedCarouselContainer, InfoContainer, Title,
  HeaderContent, NewsCardContainer, ColumnCarousel
} from './style';
import { GET_NEWS_HIGHLIGHTED } from '../../../app/redux/actions/news';
import { NewsInterface } from '../../../app/redux/reducers/news';
import Arial from '../../typography/arial';

const NewsHighlightedCarousel = ({
  getNewsHighlighted, pushUrl, highlighted, settings, title
}) => {
  useEffect(() => {
    getNewsHighlighted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {highlighted.length > 0 && (
      <NewsHighlightedCarouselContainer>
        <Container className="p-0">
          <Row>
            <Col lg={12} sm={12}>
              <InfoContainer>
                <HeaderContent>
                  <Title><Arial type="sectionTitle">{title}</Arial></Title>
                </HeaderContent>
              </InfoContainer>
            </Col>
            <ColumnCarousel lg={12} sm={12}>
              {highlighted.length > 1
                ? (
                  <CustomCarousel config={{ ...BaseSettings, ...settings }}>
                    {highlighted.length > 0 && (highlighted).slice(0, 12).map(singleNews => (
                      <NewsCardContainer key={singleNews.id}>
                        <NewsCard
                          onClick={() => pushUrl(`${routes.news.path}/${singleNews.id}`)}
                          news={singleNews}
                        />
                      </NewsCardContainer>
                    ))}
                  </CustomCarousel>
                )
                : (
                  <NewsCardContainer key={highlighted[0].id}>
                    <NewsCard
                      onClick={() => pushUrl(`${routes.news.path}/${highlighted[0].id}`)}
                      news={highlighted[0]}
                      isHighlighted
                    />
                  </NewsCardContainer>
                )
            }
            </ColumnCarousel>
          </Row>
        </Container>
      </NewsHighlightedCarouselContainer>
      )}
    </>
  );
};

NewsHighlightedCarousel.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.object,

  // HOC (connect, state)
  highlighted: PropTypes.arrayOf(NewsInterface),

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getNewsHighlighted: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { highlighted: { items: highlighted = [] } = {} } = state.news;
    return {
      highlighted
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getNewsHighlighted: () => dispatch({ type: GET_NEWS_HIGHLIGHTED._REQUEST, itemPerPage: 100 })
  })
)(NewsHighlightedCarousel);
