import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  Background,
  HeaderContainer,
  MainContainer,
  ResultContainer,
  CardContainer,
  SeeMore
} from './style';
import { GeneralCard, Tabs } from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/HPImpoved.jpg';
import {
  PATH_DETAILS_GET,
  SET_SELECTED_STC_COURSE,
  STC_COURSE_LIST_GET,
  STC_COURSE_NODE_ACTION
} from '../../redux/actions/stc_course';
import routes from '../../../routes';
import Arial from '../../../ui/typography/arial';
import Gothic from '../../../ui/typography/gothic';
import theme from '../../../ui/theme';
import NoResult from '../../../ui/atoms/NoResult';
import { nameForUrl } from '../../../utils/subnavbar';

const CourseList = ({
  heroImage,
  stcCourse,
  getSTCCourse,
  pushUrl,
  setSelectedSTCCourse,
  setAsFavorite,
  path_contents,
  paths_mapping,
  courseName,
  getPathDetails,
  pathDetails
}) => {
  const ls = localStorage;
  const { items: list = [], total_items } = stcCourse;
  const [tabs, setTabs] = useState([]);
  const [pathId, setPathId] = useState();
  const [activeTab, setActiveTab] = useState(
    Boolean(ls.getItem('activeTabCourse'))
      && tabs.filter(
        tab => tab.value === JSON.parse(ls.getItem('activeTabCourse')).value
      ).length > 0
      ? JSON.parse(ls.getItem('activeTabCourse'))
      : tabs[0]
  );

  useEffect(() => {
    if (paths_mapping?.length > 0) {
      const id = paths_mapping.find(
        p => nameForUrl(p?.path_name) === courseName
      )?.path_tid;
      setPathId(id);
    }
  }, [courseName, paths_mapping]);

  useEffect(() => {
    if (path_contents.length) {
      const selectedPath = [...path_contents].find(
        item => nameForUrl(item.name) === courseName
      );
      const selectedPathChildren = selectedPath?.children;
      const options = (selectedPathChildren || []).reduce(
        (acc, val) => [...acc, { value: val.id, label: val.name }],
        []
      );
      setActiveTab(
        Boolean(ls.getItem('activeTabCourse'))
          && options.filter(
            tab => tab.value === JSON.parse(ls.getItem('activeTabCourse')).value
          ).length > 0
          ? JSON.parse(ls.getItem('activeTabCourse'))
          : options[0]
      );
      setTabs(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path_contents, courseName]);

  useEffect(() => {
    if (activeTab) getSTCCourse(true, activeTab?.value);
    else if (pathId) {
      window.scrollTo(0, 0);
      getSTCCourse(true, pathId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pathId]);

  useEffect(() => {
    if (pathId) {
      getPathDetails(pathId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathId]);

  const handleOnClickCard = item => {
    setSelectedSTCCourse(item);
    pushUrl(`${routes.course.path}/${courseName}/${item.id}`);
  };

  const onClickSeeMore = () => {
    getSTCCourse(false, pathId);
  };

  const onChangeTab = tab => {
    setActiveTab(tab);
    ls.setItem('activeTabCourse', JSON.stringify(tab));
  };

  return (
    <>
      <HeaderContainer>
        <Background img={pathDetails?.cover_url || ProceduresDefaultCover} />
        <Container className="p-0">
          <Gothic type="sectionDescription">
            <span
              dangerouslySetInnerHTML={{ __html: pathDetails?.cover_text }}
            />
          </Gothic>
          <Gothic type="sectionDescription" className="credit-line">
            {pathDetails?.cover_credits}
          </Gothic>
        </Container>
      </HeaderContainer>
      <MainContainer>
        <Container className="p-0">
          {tabs.length > 1 && (
            <Tabs
              tabs={[...tabs]}
              onChange={onChangeTab}
              selected={activeTab}
              color={theme().colors.primaryRed}
            />
          )}
          <ResultContainer listLength={list?.length}>
            {list.map(item => (
              <CardContainer key={`stccourse-${item.id}`}>
                <GeneralCard
                  item={item}
                  onClickFavorite={() => setAsFavorite(item.id)}
                  onClickCard={() => handleOnClickCard(item)}
                  type={GeneralCard.TYPE.SMARTWORKING}
                />
              </CardContainer>
            ))}
          </ResultContainer>
          {list.length <= 0 && <NoResult>Nessun risultato</NoResult>}
          {list.length <= 0 && list.length < total_items && (
            <SeeMore onClick={onClickSeeMore}>
              <Arial type="scrollUp">Mostra Altri</Arial>
            </SeeMore>
          )}
        </Container>
      </MainContainer>
    </>
  );
};

CourseList.propTypes = {
  // HOC (connect, state)
  heroImage: PropTypes.string,
  stcCourse: PropTypes.object.isRequired,
  paths_mapping: PropTypes.array,
  pathDetails: PropTypes.object,

  // HOC (connect, dispatch)
  getSTCCourse: PropTypes.func.isRequired,
  setSelectedSTCCourse: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  getPathDetails: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { stcCourse, pathDetails } = state.stc_course;
    const { path_contents, paths_mapping } = state.app;
    return {
      heroImage,
      stcCourse,
      path_contents,
      paths_mapping,
      pathDetails
    };
  },
  dispatch => ({
    getSTCCourse: (subset = true, pathFilter = undefined) => dispatch({
      type: STC_COURSE_LIST_GET._REQUEST,
      itemPerPage: 24,
      subset,
      pathFilter
    }),
    setSelectedSTCCourse: selected => dispatch({ type: SET_SELECTED_STC_COURSE, selected }),
    setAsFavorite: nid => dispatch({
      type: STC_COURSE_NODE_ACTION._REQUEST,
      nid,
      action_type: 'favourite'
    }),
    getPathDetails: id => dispatch({ type: PATH_DETAILS_GET._REQUEST, id }),
    pushUrl: url => dispatch(push(url))
  })
)(CourseList);
