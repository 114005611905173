import { createAsyncActionType } from './index';

export const ROLE_TRAINING_LIST_GET = createAsyncActionType('roletraining', 'ROLE_TRAINING_LIST_GET');
export const SELECTED_ROLE_TRAINING_GET = createAsyncActionType('roletraining', 'SELECTED_ROLE_TRAINING_GET');
export const SET_SELECTED_ROLE_TRAINING = '@@roletraining/SET_SELECTED_ROLE_TRAINING';
export const ROLE_TRAINING_COURSES_GET = createAsyncActionType('roletraining', 'ROLE_TRAINING_COURSES_GET');

export const ROLE_TRAINING_NODE_ACTION = createAsyncActionType('roletraining', 'ROLE_TRAINING_NODE_ACTION');
export const ROLE_TRAINING_COMMENTS_GET = createAsyncActionType('roletraining', 'ROLE_TRAINING_COMMENTS_GET');
export const ROLE_TRAINING_COMMENTS_SET = createAsyncActionType('roletraining', 'ROLE_TRAINING_COMMENTS_SET');

export const SET_FILTERS_ROLE_TRAINING = '@@roletraining/SET_FILTERS_ROLE_TRAINING';
