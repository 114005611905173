import produce from 'immer';
import { SURVEY_CONTENT_GET } from '../actions/survey';

const initialState = {
  selected_survey: {}
};

const surveyReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case SURVEY_CONTENT_GET._SUCCESS: {
      const { response, survey_type } = action;
      // eslint-disable-next-line prefer-destructuring
      draft.selected_survey = {
        ...state.selected_survey,
        [survey_type]: response[0]
      };
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default surveyReducer;
