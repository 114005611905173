import React from 'react';
import PropTypes from 'prop-types';
import Typeface, { TypefaceInterface } from './index';
import { FontBase } from '../../const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'gill',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  size: FontBase
};

const types = {
  1: {
    ...baseConfig
  },
  title: {
    ...baseConfig,
    fontWeight: 'bold',
    lineHeight: 1.25
  },
  description: {
    ...baseConfig,
    size: 12,
    fontStyle: 'italic',
    lineHeight: 1.33,
    fontWeight: 'normal'
  },

  description14: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  description18: {
    ...baseConfig,
    size: 18,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  titleHome: {
    ...baseConfig,
    fontWeight: 'bold',
    size: 36
  },
  descriptionHome: {
    ...baseConfig,
    size: 15,
    lineHeight: 1.47
  },
  newsDate: {
    ...baseConfig,
    size: 10,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  backButton: {
    ...baseConfig,
    size: 16,
    fontWeight: 500,
    lineHeight: 1.33
  },
  newsTitle: {
    ...baseConfig,
    fontWeight: 'bold',
    size: 14
  },
  newsSubtitle: {
    ...baseConfig,
    fontWeight: 'normal',
    lineHeight: 1.11,
    size: 18
  },
  newsDescription: {
    ...baseConfig,
    fontWeight: 'normal',
    size: 12
  },
  newsDetailTitle: {
    ...baseConfig,
    fontWeight: 'normal',
    lineHeight: 1.4,
    size: 40
  },
  newsDetailDescription: {
    ...baseConfig,
    fontWeight: 'normal',
    size: 18,
    lineHeight: 1.1
  },
  newsDateItalic: {
    ...baseConfig,
    size: 12,
    lineHeight: '16px',
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  newsAttachment: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.33,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  sectionTitle: {
    ...baseConfig,
    size: 18,
    lineHeight: '20px',
    fontWeight: 'bold'
  },
  guidebookCardDate: {
    ...baseConfig,
    size: 10,
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 'normal'
  },
  guidebookCardAttachment: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'bold'
  },
  guidebookCardText: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.17,
    fontWeight: 'normal'
  },
  guidebookCategory: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  editorialTitle: {
    ...baseConfig,
    size: 32,
    lineHeight: 1.38,
    fontWeight: 'bold'
  },
  editorialSubtitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5,
    fontWeight: 'normal'
  },
  productCardTitle: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.29,
    fontWeight: 'bold'
  },
  productCardDetail: {
    ...baseConfig,
    size: 10,
    lineHeight: 1,
    fontWeight: 'normal'
  },
  collectionCardTitle: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.11
  },
  collectionCardFooter: {
    ...baseConfig,
    size: 10,
    fontWeight: 'normal',
    lineHeight: 1
  },
  like: {
    ...baseConfig,
    size: 16,
    lineHeight: 0.63,
    fontWeight: 'normal'
  },
  videoTitle: {
    ...baseConfig,
    size: 26,
    lineHeight: 1.15,
    fontWeight: 'bold'
  },
  videoSubTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.25,
    fontWeight: 'normal'
  },
  editorialImageTitle: {
    ...baseConfig,
    size: 20,
    lineHeight: 1.3,
    fontWeight: 'bold'
  },
  editorialImageText: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5,
    fontWeight: 'normal'
  },
  tab: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.14,
    fontWeight: 'normal'
  },
  activeTab: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.14,
    fontWeight: 'bold'
  },
  scrollUp: {
    ...baseConfig,
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.33
  },
  collectionHeaderTitle: {
    ...baseConfig,
    size: 20,
    fontWeight: 'bold',
    lineHeight: 'normal'
  },
  collectionHeaderDescription: {
    ...baseConfig,
    size: 16,
    lineHeight: 'normal'
  },
  categoryPill: {
    size: 10,
    fontWeight: 'bold'
  },
  progressInfo: {
    size: 12,
    lineHeight: 1.17,
    letterspacing: '-0.1px'
  },
  coursesHeaderLength: {
    size: 12,
    lineHeight: 1.17,
    letterSpacing: '-0.1px'
  },
  coursesHeaderLengthBold: {
    size: 14,
    fontWeight: 700,
    lineHeight: 1.29,
    letterSpacing: '-0.1px'
  },
  tagTitle: {
    size: 10,
    opacity: 0.6
  },
  tagText: {
    size: 14,
    lineHeight: 1.07
  },
  newsBannerTitle: {
    size: 50,
    lineHeight: 1.4,
    fontWeight: 'bold'
  },
  welcomeModalTitle: {
    size: 18,
    lineHeight: 1.22,
    fontWeight: 'bold'
  },
  welcomeModalSubtitle: {
    size: 14,
    lineHeight: 1.14
  },
  EditorialAboutTitle: {
    size: 40,
    fontWeight: 'normal'
  },
  EditorialAboutText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.38
  },
  EditorialGridTitle: {
    size: 20,
    fontWeight: 'bold',
    lineHeight: 1.3
  },
  EditorialGridText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.5
  },
  editorialCardTitle: {
    size: 40,
    fontWeight: 'normal'
  },
  editorialCardText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.5
  },
  filterLabel: {
    size: 14,
    fontWeight: 'bold',
    lineHeight: 1.29,
    marginBottom: 8
  },
  coachLabel: {
    ...baseConfig,
    size: 10,
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 0.5)'
  },
  coachValue: {
    ...baseConfig,
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.13
  },
  coachName: {
    ...baseConfig,
    size: 16,
    fontWeight: 'bold',
    lineHeight: 1.5
  },
  quote: {
    size: 30,
    fontWeight: 'normal',
    lineHeight: 1.33,
    fontStyle: 'italic'
  },
  author: {
    size: 16,
    fontWeight: 'bold',
    lineHeight: 1.25,
    textTransform: 'capitalize'
  },
  quoteDetail: {
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.5,
    textTransform: 'uppercase'
  },
  generalCardTag: {
    size: 10,
    fontWeight: 'bold',
    letterSpacing: 'normal'
  },
  addInfoGeneralCard: {
    size: 10,
    letterSpacing: 'normal'
  },
  bannerGeneralCard: {
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  button: {
    size: 16
    /* fontWeight: 'bold' */
  },
  comingSoonTitle: {
    size: 60,
    fontWeight: 'bold',
    lineHeight: 1.17
  },
  maintenanceDescription: {
    size: 26,
    fontWeight: 'normal',
    lineHeight: 1
  },
  titlePeople: {
    size: 11,
    lineHeight: 1.09
  },
  activityLinkDescription: {
    size: 18,
    lineHeight: 1
  },
  error: {
    ...baseConfig,
    size: 14
  }
};

const Gill = ({ type, configuration, children, style, vocabulary }) => (
  <Typeface
    style={style}
    configuration={{ ...types[type], ...configuration }}
    vocabulary={vocabulary}
  >
    {children}
  </Typeface>
);

Gill.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface,
  vocabulary: PropTypes.string
};

Gill.defaultProps = {
  type: '1'
};

export default Gill;
