import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import guidebook from '../../api/guidebook';
import { GUIDEBOOK_GET } from '../actions/guidebook';
// import { calcPagination } from '../../../utils/pagination';

function* guidebookGet({ section = false, itemPerPage = 4 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { files: guidebookList = [] } = yield select(state => state.guidebook);
    if (!guidebookList?.length) {
      // const pagination = calcPagination(undefined, section ? itemPerPage : 100);
      const { data: { data: { items: { file_data: files }, categories: filters } } = {} } = yield call(guidebook.getGuidebook);
      yield put({ type: GUIDEBOOK_GET._SUCCESS, files, filters });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > guidebook > guidebookGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* guidebookGetWatch() {
  yield takeLatest(GUIDEBOOK_GET._REQUEST, guidebookGet);
}

export default [
  guidebookGetWatch()
];
