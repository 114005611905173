import styled, { css } from 'styled-components';

export const CommentsContainer = styled.div`
  padding: 20px 0;
`;

export const CommentsWrapper = styled.div`
  & > button {
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-left: auto;
    background-color: ${props => props.theme.colors.primaryColor};
    border-color: ${props => props.theme.colors.primaryColor};
  }
`;

export const TextArea = styled.textarea`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.brownGrey};
  border-radius: 12px;
  color: ${props => props.theme.colors.black};
  margin: 14px 0 0px;
  padding: 15px 21px;
  width: 100%;
  font-style: italic;
`;

export const CommentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 40px;
  

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 20px;
  }
`;

export const CommentContainer = styled.div`
  padding: 20px 0;
  overflow-y: auto;
  height: 100%;
  max-height: 300px;
  width: 100%;
  /* background-color: #D1CCBD;
  border-radius: 6px;
  padding-left: 10px;   */


  ${props => props.showMore
    && css`
      overflow-y: scroll;
    `}
`;

export const CommentInfo = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  > div {
    width: auto;
  }
`;

export const CommentBody = styled.div`
  margin-left: 20px;
  position: relative;
  width: 100%;
`;

export const UserAvatar = styled.div`
  background-image: url(${props => props.avatar});
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
`;

export const Text = styled.div`
  margin-top: 5px;
`;

export const Timestamp = styled.span`
  color: ${props => props.theme.colors.brownGrey};
  margin-left: 10px;
`;

export const CommentsHeader = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  margin-bottom: 20px;
`;
