import styled from 'styled-components';

const BackButtonContainer = styled.div`
  padding: 20px 10px;
  width: 100%;
`;

const Span = styled.span.attrs(() => ({
  className: 'back'
}))`
  color: ${props => props.color || props.theme.colors.black};
  cursor: pointer;
`;

export { BackButtonContainer, Span };
