import { createAsyncActionType } from './index';

export const PROCESSES_PROCEDURES_LIST_GET = createAsyncActionType('processeslprocedures', 'PROCESSES_PROCEDURES_LIST_GET');
export const SELECTED_PROCESSES_PROCEDURES_GET = createAsyncActionType('processeslprocedures', 'SELECTED_PROCESSES_PROCEDURES_GET');
export const SET_SELECTED_PROCESSES_PROCEDURES = '@@processeslprocedures/SET_SELECTED_PROCESSES_PROCEDURES';
export const PROCESSES_PROCEDURES_COURSES_GET = createAsyncActionType('processeslprocedures', 'PROCESSES_PROCEDURES_COURSES_GET');

export const PROCESSES_PROCEDURES_NODE_ACTION = createAsyncActionType('processeslprocedures', 'PROCESSES_PROCEDURES_NODE_ACTION');
export const PROCESSES_PROCEDURES_COMMENTS_GET = createAsyncActionType('processeslprocedures', 'PROCESSES_PROCEDURES_COMMENTS_GET');
export const PROCESSES_PROCEDURES_COMMENTS_SET = createAsyncActionType('processeslprocedures', 'PROCESSES_PROCEDURES_COMMENTS_SET');

export const SET_FILTERS_PROCESSES_PROCEDURES = '@@processeslprocedures/SET_FILTERS_PROCESSES_PROCEDURES';
