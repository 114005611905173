import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  Background, HeaderContainer, STCAllContainer, ResultContainer,
  CardContainer, SeeMore
} from './style';
import { GeneralCard, Tabs } from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/procedures.jpg';
import {
  SET_SELECTED_STC_ALL,
  STC_ALL_LIST_GET,
  STC_ALL_NODE_ACTION
} from '../../redux/actions/stc_all';
import routes from '../../../routes';
import Arial from '../../../ui/typography/arial';
import Gothic from '../../../ui/typography/gothic';
import theme from '../../../ui/theme';
import NoResult from '../../../ui/atoms/NoResult';


const STCAll = ({
  heroImage, stcAll, getSTCAll, pushUrl,
  setSelectedSTCAll, setAsFavorite, path_contents
}) => {
  const { items: list = [], total_items = 0 } = stcAll;
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (path_contents.length) {
      const selectedPath = [...path_contents].find(item => item.code.toLowerCase() === 'extra');
      const selectedPathChildren = selectedPath?.children;
      const options = (selectedPathChildren || []).reduce((acc, val) => ([...acc, { value: val.id, label: val.name }]), [{ label: 'Tutti', value: selectedPath?.id }]);
      setActiveTab(options[0]);
      setTabs(options);
    }
  }, [path_contents]);

  const handleOnClickCard = (item) => {
    setSelectedSTCAll(item);
    pushUrl(`${routes.stcAll.path}/${item.id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSTCAll();
  }, [getSTCAll, path_contents]);

  const onClickSeeMore = () => {
    getSTCAll(false);
  };

  const onChangeTab = (tab) => {
    getSTCAll(true, tab.value);
    setActiveTab(tab);
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
        <Container className="p-0">
          <Gothic type="sectionDescription">Potrai approfondire, attraverso una serie di contenuti extra di livello avanzato, una serie di temi connessi allo sviluppo delle competenze umane e tecnologico-digitali di bambini, bambine e adolescenti.</Gothic>
        </Container>
      </HeaderContainer>
      <STCAllContainer>
        <Container className="p-0">
          {tabs.length > 1 && <Tabs tabs={[...tabs]} onChange={onChangeTab} selected={activeTab} color={theme().colors.primaryRed} />}
          <ResultContainer>
            {
              list.map(item => (
                <CardContainer key={`stcall-${item.id}`}>
                  <GeneralCard item={item} onClickFavorite={() => setAsFavorite(item.id)} onClickCard={() => handleOnClickCard(item)} type={GeneralCard.TYPE.SMARTWORKING} />
                </CardContainer>
              ))
            }
          </ResultContainer>
          {list.length <= 0 && <NoResult>Nessun risultato</NoResult>}
          {list.length && list.length < total_items && (
          <SeeMore onClick={onClickSeeMore}>
            <Arial type="scrollUp">Mostra Altri</Arial>
          </SeeMore>
          )}
        </Container>
      </STCAllContainer>
    </>
  );
};

STCAll.propTypes = {
  // HOC (connect, state)
  heroImage: PropTypes.string,
  stcAll: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getSTCAll: PropTypes.func.isRequired,
  setSelectedSTCAll: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { stcAll } = state.stc_all;
    const { path_contents } = state.app;
    return {
      heroImage,
      stcAll,
      path_contents
    };
  },
  dispatch => ({
    getSTCAll: (subset = true, pathFilter = undefined) => dispatch({
      type: STC_ALL_LIST_GET._REQUEST, itemPerPage: 24, subset, pathFilter
    }),
    setSelectedSTCAll: (selected) => dispatch({ type: SET_SELECTED_STC_ALL, selected }),
    setAsFavorite: (nid) => dispatch({ type: STC_ALL_NODE_ACTION._REQUEST, nid, action_type: 'favourite' }),
    pushUrl: url => dispatch(push(url))
  })
)(STCAll);
