import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';

import { shouldUseMock } from '../../utils/common';
import { deleteAppointmentMock, getCalendarMock, insertAppointmentMock, retrieveAppointmentMock } from './mock';


export default {
  getAgenda: (filters) => {
    if (shouldUseMock()) {
      return getCalendarMock();
    }
    return apiCall(`${baseUrl}${extendUrl}agenda${parseQueryParams(filters)}`, 'GET');
  },
  insertAppointment: (params) => {
    const { send_notification, ...otherParams } = params
    if (shouldUseMock()) {
      return insertAppointmentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}appointment/insert${send_notification ? '?send_notification=1' : ''}`, 'POST', otherParams);
  },
  retrieveAppointment: (filters) => {
    if (shouldUseMock()) {
      return retrieveAppointmentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}retrieve_appointment${parseQueryParams(filters)}`, 'GET');
  },
  deleteAppointment: (params) => {
    if (shouldUseMock()) {
      return deleteAppointmentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}appointment/${params.delete}/delete`, 'POST');
  },
};
