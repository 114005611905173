import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';

import {
  SubNavBarContainer,
  ContentWrapper,
  SubMenuElementContainer
} from './style';

import routes from '../../../routes';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Gothic from '../../typography/gothic';
import { Container } from '../../gridSystem';
import { Logo } from '../../atoms';
import { LogoContainer } from '../../../app/pages/Auth/styles';
import { getUrl } from '../../../utils/subnavbar';

const initialSubMenuElements = [
  //{
  //   title: 'Il gruppo',
  //   url: routes.mission.path
  // },
  // {
  //   title: 'integrationAgenda',
  //   url: routes.integrationagenda.path
  // },
  // {
  //   title: 'newsTitle',
  //   url: routes.news.path
  // },
  // {
  //   title: 'roleTrainingTitle',
  //   url: routes.roleTraining.path
  // },
  // {
  //   title: 'proceduresTitle',
  //   url: routes.processesProcedures.path
  // },
  // {
  //   title: 'productsServicesTitle',
  //   url: routes.productsServices.path
  // },
  // {
  //   title: 'stcImproved',
  //   url: routes.stcImproved.path
  // },
  // {
  //   title: 'stcConnected',
  //   url: routes.stcConnected.path
  // },
  // {
  //   title: 'stcAll',
  //   url: routes.stcAll.path
  // },
  {
    title: 'faqTitle',
    url: routes.faq.path,
    weight: '99'
  }
  //   title: 'rubricChange',
  //   url: routes.rubricChange.path
  // },
  // {
  //   title: 'lifeIspTitle',
  //   url: routes.lifeIsp.path
  // },
  // {
  //   title: 'Cosa Cambia',
  //   url: routes.whatChange.path
  // },
  // {
  //   title: 'obbligatoryTitle',
  //   url: routes.compulsarytraining.path
  // }
  // {
  //   title: 'productsTitle',
  //   url: routes.products.path
  // },
  // {
  //   title: 'proceduresTitle',
  //   url: routes.procedures.path
  // },
  // {
  //   title: 'guidebookTitle',
  //   url: routes.guidebook.path
  // },
  //{
  //  title: 'productsAndServices',
  //  url: routes.productsAndServices.path
  //},
  //{
  //  title: 'commercialGap',
  //  url: routes.commercialGap.path
  //}
  // {
  //   title: 'faqTitle',
  //   url: routes.faq.path
  // }
];

// const subMenuElementsTutor = [
//   {
//     title: 'integrationAgenda',
//     url: routes.integrationagenda.path
//   },
//   {
//     title: 'newsTitle',
//     url: routes.news.path
//   },
//   // {
//   //   title: 'rubricChange',
//   //   url: routes.rubricChange.path
//   // },
//   {
//     title: 'guidebookTitle',
//     url: routes.guidebook.path
//   }
// ];

const subMenuElement = (
  { title, url, hasLogo },
  redirect,
  isActive = true,
  vocabulary
) => {
  const handleOnCliCkMenuElement = urlSelected => {
    redirect(urlSelected);
    localStorage.removeItem('activeTabConnected');
    localStorage.removeItem('activeTabImproved');
  };

  return (
    <SubMenuElementContainer
      onClick={() => handleOnCliCkMenuElement(url)}
      active={isActive}
      key={title}
      vocabulary={vocabulary}
      hasLogo={hasLogo}
    >
      <Gothic type="activeTab" vocabulary={title}>
        {getValueVocabulary(vocabulary[title], title)}
      </Gothic>
      {hasLogo && (
        <LogoContainer>
          <Logo size={Logo.SIZE.SMALL} />
        </LogoContainer>
      )}
    </SubMenuElementContainer>
  );
};

const SubNavBar = ({
  currentPath,
  pushUrl,
  vocabulary,
  menuMobileOpen,
  navigationMobile,
  pathsMapping
}) => {
  const [activePath, setActivePath] = useState(currentPath);
  const [subMenuElements, setSubMenuElements] = useState(
    initialSubMenuElements
  );

  useEffect(() => {
    setActivePath(currentPath);
  }, [currentPath]);

  const changeLocation = url => {
    pushUrl(url);
  };

  useEffect(() => {
    if (pathsMapping && pathsMapping.length > 0) {
      const elements = pathsMapping.map(p => ({
        title: p?.path_name,
        url: getUrl(p?.path_name),
        weight: p?.path_weight
      }));
      elements.sort((a, b) => a.weight - b.weight);
      const newElements = [...elements, ...initialSubMenuElements];
      setSubMenuElements(newElements);
    }
  }, [pathsMapping]);

  return (
    <SubNavBarContainer navigationMobile={navigationMobile}>
      <Container className="p-0">
        <ContentWrapper className="container-fluid">
          <>
            {subMenuElements.map(elem => subMenuElement(
              elem,
              changeLocation,
              activePath.includes(elem?.url),
              vocabulary
            ))}
          </>
        </ContentWrapper>
      </Container>
    </SubNavBarContainer>
  );
};

SubNavBar.propTypes = {
  // HOC (connect, state)
  currentPath: PropTypes.string.isRequired,
  pathsMapping: PropTypes.array,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => ({
    currentPath: state.app.currentPath,
    faqUrl: state.app.appConfig.faqUrl,
    vocabulary: state.app.vocabulary,
    menuMobileOpen: state.app.menuMobileOpen,
    navigationMobile: state.app.navigationMobile,
    pathsMapping: state.app.paths_mapping
  }),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(SubNavBar);
