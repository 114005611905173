import styled, { keyframes } from 'styled-components';
import { Col } from '../../../ui/gridSystem';

const LoadEnter = keyframes`
  0% {
    transform: translateY(10%);
    
  }
  100% {
    transform: translate(0);
    
  }
`;

const FavoritesContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 20px 20px;
`;

const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  transition: ${props => props.theme.constants.transitionBase};
  animation: ${LoadEnter} 0.3s;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const TabsContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export { FavoritesContainer, CardContainer, TabsContainer };
