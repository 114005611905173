import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CourseCardContainer,
  Title,
  CollectionContent,
  CollectionDetailsFooter,
  Category
} from './style';

import { COURSE_DETAILS_SET } from '../../../app/redux/actions/course';
import Arial from '../../typography/arial';

const CollectionsCardItem = ({
  collection,
  courseDetailsSet,
  onBookmark,
  bookmarked
}) => {
  const { title, cover, category = 'categoria 1', courses = [] } = collection;

  const selectCourse = () => {
    courseDetailsSet(collection);
  };

  const bookmark = e => {
    e.stopPropagation();
    onBookmark && onBookmark(collection);
  };

  return (
    <CourseCardContainer onClick={selectCourse} cover={cover}>
      {category && (
        <CollectionContent left>
          <Category>{category}</Category>
        </CollectionContent>
      )}

      <CollectionContent right>
        <span className="icon-info" />
        <span className="icon-heart-white" onClick={bookmark} />
      </CollectionContent>

      <Title>
        <Arial type="collectionCardTitle">{title}</Arial>
      </Title>
      <CollectionDetailsFooter>
        <Arial type="collectionCardFooter">
          <span className="icon-icon-video" />
          {courses?.length} oggetti formativi
        </Arial>
        {/* <Arial type="collectionCardFooter"><span className="icon-clock" />{duration} min. totali</Arial> */}
      </CollectionDetailsFooter>
    </CourseCardContainer>
  );
};

CollectionsCardItem.propTypes = {
  collection: PropTypes.object,
  onBookmark: PropTypes.func,
  bookmarked: PropTypes.bool,

  // HOC (connect, dispatch)
  courseDetailsSet: PropTypes.func.isRequired
};

CollectionsCardItem.defaultProps = {
  bookmarked: false
};

export default connect(
  () => ({}),
  dispatch => ({
    courseDetailsSet: collection =>
      dispatch({ type: COURSE_DETAILS_SET, collection })
    // pushUrl: id => dispatch(push(`${routes.collection.path}/${id}`))
  })
)(CollectionsCardItem);
