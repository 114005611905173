import React from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';

import { connect } from 'react-redux';
import { ScrollerContainer, Text, Icon } from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Gill from '../../typography/gill';

const Scroller = ({ target, vocabulary }) => (
  <ScrollerContainer>
    <div onClick={() => scrollToElement(`#${target}`, { offset: -150 }, 10)}>
      <Text><Gill type="scrollUp" vocabulary="action_come_back">{getValueVocabulary(vocabulary.action_come_back || 'action_come_back')}</Gill></Text>
      <Icon className="icon-torna-su" />
    </div>
  </ScrollerContainer>
);

Scroller.propTypes = {
  target: PropTypes.string,
  vocabulary: PropTypes.object
};

Scroller.defaultProps = {
  target: 'anchor'
};

export default connect(
  state => {
    const { vocabulary } = state.app;

    return {
      vocabulary
    };
  }
)(Scroller);
