import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Settings } from './settings';
import { CardContainer, ResultContainer } from './style';
import { CustomCarousel } from '../index';
import { COLLECTION_LIST_GET, SET_SELECTED_COLLECTION } from '../../../app/redux/actions/collections';
import { GeneralCard, NoResult } from '../../atoms';
import { PRODUCTS_NODE_ACTION } from '../../../app/redux/actions/product';
import routes from '../../../routes';
import { getTagColor } from '../../../utils/common';

const Collections = ({
  slider, getCollection, categories = [],
  setSelectedCollection, pushUrl, courses, setAsFavoriteCollection,
  selectedFilters
}) => {
  const [course, setCourse] = useState(courses);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCollection]);

  useEffect(() => {
    setCourse(courses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const handleOnClickCard = (item) => {
    setSelectedCollection(item);
    pushUrl(`${routes.collection.path}/${item.id}`);
  };

  return (
    <>
      {slider ? (
        <div>
          <CustomCarousel config={Settings}>
            {course?.map(collection => (
              <GeneralCard
                key={collection.id}
                item={collection}
                onClickCard={() => handleOnClickCard(collection)}
                onClickFavorite={() => setAsFavoriteCollection(collection.id)}
                colorTag={getTagColor(categories, collection.category_id)}
              />
            ))}
          </CustomCarousel>
        </div>
      ) : (
        <ResultContainer>
          {course?.length ? (course.map((collection) => (
            <CardContainer key={collection.id}>
              <GeneralCard
                item={collection}
                onClickCard={() => handleOnClickCard(collection)}
                onClickFavorite={() => setAsFavoriteCollection(collection.id)}
                colorTag={getTagColor(categories, collection.category_id)}
              />
            </CardContainer>
          ))) : (<NoResult />)}
        </ResultContainer>
      )}
    </>
  );
};

Collections.propTypes = {
  categories: PropTypes.array,
  slider: PropTypes.bool,
  selectedFilters: PropTypes.array,

  // HOC (connect, state)
  courses: PropTypes.arrayOf(PropTypes.object),

  // HOC (connect, dispatch)
  getCollection: PropTypes.func.isRequired,
  setSelectedCollection: PropTypes.func.isRequired,
  setAsFavoriteCollection: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

Collections.defaultProps = {
  slider: false
};

export default connect(
  state => {
    const { collection: { courses = [], filters: { categories = [] } = {} } } = state.collections;

    return {
      courses,
      categories
    };
  },
  dispatch => ({
    setSelectedCollection: (selected) => dispatch({ type: SET_SELECTED_COLLECTION, selected }),
    getCollection: (filters) => dispatch({
      type: COLLECTION_LIST_GET._REQUEST, itemPerPage: 100, filters, subset: true
    }),
    setAsFavoriteCollection: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(Collections);
