import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { parseQueryParams } from '../../utils/queryParams';
import { getCoacheeMock, getColleaguesMock, getSearchListMock } from './mock';

export default {
  getSearchList: (filters) => {
    if (shouldUseMock()) {
      return getSearchListMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getColleaguesList: (filters) => {
    if (shouldUseMock()) {
      return getColleaguesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}users${parseQueryParams(filters)}`, 'GET');
  },
  getCoachee: (filters) => {
    if (shouldUseMock()) {
      return getCoacheeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}users${parseQueryParams(filters)}`, 'GET');
  }
};
