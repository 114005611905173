import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GO_TO_STEP, SIGNUP_RESET } from '../../redux/actions';
import { Container } from '../../../ui/gridSystem';
import {
  Body,
  // Disclaimer,
  SignupContainer,
  Steps,
  StepsContainer,
  Subtitle,
  Title
} from './style';
import Gill from '../../../ui/typography/gill';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import SignupForm from './Form';
import SignupCredentials from './Credentials';

const Signup = ({ vocabulary, step, goToStep, resetSignup }) => {
  const goBack = () => {
    goToStep(step - 1);
  };
  const goNext = () => {
    goToStep(step + 1);
  };

  useEffect(() => {
    return () => {
      resetSignup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Steps>
        <StepsContainer>
          <Gill style={{ width: 'fit-content' }}>
            Step <span>{step}</span>/2
          </Gill>
        </StepsContainer>
      </Steps>
      <SignupContainer>
        <Container>
          <Title>
            <Gill type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.signupTitle, 'signupTitle')}
            </Gill>
          </Title>
          <Subtitle>
            <Gill
              type="descriptionHome"
              vocabulary="authTitle"
              style={{ fontSize: 24 }}
            >
              {getValueVocabulary(vocabulary.signupSubitle, 'signupSubitle')}
            </Gill>
          </Subtitle>
          <Body>
            {step === 1 && <SignupCredentials goNext={goNext} />}
            {step === 2 && <SignupForm goBack={goBack} />}
          </Body>
          {/* <Disclaimer>
              <Container>
                {getValueVocabulary(vocabulary.signupMsg, 'signupMsg')}
              </Container>
            </Disclaimer> */}
        </Container>
      </SignupContainer>
    </>
  );
};

Signup.propTypes = {
  vocabulary: PropTypes.object,
  step: PropTypes.number,
  goToStep: PropTypes.func,
  resetSignup: PropTypes.func
};

export default connect(
  state => {
    const {
      vocabulary,
      signup: { step }
    } = state.app;
    return {
      vocabulary,
      step
    };
  },
  dispatch => ({
    goToStep: step => dispatch({ type: GO_TO_STEP, step }),
    resetSignup: () => dispatch({ type: SIGNUP_RESET })
  })
)(Signup);
