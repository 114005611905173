import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import { ModalGlobalContainer, ModalWrapper } from '../Modal.style';

import Arial from '../../../typography/arial';
import {
  Title,
  IconClose,
  IconError,
  ErrorContent,
  ShareModalContainer,
  ButtonsContainer
} from './style';

import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Button } from '../../../atoms';
import HtmlRaw from '../../HtmlRaw';

const modalId = 'ContractModal';

const ContractModal = ({ onClose, contract, contract_title }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          height="370"
        >
          <IconClose className="icon-close" onClick={onClose} />
          <ShareModalContainer>
            <Title>
              <b>{contract_title}</b>
            </Title>
            <ErrorContent>
              <IconError className="icon-error" />
              <Arial type="editorialImageText">
                <HtmlRaw html={contract} />
              </Arial>
            </ErrorContent>
            <ButtonsContainer>
              <Button
                type={Button.TYPE.SECONDARY}
                onClick={onClose}
                btnText="Chiudi"
                height={40}
              />
            </ButtonsContainer>
          </ShareModalContainer>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

ContractModal.propTypes = {
  // HOC (connect, state)
  contract: PropTypes.string,
  contract_title: PropTypes.string,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};
ContractModal.id = modalId;

export default connect(
  state => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ContractModal);
