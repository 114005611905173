import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  CompulsaryTrainingContainer, InfoContainer, Title, SeeAll, HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import { GeneralCard } from '../../atoms';
import routes from '../../../routes';
import { Settings } from '../Collections/settings';
import {
  COMPULSARY_TRAINING_LIST_GET, COMPULSARY_TRAINING_NODE_ACTION,
  SET_SELECTED_COMPULSARY_TRAINING
} from '../../../app/redux/actions/compulsarytraining';
import { getTagColor } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { NewsCardContainer } from '../NewsCardCarousel/style';
import { ColumnCarousel } from '../RubricChangeCarousel/style';

const CompulsaryTrainingCardCarousel = ({
  pushUrl, courses, categories, getCompulsaryTraining, setSelectedCompulsaryTraining,
  setAsFavoriteCompulsaryTraining, vocabulary
}) => {
  const [course, setCourse] = useState(courses);
  useEffect(() => {
    window.scrollTo(0, 0);
    getCompulsaryTraining();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompulsaryTraining]);

  useEffect(() => {
    setCourse(courses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const handleOnClickCard = (item) => {
    setSelectedCompulsaryTraining(item);
    pushUrl(`${routes.compulsarytraining.path}/${item.id}`);
  };

  return (
    <CompulsaryTrainingContainer>
      <Container className="p-0">
        <Row>
          <Col lg={12} sm={12}>
            <InfoContainer>
              <HeaderContent>
                <Title><Arial type="sectionTitle" vocabulary="obbligatoryTitle">{getValueVocabulary(vocabulary.obbligatoryTitle, 'obbligatoryTitle')}</Arial></Title>
                <SeeAll onClick={() => pushUrl(routes.compulsarytraining.path)}>
                  <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                  <span className="icon-arrow-right" />
                </SeeAll>
              </HeaderContent>
            </InfoContainer>
          </Col>
          <ColumnCarousel sm={12}>
            <CustomCarousel config={Settings}>
              {(course || []).slice(0, 12).map(item => (
                <NewsCardContainer key={item.id}>
                  <GeneralCard
                    key={`obbligatory-${item.id}`}
                    item={item}
                    onClickCard={() => handleOnClickCard(item)}
                    onClickFavorite={() => setAsFavoriteCompulsaryTraining(item.id)}
                    colorTag={getTagColor(categories, item.category_id)}
                    type={GeneralCard.TYPE.OBBLIGATORY}
                  />
                </NewsCardContainer>
              ))
              }
            </CustomCarousel>
          </ColumnCarousel>
        </Row>
      </Container>
    </CompulsaryTrainingContainer>
  );
};

CompulsaryTrainingCardCarousel.propTypes = {
  // HOC (connect, state)
  courses: PropTypes.array,
  categories: PropTypes.array,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getCompulsaryTraining: PropTypes.func.isRequired,
  setSelectedCompulsaryTraining: PropTypes.func.isRequired,
  setAsFavoriteCompulsaryTraining: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { compulsary_training: { courses = [], filters: { categories = [] } = {} } } = state.compulsarytraining;
    const { vocabulary } = state.app;
    return {
      courses,
      categories,
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    setSelectedCompulsaryTraining: (selected) => dispatch({ type: SET_SELECTED_COMPULSARY_TRAINING, selected }),
    setAsFavoriteCompulsaryTraining: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    getCompulsaryTraining: () => dispatch({ type: COMPULSARY_TRAINING_LIST_GET._REQUEST, itemPerPage: 100 })
  })
)(CompulsaryTrainingCardCarousel);
