import styled from 'styled-components';

export const HeaderContainer = styled.div`
  background: ${props => props.theme.colors.mainColorGradient};
  color: ${props => props.theme.colors.white};
  padding: 20px 53px 25px 30px;
  position: relative;
  border-radius: 4px;
  
  @media ${props => props.theme.device.tabletL} {
    border-radius: 0;
  }
`;

export const ProgressContainer = styled.div`
  min-width: 230px;
  @media ${props => props.theme.device.tabletL} {
    flex: 1 0 auto;
  }
`;

export const ProgressWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  div:last-child {
    text-align: right;
  }
`;

export const Category = styled.div`
  color: ${props => (props.categoryColor ? 'inherit' : 'black')};
  padding: 3px 10px;
  border-radius: 12px;
  background-color: ${props => (props.categoryColor ? props.categoryColor : props.theme.colors.white)};
  margin-bottom: 10px;
  text-transform: uppercase;
  width: fit-content;
  display: table;
`;

export const Share = styled.div`
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: ${props => props.theme.constants.transitionBase};
  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
  }
`;

export const Social = styled.div`
  color: ${props => (props.isActive
    ? props.theme.colors.azureDark
    : props.theme.colors.brownGrey)};
  cursor: pointer;
  transition: ${props => props.theme.constants.transitionBase};
  &:hover {
    opacity: 0.6;
  }
`;

export const IconsCompletation = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row-reverse;
    flex: unset;
  }
`;

export const TitleShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-child {
    margin-right: 40px;
  }
  > div {
    width: initial;
  }

  @media ${props => props.theme.device.tabletL} {
    flex-direction: column;
    align-items: stretch;

    > div:first-child {
    margin-right: 0;
  }
  }
`;

export const SocialShare = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
  font-size: 18px;
  min-width: 80px;
`;

export const IconShare = styled.span``;

export const IconViews = styled.span`
  margin-right: 4px;
`;

export const Info = styled.div``;
