import React, { } from 'react';
import Arial from '../../typography/arial';
import { InfoCourseContainer, TextElement, TextWrapper } from './style';


const InfoCourse = () => (
  <InfoCourseContainer>
    <TextWrapper>

      <TextElement>
        <Arial type="guidebookCardDate" configuration={{ color: 'gray' }}>LIVELLO</Arial>
        <Arial type="author" configuration={{ lineHeight: '1.8' }}>Intermedio</Arial>
      </TextElement>
      <TextElement>
        <Arial type="guidebookCardDate" configuration={{ color: 'gray' }}>LINGUA</Arial>
        <Arial type="author" configuration={{ lineHeight: '1.8' }}>Italiano</Arial>
      </TextElement>
      <TextElement>
        <Arial type="guidebookCardDate" configuration={{ color: 'gray' }}>NUMERO PARTECIPANTI</Arial>
        <Arial type="author" configuration={{ lineHeight: '1.8' }}>16</Arial>
      </TextElement>
    </TextWrapper>


  </InfoCourseContainer>
);

InfoCourse.propTypes = {

  // HOC (connect, state)

  // HOC (connect, dispatch)
};

export default InfoCourse;
