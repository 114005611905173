import styled from 'styled-components';
import { Col, Row, Container } from '../../../ui/gridSystem';

const NewsDetailContainer = styled.div`
  padding-bottom: 100px;
  overflow-x: hidden;
`;

const NewsDetailBody = styled(Container)`
  a {
    text-decoration: underline;
  }
`;

const NewsDetailHeader = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  position: relative;
  padding: 20px 0 40px;

  &:before {
    content: '';
    background-color: #323232;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 0px 10px;
  }
`;

const Pdf = styled.a`
  color: ${props => props.theme.colors.white};
  margin: 8.5px 0;
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
  
  div, span {
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div, span {
      color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};;
    }
  }
`;

const Share = styled.div`
  color: ${props => props.theme.colors.white};
  margin: 8.5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 5px;
  }
`;

const Icon = styled.span`
`;

const Title = styled.div`
  margin-bottom: 10px;
`;

const Date = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  flex-direction: row;

  > span{
    margin-right: 5px;
  }

  @media ${props => props.theme.device.tablet} {
    margin-top: 10px;
  }
`;

const Body = styled.div`
  padding: 50px 0; 

  @media ${props => props.theme.device.tablet} {
    padding: 50px 10px;
  }
`;

const ContainerMedia = styled.div`
  padding: 50px 0 20px;
`;

const ContainerEditorialLeft = styled.div`
  background-color: ${props => props.theme.colors.greyDark};
  padding: 5px 0;
`;

const ColumnDocuments = styled(Col)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  z-index: 3;
  padding-top: 15px;
`;

const RowHeader = styled(Row)`
  display: flex;
  position: relative;
  z-index: 3;
  margin-top: 10px;
`;

const RowDate = styled.div`
  position: relative;
  z-index: 3;
`;

export {
  NewsDetailContainer, NewsDetailHeader, Pdf, Share,
  Icon, Date, Body, ContainerMedia, ContainerEditorialLeft,
  ColumnDocuments, Documents, RowHeader, RowDate, Title, NewsDetailBody
};
