import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getGuidebookMock } from './mock';

export default {
  getGuidebook: () => {
    if (shouldUseMock()) {
      return getGuidebookMock();
    }
    return apiCall(`${baseUrl}${extendUrl}manuals`, 'GET');
  }
};
