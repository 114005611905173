import React, { Component } from 'react';
import { Menu, Table, TableCard } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import { BadgesContainer, Title } from './style';

class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = [];

    return (
      <Container>
        <Menu customClass="menu-desktop" />
        <BadgesContainer>
          <Title>I Miei Distintivi e I Miei Punti</Title>
          <Table type="badges" data={data} />
          <TableCard type="badges" data={data} />
        </BadgesContainer>
      </Container>
    );
  }
}

Badges.propTypes = {};

export default Badges;
