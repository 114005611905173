import styled from 'styled-components';

export const CommonCardContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.biscuit50};
  margin-bottom: 10px;
  min-height: calc(100% - 10px);
  position: relative;
`;

export const Content = styled.div`
  padding: ${props => (props.noPadding ? '0px' : '15px 20px')};
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.black};
`;

export const Description = styled.div`
  text-align: right;
  color: ${props => props.theme.colors.greyMedium};
  margin-left: auto;
`;

export const RightContent = styled.div`
  width: 50%;
  margin-left: auto;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  height: 4rem;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    ${Description} {
      margin-left: 0;
    }

    &.lessons {
      flex-direction: column;
      align-items: flex-start;
      ${Title} {
        width: 80%;
        margin-bottom: 8px;
      }
    }
  }
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  // for IE
  min-height: 100%;
  min-width: 100%;
`;
