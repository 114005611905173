import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BackButton } from '../../atoms';

import {
  ActivityContainer, LessonInfoContainer, Title, Description, Iframe
} from './style';

import Roboto from '../../typography/roboto';
import Intro from '../../typography/intro';
import { LessonScormInterface } from '../../../app/redux/reducers/lesson';
import { PillInterface } from '../../../app/redux/reducers/home';

const Activity = ({ activity }) => {
  const [frameHeight, setFrameHeight] = useState(0);

  const {
    node_scorm_id, id, title, description, iframe_uri, url_source
  } = activity;
  if (!(node_scorm_id || id)) return <div>scorm vuoto :(</div>;

  const adjustHeight = () => {
    const iFrameRef = document.getElementById('scorm_frame');
    if (iFrameRef) {
      const defaultValue = '488px';
      // setFrameHeight(
      //   iFrameRef.contentWindow ?
      //     iFrameRef.contentWindow.document ?
      //       iFrameRef.contentWindow.document.getElementsByTagName('iframe') ?
      //         iFrameRef.contentWindow.document.getElementsByTagName('iframe')[0].contentWindow ?
      //           iFrameRef.contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document ?
      //             iFrameRef.contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('presentation') ?
      //               `${iFrameRef.contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('presentation').getBoundingClientRect().height}px`
      //             :defaultValue
      //           :defaultValue
      //         : defaultValue
      //       : defaultValue
      //     : defaultValue
      //   : defaultValue
      // );
      setFrameHeight(
        iFrameRef.contentWindow
          ? iFrameRef.contentWindow.document
            ? iFrameRef.contentWindow.document.getElementsByTagName('iframe')
              ? `${iFrameRef.contentWindow.document.getElementsByTagName('iframe')[0].getBoundingClientRect().height}px`
              : defaultValue
            : defaultValue
          : defaultValue
      );
      // iFrameRef.style.height
    }
  };

  return (
    <>
      <BackButton />
      <ActivityContainer frameHeight={frameHeight}>
        <LessonInfoContainer>
          <Title><Intro type="title" configuration={{ size: 20 }}>{title}</Intro></Title>
          {description ? (
            <Description>
              <Roboto type="description">
                {description}
              </Roboto>
            </Description>
          ) : null}
        </LessonInfoContainer>
        <Iframe
          frameHeight={frameHeight}
          title={title}
          src={iframe_uri || url_source}
          id="scorm_frame"
          onLoad={adjustHeight}
        />
      </ActivityContainer>
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.oneOfType([LessonScormInterface, PillInterface]),

  // HOC (connect, state)
  user: PropTypes.shape({})
};

export default connect(
  state => ({
    user: state.user.data
  })
)(Activity);
