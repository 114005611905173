import styled, { keyframes } from 'styled-components';

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  background-color: ${props => props.theme.colors.transparent};
  margin-right: 13px;

  @media ${props => props.theme.device.mobileL} {
    padding-right: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;

const SliderContainer = styled.div``;

const CourseCompleted = styled.span`
  color: ${props => props.theme.colors.greyLighter};
  white-space: nowrap;
`;

const Title = styled.div`
  margin-right: 15px;

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 5px;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
`;

const Subtitle = styled.div`
  color:  ${props => props.theme.colors.darkerGrey};
  width: 70%;

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 5px;
  }
`;

const courseContainerAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
  }
`;

const CourseContainer = styled.div`
  animation: ${courseContainerAnimation}.3s;
  position: relative;
  width: 100%;
  padding-bottom: 20px;

  @media ${props => props.theme.device.laptop} {
    padding: 0 30px 20px 30px;
  }

  @media ${props => props.theme.device.tabletL} {
      padding: 0px 25px 40px 25px;
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 20px 0 20px 20px;
  }

  ${InfoContainer} {
    .state {
      background-color: rgba(60,60,60, 0.2);
      color: ${props => props.theme.colors.white};
      letter-spacing: normal;
    }
  }

  ${Title} {
    color: ${props => props.theme.colors.white};
  }

  ${Subtitle} {
    color: ${props => props.theme.colors.white};
  }
`;

const Icon = styled.span`
  opacity: 0.8;
  color: ${props => props.theme.colors.lightGrey};
  font-size: 20px;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    margin-left: auto;
  }
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 5px;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export {
  CourseContainer,
  InfoContainer,
  Title,
  Subtitle,
  Icon,
  TitleContent,
  SubtitleContent,
  SliderContainer,
  CourseCompleted
};
