import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getSurveyContentMock, setInsertSurveyMock, getSurveyContentAdditionalMock } from './mock';

export default {
  getSurveyContent: ({ id, survey_type }) => {
    if (shouldUseMock()) {
      return survey_type === 'mandatory' ? getSurveyContentMock() : getSurveyContentAdditionalMock();
    }
    return apiCall(`${baseUrl}${extendUrl}survey/${id}/content`, 'GET');
  },
  setInsertSurvey: (body) => {
    if (shouldUseMock()) {
      return setInsertSurveyMock();
    }
    return apiCall(`${baseUrl}${extendUrl}survey/insert`, 'POST', body);
  }
};
