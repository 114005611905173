import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer, ModalWrapper
} from '../Modal.style';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import {
  ContactsCoachContainer, Title, IconClose, Description,
  CoachImg, CoachData, SingleData,
  StudentWrapper, StudentsContainer, ItemCarousel
} from './style';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import Arial from '../../../typography/arial';
// eslint-disable-next-line import/no-cycle
import { CircleImage } from '../../../atoms';
import CustomCarousel from '../../CustomCarousel';
import { GET_COACH_COACHEE } from '../../../../app/redux/actions/coachcoachee';

const modalId = 'CoachContactsModals';

const CoachContactsModals = ({
  onClose, vocabulary, user_type, coachCoacheeList, getCoachee
}) => {
  useEffect(() => {
    if (coachCoacheeList.length === 0) {
      getCoachee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoachee]);

  const renderField = (typeLabel, typeValue, field, value) => (
    <>
      {typeLabel !== '' && (
        <Arial type={typeLabel}>{field.toUpperCase()}</Arial>
      )}
      <Arial type={typeValue}>{value}</Arial>
    </>
  );

  //Settings Carousel
  const baseSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: true,
    arrows: true,
    centerPadding: 1
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <IconClose className="icon-close" onClick={onClose} />
            {/* {user_type === 'student' && (
              <ContactsCoachContainer>
                <Title vocabulary="coachContactsModalTitle">
                  {getValueVocabulary(vocabulary.coachContactsModalTitle, 'coachContactsModalTitle')}
                </Title>
                <Description>
                  <Arial type="editorialSubtitle" vocabulary="coachContactsModalSubtitle">
                    {getValueVocabulary(vocabulary.coachContactsModalSubtitle, 'coachContactsModalSubtitle')}
                  </Arial>
                </Description>
                <CoachContainer>
                  <CoachImg>
                    <CircleImage src={dataCoach?.img} name={`${dataCoach?.name} ${dataCoach?.surname}`} size={80} />
                    {renderField('', 'coachName', '', dataCoach?.name)}
                    {renderField('', 'guidebookCardText', '', dataCoach?.role)}
                  </CoachImg>
                  <CoachData>
                    <SingleData>{renderField('CoachLabel', 'CoachValue', 'Email', dataCoach?.email)}</SingleData>
                    <SingleData>{renderField('CoachLabel', 'CoachValue', 'Mobile', dataCoach?.mobile_number)}</SingleData>
                    <SingleData>{renderField('CoachLabel', 'CoachValue', 'Fisso', dataCoach?.phone_number)}</SingleData>
                  </CoachData>
                </CoachContainer>
              </ContactsCoachContainer>
            )}
            {user_type === 'tutor' && ( */}
            <ContactsCoachContainer>
              <Title vocabulary="coacheeContactsModalTitle">
                {user_type === 'tutor' ? getValueVocabulary(vocabulary.coacheeContactsModalTitle, 'coacheeContactsModalTitle') : getValueVocabulary(vocabulary.coachContactsModalTitle, 'coachContactsModalTitle')}
              </Title>
              <Description>
                <Arial type="editorialSubtitle" vocabulary="coacheeContactsModalSubtitle">
                  {user_type === 'tutor' ? getValueVocabulary(vocabulary.coacheeContactsModalSubtitle, 'coacheeContactsModalSubtitle') : getValueVocabulary(vocabulary.coachContactsModalSubtitle, 'coachContactsModalSubtitle')}
                </Arial>
              </Description>
              <StudentsContainer>
                <CustomCarousel config={baseSettings}>
                  {(coachCoacheeList || []).map((coachee, index) => (
                    <ItemCarousel key={index}>
                      <StudentWrapper>
                        <CoachImg>
                          {
                            coachee.img ? <CircleImage src={coachee.img} size={80} /> : (
                              <CircleImage
                                name={`${coachee?.name} ${coachee?.surname}`}
                                size={80}
                              />
                            )
                          }
                          {renderField('', 'coachName', '', `${coachee?.name} ${coachee?.surname}`)}
                          {renderField('', 'guidebookCardText', '', coachee?.role)}
                        </CoachImg>
                        <CoachData>
                          <SingleData>{renderField('CoachLabel', 'CoachValue', 'Email', coachee?.mail)}</SingleData>
                          <SingleData>{renderField('CoachLabel', 'CoachValue', 'Telefono', coachee?.mobile_number || '-')}</SingleData>
                        </CoachData>
                      </StudentWrapper>
                    </ItemCarousel>
                  ))}
                </CustomCarousel>
              </StudentsContainer>
            </ContactsCoachContainer>
            {/* )} */}
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

CoachContactsModals.propTypes = {

  // HOC (connect, state)
  // userID: PropTypes.number
  vocabulary: PropTypes.object,
  coachCoacheeList: PropTypes.array,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

CoachContactsModals.id = modalId;

export default connect(
  state => {
    const { coach_coachee_list: coachCoacheeList = [] } = state.coachcoachee;
    const { data: modalData = {} } = state.modal;
    const { data: { id: userID, user_type } = {} } = state.user;
    const { vocabulary } = state.app;
    return {
      modalData, userID, vocabulary, user_type, coachCoacheeList
    };
  },
  dispatch => ({
    getCoachee: () => dispatch({ type: GET_COACH_COACHEE._REQUEST }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(CoachContactsModals);
