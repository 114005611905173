import styled from 'styled-components';

const inputMaxWidth = 340;
export const SignupContainer = styled.div`
  padding-bottom: 20px;
  color: ${props => props.theme.colors.black};
`;

export const QuestionWrapper = styled.div`
  margin-bottom: 25px;

  & > p {
    font-weight: 600;
    font-size: 17px;

    &.required::after {
      content: '*';
      color: ${props => props.theme.colors.primaryColor};
      margin-left: 6px;
    }
    &.warning {
      font-size: 14px;
      font-style: italic;
      color: ${props => props.theme.colors.mineShaft};
      margin-top: -3px;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > a:hover,
  a:active {
    text-decoration: underline;
    color: ${props => props.theme.colors.primaryColor};
  }
`;

export const PrivacyWrapper = styled.div`
  margin-top: 40px;

  & span.required::after {
      content: '*';
      color: ${props => props.theme.colors.primaryColor};
      margin-left: 6px;
    }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 6px;
  gap: 10px;

  & > label {
    width: fit-content;
    min-width: 130px;
    margin-bottom: 0;
  }
  & > div:last-child {
    max-width: ${inputMaxWidth}px;
  }
`;

export const InputWrapper = styled.div`
  max-width: ${inputMaxWidth}px;
`;
