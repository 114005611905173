import {
  all,
  takeLatest,
  takeEvery,
  put,
  call,
  select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from '../actions';
import {
  HOME_COLLECTIONS_GET,
  HOME_COURSES_GET,
  HOME_COURSE_LIST_GET,
  HOME_PILLS_GET,
  HOME_WELCOME_MODAL_GET
} from '../actions/home';

import home from '../../api/home';
import stc_course from '../../api/stc_course';
import { calcPagination } from '../../../utils/pagination';
import WelcomeModal from '../../../ui/components/Modals/WelcomeModal';

function* homeCoursesGet({ id, status, itemPerPage = 4 }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (!(id || status)) {
      return;
    }

    const { total_items: totalCount, count, from } = yield select(
      state => state.home.coursesData[id] || {}
    );
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      const filters = {
        ...pagination,
        status,
        collection_id: id
      };
      const { data: { data: response } = {} } = yield call(
        home.getCourses,
        filters
      );
      yield put({
        type: HOME_COURSES_GET._SUCCESS,
        response: { ...response, collectionId: id, status }
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeCoursesGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homeCoursesGetWatch() {
  yield takeEvery(HOME_COURSES_GET._REQUEST, homeCoursesGet);
}

function* homeCollectionsCoursesGet() {
  const { collections } = yield select(state => state.home.collectionsData);
  yield all(
    (collections || []).map(collection =>
      homeCoursesGet({ ...collection, itemPerPage: 8 })
    )
  );
}

function* homeCollectionsGet({ itemPerPage = 8 }) {
  try {
    yield put({ type: LOADER_OPEN });

    const { total_items: totalCount, count, from } = yield select(
      state => state.home.collectionsData
    );
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      const { data: { data: response } = {} } = yield call(
        home.getCollections,
        pagination
      );
      yield put({ type: HOME_COLLECTIONS_GET._SUCCESS, response });

      // collections are faked paginated right now :)
      yield homeCollectionsCoursesGet();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeCollectionsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homeCollectionsGetWatch() {
  yield takeLatest(HOME_COLLECTIONS_GET._REQUEST, homeCollectionsGet);
}

function* homePillsGet({ itemPerPage = 4 }) {
  try {
    yield put({ type: LOADER_OPEN });

    const { total_items: totalCount, count, from } = yield select(
      state => state.home.pillsData
    );
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      const { data: { data: response } = {} } = yield call(
        home.getVideoPills,
        pagination
      );
      yield put({ type: HOME_PILLS_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homePillGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homePillsGetWatch() {
  yield takeLatest(HOME_PILLS_GET._REQUEST, homePillsGet);
}

function* homeWelcomeModalGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(home.getWelcomeModal);
    yield put({ type: HOME_WELCOME_MODAL_GET._SUCCESS });
    yield put({ type: MODAL_OPEN, id: WelcomeModal.id, data: response });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeWelcomeModalGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homeWelcomeModalGetWatch() {
  yield takeLatest(HOME_WELCOME_MODAL_GET._REQUEST, homeWelcomeModalGet);
}

function* courseListGet({ path_ids }) {
  try {
    yield put({ type: LOADER_OPEN });
    const response = {};
    for (const p of path_ids) {
      const filters = {
        path: p,
        items_per_page: 12
      };
      const {
        data: { data }
      } = yield call(stc_course.getStcCourseList, filters);
      response[p] = data;
    }
    yield put({ type: HOME_COURSE_LIST_GET._SUCCESS, response });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > courseListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseListGetWatch() {
  yield takeLatest(HOME_COURSE_LIST_GET._REQUEST, courseListGet);
}

export default [
  homeCollectionsGetWatch(),
  homeCoursesGetWatch(),
  homePillsGetWatch(),
  homeWelcomeModalGetWatch(),
  courseListGetWatch()
];
