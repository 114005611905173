import styled from 'styled-components';

export const ContactsCoachContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 395px;
  color: ${props => props.theme.colors.black};
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.black};
  text-align: center;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -26px;
    width: calc(100% + 52px);
    bottom: 0;
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.black};
  text-align: center;
`;

export const CoachContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;
export const CoachImg = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div:nth-child(2) {
    margin: 20px 0 5px;
  }
  > div:last-child {
    color: rgba(255, 255, 255, 0.5);
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    padding-bottom: 20px;
  }
`;
export const CoachData = styled.div`
  width: 50%;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px 0 20px 20px;
  div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    border-left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    padding: 20px 0; 
    text-align: center;
  }
`;
export const SingleData = styled.div`
  div:first-child {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
  }
`;
export const IconClose = styled.span`
  top: 20px!important;
  right: 20px!important;
  z-index: 100;
`;

export const StudentsContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const ItemCarousel = styled.div`
`;

export const StudentWrapper = styled.div`
  width: 100%;
  display: flex; 

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;