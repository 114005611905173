import styled from 'styled-components';
import { Col } from '../../../ui/gridSystem';
import {
  CustomSelectContainer,
  FilterContainer,
  FilterLabel,
  FilterPillsContainer
} from '../Procedures/style';

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;
    top: -9px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 420px;
    top: -31px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 50%;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
`;

export const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const TabsContainer = styled.div`
  margin-top: -80px;
  margin-bottom: 80px;
`;

export const SeeMore = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 100px;
`;

export const ProductsFilterContainer = styled(FilterContainer)``;

export const ProductsFilterLabel = styled(FilterLabel)``;

export const ProductsCustomSelectContainer = styled(CustomSelectContainer)``;

export const ProductsFilterPillsContainer = styled(FilterPillsContainer)``;
