import styled, { css } from 'styled-components';
import { NavBarHeight, SubNavBarHeight } from '../../../const';

const SubNavBarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: ${SubNavBarHeight}px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.white};
  padding: 0 2rem;
  z-index: ${props => props.theme.zIndex.navBar};
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  top: ${NavBarHeight}px;
  left: 0;
  background-color: ${props => props.theme.colors.primaryColor};

  font-family: ${props => props.theme.fontset.gill};
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;

  &.header-wrapper {
    width: 100%;
    margin: 0;
    position: fixed;
    top: ${NavBarHeight}px;
    left: 0;
    z-index: ${props => props.theme.zIndex.headerWrapper};
    margin-left: auto;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 0 30px;
    div {
      div {
        div {
          .close-modal {
            outline: none;
            animation: fadein 1s;
            background-color: ${props => props.theme.colors.transparent};
            border: none;
            display: block;
            padding: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            z-index: ${props => props.theme.zIndex.closeModal};

            &::after {
              content: '';
              position: absolute;
              transform: rotate(-45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }

            &::before {
              content: ' ';
              position: absolute;
              transform: rotate(45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }
          }
        }
      }
    }
  }

  @media ${props => props.theme.device.tablet} {
    display: none;
  }

  @media ${props => props.theme.device.tablet && !props.navigationMobile} {
    display: block;
    height: 100%;
    padding: 0 20px;
    margin-top: 20px;
    width: 80%;
    left: 30%;
    top: 0;

    general-container div {
      height: 100vh;
      flex-direction: column;
      padding: 0;
      div {
        div {
          white-space: nowrap;
          margin-right: 20px;
          margin-left: 0px;
        }
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0;

  > div {
    > div[data-vocabulary] {
      max-width: 165px;
      text-align: center;

      @media ${props => props.theme.device.tablet} {
        max-width: unset;
        text-align: left;
      }
    }
  }

  @media ${props => props.theme.device.tablet} {
    overflow: overlay;
    white-space: nowrap;
    height: 100vh;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 89px;
    left: 0px;
    align-items: flex-start;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    justify-content: start;
  }
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const SubMenuElementContainer = styled.div`
  cursor: pointer;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.active
    && css`
      border-bottom: 4px solid ${props.theme.colors.white};
    `};
  &:hover {
    color: #000;
  }
  > div {
    margin-top: 12px;
  }
  > div:last-child {
    @media ${props => props.theme.device.tablet} {
      text-align: left;
    }
  }


  /* ${props => props.active
    && css`
      &:after {
        content: '';
        position: absolute;
        bottom: -22px;
        left: -10px;
        background-color: ${props.theme.colors.greenFluo};
        height: 4px;
        width: calc(100% + 20px);
      }
    `}; */
  @media ${props => props.theme.device.tablet} {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    height: 59px;
    width: 100%;
    border: 1px solid #e8e8e8;

    &:after {
      bottom: 73px;
      left: -10px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    display: flex;
    padding: 20px;
    font-size: 14px;

    ${props => props.active
      && css`
        &:after {
          /*
          content: '';
          position: absolute;
          bottom: -8px;
          left: -168px;
          background-color: ${props.theme.colors.primaryColor};
          height: 4px;
          width: 100px;
           width: calc(100% + 20px); 
           */
          margin-right: 0px;
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          background-color: ${props.theme.colors.lightGreen};
          height: 100%;
          width: 4px;
        }
      `};
  }
`;

export {
  SubNavBarContainer, SubMenuElementContainer, ContentWrapper, Text
};
