import styled, { css } from 'styled-components';

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: ${props => props.theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin: 5px 0;
  transition: ${props => props.theme.constants.transitionBase};

  > div {
    white-space: normal;
    max-width: 240px; 
  }
`;

const Date = styled.div`
  color: ${props => props.theme.colors.white};
`;

const Image = styled.div`
  height: 220px;
  width: 100%;
  opacity: 0.7;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    content: '';
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.3)};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: ${props => props.theme.constants.transitionBase};
    top: 0;
    left: 0;
  }
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transform: scale(1.03);
  }
`;

const NewsCardDetails = styled.div`
  padding: 10px;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  max-height: 92px;
  width: 100%;
`;

const Description = styled.div`
  max-height: 24px;
  margin-bottom: 10px;
  transition: ${props => props.theme.constants.transitionBase};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const NewsCardContainer = styled.div`
  position: relative;
  height: 222px;
  width: ${props => (props.isHighlighted ? '100%' : '280px')};
  top: 0;

  left: 0;
  border-radius: 4px;
  cursor: pointer;
  margin-left: ${props => (props.isMargin ? '20px' : '-8px')};

  @media ${props => props.theme.device.mobileL} {
      margin: auto;
  }

  &:hover {
    ${Image} {
      &:before {
        background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
      }
    }
  }

  ${NewsCardDetails} {
    background-color: transparent;
  }

  ${Description} {
    color: ${props => props.theme.colors.white};
  }

  ${Title} {
    color: ${props => props.theme.colors.white};
  }
  
  ${({ isAgenda }) => isAgenda && css`
    height: 276px;
    width: 260px;
    margin-left: 0px;
    min-width: 0;

    ${Image} {
      height: 276px;
    }

    @media ${props => props.theme.device.mobileL} {
      width: 290px;
      margin-bottom: 15px;
    }
  `}
`;

export {
  NewsCardContainer,
  Image,
  NewsCardDetails,
  Title,
  Description,
  Date
};
