import {
  apiCall, baseUrl, extendUrl, extendUrlV1
} from './config';
import { shouldUseMock } from '../../utils/common';
import {
  getConfigMock,
  getFaqMock,
  getVocabularyMock,
  pathsMappingGetMock
} from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getConfig: () => {
    if (shouldUseMock()) {
      return getConfigMock();
    }
    return apiCall(`${baseUrl}${extendUrl}platform_configuration`, 'GET');
  },
  getFaq: () => {
    if (shouldUseMock()) {
      return getFaqMock();
    }
    return apiCall(`${baseUrl}${extendUrl}faq`, 'GET');
  },
  getVocabulary: params => {
    if (shouldUseMock()) {
      return getVocabularyMock(params.name);
    }
    if (params.name) {
      return apiCall(
        `${baseUrl}${extendUrl}vocabulary${parseQueryParams(params)}`,
        'GET'
      );
    }
    return apiCall(`${baseUrl}${extendUrl}vocabulary`, 'GET');
  },
  pathsMappingGet: pid => {
    if (shouldUseMock()) {
      return pathsMappingGetMock();
    }
    return apiCall(`${baseUrl}${extendUrlV1}path_mapping/${pid}`, 'GET');
  }
};
