import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../gridSystem';
import Gill from '../../typography/gill';

import { Spinner } from '../index';

import {
  AccessStatisticContainer,
  Title,
  Description,
  Icon,
  Span,
  RowContainer
} from './style';

const AccessStatistic = ({
  title,
  icon,
  description,
  isLoading
  // label, percentage, isPositive
}) => (
  <AccessStatisticContainer>
    {isLoading ? (
      <Spinner />
    ) : (
      <RowContainer>
        <Row>
          <Col sm={6}>
            <Title>
              <Gill type="title">{title}</Gill>
            </Title>
            <Description>
              <Gill type="description">{description}</Gill>
            </Description>
          </Col>
          <Col sm={6}>
            <Icon>
              <Span customClass={icon} />
            </Icon>
          </Col>
        </Row>
      </RowContainer>
    )}
  </AccessStatisticContainer>
);

// <InfoAccessContainer>
//  <ArrowIcon isPositive={isPositive}>
//    {isPositive && <Span customClass="icon-grow" />}
//   {!isPositive && <Span customClass="icon-decrease" />}
//  </ArrowIcon>
//  <Percentage isPositive={isPositive}>{percentage}%</Percentage>
//  <Label>{label}</Label>
//</InfoAccessContainer>
AccessStatistic.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool
  // label: PropTypes.string,
  // percentage: PropTypes.number,
  // isPositive: PropTypes.bool
};

AccessStatistic.defaultProps = {
  icon: 'icon-calendar'
  // label: 'label',
  // percentage: 10,
  // isPositive: false
};

export default AccessStatistic;
