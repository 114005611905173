import { createAsyncActionType } from './index';

export const STC_ALL_LIST_GET = createAsyncActionType('stc_all', 'STC_ALL_LIST_GET');
export const SET_SELECTED_STC_ALL = '@@stc_all/SET_SELECTED_STC_ALL';
export const SELECTED_STC_ALL_GET = createAsyncActionType('stc_all', 'SELECTED_STC_ALL_GET');
export const STC_ALL_PILLS_GET = createAsyncActionType('stc_all', 'STC_ALL_PILLS_GET');
export const STC_ALL_NODE_ACTION = createAsyncActionType('stc_all', 'STC_ALL_NODE_ACTION');
export const STC_ALL_COMMENTS_GET = createAsyncActionType('stc_all', 'STC_ALL_COMMENTS_GET');
export const STC_ALL_COMMENTS_SET = createAsyncActionType('stc_all', 'STC_ALL_COMMENTS_SET');
export const STC_ALL_PILL_SET_STATS = createAsyncActionType('stc_all', 'STC_ALL_PILL_SET_STATS');
export const STC_ALL_SHARE_PILL_COLLECTION = createAsyncActionType('stc_all', 'STC_ALL_SHARE_PILL_COLLECTION');
