import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Row, Col, Container } from '../../gridSystem';

import {
  AboutUsHeaderContent, AboutUsInfoContainer, Title, AboutUsContainer
} from './style';
import Arial from '../../typography/arial';
import { ContentCard } from '../../atoms';
import routes from '../../../routes';
import storyImg from '../../assets/img/story.png';
import missionImg from '../../assets/img/mission.png';
import activityImg from '../../assets/img/activity.png';
import structureImg from '../../assets/img/structure.png';

const AboutUs = ({ pushUrl }) => (
  <Container className="p-0">
    <Row>
      <Col lg={12} sm={12}>
        <AboutUsInfoContainer>
          <AboutUsHeaderContent>
            <Title><Arial type="sectionTitle">Conosci il gruppo</Arial></Title>
          </AboutUsHeaderContent>
        </AboutUsInfoContainer>
      </Col>
      <Col sm={12}>
        <AboutUsContainer>
          <ContentCard
            onClick={() => pushUrl(`${routes.organization.path}`)}
            subtitle="Storia"
            background={storyImg}
          />
          <ContentCard
            onClick={() => pushUrl(`${routes.leadership.path}`)}
            subtitle="Mission e valori"
            background={missionImg}
          />
          <ContentCard
            onClick={() => pushUrl(`${routes.mission.path}`)}
            subtitle="Attività"
            background={activityImg}
          />
          <ContentCard
            onClick={() => pushUrl(`${routes.values.path}`)}
            subtitle="Struttura organizzativa e top management"
            background={structureImg}
          />
        </AboutUsContainer>
      </Col>
    </Row>
  </Container>
);

AboutUs.propTypes = {
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(AboutUs);
