import styled from 'styled-components';

export const ResultContainer = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  width: 25%;
  padding-top: 20px;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
