import styled from 'styled-components';

const ShareResultsContainer = styled.div`
  width: 380px;
  height: 230px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.01);
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 15px 20px 30px 20px;
  margin-bottom: 20px;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

const Title = styled.div`
  position: relative;
  padding-bottom: 14px;
  line-height: 20px;

  &:before {
    content: '';
    position: absolute;
    width: 112%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -20px;
    bottom: 0;
  }
`;

const Text = styled.div`
  margin-top: 26px;
  color: ${props => props.theme.colors.brownGrey};
  padding-bottom: 14px;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

export {
  ShareResultsContainer, Title, Text, ButtonContainer
};
