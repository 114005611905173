import styled from 'styled-components';
import { Col } from '../../gridSystem';

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    padding: 0px;
  }

  @media ${props => props.theme.device.mobileL} {
    padding-right: 20px;
    margin-bottom: 15px;
  }

  .state {
    width: auto;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color:  ${props => props.theme.colors.darkerGrey};
    text-transform: uppercase;
    padding: 2px 5px;

    @media ${props => props.theme.device.tablet} {
      display: none;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 15px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TojCarouselContainer = styled.div`
  position: relative;
  width: 80%;
  margin-top: 20px;

  @media ${props => props.theme.device.laptop} {
    padding: 30px 30px 20px 30px;
  }

  @media ${props => props.theme.device.tabletL} {
    padding: 30px 25px 40px 25px;
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0;
  }

  .slick-slider .slick-next {
    top: 145px;
  }
  .slick-slider .slick-prev {
    top: 145px;
  }


  ${InfoContainer} {
    .state {
      background-color: rgba(60,60,60, 0.2);
      color:  ${props => props.theme.colors.darkerGrey};
      letter-spacing: normal;
    }
  }

  ${Title} {
    color: ${props => props.theme.colors.white};
  }
`;

const Icon = styled.span`
  margin-left: auto;
  opacity: 0.8;
  color: ${props => props.theme.colors.lightGrey};
  font-size: 20px;
  cursor: pointer;
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 5px;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    justify-content: space-between;
  }
`;


const TojCardContainer = styled.div`
 padding: 0 10px;
 outline: none;
`;

const ColumnCarousel = styled(Col)`
  padding: 0 10px;
`;

export {
  TojCarouselContainer,
  InfoContainer,
  Title,
  HeaderContent,
  Icon,
  TitleContent,
  TojCardContainer,
  ColumnCarousel
};
