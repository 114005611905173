import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';

import { shouldUseMock } from '../../utils/common';
import { getCertificationTableContentsMock } from './mock';


export default {
  getCertificationTableContents: (filters) => {
    if (shouldUseMock()) {
      return getCertificationTableContentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}certification${parseQueryParams(filters)}`, 'GET');
  }
};
