import produce from 'immer';
import PropTypes from 'prop-types';
import { GUIDEBOOK_GET } from '../actions/guidebook';

export const guidebookInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url_source: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string
});

const initialState = {
  files: [],
  filters: []
};

const guidebookReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GUIDEBOOK_GET._SUCCESS: {
      draft.files = action.files;
      draft.filters = action.filters;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default guidebookReducer;
