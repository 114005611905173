import theme from '../ui/theme';

export const CourseStates = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  REGISTERED: 'registered'
};

const CourseStatesColor = {
  registered: {
    label: 'Da iniziare',
    labelColor: '#ffffff',
    primary: theme().colors.primaryRed,
    secondary: '#ffffff'
  },
  in_progress: {
    label: 'In corso',
    labelColor: '#ffffff',
    primary: theme().colors.teaLeaf,
    secondary: '#ffffff'
  },
  completed: {
    label: 'Completato',
    labelColor: '#ffffff',
    primary: theme().colors.mustard,
    secondary: '#ffffff'
  }
};

export const getStateColor = status => CourseStatesColor[status] || {};
