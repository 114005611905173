import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  CategoriesContainer, FaqContainer, FaqWrapper,
  Title, AnswersContainer, AnswerContainer,
  AnswerText, AnswerBody, Category, MobileWrapperBackButton
} from './style';
import { FAQ_GET } from '../../redux/actions';
import { BackButton, NoResult } from '../../../ui/atoms';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Gill from '../../../ui/typography/gill';
import HtmlRaw from '../../../ui/components/HtmlRaw';

const renderAnswer = ({ question, answer }, callback, isOpen) => (
  <AnswerContainer key={question} isOpen={isOpen}>
    <AnswerText onClick={callback}><HtmlRaw html={question} /><span className={isOpen ? 'icon-grow' : 'icon-decrease'} /></AnswerText>
    <AnswerBody>
      <HtmlRaw html={answer} />
      {/*<Contact onClick={() => console.log('contact', question)}><span className="icon-pencil" />contattaci</Contact>*/}
    </AnswerBody>
  </AnswerContainer>
);

const Faq = ({
  categories, getFaq, items, pushUrl, vocabulary
}) => {
  const [activeCategory, setActiveCategory] = useState(categories?.length ? categories[0] : {});
  const [activeAnswers, setActiveAnswers] = useState([]);
  const [activeAnswer, setActiveAnswer] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveCategory(categories?.length ? categories[0] : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    setActiveAnswers((items || []).find(({ data: { category_id } = {} }) => category_id === activeCategory?.id)?.data?.faq_items || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    setActiveAnswer(activeAnswers?.length ? 0 : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnswers]);

  const selectCategory = (category) => {
    setActiveCategory(category);
  };

  return (
    <FaqContainer>
      <Container className="p-0">
        <MobileWrapperBackButton>
          <BackButton color="black" />
        </MobileWrapperBackButton>
        <Title><Gill type="EditorialAboutTitle" vocabulary="faqTitle">{getValueVocabulary(vocabulary.faqTitle, 'faqTitle')}</Gill></Title>
        <FaqWrapper>
          <CategoriesContainer>
            {(categories || []).map(category => (
              <Category
                key={category?.id}
                isActive={category?.id === activeCategory?.id}
                onClick={() => selectCategory(category)}
                title={category?.name}
              >{category?.name}
              </Category>
            ))}
          </CategoriesContainer>
          <AnswersContainer>
            {activeAnswers?.length ? activeAnswers.map(({ data }, index) => renderAnswer(data, () => setActiveAnswer(index), activeAnswer === index)) : (<NoResult />)}
          </AnswersContainer>
        </FaqWrapper>
      </Container>
    </FaqContainer>
  );
};

Faq.propTypes = {

  // HOC (connect, state)
  categories: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getFaq: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { faq: { categories, items } = {}, vocabulary } = state.app;
    return { categories, items, vocabulary };
  },
  dispatch => ({
    getFaq: () => dispatch({ type: FAQ_GET._REQUEST }),
    pushUrl: url => dispatch(push(url))
  })
)(Faq);
