import { apiCall, baseUrl, extendUrl, extendUrlV1 } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import { getMostViewedMock, mostviewedNodeActionMock } from './mock';

export default {
  getMostViewedList: filters => {
    if (shouldUseMock()) {
      return getMostViewedMock();
    }
    return apiCall(
      `${baseUrl}${extendUrlV1}most_viewed_pill_collection${parseQueryParams(
        filters
      )}`,
      'GET'
    );
  },
  mostViewedNodeAction: payload => {
    if (shouldUseMock()) {
      return mostviewedNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  }
};
