import produce from 'immer';
import { EDITORIAL_GET, EDITORIAL_GET_BY_ALIAS } from '../actions/editorial';

const initialState = {
};

const editorialReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case EDITORIAL_GET_BY_ALIAS._SUCCESS: {
      const { key } = action;
      const { items } = action;
      draft = {
        ...state,
        ...{ [key]: items }
      };
      break;
    }
    case EDITORIAL_GET._SUCCESS: {
      const { response } = action;
      draft = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default editorialReducer;
