import styled, { css } from 'styled-components';

const LessonWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: solid 1px ${props => props.theme.colors.hazeGray};
  padding: 15px 0;
  margin: 0 20px;
`;

const LessonContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  
  transition: all 125ms ease-in;
  
  &:last-child {
    ${LessonWrapper} {
      border-bottom: none;
    }
  }

  ${props => (props.blocked
    ? css`
      opacity: 0.5;
      cursor: not-allowed;
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    `
    : css`
    &:hover {
      background-color: ${props => props.theme.colors.primaryLighter};
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      scale: 1.010;
      cursor: pointer;
    }
  `)};
`;

const Title = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.greyDark};
  margin-bottom: 10px;
`;

const Duration = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.santasGray};

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 5px;
`;

const Image = styled.div`
  min-width: 110px;
  height: 62px;
  border-radius: 4px;

  background: url(${props => props.source});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;

  @media ${props => props.theme.device.mobileL} {
    width: 110px;
    height: 83px;
  }
`;

const IconLocked = styled.div`
  position: absolute;
  top: 10px;
  right: 0;

  @media ${props => props.theme.device.mobileL} {
    top: initial;
    bottom: 12px;
  }
`;

const LessonDetailsContainer = styled.div`
  padding: 2px 0;
  width: 70%;
`;

const Span = styled.span.attrs(({
  customClass
}) => ({
  className: `${customClass}`
}))``;

const CourseStatusContainer = styled.div`
  margin-left: auto;

  @media ${props => props.theme.device.mobileL} {
    position: absolute;
    right: 0;
    top: initial;
    bottom: 12px;
  }
`;

const Foot = styled.div`
  display: flex;
`;

export {
  LessonContainer,
  Title,
  Duration,
  Image,
  IconLocked,
  LessonDetailsContainer,
  Span,
  CourseStatusContainer,
  LessonWrapper,
  Foot
};
