import styled from 'styled-components';

export const StatisticsContainer = styled.div`
  width: 100%;
  height: 100%;

  margin-bottom: 40px;
  padding: 30px 0;
  
  @media ${props => props.theme.device.laptop} {
    padding: 30px 20px;
  }
  
  @media ${props => props.theme.device.tablet} {
    padding: 20px;
  }
  
  .recent-items {
    width: 100%;
    cursor: pointer; 
    transition: all 125ms ease-in;
    
    &:hover {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      scale: 1.010;
      cursor: pointer;
    }

    &:first-child {
      div {
        border-top: none;
      }
    }
  }
`;

export const StatisticsInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
  > div {
    width: calc(50% - 10px);
  }
  
  > div:nth-child(odd) {
    margin-right: 20px;
  }
  
  > div:last-child {
    width: 100%;
    margin-right: 0px;
  }
  
  @media ${props => props.theme.device.tablet} {
    > div {
      width: 100%;
    }

    > div:nth-child(odd) {
      margin-right: 0;
    }

    > div:nth-child(even) {
      //margin-right: 10px;
    }

    > div:first-child {
      width: 100%;
    }

    > div:last-child {
      width: 100%;
      margin-right: 0px;
    }
  }
  
  @media ${props => props.theme.device.mobileL} {
    > div {
      width: 100%
    }
    
    > div:nth-child(odd) {
      margin-right: 0;
    }
  }
  
  .rdt_TableCol {
    text-transform: unset;
    
    &_Sortable {
      span {
        display: none;
      }
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const StatsWrapper = styled.div`
  min-height: 200px;
`;

export const ChartWrapper = styled.div`
  min-height: 200px;
  margin-top: 15px;
`;
