import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { HOME_PILLS_GET } from '../../redux/actions/home';
import { PillsDataInterface } from '../../redux/reducers/home';

import {
  PillsContainer, ResultContainer, Title, CardContainer
} from './style';
import { VideoCardItem } from '../../../ui/components';
import { BackButton, NoResult } from '../../../ui/atoms';
import { Container } from '../../../ui/gridSystem';
import routes from '../../../routes';
import Intro from '../../../ui/typography/intro';

const Pills = ({
  getPills, pushUrl, pillsData
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getPills();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pills } = pillsData;
  return (
    <PillsContainer>
      <Container className="p-0">
        <BackButton />
        <Title>
          <Intro type="title" configuration={{ size: 20 }}>Video pillole</Intro>
        </Title>
        <ResultContainer>
          {pills.length > 0 ? (pills.map((pill) => (
            <CardContainer key={pill.id}>
              <VideoCardItem
                key={pill.id}
                onClick={() => pushUrl(`${routes.pill.path}/${pill.id}`)}
                pill={pill}
              />
            </CardContainer>
          ))) : (<NoResult />)}
        </ResultContainer>
      </Container>
    </PillsContainer>
  );
};

Pills.propTypes = {
  // HOC (connect, state)
  pillsData: PillsDataInterface,

  // HOC (connect, dispatch)
  getPills: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    pillsData: state.home.pillsData
  }),
  dispatch => ({
    getPills: () => dispatch({ type: HOME_PILLS_GET._REQUEST, itemPerPage: 24 }),
    pushUrl: url => dispatch(push(url))
  })
)(Pills);
