import styled from 'styled-components';
import { Row, Col } from '../../../ui/gridSystem';

const NewsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  @media ${props => props.theme.device.mobileL} {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 20px;
    width: 80%;
  }
`;

const ResultContainer = styled(Row)`
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

const Title = styled(Col)`
  padding: 20px 0px 0px 0px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 40px 10px 0;
  color: ${props => props.theme.colors.black};

  @media ${props => props.theme.device.tablet} {
    padding-left: 0;
  }

  .count {
    font-weight: 900;
  }
`;

const RowFilters = styled(Row)`
  padding: 0px 10px;

  @media ${props => props.theme.device.tablet} {
    padding: 0px 30px;
  }
`;

const ColumnFilter = styled(Col)`
  padding: 10px 10px 0;
`;

export {
  NewsContainer,
  ResultContainer,
  Title,
  CardContainer,
  RowFilters,
  ColumnFilter
};
