import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getTojContentMock, getTojListMock } from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getTojContent: (params) => {
    if (shouldUseMock()) {
      return getTojContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}toj/details${parseQueryParams(params)}`, 'GET');
  },
  getTojList: (filters) => {
    if (shouldUseMock()) {
      return getTojListMock();
    }
    return apiCall(`${baseUrl}${extendUrl}toj/plannedlist${parseQueryParams(filters)}`, 'GET');
  }
};
