import styled from 'styled-components';

const SeeAllContainer = styled.div`
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  white-space: nowrap;
  padding-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${props => props.theme.constants.transitionBase};

  span {
    position: absolute;
    right: 0;
    transition: ${props => props.theme.constants.transitionBase};
  }

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.primaryColor, 0.8)};
    span {
      right: -5px;
    }
  }
`;

export { SeeAllContainer };
