import styled from 'styled-components';
import BackgroundImg from '../../../ui/assets/img/immagine-sfondo.png';


const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;
    
    .mobile-no-padding-right {
      padding-right: 0;
    }
    
    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 420px;
  }
`;

const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  
  @media ${props => props.theme.device.mobileL} {
    height: 50%;
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(${props => props.theme.colors.transparent}, ${props => props.theme.colors.doveGray});
  }
`;

const FilterLabel = styled.div`
  margin-bottom: 8px;
  color:black;
`;

const ProceduresContainer = styled.div`
  width: 100%;
  height: 500px;
  padding: 0 20px;
  position: relative;
  
  ${FilterLabel} {
    padding-top: 40px;
  }
`;

const SeeMore = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 100px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

const CustomSelectContainer = styled.div`
  width: 280px;
  margin-right: 17px;
  margin-bottom: 50px;
    
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  margin-right: 0;
  }
`;

const FilterPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 297px);
  padding-top: 5px;
  
  > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  z-index: 1;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primary.black};
  margin-top: 100px;
  margin-bottom: 25px;
  margin-left: 100px;

  @media ${props => props.theme.device.mobileL} {
  margin-left: 40px;
}

`;

const Description = styled.div`
color: ${props => props.theme.colors.primary.white};
width: 630px;
margin-left: 100px;

@media ${props => props.theme.device.mobileL} {
  width: auto;
  margin-left: 40px;
}
`;

export {
  Background, HeaderContainer, ProceduresContainer, SeeMore,
  FilterContainer, CustomSelectContainer, FilterPillsContainer, FilterLabel, Mask, Title, Description
};
