import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getFavoritesMock,
  favoriteNodeActionMock
} from './mock';

export default {
  getFavoriteList: (filters) => {
    if (shouldUseMock()) {
      return getFavoritesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}favourite${parseQueryParams(filters)}`, 'GET');
  },
  favoriteNodeAction: (payload) => {
    if (shouldUseMock()) {
      return favoriteNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  }
};
