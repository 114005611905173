import styled, { css } from 'styled-components';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;

  font-family: 'Arial';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobile} {
    font-size: 12px;
  }

  div[class*='-placeholder'] {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    color: ${props => props.theme.colors.black};
  }

  div[class*='-menu'] {
    font-weight: normal;
  }

  div[class*='-indicatorContainer'] {
    position: relative;
    color: ${props => props.theme.colors.black};

    &:first-child {
      padding-right: 0;
    }

    &:hover {
      color: ${props => props.theme.colors.black};
    }

    &:after {
      content: '';
      display: block;
      height: 70%;
      position: absolute;
      width: 1px;
      background: ${props => props.theme.colors.white};
      top: 15%;
    }
  }

  div[class*='ValueContainer'] {
    overflow: auto;
    height: inherit;
  }

  div[class*='singleValue'] {
    color: ${props => props.theme.colors.black};
  }

  div[class*='control'] {
    > div:first-child {
      overflow: auto;
      height: inherit;
    }
  }

  ${props => props.readonly
    && css`
      pointer-events: none;
      div[class*='control'] {
        border: none;
        background-color: transparent;
      }

      div[class*='ValueContainer'] {
        padding: 0;
      }

      div[class*='IndicatorsContainer'] {
        display: none;
      }
    `}

  ${({ isSearchType }) => isSearchType
    && css`
      div[class*='control'] {
        cursor: auto;
      }
      div[class*='-indicatorContainer'] {
        cursor: pointer;
      }
      span ~ div[class*='-indicatorContainer'] {
        display: none;
      }
    `}

    & > .signup-select {
    background-color: ${props => props.theme.colors.white};
    border-radius: 4px;

    div[class*='control'] {
      border: none;
      background-color: transparent;
    }

    div[class*='-option'] {
      background-color: ${props => props.theme.colors.white};

      &:hover {
        background-color: ${props => props.theme.colors.primaryColor};
      }
    }
  }
`;

export { SelectContainer };
