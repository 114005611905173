import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Row, Col, Container } from '../../gridSystem';

import {
  ContentsCatalogHeaderContent, ContentsCatalogInfoContainer, Title, ContentsCatalogContainer
} from './style';
import Arial from '../../typography/arial';
import { ContentCard } from '../../atoms';
import routes from '../../../routes';
import Products from '../../assets/img/Products.png';
import LifeIsp from '../../assets/img/lifeIsp.jpg';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const ContentsCatalog = ({ pushUrl, vocabulary }) => (
  <Container className="p-0">
    <Row>
      <Col lg={12} sm={12}>
        <ContentsCatalogInfoContainer>
          <ContentsCatalogHeaderContent>
            <Title><Arial type="sectionTitle">Il catalogo contenuti</Arial></Title>
          </ContentsCatalogHeaderContent>
        </ContentsCatalogInfoContainer>
      </Col>
      <Col sm={12}>
        <ContentsCatalogContainer>
          <ContentCard
            onClick={() => pushUrl(`${routes.compulsarytraining.path}`)}
            title="Catalogo"
            subtitle={getValueVocabulary(vocabulary.obbligatoryTitle, 'obbligatoryTitle')}
            background={Products}
          />
          <ContentCard
            onClick={() => pushUrl(`${routes.lifeIsp.path}`)}
            title="Catalogo"
            subtitle={getValueVocabulary(vocabulary.lifeIspTitle, 'lifeIspTitle')}
            background={LifeIsp}
          />
          {/*<ContentCard
            onClick={() => pushUrl(`${routes.products.path}`)}
            title="Catalogo"
            subtitle={getValueVocabulary(vocabulary.productsTitle, 'productsTitle')}
            background={ProductsImg}
          />
          <ContentCard
            onClick={() => pushUrl(`${routes.procedures.path}`)}
            title="Catalogo"
            subtitle={getValueVocabulary(vocabulary.proceduresTitle, 'proceduresTitle')}
            background={ProcessAndProcedures}
          />*/}
        </ContentsCatalogContainer>
      </Col>
    </Row>
  </Container>
);

ContentsCatalog.propTypes = {
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  vocabulary: PropTypes.object
};

export default connect(
  state => {
    const { vocabulary } = state.app;

    return {
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(ContentsCatalog);
