import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from '../../gridSystem';
import Arial from '../../typography/arial';
import {
  Image, EditorialImageRightContainer, ColumnImage, Columntext,
  Overlay, IconVideo
} from './style';
import { VideoModal } from '../Modals';
import { MODAL_OPEN } from '../../../app/redux/actions';

const EditorialImageRight = ({ content: { article, file }, openVideoModal }) => {
  const openVideo = () => {
    openVideoModal();
  };
  const { data: media } = file ? file[0] : {};
  const { data: contentText } = article ? article[0] : {};
  return (
    <EditorialImageRightContainer>
      <Row>
        {(contentText?.title || contentText?.body)
          && (
          <Columntext md={6}>
            {contentText?.title && <Arial type="editorialImageTitle">{contentText?.title}</Arial>}
            {contentText?.body && <div dangerouslySetInnerHTML={{ __html: contentText?.body }} />}
          </Columntext>
          )}
        {media?.image && (
        <ColumnImage md={6}>
          <Image withVideo={!!media.video} src={media?.image?.url}>
            {media?.video && (
            <Overlay onClick={() => openVideo()}>
              <IconVideo>
                <span className="icon-video"><span className="path1" /><span className="path2" /></span>
              </IconVideo>
            </Overlay>
            )}
          </Image>
        </ColumnImage>
        )}
      </Row>
      {media?.video && <VideoModal videoUrl={media?.video?.url} />}
    </EditorialImageRightContainer>
  );
};

EditorialImageRight.propTypes = {
  content: PropTypes.object,

  // HOC (connect, dispatch)
  openVideoModal: PropTypes.func
};

export default connect(
  () => ({}),
  dispatch => ({
    openVideoModal: () => dispatch({ type: MODAL_OPEN, id: VideoModal.id })
  })
)(EditorialImageRight);
