import styled from 'styled-components';
import { Col } from '../../gridSystem';

export const EditorialImageRightContainer = styled.div`
  padding: 50px 0;

  @media ${props => props.theme.device.tablet} {
    padding: 50px 10px;
  }

  ul {
    list-style: initial;
  }
`;

export const ColumnImage = styled(Col)``;

export const Columntext = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  color: black;

  > div {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    padding-right: 0;
    padding-bottom: 20px;
  }
`;

export const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 270px;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
`;

export const IconVideo = styled.div`
  font-size: 60px;
  cursor: pointer;
`;
