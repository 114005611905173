import styled from 'styled-components';

const ClassroomsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    padding-top: 20px;
  }
`;

const Title = styled.div`
  font-family: Intro;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primaryBlack};
  padding-bottom: 1em;
`;

export { ClassroomsContainer, Title };
