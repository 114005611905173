import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container /*Row*/ } from '../../../ui/gridSystem';
// import {
//   GuidebookContainer, Category, CategoryColumn, GuidebookListColumn,
//   GuidebookItemColumn, RowGuidebook
// } from './style';
// import { GUIDEBOOK_GET } from '../../redux/actions/guidebook';
// //import { BackButton, NoResult } from '../../../ui/atoms';

// import { GuideboookCard } from '../../../ui/components';
// import Arial from '../../../ui/typography/arial';
// //import routes from '../../../routes';
// import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import ComingSoon from '../../../ui/atoms/ComingSoon';

const Guidebook = ({
  guidebook, getGuidebook, categories, vocabulary
}) => {
  // const [guidebookList, setGuidebook] = useState([]);
  // const [noResults, setNoResults] = useState(false);
  // const [activeCategory, setActiveCategory] = useState('Tutte le categorie');

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getGuidebook();
  // }, [getGuidebook]);

  // useEffect(() => {
  //   setGuidebook(guidebook);
  // }, [guidebook]);

  // const filterGuidebook = (category = null) => {
  //   let filteredGuidebook = guidebook;
  //   if (category) filteredGuidebook = guidebook.filter(cat => cat.data.category === category);
  //   if (filteredGuidebook.length === 0) setNoResults(true);
  //   setGuidebook(filteredGuidebook);
  //   setActiveCategory(category || 'Tutte le categorie');
  // };

  return (
    <Container>
      <ComingSoon />
      {/* <GuidebookContainer>
        <Container className="p-0">
          <Row>
            <CategoryColumn md={3} sm={12}>
              <Category onClick={() => filterGuidebook()} isActive={activeCategory === 'Tutte le categorie'}>
                <Arial type="guidebookCategory" vocabulary="faqTitle">{getValueVocabulary(vocabulary.guidebookDescription, 'guidebookDescription')}</Arial>
              </Category>
              {(categories || []).map(cat => (
                <Category
                  isActive={activeCategory === cat?.name}
                  onClick={() => filterGuidebook(cat.name)}
                  key={cat?.id}
                >
                  <Arial type="guidebookCategory">
                    {cat.name}
                  </Arial>
                </Category>
              ))}
            </CategoryColumn>
            <GuidebookListColumn md={9} sm={12}>
              <RowGuidebook>
                {(guidebookList || []).map((singleGuidebook, i) => (
                  <GuidebookItemColumn key={i}>
                    <GuideboookCard guidebook={singleGuidebook} />
                  </GuidebookItemColumn>
                ))}
                {noResults && <NoResult />}
              </RowGuidebook>
            </GuidebookListColumn>
          </Row>
        </Container>
      </GuidebookContainer> */}
    </Container>
  );
};

Guidebook.propTypes = {
  // HOC (connect, dispatch)
  getGuidebook: PropTypes.func.isRequired,

  // HOC (connect, state)
  guidebook: PropTypes.array,
  categories: PropTypes.array,
  vocabulary: PropTypes.object
};

export default connect(
  state => {
    const { files: guidebook } = state.guidebook;
    const { filters: categories } = state.guidebook;
    const { vocabulary } = state.app;
    return {
      guidebook,
      categories,
      vocabulary
    };
  },
  dispatch => ({
    // getGuidebook: () => dispatch({ type: GUIDEBOOK_GET._REQUEST })
  })
)(Guidebook);
