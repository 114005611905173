import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import { LESSON_CONTENTS_GET } from '../actions/lesson';

import lesson from '../../api/lesson';

function* getLesson(lessonId) {
  const { data: { data: response } = {} } = yield call(lesson.getLesson, { course_id: lessonId });
  yield put({ type: LESSON_CONTENTS_GET._SUCCESS, response: { ...response, lessonId } });
}

function* lessonsContentsGet({ lessonId, reload = true }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (!lessonId) return;

    if (!reload) {
      const checkLessonID = yield select(state => state.lesson.lessons[lessonId]);
      if (!(checkLessonID && checkLessonID.lessonId)) {
        yield getLesson(lessonId);
      }
    } else {
      yield getLesson(lessonId);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > lesson > lessonsContentsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* lessonsContentsGetWatch() {
  yield takeLatest(LESSON_CONTENTS_GET._REQUEST, lessonsContentsGet);
}

export default [
  lessonsContentsGetWatch()
];
