import { createAsyncActionType } from './index';

export const SMARTWORKING_LIST_GET = createAsyncActionType('smartworking', 'SMARTWORKING_LIST_GET');
export const SET_SELECTED_SMARTWORKING = '@@smartworking/SET_SELECTED_SMARTWORKING';
export const SELECTED_SMARTWORKING_GET = createAsyncActionType('smartworking', 'SELECTED_SMARTWORKING_GET');
export const SMARTWORKING_PILLS_GET = createAsyncActionType('smartworking', 'SMARTWORKING_PILLS_GET');
export const SMARTWORKING_NODE_ACTION = createAsyncActionType('smartworking', 'SMARTWORKING_NODE_ACTION');
export const SMARTWORKING_COMMENTS_GET = createAsyncActionType('smartworking', 'SMARTWORKING_COMMENTS_GET');
export const SMARTWORKING_COMMENTS_SET = createAsyncActionType('smartworking', 'SMARTWORKING_COMMENTS_SET');
export const SMARTWORKING_PILL_SET_STATS = createAsyncActionType('smartworking', 'SMARTWORKING_PILL_SET_STATS');
export const SMARTWORKING_SHARE_PILL_COLLECTION = createAsyncActionType('smartworking', 'SMARTWORKING_SHARE_PILL_COLLECTION');
