import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getCourseMock,
  getCourseContentMock,
  compulsaryTrainingNodeActionMock,
  getCompulsaryTrainingCommentsMock,
  setCompulsaryTrainingCommentsMock
} from './mock';

export default {
  getCompulsaryTrainingList: (filters) => {
    if (shouldUseMock()) {
      return getCourseMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getCompulsaryTraningCourses: (filters) => {
    if (shouldUseMock()) {
      return getCourseContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/content${parseQueryParams(filters)}`, 'GET');
  },
  compulsaryTrainingNodeAction: (payload) => {
    if (shouldUseMock()) {
      return compulsaryTrainingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getCompulsaryTrainingComments: ({ id }) => {
    if (shouldUseMock()) {
      return getCompulsaryTrainingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setCompulsaryTrainingComments: (body) => {
    if (shouldUseMock()) {
      return setCompulsaryTrainingCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  }
};
