import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { APP_MENU_TOGGLE } from '../../../app/redux/actions';

import Roboto from '../../typography/roboto';
import { Container } from '../../gridSystem';
import { MenuContainer, ItemList, Span } from './style';

const Menu = ({ customClass, menuList, menuToggle, callback }) => {
  const [actualMenu, setActualMenu] = useState(menuList);
  useEffect(() => {
    setActualMenu(menuList);
  }, [menuList]);

  const changeLocation = url => {
    menuToggle();
    callback && callback(url);
  };

  return (
    <MenuContainer customClass={customClass}>
      <Container>
        <ItemList className="items-list">
          {(actualMenu || []).map(item => (
            <div
              key={item.icon}
              className="items-list-item items-list-item-mobile"
              onClick={() => changeLocation(item.url)}
              style={{ alignItems: 'center', display: 'inline-flex' }}
            >
              <Span className={item.icon} style={{ marginRight: '10px' }} />
              <Span>
                <Roboto type="itemListMobile">{item.title}</Roboto>
              </Span>
            </div>
          ))}
        </ItemList>
      </Container>
    </MenuContainer>
  );
};

Menu.propTypes = {
  customClass: PropTypes.string,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  callback: PropTypes.func,

  // HOC (connect, dispatch)
  menuToggle: PropTypes.func.isRequired
};

export default connect(
  state => ({
    faqUrl: state.app.appConfig.faqUrl
  }),
  dispatch => ({
    menuToggle: () => dispatch({ type: APP_MENU_TOGGLE })
  })
)(Menu);
