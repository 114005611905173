import React from 'react';
import PropTypes from 'prop-types';

import { BodyContainer, BodyItem } from './style';

const BodyDouble = ({
  content: { first_part, second_part }
}) => (
  <BodyContainer>
    <BodyItem type="first_part" dangerouslySetInnerHTML={{ __html: first_part }} />
    <BodyItem type="second_part" dangerouslySetInnerHTML={{ __html: second_part }} />
  </BodyContainer>
);

BodyDouble.propTypes = {
  content: PropTypes.object
};

export default BodyDouble;
