import styled from 'styled-components';

export const VideoInfo = styled.div`
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.brownGrey};
`;

export const VideoInfoWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.black};

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const VideoSocialWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  div:first-child {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      margin-right: 10px;
    }
  }
`;

export const Social = styled.div`
  color: ${props => (props.isActive
    ? props.theme.colors.primaryColor
    : props.theme.colors.brownGrey)};
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    font-size: 20px;
    margin-right: 9px;
  }
  div,
  span {
    cursor: pointer;
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div,
    span {
      opacity: 0.6;
    }
  }
`;
export const Description = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  margin-bottom: 18px;
`;

export const Like = styled.div`
  > div {
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > button {
    width: 100px;
    margin-left: 10px;
  }

  & > button:nth-child(2n) {
    min-width: 180px;
    width: auto;
    white-space: nowrap;
  }
`;

export const DownloadPDF = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  min-width: fit-content;
  text-align: right;
  
  span.icon-download {
    margin-right: 10px;
  }

  @media ${props => props.theme.device.mobileL} {
    margin-top: 10px;
  }
`;
