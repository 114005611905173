import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { isTablet } from 'react-device-detect';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer, Background, HeaderWrapper, Question, Title, QuestionContainer
} from './style';
import Arial from '../../../ui/typography/arial';
import {
  BackButton, Checkbox
} from '../../../ui/atoms';
import RadioButton from '../../../ui/atoms/RadioButton';
import QuestionBox from '../../../ui/components/QuestionBox';
import SurveyComments from '../../../ui/components/SurveyComments';
import Stars from '../../../ui/components/Stars';
import { SET_INSERT_SURVEY, SURVEY_CONTENT_GET } from '../../redux/actions/survey';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import routes from '../../../routes';
import { newForm, newFormField } from '../../../utils/form';


const Survey = ({
  heroImage, selected_survey, getSurveyContent, location, pushUrl, tojId,
  setSurveyInsert
}) => {
  const { mandatory_survey_id, additional_survey_id } = queryString.parse(location.search);
  const [form, setForm] = useState({});
  const [showOtherQuestions, setShowOtherQuestions] = useState(false);
  const [mandatorySurvey, setMandatorySurvey] = useState({});
  const [additionalSurvey, setAdditionalSurvey] = useState({});

  useEffect(() => {
    const { mandatory = {}, additional = {} } = selected_survey;
    setMandatorySurvey(mandatory);
    setAdditionalSurvey(additional);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_survey]);

  useEffect(() => {
    const { survey_items: survey_items_mandatory = [] } = { ...mandatorySurvey };
    const { survey_items: survey_items_additional = [] } = { ...additionalSurvey };
    const initialFormMandatory = newForm([...survey_items_mandatory].map((survey, index) => newFormField({
      field: `survey-${index}-${survey?.data?.question_id}`, required: true, survey, mandatory: true, firstQuestion: index === 0,
      value: index === 0 && form[`survey-${index}-${survey?.data?.question_id}`]?.value ? form[`survey-${index}-${survey?.data?.question_id}`].value : null,
      valid: index === 0 && form[`survey-${index}-${survey?.data?.question_id}`]?.value ? true : false
    })));
    const initialFormAdditional = newForm([...survey_items_additional].map((survey, index) => newFormField({
      field: `survey-additional-${index}-${survey?.data?.question_id}`, required: survey?.data?.mandatory_response, survey, mandatory: false, value: null
    })));

    setForm({ ...initialFormMandatory, ...initialFormAdditional });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatorySurvey, additionalSurvey, showOtherQuestions]);

  useEffect(() => {
    if (!mandatory_survey_id && tojId) {
      pushUrl(`${routes.toj.path}/${tojId}`);
    }
  }, [location, tojId, mandatory_survey_id, additional_survey_id, pushUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (mandatory_survey_id) {
      getSurveyContent(mandatory_survey_id, 'mandatory');
    }
    if (additional_survey_id) {
      getSurveyContent(additional_survey_id, 'additional');
    }
  }, [mandatory_survey_id, additional_survey_id, getSurveyContent]);

  const handleOnChange = (field = '', newValue, type, index) => {
    const { required, value = [], firstQuestion } = form[field];
    switch (type) {
      case 'checkbox': {
        const checkboxValue = value && [...value].indexOf(newValue) >= 0 ? [...value].filter(val => val !== newValue) : value ? [...value, newValue] : [newValue];
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: checkboxValue.length ? checkboxValue : null,
            valid: required ? !!checkboxValue.length : true
          }
        });
        break;
      }
      default:
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: newValue,
            valid: required ? !!newValue : true
          }
        });
        break;
    }

    if (firstQuestion) {
      setShowOtherQuestions(index === 0);
    }
  };

  const questionType = (key, question) => {
    const { type: question_type, data } = question;
    switch (question_type) {
      case 'choice_question': {
        const {
          question_id, type, answers, stars_amount
        } = data;
        return type === 'Single choice'
          ? answers.map((answer, index) => <RadioButton label={answer} name={`radio-${question_id}`} value={answer} onChange={(value) => handleOnChange(key, value, '', index)} />)
          : type === 'Multiple choice' ? answers.map((answer) => <Checkbox label={answer} name={`checkbox-${question_id}`} value={answer} onChange={(value) => handleOnChange(key, value, 'checkbox')} />)
            : <Stars onClick={(value) => handleOnChange(key, value)} stars={stars_amount} />;
      }
      case 'free_text_question':
      default: {
        return <SurveyComments onChange={(value) => handleOnChange(key, value)} />;
      }
    }
  };

  const handleOnSubmit = () => {
    setSurveyInsert({
      tojId, form, mandatory_survey_id, additional_survey_id, title: additionalSurvey?.title || mandatorySurvey?.title
    });
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage} />
        <Container>
          <HeaderWrapper>
            <Container>
              <BackButton />
              <Arial type="newsDetailTitle">{additionalSurvey?.title || mandatorySurvey?.title}</Arial>
            </Container>
          </HeaderWrapper>
        </Container>
      </HeaderContainer>
      <Container>

        <Row>
          <Col lg={isTablet ? 6 : 8} md={12} sm={12}>
            {
              Object.keys(form).length && Object.keys(form).splice(0, !showOtherQuestions ? 1 : Object.keys(form).length).map(key => (
                <Row>
                  <Question>
                    <Title>
                      <Arial type="newsTitle">
                        {form[key].survey.data.question}
                      </Arial>
                    </Title>
                    {questionType(key, form[key].survey)}
                  </Question>
                </Row>
              ))
            }
          </Col>
          <Col lg={isTablet ? 6 : 4} md={12} sm={12}>
            <QuestionContainer>
              <QuestionBox question={Object.keys(form).splice(0, !showOtherQuestions ? 1 : Object.keys(form).length)} form={form} handleOnSubmit={handleOnSubmit} showOtherQuestions={showOtherQuestions} />
            </QuestionContainer>
          </Col>
        </Row>
      </Container>


    </>
  );
};


Survey.propTypes = {
  tojId: PropTypes.number.isRequired,
  // HOC (connect, dispatch)
  getSurveyContent: PropTypes.func.isRequired,
  setSurveyInsert: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  // HOC (connect, state)
  location: PropTypes.object,
  selected_survey: PropTypes.object
};

export default connect(state => {
  const { selected_survey } = state.survey;
  const { location } = state.router;
  return {
    location,
    selected_survey
  };
},
  dispatch => ({
    getSurveyContent: (id, survey_type) => dispatch({ type: SURVEY_CONTENT_GET._REQUEST, id, survey_type }),
    setSurveyInsert: ({
      tojId, form, mandatory_survey_id, additional_survey_id, title
    }) => dispatch({
      type: SET_INSERT_SURVEY._REQUEST, tojId, form, mandatory_survey_id, additional_survey_id, title
    }),
    pushUrl: url => dispatch(push(url))
  }))(withMediaQueries(Survey));
