import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from '../../../routes';
import { Settings } from '../Collections/settings';
import { handleClickCourseUrl } from '../../../utils/common';
import { CustomCarousel, NewsCard } from '../index';
import { Container, Row, Col } from '../../gridSystem';
import {
  NewsCardCarouselContainer,
  InfoContainer,
  Title,
  HeaderContent,
  SeeAll,
  NewsCardContainer,
  ColumnCarousel
} from './style';
import { NEWS_GET } from '../../../app/redux/actions/news';
import { NewsInterface } from '../../../app/redux/reducers/news';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const NewsCardCarousel = ({
  getNews,
  pushUrl,
  news,
  settings,
  title,
  vocabulary
}) => {
  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsCardCarouselContainer>
      <Container className="p-0">
        <Row>
          <Col lg={12} sm={12}>
            <InfoContainer>
              <HeaderContent>
                <Title>
                  <Arial type="sectionTitle">{title}</Arial>
                </Title>
                <SeeAll onClick={() => pushUrl(`${routes.news.path}`)}>
                  <Arial type="newsAttachment" vocabulary="newsShowAll">
                    {getValueVocabulary(vocabulary.newsShowAll, 'newsShowAll')}
                  </Arial>
                  <span className="icon-arrow-right" />
                </SeeAll>
              </HeaderContent>
            </InfoContainer>
          </Col>
          <ColumnCarousel lg={12} sm={12}>
            <CustomCarousel config={{ ...Settings, ...settings }}>
              {news.length > 0 &&
                news.slice(0, 12).map(singleNews => (
                  <NewsCardContainer key={singleNews.id}>
                    <NewsCard
                      onClick={() => handleClickCourseUrl(`${routes.news.path}/${singleNews.id}`)}
                      news={singleNews}
                    />
                  </NewsCardContainer>
                ))}
            </CustomCarousel>
          </ColumnCarousel>
        </Row>
      </Container>
    </NewsCardCarouselContainer>
  );
};

NewsCardCarousel.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.object,

  // HOC (connect, state)
  news: PropTypes.arrayOf(NewsInterface),
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { news: { items: news = [] } = {} } = state.news;
    const { vocabulary } = state.app;
    return {
      news,
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getNews: () =>
      dispatch({ type: NEWS_GET._REQUEST, carousel: true, itemPerPage: 100 })
  })
)(NewsCardCarousel);
