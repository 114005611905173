import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  ProductsContainer, TabsContainer, ProductsFilterContainer,
  ProductsFilterLabel, ProductsCustomSelectContainer, ProductsFilterPillsContainer,
  Background, HeaderContainer, Mask, Description, Title, MaskContainer
} from './style';
import { /* PRODUCTS_NODE_ACTION,  */ PRODUCTS_LIST_GET/* , SET_SELECTED_PRODUCT  */ } from '../../redux/actions/product';
import {
  CustomSelect, FilterPill, Tabs
} from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/prodotti-ubi.jpg';
import Arial from '../../../ui/typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const defaultFamiglia = [
  //{ label: 'Tutti', value: 'all' }
];

const Products = ({
  productsList, heroImage, getProducts, vocabulary,
  /* setSelectedProduct, setAsFavoriteProduct, pushUrl */
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts]);

  const defaultTabs = [
    { label: 'Tutti i segmenti di clientela', value: 'all' }
  ];
  // eslint-disable-next-line no-unused-vars
  const { products: { courses: list = [], filters: { categories = [] } = {} } = {} } = productsList;

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);
  //const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    //setProducts(list);
    if (activeTab.value === 'all') {
      const payload = [...categories].filter(category => !!category.parent);
      setFilters(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList]);

  const onChangeTab = (tab) => {
    if (tab.value === 'all') {
      const payload = [...categories].filter(category => !!category.parent);
      setFilters(payload);
      getProducts();
    } else {
      const payload = [...categories].filter(category => category.parent === tab.value);
      setFilters(payload);
      getProducts({ category_id: [tab.value, ...payload.map(item => item.id)].toString().replace(/,/g, ';') });
    }
    setSelectedFilters([]);
    setActiveTab(tab);
  };

  const onChangeFilter = (newFilters) => {
    if (newFilters.length) {
      getProducts({ category_id: [...newFilters.map(item => item.value)].toString().replace(/,/g, ';') });
    } else {
      onChangeTab(activeTab);
    }
    setSelectedFilters(newFilters);
  };

  const removeFilter = (item) => {
    const newFilters = (selectedFilters || []).filter(filter => filter.value !== item);
    if (newFilters.length) {
      getProducts({ category_id: [...newFilters.map(filter => filter.value)].toString().replace(/,/g, ';') });
      setSelectedFilters(newFilters);
    } else {
      onChangeTab(activeTab);
    }
  };

  /* const handleOnClickCard = (item) => {
    setSelectedProduct(item);
    pushUrl(`${routes.products.path}/${item.id}`);
  }; */

  return (
    <Container>
      <MaskContainer>
        <Mask>
          <Title>
            <Arial type="comingSoonTitle">Coming soon!</Arial>
          </Title>
          <Description>
            <Arial type="EditorialGridTitle" configuration={{ fontWeight: 'normal' }}>Collection di documenti per supportare la tua conoscenza dei principali prodotti </Arial>
          </Description>
        </Mask>

        <HeaderContainer>
          <Background img={heroImage || ProceduresDefaultCover} />
        </HeaderContainer>
        <ProductsContainer>

          <Container className="p-0">
            <TabsContainer>
              <Tabs tabs={[...defaultTabs, ...categories.filter(category => !category.parent).map(({ id: value, name: label }) => ({ label, value }))]} onChange={onChangeTab} selected={activeTab} />
            </TabsContainer>
            <>
              <ProductsFilterLabel><Arial type="filterLabel" vocabulary="productsFilterLabel">{getValueVocabulary(vocabulary.productsFilterLabel, 'productsFilterLabel')}</Arial></ProductsFilterLabel>
              <ProductsFilterContainer>
                <ProductsCustomSelectContainer>
                  <CustomSelect
                    options={[...defaultFamiglia, ...filters.map(({ id: value, name: label }) => ({ label, value }))]}
                    onChange={newValue => onChangeFilter(newValue || [])}
                    selected={selectedFilters}
                    placeholder={selectedFilters.length ? 'Seleziona' : 'Tutti'}
                    isMulti
                  />
                </ProductsCustomSelectContainer>
                <ProductsFilterPillsContainer>
                  {(selectedFilters || []).map(filter => <FilterPill onRemove={removeFilter} value={filter.value} label={filter.label} />)}
                </ProductsFilterPillsContainer>
              </ProductsFilterContainer>
            </>
            {/*  <ResultContainer>
            {(products || []).length > 0 ? (products.map((product) => (
              <CardContainer sm={12} md={6} lg={3} key={product.id}>
                <GeneralCard
                  key={`product-${product.id}`}
                  item={product}
                  onClickCard={() => handleOnClickCard(product)}
                  onClickFavorite={() => setAsFavoriteProduct(product.id)}
                  type={GeneralCard.TYPE.PRODUCT}
                  colorTag={getTagColor(categories, product.category_id)}
                />
              </CardContainer>
            ))) : (<NoResult />)}
          </ResultContainer> */}
          </Container>
        </ProductsContainer>
      </MaskContainer>
    </Container>
  );
};

Products.propTypes = {

  // HOC (connect, state)
  productsList: PropTypes.object,
  heroImage: PropTypes.string,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  getProducts: PropTypes.func.isRequired,
  /*  setSelectedProduct: PropTypes.func.isRequired,
   setAsFavoriteProduct: PropTypes.func.isRequired,
   pushUrl: PropTypes.func.isRequired */
};

export default connect(
  (state) => {
    const productsList = state.product;
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { vocabulary } = state.app;

    return {
      productsList,
      heroImage,
      vocabulary
    };
  },
  dispatch => ({
    getProducts: (filters) => dispatch({
      type: PRODUCTS_LIST_GET._REQUEST, filters, itemPerPage: 100, subset: true
    }),
    /*  setSelectedProduct: (selected) => dispatch({ type: SET_SELECTED_PRODUCT, selected }),
     setAsFavoriteProduct: (nid) => dispatch({
       type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
     }),
     pushUrl: url => dispatch(push(url)) */
  })
)(Products);
