import React, { Component } from 'react';

import { Menu } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';

class Social extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container>
        <Menu customClass="menu-desktop" />
      </Container>
    );
  }
}

Social.propTypes = {};

export default Social;
