import produce from 'immer';
import {
  FAVORITE_LIST_GET
} from '../actions/favorite';

const initialState = {};

const favoriteReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case FAVORITE_LIST_GET._SUCCESS: {
      const { response } = action;
      draft = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default favoriteReducer;
