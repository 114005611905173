import produce from 'immer';

import {
  SELECTED_SMARTWORKING_GET,
  SMARTWORKING_LIST_GET,
  SET_SELECTED_SMARTWORKING, SMARTWORKING_COMMENTS_GET, SMARTWORKING_PILLS_GET
} from '../actions/smartworking';

const initialState = {
  smartWorking: {},
  selectedSmartWorking: {},
  pills: [],
  comments: []
};

const smartWorkingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case SMARTWORKING_LIST_GET._SUCCESS: {
      const { response } = action;
      const { from, items } = response;
      const list = (!from || from <= 0) ? items : [...state.smartWorking.items, ...items];
      draft.smartWorking = {
        ...response,
        items: list
      };
      break;
    }

    case SMARTWORKING_PILLS_GET._SUCCESS: {
      const { response } = action;
      const { pill_items } = response[0];
      draft.pills = pill_items;
      break;
    }

    case SELECTED_SMARTWORKING_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedSmartWorking = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_SMARTWORKING: {
      const { selected } = action;
      draft.selectedSmartWorking = selected;
      break;
    }

    case SMARTWORKING_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default smartWorkingReducer;
