import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import lifeIsp from '../../api/lifeIsp';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import {
  LIFE_ISP_COMMENTS_GET, LIFE_ISP_COMMENTS_SET,
  LIFE_ISP_COURSES_GET,
  LIFE_ISP_GET,
  LIFE_ISP_NODE_ACTION, SELECTED_LIFE_ISP_GET
} from '../actions/life_isp';
import routes from '../../../routes';


function* lifeispGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { products: { total_items: totalCount, courses = [] } = {} } = yield select(state => state.lifeIsp);
    const pagination = calcPagination(subset ? undefined : { from: courses.length , totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'vivere_isp', ...filters, ...pagination };
      const { data: { data: response } } = yield call(lifeIsp.getLifeIspList, complete_filters);
      yield put({ type: LIFE_ISP_GET._SUCCESS, response, subset });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > life_isp > lifeispGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* lifeispGetWatch() {
  yield takeLatest(LIFE_ISP_GET._REQUEST, lifeispGet);
}

function* setSelectedLifeIsp({ id, itemPerPage = 1, refresh = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_product } = yield select(state => state.lifeIsp);
    if ((selected_product && Object.keys(selected_product).length <= 0 && pagination && !pagination.isLastPage) || refresh) {
      const complete_filters = { type: 'vivere_isp', id, ...pagination };
      const { data: { data: response } } = yield call(lifeIsp.getLifeIspList, complete_filters);
      yield put({ type: SELECTED_LIFE_ISP_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > life_isp > setSelectedLifeIsp', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedLifeIspWatch() {
  yield takeLatest(SELECTED_LIFE_ISP_GET._REQUEST, setSelectedLifeIsp);
}

function* lifeIspCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(lifeIsp.getLifeIspCourses, { course_id: collectionId });
      yield put({ type: LIFE_ISP_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > life_isp > lifeIspCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* lifeIspCoursesGetWatch() {
  yield takeLatest(LIFE_ISP_COURSES_GET._REQUEST, lifeIspCoursesGet);
}

function* lifeIspNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(lifeIsp.lifeIspNodeAction, payload);
      yield put({ type: LIFE_ISP_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: LIFE_ISP_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: LIFE_ISP_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_product } = yield select(state => state.lifeIsp);
            if (selected_product.id) yield put({ type: LIFE_ISP_COURSES_GET._REQUEST, collectionId: selected_product.id, loader: false });
          } else {
            yield put({ type: LIFE_ISP_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > life_isp > lifeIspNodeAction', err);
      yield put(push(routes.home.path));
    }
  }
}

function* lifeIspNodeActionWatch() {
  yield takeLatest(LIFE_ISP_NODE_ACTION._REQUEST, lifeIspNodeAction);
}

function* lifeIspCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(lifeIsp.getLifeIspComments, { id });
      yield put({ type: LIFE_ISP_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > life_isp > lifeIspCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* lifeIspCommentsGetWatch() {
  yield takeLatest(LIFE_ISP_COMMENTS_GET._REQUEST, lifeIspCommentsGet);
}

function* lifeIspCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(lifeIsp.setLifeIspComments, payload);
    yield put({ type: LIFE_ISP_COMMENTS_SET._SUCCESS });
    yield put({ type: LIFE_ISP_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > life_isp > lifeIspCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* lifeIspCommentsSetWatch() {
  yield takeLatest(LIFE_ISP_COMMENTS_SET._REQUEST, lifeIspCommentsSet);
}

export default [
  lifeispGetWatch(),
  setSelectedLifeIspWatch(),
  lifeIspCoursesGetWatch(),
  lifeIspNodeActionWatch(),
  lifeIspCommentsGetWatch(),
  lifeIspCommentsSetWatch()
];
