import styled from 'styled-components';
import {
  NavBarHeight,
  SubNavBarHeight
} from '../../../const';
import Background from '../../assets/img/immagine-sfondo.png';

export const Mask = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - ${NavBarHeight}px - ${SubNavBarHeight}px);
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: -190px;

  @media ${props => props.theme.device.tablet} {
    margin-left: -22px;
    background-position: right bottom;
  }

  @media ${props => props.theme.device.mobileL} {
    background-position-y: 170px;
    background-position-x: -920px;
  }
`;

export const Title = styled.div`
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  
  > div {
    @media ${props => props.theme.device.mobileL} {
      font-size: 40px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    top: 20%
    left: 5%;
  }
`;

export const Description = styled.div`
  position: absolute;
  top: 40%;
  width: 60%;

  @media ${props => props.theme.device.tablet} {
    top: 26%;
    left: 5%;
  }
`;
