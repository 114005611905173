import styled from 'styled-components';

const AuthContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  height: 100vh;
  width: 100vw;
`;

const Background = styled.div`
  height: 100%;
  position: relative;
  width: 45vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 20vh;
    width: 100%;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.biscuit50};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const FormContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 55%;
  width: 100%;
  max-width: 35vw;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.biscuit50};
  border-radius: 12px;
  padding: 35px 10px 15px;

  @media ${props => props.theme.device.mobileL} {
    position: relative;
    left: unset;
    top: unset;
    max-width: initial;
  }
`;

const Image = styled.img`
  margin-bottom: 60px;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-top: 15px;
  width: 380px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  & input {
    max-width: 100%;
  }
`;

const ShowHidePasswordIcon = styled.span`
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;

  &.visible {
    &::after {
      content: '/';
      position: absolute;
      color: #979797;
      transform: rotate(-55deg);
      left: 4px;
      top: -2px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 30%;
  width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    top: 100px;
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  width: 400px;
  max-width: 100%;

  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
    margin-top: 15px;
  }
`;

const Subtitle = styled.div`
  line-height: 1.4;
  width: 400px;
  max-width: 100%;

  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const ResetPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;

export {
  AuthContainer,
  Background,
  Image,
  FormContainer,
  ShowHidePasswordIcon,
  InputWrapper,
  LogoContainer,
  Title,
  Subtitle,
  ResetPassword
};
