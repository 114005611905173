import styled from 'styled-components';

const NewsDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 100px;
  background-color: ${props => props.theme.colors.transparent};
`;

const CardContainer = styled.div`
  width: 25%;
  padding-top: 20px;

  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ResultContainer = styled.div`
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.white};
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.veryLightPink};
`;

const Iframe = styled.iframe`
  min-height: 380px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
`;

const NewsDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
  }
`;

const Date = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  display: flex;
  justify-content: center;
  flex-direction: row;

  > span {
    margin-right: 5px;
  }

  @media ${props => props.theme.device.tablet} {
    margin-top: 10px;
  }
`;

const Text = styled.div`
  color: ${props => props.theme.colors.black};
  padding-bottom: 30px;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
`;

const NewsDetailAttachment = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 30px;
`;

const Pdf = styled.a`
  color: ${props => props.theme.colors.white};
  margin: 20px 0 17px;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }

  div,
  span {
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div,
    span {
      color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
    }
  }
`;

const Share = styled.div`
  color: ${props => props.theme.colors.white};
  margin: 20px 0 17px;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
`;

const Icon = styled.span``;

const ContainerMedia = styled.div`
  margin-bottom: 20px;
`;

export {
  NewsDetailContainer,
  ResultContainer,
  Title,
  CardContainer,
  Iframe,
  NewsDetailHeader,
  Date,
  Text,
  NewsDetailAttachment,
  Pdf,
  Icon,
  Subtitle,
  Share,
  ContainerMedia
};
