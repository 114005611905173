import { apiCall, baseUrl, extendUrl, extendUrlV1 } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  getSmarWorkingMock,
  smartWorkingNodeActionMock,
  getSmartWorkingCommentsMock,
  setSmartWorkingCommentsMock,
  getSmartWorkingPillsMock,
  setStatsSmartWorkingPillMock,
  pathDetailsGetMock
} from './mock';

export default {
  getStcCourseList: filters => {
    if (shouldUseMock()) {
      return getSmarWorkingMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}pill/collection${parseQueryParams(filters)}`,
      'GET'
    );
  },
  getStcCoursePills: ({ collectionId }) => {
    if (shouldUseMock()) {
      return getSmartWorkingPillsMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}pill/collection/${collectionId}/content`,
      'GET'
    );
  },
  stcCourseNodeAction: payload => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  getStcCourseComments: ({ id }) => {
    if (shouldUseMock()) {
      return getSmartWorkingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setStcCourseComments: body => {
    if (shouldUseMock()) {
      return setSmartWorkingCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  },
  setStatsStcCoursePill: ({ collectionId, pillId, body }) => {
    if (shouldUseMock()) {
      return setStatsSmartWorkingPillMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}user/stats/${collectionId}/collection/${pillId}/pill`,
      'POST',
      body
    );
  },
  stcCourseSharePillCollection: payload => {
    if (shouldUseMock()) {
      return smartWorkingNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/share`, 'POST', payload);
  },
  pathDetailsGet: id => {
    if (shouldUseMock()) {
      return pathDetailsGetMock();
    }
    return apiCall(`${baseUrl}${extendUrlV1}path_details/${id}`, 'GET');
  }
};
