import produce from 'immer';
import { TOJ_CONTENT_GET, TOJ_LIST_GET } from '../actions/toj';

const initialState = {
  selected_toj: {},
  tojList: []
};

const tojReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case TOJ_CONTENT_GET._SUCCESS: {
      const { response } = action;
      draft.selected_toj = response;
      break;
    }

    case TOJ_LIST_GET._SUCCESS: {
      const { response } = action;
      draft.tojList = response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default tojReducer;
