import styled from 'styled-components';
import {
  CollectionContainer,
  CourseContainer,
  CourseElementCounter,
  CourseElementDetails,
  CourseTitle,
  Cover
} from '../Collection/style';

export const CompulsaryTrainingCoursesContainer = styled(CollectionContainer)`
  color: ${props => props.theme.colors.black};
`;

export const MainSection = styled.div`
  margin-top: 20px;


  @media ${props => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const VideoContainer = styled.div`
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.4)};
  min-height: 550px;
  width: 100%;
  margin-bottom: -13px;
  position: relative;
`;

export const VideoInfo = styled.div`
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.brownGrey};
`;

export const VideoInfoWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const VideoSocialWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  div:first-child {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      margin-right: 10px;
    }
  }
`;

export const TagContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 20px 0;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const TagWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;

  span:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Tag = styled.span`
  background-color: ${props => props.theme.colors.white};
  height: 28px;
  border-radius: 14px;
  color: ${props => props.theme.colors.veryLightPink};
  padding: 6px 10px;
`;

export const CompulsaryTrainingCoursesListContainer = styled.div``;

export const CompulsaryTrainingCourseContainer = styled(CourseContainer)``;

export const CompulsaryTrainingCourseElementCounter = styled(
  CourseElementCounter
)``;

export const CompulsaryTrainingCourseCover = styled(Cover)``;

export const CompulsaryTrainingCourseElementDetails = styled(
  CourseElementDetails
)``;

export const CompulsaryTrainingCourseTitle = styled(CourseTitle)`
>div.tooltip {
  display: inline;
  position: relative;

  .tooltip:hover:after{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #fff;
    content: attr(title);
    margin: -82px auto 0;
    font-size: 16px;
    padding: 13px;
    width: 220px;
  }
  .tooltip:hover:before{
    border: solid;
    border-color: #444 transparent;
    border-width: 12px 6px 0 6px;
    content: "";
    left: 45%;
    bottom: 30px;
    position: absolute;
  }
}
`;

export const Iframe = styled.iframe`
  min-height: 550px;
  height: auto;
  height: calc(${props => props.frameHeight} + 30px);
  overflow: hidden;
  width: 100%;
`;

export const Frame = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.black40};
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;