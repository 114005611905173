import styled from 'styled-components';

const ActivityContainer = styled.div.attrs(({
  customClass
}) => ({
  className: `${customClass}`
}))`
  position: relative;
  min-height: 580px;
  height: calc(${props => props.frameHeight} + 100px);
  overflow: hidden;
  
  @media ${props => props.theme.device.mobileL} {
    margin: 0 -20px;
  }
`;

const LessonInfoContainer = styled.div`
  margin-bottom: 32px;
  @media ${props => props.theme.device.mobileL} {
    margin: 0 20px;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryBlack};
  padding-bottom: 3px;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.gray};

  @media ${props => props.theme.device.mobileL} {
    padding-bottom: 20px;
  }
`;

export const Iframe = styled.iframe`
  min-height: 850px;
  height: calc(${props => props.frameHeight} + 30px);
  overflow: hidden;
  //position: absolute;
  //left: 0;
  //top: -100px;
  width: 100%;
`;

export {
  ActivityContainer, Title, Description, LessonInfoContainer
};
