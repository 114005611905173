import { createAsyncActionType } from './index';

export const WHAT_CHANGE_GET = createAsyncActionType('what_change', 'WHAT_CHANGE_GET');
export const SELECTED_WHAT_CHANGE_GET = createAsyncActionType('what_change', 'SELECTED_WHAT_CHANGE_GET');
export const SET_SELECTED_WHAT_CHANGE = '@@what_change/SET_SELECTED_WHAT_CHANGE';
export const WHAT_CHANGE_COURSES_GET = createAsyncActionType('what_change', 'WHAT_CHANGE_COURSES_GET');

export const WHAT_CHANGE_NODE_ACTION = createAsyncActionType('what_change', 'WHAT_CHANGE_NODE_ACTION');
export const WHAT_CHANGE_COMMENTS_GET = createAsyncActionType('what_change', 'WHAT_CHANGE_COMMENTS_GET');
export const WHAT_CHANGE_COMMENTS_SET = createAsyncActionType('what_change', 'WHAT_CHANGE_COMMENTS_SET');
