import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import stc_all from '../../api/stc_all';
import { calcPagination } from '../../../utils/pagination';
import {
  STC_ALL_LIST_GET, SELECTED_STC_ALL_GET, STC_ALL_NODE_ACTION,
  STC_ALL_COMMENTS_GET, STC_ALL_COMMENTS_SET, STC_ALL_PILLS_GET,
  STC_ALL_PILL_SET_STATS, STC_ALL_SHARE_PILL_COLLECTION
} from '../actions/stc_all';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';


function* stcAllListGet({
  itemPerPage = 24, filters: filterList = {}, loader = true, subset = true, pathFilter = undefined
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { stcAll: { total_items: totalCount, items = [] } = {} } = yield select(state => state.stc_all);
    const pagination = calcPagination(subset ? undefined : { from: items.length, totalCount }, itemPerPage);
    const { path_contents } = yield select(state => state.app);
    if (path_contents.length && pagination && !pagination.isLastPage) {
      const path = pathFilter || (path_contents || []).find(item => item.name === 'Extra').id;
      const filters = {
        path, ...pagination, ...filterList
      };
      const { data: { data: response } } = yield call(stc_all.getStcAllList, filters);
      yield put({ type: STC_ALL_LIST_GET._SUCCESS, response, subset });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_all > stcAllListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* stcAllListGetWatch() {
  yield takeLatest(STC_ALL_LIST_GET._REQUEST, stcAllListGet);
}

function* setSelectedStcAll({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selectedStcAll } = yield select(state => state.stc_all);
    const { path_contents } = yield select(state => state.app);
    if (path_contents.length && Object.keys(selectedStcAll).length <= 0 && pagination && !pagination.isLastPage) {
      const path = (path_contents || []).find(item => item.name.toLowerCase() === 'extra').id;
      const filters = { path, id, ...pagination };
      const { data: { data: response } } = yield call(stc_all.getStcAllList, filters);
      yield put({ type: SELECTED_STC_ALL_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_all > setSelectedStcAll', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedStcAllWatch() {
  yield takeLatest(SELECTED_STC_ALL_GET._REQUEST, setSelectedStcAll);
}

function* stcAllPillsGet({ collectionId }) {
  if (collectionId) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response = {} } = {} } = yield call(stc_all.getStcAllPills, { collectionId });
      yield put({ type: STC_ALL_PILLS_GET._SUCCESS, response });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_all > stcAllPillsGet', err);
      yield put(push(routes.home.path));
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcAllPillsGetWatch() {
  yield takeLatest(STC_ALL_PILLS_GET._REQUEST, stcAllPillsGet);
}

function* stcAllNodeAction({ nid, action_type, node_type = 'pill_collection' }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(stc_all.stcAllNodeAction, payload);
      yield put({ type: STC_ALL_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: STC_ALL_LIST_GET._REQUEST });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selectedStcAll } = yield select(state => state.stc_all);
            if (selectedStcAll.id) yield put({ type: STC_ALL_PILLS_GET._REQUEST, collectionId: selectedStcAll.id, loader: false });
          } else {
            yield put({ type: STC_ALL_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > stc_all > stcAllNodeAction', err);
    }
  }
}

function* stcAllNodeActionWatch() {
  yield takeLatest(STC_ALL_NODE_ACTION._REQUEST, stcAllNodeAction);
}

function* stcAllCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(stc_all.getStcAllComments, { id });
      yield put({ type: STC_ALL_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_all > stcAllCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcAllCommentsGetWatch() {
  yield takeLatest(STC_ALL_COMMENTS_GET._REQUEST, stcAllCommentsGet);
}

function* stcAllCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(stc_all.setStcAllComments, payload);
    yield put({ type: STC_ALL_COMMENTS_SET._SUCCESS });
    yield put({ type: STC_ALL_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_all > stcAllCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcAllCommentsSetWatch() {
  yield takeLatest(STC_ALL_COMMENTS_SET._REQUEST, stcAllCommentsSet);
}


function* stcAllPillStatsSet({ collectionId, pillId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      collectionId,
      pillId,
      body: {
        status: 'completed'
      }
    };
    yield call(stc_all.setStatsStcAllPill, params);
    yield put({ type: STC_ALL_PILL_SET_STATS._SUCCESS });
    yield put({ type: SELECTED_STC_ALL_GET._REQUEST, id: collectionId });
    yield put({ type: STC_ALL_PILLS_GET._REQUEST, collectionId });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > stc_all > stcAllPillStatsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* stcAllPillStatsSetWatch() {
  yield takeLatest(STC_ALL_PILL_SET_STATS._REQUEST, stcAllPillStatsSet);
}

function* stcAllSharePillCollection({ data }) {
  if (data) {
    try {
      yield put({ type: LOADER_OPEN });
      yield call(stc_all.stcAllSharePillCollection, data);
      yield put({ type: STC_ALL_SHARE_PILL_COLLECTION._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > stc_all > stcAllSharePillCollection', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* stcAllSharePillCollectionWatch() {
  yield takeLatest(STC_ALL_SHARE_PILL_COLLECTION._REQUEST, stcAllSharePillCollection);
}

export default [
  stcAllListGetWatch(),
  setSelectedStcAllWatch(),
  stcAllPillsGetWatch(),
  stcAllNodeActionWatch(),
  stcAllCommentsGetWatch(),
  stcAllCommentsSetWatch(),
  stcAllPillStatsSetWatch(),
  stcAllSharePillCollectionWatch()
];
