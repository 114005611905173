import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px 124px 14px 124px;
`;

export const Title = styled.div`
  width: 525px;
  text-align: center;
  margin: auto auto 20px;
  color: ${props => props.theme.colors.black};
`;

export const Subtitle = styled.div`
  width: 525px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  margin: auto auto 20px;
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 400px;
`;

export const Source = styled.source``;
export const Track = styled.track``;

export const CloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 99;
  font-size: 16px;
  top: 20px;
  right: 20px;
  color: ${props => props.theme.colors.black};
`;
