import styled from 'styled-components';

const TimeLineContainer = styled.div`
  position: relative;

  &:after {
  background: linear-gradient(to bottom, #beddb9 0%, #268900 75%);
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  bottom: 10px;
  width: 4px;
}
`;

const TimelineItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 30px;
  position: relative;
  margin: 9px 0 -23px 0;
  margin-bottom: 10px;
`;

const TimelineItemContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px;
  position: relative;
  text-align: left;
  margin-left: 19px;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

const AgendaTimelineContainer = styled.div`
background-color: ${props => props.theme.colors.transparent};
color: ${props => props.theme.colors.white};
padding-top:10px;
display: flex;
flex-direction: column;
position: relative;
margin: -6px 0;
height: auto;
border-bottom: 1px solid #5c5c5c;
margin-bottom: 20px;
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.brownGrey};
`;

export const TojCarouselContainer = styled.div`
  width: 580px;

  @media ${props => props.theme.device.mobileL} {
    width: 305px;
  }
`;

export {
  AgendaTimelineContainer,
  TimeLineContainer,
  TimelineItemContainer,
  TimelineItemContent
};
