import styled from 'styled-components';

export const FilterPillContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 5px 5px 5px 10px;
  height: 28px;
  border-radius: 14px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryColor};

  @media ${props => props.theme.device.tablet} {
    white-space: nowrap;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;

export const Remove = styled.div`
  font-size: 14px;
  cursor: pointer;
`;
