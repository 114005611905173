import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  CourseContainer, InfoContainer, Title, SeeAll, HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import routes from '../../../routes';
import {
  PRODUCTS_NODE_ACTION
} from '../../../app/redux/actions/product';
import { FAVORITE_LIST_GET } from '../../../app/redux/actions/favorite';
import { MOST_VIEWED_LIST_GET } from '../../../app/redux/actions/mostviewed';
import { Settings } from '../Collections/settings';
import { GeneralCard } from '../../atoms';
import { SET_SELECTED_SMARTWORKING, SMARTWORKING_NODE_ACTION } from '../../../app/redux/actions/smartworking';
import {
  COMPULSARY_TRAINING_NODE_ACTION,
  SET_SELECTED_COMPULSARY_TRAINING
} from '../../../app/redux/actions/compulsarytraining';
import { COLLECTION_NODE_ACTION, SET_SELECTED_COLLECTION } from '../../../app/redux/actions/collections';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { LIFE_ISP_GET, LIFE_ISP_NODE_ACTION, SET_SELECTED_LIFE_ISP } from '../../../app/redux/actions/life_isp';
import { NewsCardContainer } from '../NewsCardCarousel/style';
import { ColumnCarousel } from '../RubricChangeCarousel/style';
import {getTagColor} from "../../../utils/common";

const LifeIspCardsContainer = ({
  title, type, pushUrl, product, favorites, mostviewed,
  getProducts, getFavorites, getMostViewed, setAsFavoriteProduct, setAsFavoriteLifeIsp, vocabulary,
  setAsFavoriteSmartWorking, setAsFavoriteCompulsaryTraining, setAsFavoriteCollection,
  setSelectedProduct, setSelectedSmartWorking, setSelectedCompulsaryTraining, setSelectedCollection
}) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [route, setRoute] = useState(routes.lifeIsp.path);
  useEffect(() => {
    switch (type) {
      case 'favorites': {
        setRoute(routes.favorites.path);
        if (favorites.length <= 0) getFavorites();
        break;
      }
      case 'most_viewed': {
        setRoute(routes.mostViewed.path);
        if (mostviewed.length <= 0) getMostViewed();
        break;
      }
      default:
        getProducts();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    switch (type) {
      case 'favorites': {
        setProducts(favorites);
        break;
      }
      case 'most_viewed': {
        setProducts(mostviewed);
        break;
      }
      default: {
        const { products: { courses = [], filters: {categories: listCategories = []} = {} } = {} } = product;
        setProducts(courses);
        setCategories(listCategories)
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, favorites, mostviewed]);

  const getTypeGeneralCard = (category_root) => {
    switch (category_root) {
      case 'cc_prodotti':
      case 'cc_vivere_in_isp':
        return GeneralCard.TYPE.PRODUCT;
      case 'cc_formazione_obbligatoria':
        return GeneralCard.TYPE.OBBLIGATORY;
      case 'cc_processi_e_procedure':
        return GeneralCard.TYPE.COLLECTION;
      default:
        return GeneralCard.TYPE.SMARTWORKING;
    }
  };

  const getActionFavorite = (category_root, id) => {
    switch (category_root) {
      case 'cc_prodotti':
        setAsFavoriteProduct(id);
        break;
      case 'cc_vivere_in_isp':
        setAsFavoriteLifeIsp(id);
        break;
      case 'cc_formazione_obbligatoria':
        setAsFavoriteCompulsaryTraining(id);
        break;
      case 'cc_processi_e_procedure':
        setAsFavoriteCollection(id);
        break;
      default:
        setAsFavoriteSmartWorking(id);
        break;
    }
  };

  const getRedirectPath = (item) => {
    console.log('item', item.category_root);
    switch (item.category_root) {
      case 'cc_prodotti':
        setSelectedProduct(item);
        pushUrl(`${routes.products.path}/${item.id}`);
        break;
      case 'cc_formazione_obbligatoria':
        setSelectedCompulsaryTraining(item);
        pushUrl(`${routes.compulsarytraining.path}/${item.id}`);
        break;
      case 'cc_processi_e_procedure':
        setSelectedCollection(item);
        pushUrl(`${routes.collection.path}/${item.id}`);
        break;
      case 'cc_vivere_in_isp':
        setSelectedCollection(item);
        pushUrl(`${routes.lifeIsp.path}/${item.id}`);
        break;
      default:
        setSelectedSmartWorking(item);
        pushUrl(`${routes.smartWorking.path}/${item.id}`);
        break;
    }
  };

  return (
    products && products.length ? (
      <CourseContainer>
        <Container className="p-0">
          <Row>
            <Col lg={12} sm={12}>
              <InfoContainer>
                <HeaderContent>
                  <Title><Arial type="sectionTitle" vocabulary="lifeIspTitle">{getValueVocabulary(vocabulary.lifeIspTitle, 'lifeIspTitle')}</Arial></Title>
                  <SeeAll onClick={() => pushUrl(route)}>
                    <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                    <span className="icon-arrow-right" />
                  </SeeAll>
                </HeaderContent>
              </InfoContainer>
            </Col>
            <ColumnCarousel sm={12}>
              <CustomCarousel config={Settings}>
                {(products || []).slice(0, 12).map(item => (
                  <NewsCardContainer>
                    <GeneralCard
                      key={`product-${item._id}`}
                      item={item}
                      onClickCard={() => getRedirectPath(item)}
                      onClickFavorite={() => getActionFavorite(item.category_root, item.id)}
                      type={getTypeGeneralCard(item.category_root)}
                      colorTag={getTagColor(categories, item.category_id)}
                    />
                  </NewsCardContainer>
                ))}
              </CustomCarousel>
            </ColumnCarousel>
          </Row>
        </Container>
      </CourseContainer>
    )
      : <></>
  );
};

LifeIspCardsContainer.propTypes = {

  title: PropTypes.string,
  type: PropTypes.string,

  // HOC (connect, state)
  product: PropTypes.object,
  favorites: PropTypes.array,
  mostviewed: PropTypes.array,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getFavorites: PropTypes.func.isRequired,
  getMostViewed: PropTypes.func.isRequired,
  setAsFavoriteProduct: PropTypes.func.isRequired,
  setAsFavoriteLifeIsp: PropTypes.func.isRequired,
  setAsFavoriteSmartWorking: PropTypes.func.isRequired,
  setAsFavoriteCompulsaryTraining: PropTypes.func.isRequired,
  setAsFavoriteCollection: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setSelectedSmartWorking: PropTypes.func.isRequired,
  setSelectedCompulsaryTraining: PropTypes.func.isRequired,
  setSelectedCollection: PropTypes.func.isRequired
};

export default connect(
  state => {
    const product = state.lifeIsp;
    const { items: favorites = [] } = state.favorite;
    const { items: mostviewed = [] } = state.mostviewed;
    const { vocabulary } = state.app;
    return {
      product,
      mostviewed,
      favorites,
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getProducts: (filters) => dispatch({
      type: LIFE_ISP_GET._REQUEST, filters, itemPerPage: 100, subset: true
    }),
    getFavorites: () => dispatch({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100 }),
    getMostViewed: () => dispatch({ type: MOST_VIEWED_LIST_GET._REQUEST, itemPerPage: 100 }),
    setAsFavoriteProduct: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    setAsFavoriteLifeIsp: (nid) => dispatch({
      type: LIFE_ISP_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    setAsFavoriteSmartWorking: (nid) => dispatch({
      type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'pill_collection'
    }),
    setAsFavoriteCompulsaryTraining: (nid) => dispatch({
      type: COMPULSARY_TRAINING_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    setAsFavoriteCollection: (nid) => dispatch({
      type: COLLECTION_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    setSelectedProduct: (selected) => dispatch({ type: SET_SELECTED_LIFE_ISP, selected }),
    setSelectedSmartWorking: (selected) => dispatch({ type: SET_SELECTED_SMARTWORKING, selected }),
    setSelectedCompulsaryTraining: (selected) => dispatch({ type: SET_SELECTED_COMPULSARY_TRAINING, selected }),
    setSelectedCollection: (selected) => dispatch({ type: SET_SELECTED_COLLECTION, selected })
  })
)(LifeIspCardsContainer);
