import styled from 'styled-components';

const Title = styled.div`
  position: absolute;
  bottom: 56px;
  left: 20px;
  color: ${props => props.theme.colors.white};
  min-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.white};
  margin-bottom: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Duration = styled.div`
  color: ${props => props.theme.colors.white};
`;

const Icon = styled.span`
  font-size: 10px;
  color: ${props => props.theme.colors.gray2};
  margin-right: 6px;
`;

const Time = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${props => props.theme.colors.doveGray};
`;

const CourseCardContainer = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: 280px;
  height: 276px;
  border-radius: 4px;
  margin: auto;
  padding: 20px;  
  transition: ${props => props.theme.constants.transitionBase};

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  ${Icon} {
    color: ${props => props.theme.colors.darkerGrey};
  }

  ${Time} {
    color: ${props => props.theme.colors.darkerGrey};
  }
  
    &:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.3)};
      transition: ${props => props.theme.constants.transitionBase};
    }
    
    &:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      &:before {
        background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
      }
    }
`;

const Category = styled.span`
  padding: 2px 6px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.orange};
  height: 16px;
  width: 90px;
  text-transform: uppercase;
  display:table;

`;

const CollectionContent = styled.div`
  position: absolute;
  top: 20px;
  right: ${props => (props.right ? '20px' : null)};
  left: ${props => (props.left ? '20px' : null)};
  
  span:not(:last-child) {
    margin-right: 6px;
  }
  
  span {
    color: ${props => props.theme.colors.white};
    font-size: 20px;
    transition: ${props => props.theme.constants.transitionBase};
  }
  span:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
  
  ${Category} {
    font-size: 10px;
    font-weight: bold;
  }
`;

const CollectionDetailsFooter = styled.div`
  position: absolute;
  color: ${props => props.theme.colors.white};
  bottom: 22px;
  left: 0;
  padding: 0 10px;
  width: 100%;
  height: 20px;
  display: inline-flex;
  align-items: center;
  
  div {
    width: 50%;
  }
  
  span {
    font-size: 12px;
    margin-right: 5px;
  }
`;

const CourseRequired = styled.div`
  background-color: red;
  color: ${props => props.theme.colors.white};
  border-radius: 0 15px 15px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  padding: 5px 10px;
  top: 110px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  position: absolute;
  top: calc(132px - 18px);
`;

export {
  Category,
  CourseCardContainer,
  Title,
  Description,
  Time,
  CollectionContent,
  CourseRequired,
  ProgressBarContainer,
  CollectionDetailsFooter,
  Duration
};
