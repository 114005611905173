import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import {
  COLLECTION_NODE_ACTION, COLLECTION_LIST_GET, SELECTED_COLLECTION_GET, COLLECTION_COURSES_GET, COLLECTION_COMMENTS_GET, COLLECTION_COMMENTS_SET
} from '../actions/collections';
import collections from '../../api/collections';
import { calcPagination } from '../../../utils/pagination';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* collectionListGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { collection: { total_items: totalCount, count, from } = {} } = yield select(state => state.collections);
    const pagination = calcPagination(subset ? undefined : { count, from, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'processi_procedure', ...filters, ...pagination };
      const { data: { data: response } } = yield call(collections.getCollectionList, complete_filters);
      yield put({ type: COLLECTION_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > collections > collectionListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* collectionListGetWatch() {
  yield takeLatest(COLLECTION_LIST_GET._REQUEST, collectionListGet);
}

function* setSelectedCollection({ id, itemPerPage = 1 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_collection } = yield select(state => state.collections);
    if (Object.keys(selected_collection).length <= 0 && pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'processi_procedure', id, ...pagination };
      const { data: { data: response } } = yield call(collections.getCollectionList, complete_filters);
      yield put({ type: SELECTED_COLLECTION_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > collections > setSelectedCollection', err);
    yield put(push(routes.home.path));
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedCollectionWatch() {
  yield takeLatest(SELECTED_COLLECTION_GET._REQUEST, setSelectedCollection);
}

function* collectionCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(collections.getCollectionCourses, { course_id: collectionId });
      yield put({ type: COLLECTION_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > collections > collectionCoursesGet', err);
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* collectionCoursesGetWatch() {
  yield takeLatest(COLLECTION_COURSES_GET._REQUEST, collectionCoursesGet);
}

function* collectionNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(collections.collectionNodeAction, payload);
      yield put({ type: COLLECTION_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: COLLECTION_LIST_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: COLLECTION_LIST_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_collection } = yield select(state => state.collections);
            if (selected_collection.id) yield put({ type: COLLECTION_COURSES_GET._REQUEST, collectionId: selected_collection.id, loader: false });
          } else {
            yield put({ type: COLLECTION_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > collections > collectionNodeAction', err);
    }
  }
}

function* collectionNodeActionWatch() {
  yield takeLatest(COLLECTION_NODE_ACTION._REQUEST, collectionNodeAction);
}

function* collectionCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(collections.getCollectionComments, { id });
      yield put({ type: COLLECTION_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > collections > collectionCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* collectionCommentsGetWatch() {
  yield takeLatest(COLLECTION_COMMENTS_GET._REQUEST, collectionCommentsGet);
}

function* collectionCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(collections.setCollectionComments, payload);
    yield put({ type: COLLECTION_COMMENTS_SET._SUCCESS });
    yield put({ type: COLLECTION_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > collection > collectionCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* collectionCommentsSetWatch() {
  yield takeLatest(COLLECTION_COMMENTS_SET._REQUEST, collectionCommentsSet);
}

export default [
  collectionListGetWatch(),
  setSelectedCollectionWatch(),
  collectionCoursesGetWatch(),
  collectionNodeActionWatch(),
  collectionCommentsGetWatch(),
  collectionCommentsSetWatch()

];
