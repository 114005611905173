import produce from 'immer';

import {
  SELECTED_STC_IMPROVED_GET,
  STC_IMPROVED_LIST_GET,
  SET_SELECTED_STC_IMPROVED, STC_IMPROVED_COMMENTS_GET, STC_IMPROVED_PILLS_GET
} from '../actions/stc_improved';

const initialState = {
  stcImproved: {},
  selectedStcImproved: {},
  pills: [],
  comments: [],
  testLink: ''
};

const stcImprovedReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case STC_IMPROVED_LIST_GET._SUCCESS: {
      const { response, subset } = action;
      const { items: pills } = response;
      const items = pills || [];
      const list = subset || !state.stcImproved.items || state.stcImproved.items <= 0 ? items : [...state.stcImproved.items, ...items];
      draft.stcImproved = {
        ...response,
        items: list
      };
      break;
    }

    case STC_IMPROVED_PILLS_GET._REQUEST: {
      draft.pills = initialState.pills;
      break;
    }

    case STC_IMPROVED_PILLS_GET._SUCCESS: {
      const { response } = action;
      const {
        pill_items, link, previous_pill_collection, next_pill_collection
      } = response[0];
      const uri = link ? link.uri : '';
      draft.pills = pill_items.reduce((acc, val, index, array) => {
        if (!array[index - 1]) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        if (array[index - 1] && (acc[index - 1].stats.status === 'completed')) {
          return [...acc, {
            ...val, blocked: false, index, last_item: index === array.length - 1
          }];
        }
        return [...acc, {
          ...val, blocked: true, index, last_item: index === array.length - 1
        }];
      }, []);
      draft.testLink = uri;
      draft.prevCourse = previous_pill_collection;
      draft.nextCourse = next_pill_collection;
      break;
    }

    case SELECTED_STC_IMPROVED_GET._REQUEST: {
      draft.selectedStcImproved = initialState.selectedStcImproved;
      break;
    }

    case SELECTED_STC_IMPROVED_GET._SUCCESS: {
      const { response } = action;
      const { items } = response;
      draft.selectedStcImproved = items[0] ? items[0] : null;
      break;
    }

    case SET_SELECTED_STC_IMPROVED: {
      const { selected } = action;
      draft.selectedStcImproved = selected;
      break;
    }

    case STC_IMPROVED_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default stcImprovedReducer;
