import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

/*Note: The order of data.labels, data.datesets.data and datea.datesets.backgroundColor
must be in their corresponding information
Sample: https://www.chartjs.org/docs/latest/charts/doughnut.html
*/
const DoughnutChart = ({ config }) => {
  const defaultConfig = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 0
      }
    ],
    ...config
  };
  const options = {
    cutoutPercentage: 90,
    legend: {
      display: false
    }
  };
  return <Doughnut data={defaultConfig} options={options} />;
};

DoughnutChart.propTypes = {
  config: PropTypes.object
};


export default DoughnutChart;
