import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  VideoPlay, ImageItem, VideoContainer, ImageContainer,
  VideoPlayContainer, Overlay, VideoTitle, VideoSubTitle
} from './style';
import Arial from '../../typography/arial';

const Video = ({
  imageUrl, videoUrl, videoTitle, videoSubTitle
}) => {
  const videoRef = useRef();
  const [video, setVideoStatus] = useState(false);
  const playVideo = () => {
    setVideoStatus(true);
    videoRef.current.play();
  };

  return (
    <>
      <VideoContainer>
        <ImageContainer visible={video}>
          <ImageItem src={imageUrl} />
          <Overlay>
            {videoTitle && <VideoTitle><Arial type="videoTitle">{videoTitle}</Arial></VideoTitle> }
            {videoSubTitle && (
            <VideoSubTitle>
              <Arial type="videoSubTitle">
                <div dangerouslySetInnerHTML={{ __html: videoSubTitle }} />
              </Arial>
            </VideoSubTitle>
            )}
            {videoUrl
              && (
              <VideoPlay onClick={() => playVideo()}>
                <span className="icon-video"><span className="path1" /><span className="path2" /></span>
              </VideoPlay>
              )}
          </Overlay>
        </ImageContainer>
        {videoUrl && (
        <VideoPlayContainer visible={video}>
          <video ref={videoRef} controls>
            <source src={videoUrl} type="video/mp4" />
            <track src={videoUrl} kind="captions" />
          </video>
        </VideoPlayContainer>
        )}
      </VideoContainer>
    </>
  );
};

Video.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string,
  videoTitle: PropTypes.string,
  videoSubTitle: PropTypes.string
};

export default Video;
