import styled from 'styled-components';

export const ShareModalContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 300px;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.black};
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin-right: -26px;
  margin-left: -26px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.white50};
    left: 0;
    bottom: 0;
  }
`;

export const Colleague = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.black};
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px 20px;
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    background-color: ${props =>
      props.theme.mixin.rgba(props.theme.colors.gray, 0.3)};
  }

  > div:not(:first-child) {
    margin-left: 10px;
  }

  label {
    span {
      top: -8px;
    }
  }
`;

export const ColleaguesContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  max-height: 270px;
  margin-top: 10px;
  margin-right: -26px;
  margin-left: -26px;

  ${Colleague} {
    border-bottom: 1px solid ${props => props.theme.colors.white50};
  }
  @media ${props => props.theme.device.mobileL} {
    height: initial;
    max-height: 25%;
  }
`;

export const Invite = styled.span`
  background-color: ${props =>
    props.theme.mixin.rgba(props.theme.colors.black, 0.3)};
  border-radius: 12px;
  padding: 4px 6px;
  color: ${props => props.theme.colors.black};
  @media ${props => props.theme.device.mobileL} {
    margin: 0;
  }
`;

export const InvitationsContainer = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  min-height: 40px;
  width: 100%;
  padding: 20px 0px 0px 0px;

  @media ${props => props.theme.device.mobileL} {
    min-height: initial;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  width: 100%;
  button {
    max-width: 200px;
    margin-bottom: 0px;
    width: 150px;
    height: 40px;
  }
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    background: ${props => props.theme.colors.primary.white};

    button {
      margin-right: 10px;
    }
  }
`;

export const IconClose = styled.span`
  top: 20px !important;
  right: 20px !important;
  z-index: 100;
`;

export const ColleagueHeader = styled.div`
  display: flex;
  color: ${props => props.theme.colors.black};
  height: 58px;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.white50};
    left: 0;
    bottom: 0;
  }

  > div {
    padding-left: 62px;
  }
`;

export const Email = styled.div`
  color: ${props => props.theme.colors.brownGrey};

  @media ${props => props.theme.device.mobileL} {
    max-width: 120px;
    overflow-wrap: break-word;
  }
`;

export const Collaborate = styled.div`
  @media ${props => props.theme.device.mobileL} {
    max-width: 87px;
    overflow-wrap: break-word;
  }
`;

export const Flexer = styled.div`
  display: flex;
  padding: 0.3rem 0;
  justify-content: space-between;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
  > div {
    flex: 0 0 28%;

    @media ${props => props.theme.device.mobileL} {
      flex: 1 0 auto;
    }
  }
`;

export const WrapperRow = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const ErrorDates = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${props => props.theme.device.mobileL} {
    justify-content: center;
  }
`;
export const ErrorMsg = styled.div`
  color: ${props => props.theme.colors.orangeDarker};
  margin-top: 5px;
  font-size: 12px;
  font-style: normal;
`;
export const WrapperInput = styled.div`
  width: 100%;
`;

export const WrapperCheckbox = styled.div`
  padding: 4px;
  width: 30%;
  @media ${props => props.theme.device.mobileL} {
    width: initial;
  }
`;

export const WrapperTimePicker = styled.div`
  height: 38px;
  border: solid 1px
    ${({ valid, value, theme }) =>
      valid || !value ? theme.colors.greyMedium : theme.colors.orangeDarker};
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 10px;
  border-radius: 4px;
  color: ${props => props.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: no-wrap;

  input {
    background-color: rgba(0, 0, 0, -0.3);
    color: ${props => props.theme.colors.black};
    border: none;
    flex: 1 0 auto;
    height: 100% !important;
    text-align: center;

    &:focus {
      outline: none;
    }
  }
  @media ${props => props.theme.device.mobileL} {
    margin: 6px 0;
  }
`;

export const IconInput = styled.span`
  font-size: 20px;
`;

export const IconWrapper = styled.div`
  padding: 9px;
  color: ${props => props.theme.colors.black};
  max-height: 100%;
  margin-right: -10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    background-color: ${props => props.theme.colors.white50};
    left: 0;
    top: 10%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 40px;
    padding-left: 9px;
    padding-top: 7px;
  }
`;
