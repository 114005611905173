import styled from 'styled-components';
import { Col, Row } from '../../gridSystem';

const GuidebookSectionContainer = styled.div``;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
`;

const GuidebookSectionTitle = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GuidebookItemsRow = styled(Row)`
  padding: 0 30px;
  width: 100%;
`;

const ColumnGuidebook = styled(Col)`
  padding: 0 10px;
  
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 20px;
  }
`;

export {
  GuidebookSectionContainer, GuidebookSectionTitle, Title, GuidebookItemsRow,
  ColumnGuidebook
};
