import styled from 'styled-components';
import {
  CoursesHeader,
  CoursesHeaderClock,
  CoursesHeaderDuration,
  CoursesHeaderLength,
  CoursesList
} from '../../../app/pages/Collection/style';

export const PillsListContainer = styled.div``;

export const PillsHeader = styled(CoursesHeader)``;

export const PillsHeaderClock = styled(CoursesHeaderClock)``;

export const PillsHeaderLength = styled(CoursesHeaderLength)``;

export const PillsHeaderDuration = styled(CoursesHeaderDuration)`
width: unset;
`;

export const PillsList = styled(CoursesList)``;

export const PillsInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 23px 0 0;
    margin-bottom: 20px;
`;

export const NextPrevCourseContainer = styled.div`
  button {
    margin: 5px 0;
  }
`;
