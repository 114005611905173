import { createAsyncActionType } from './index';

export const COMPULSARY_TRAINING_LIST_GET = createAsyncActionType('compulsarytraining', 'COMPULSARY_TRAINING_LIST_GET');
export const SELECTED_COMPULSARY_TRAINING_GET = createAsyncActionType('compulsarytraining', 'SELECTED_COMPULSARY_TRAINING_GET');
export const SET_SELECTED_COMPULSARY_TRAINING = '@@compulsarytraining/SET_SELECTED_COMPULSARY_TRAINING';
export const SET_SELECTED_FILTERS_COMPULSARY_TRAINING = '@@compulsarytraining/SET_SELECTED_FILTERS_COMPULSARY_TRAINING';
export const COMPULSARY_TRAINING_COURSES_GET = createAsyncActionType('compulsarytraining', 'COMPULSARY_TRAINING_COURSES_GET');

export const COMPULSARY_TRAINING_NODE_ACTION = createAsyncActionType('compulsarytraining', 'COMPULSARY_TRAINING_NODE_ACTION');
export const COMPULSARY_TRAINING_COMMENTS_GET = createAsyncActionType('compulsarytraining', 'COMPULSARY_TRAINING_COMMENTS_GET');
export const COMPULSARY_TRAINING_COMMENTS_SET = createAsyncActionType('compulsarytraining', 'COMPULSARY_TRAINING_COMMENTS_SET');
