import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../gridSystem';
import {
  EditorialQuoteContainer, Image, Quote, QuoteText, Author, Detail,
  IconQuote
} from './style';
import Arial from '../../typography/arial';
import quoteL from '../../assets/img/quote_left@2x.png';
import quoteR from '../../assets/img/quote_right@2x.png';

const EditorialQuote = ({
  content: {
    image, body, personal_data, job_title
  }
}) => (
  <EditorialQuoteContainer>
    <Container>
      <Image img={image?.url} />
      <Quote>
        <IconQuote src={quoteL} />
        <QuoteText>
          <Arial type="quote"><div dangerouslySetInnerHTML={{ __html: body }} /></Arial>
        </QuoteText>
        <IconQuote src={quoteR} />
      </Quote>
      <Author>
        <Arial type="author">{personal_data}</Arial>
      </Author>
      <Detail>
        <Arial type="quoteDetail">{job_title}</Arial>
      </Detail>
    </Container>
  </EditorialQuoteContainer>
);

EditorialQuote.propTypes = {
  content: PropTypes.object
};

export default EditorialQuote;
