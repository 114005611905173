import { createAsyncActionType } from './index';

export const HOME_COLLECTIONS_GET = createAsyncActionType(
  'home',
  'HOME_COLLECTIONS_GET'
);
export const HOME_COURSES_GET = createAsyncActionType(
  'home',
  'HOME_COURSES_GET'
);

export const HOME_WELCOME_MODAL_GET = createAsyncActionType(
  'home',
  'HOME_WELCOME_MODAL_GET'
);

export const HOME_PILLS_GET = createAsyncActionType('home', 'HOME_PILLS_GET');

export const HOME_COURSE_LIST_GET = createAsyncActionType(
  'home',
  'HOME_COURSE_LIST_GET'
);
