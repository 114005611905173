import produce from 'immer';
import PropTypes from 'prop-types';

import { LESSON_CONTENTS_GET } from '../actions/lesson';
import { CourseStates } from '../../../utils/course-state';

export const LessonScormInterface = PropTypes.shape({
  node_scorm_id: PropTypes.number.isRequired,
  file_scorm_id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.string,
  iframe_uri: PropTypes.string.isRequired,
  blocked: PropTypes.oneOf([0, 1]),
  status: PropTypes.oneOf(Object.values(CourseStates)),
  score: PropTypes.string,
  session_time: PropTypes.string
});

export const LessonInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  lessons: PropTypes.objectOf(LessonScormInterface)
});

const initialState = {
  lessons: {}
};

const lessonReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case 'todefine':
      break;
    case LESSON_CONTENTS_GET._SUCCESS: {
      const { lessonId } = action.response;

      draft.lessons[lessonId] = action.response;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default lessonReducer;
