import styled from 'styled-components';

const PillsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: ${props => props.theme.colors.transparent};
`;

const CardContainer = styled.div`
  width: 25%;
  padding-top: 20px;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ResultContainer = styled.div`
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Title = styled.div`
  padding: 20px 0px 0px 0px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primaryBlack};
  
  @media ${props => props.theme.device.tablet} {
    padding-left: 0;
  }
  
  .count {
    font-weight: 900;
  }
`;

export {
  PillsContainer, ResultContainer, Title, CardContainer
};
