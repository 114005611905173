import styled, { css } from 'styled-components';
import Tabs from './index';

export const TabItem = styled.div``;

const funzioneCalcoloStile = ({ theme, tabType, color }) => {
  let style;
  switch (tabType) {
    case Tabs.TYPE.PRIMARY:
      style = css`
        ${TabItem} {
          position: relative;
          height: 40px;
          min-width: 230px;
          padding: 12px;
          display: inline-block;
          border: 2px solid ${theme.colors.primaryColor};
          background-color: ${theme.colors.transparent};
          color: ${theme.colors.primaryColor};
          cursor: pointer;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }

          &.active {
            border: 1px solid ${theme.colors.primaryColor};
            background-color:  ${theme.colors.primaryColor};
            color: ${theme.colors.white};
          }
        }
      `;
      break;
    case Tabs.TYPE.SECONDARY:
    default:
      style = css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow: auto;
        padding-bottom: 7px;
      

        @media ${props => props.theme.device.mobileL} {
          border-bottom: 1px solid ${theme.colors.primaryRed};
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 6px;
          background-color: ${theme.colors.gray};
          z-index: 0;

          @media ${props => props.theme.device.mobileL} {
            display: none;
          }
        }

        ${TabItem} {
          position: relative;
          display: inline-block;
          cursor: pointer;
          width: fit-content;
          margin-top: 10px;
          padding: 10px 0;
          padding-right: 70px;
          white-space: nowrap;
          z-index: 1;
          color: ${color || 'white'};
         

          &:after {
            content: '';
            display: block;
            width: calc(100% - 70px);
            height: 2px;
            position: absolute;
            left: 0;
            bottom: -0.5px;
            background-color: ${theme.colors.transparent};
            transition: ${theme.constants.transitionBase};
          }

          &.active {
            &:after {
              background-color: ${color || theme.colors.white};
            }
          }
        }
      `;
      break;
  }

  return style;
};

export const TabsContainer = styled.div`
  ${props => funzioneCalcoloStile(props)};
`;
