import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer, Background, HeaderWrapper, FooterWrapper, OverviewContainer,
  Text, BoxesContainer, InfoContainer, InfoTitle, InfoDescription,
  Slot, SlotContainer, SlotWrapper, IconWrapper, Title
} from './style';
import ProceduresDefaultCover from '../../../ui/assets/img/agenda.jpg';
import Arial from '../../../ui/typography/arial';
import {
  BackButton, Tabs
} from '../../../ui/atoms';
import { TabsContainer } from '../CompulsaryTraining/style';
import { GuideboookCard, TeacherBox } from '../../../ui/components';
import { GuidebookItemColumn, GuidebookListColumn, RowGuidebook } from '../Guidebook/style';
import AgendaTimeline from '../../../ui/components/AgendaTimeline';
import ShareResults from '../../../ui/atoms/ShareResults';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { TojCarouselContainer } from '../../../ui/components/TojCarousel/style';
import { TOJ_CONTENT_GET } from '../../redux/actions/toj';
import routes from '../../../routes';
import { parseQueryParams } from '../../../utils/queryParams';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

import durationFormatHour from '../../../utils/duration-format-hour';
import ProductCardsContainer from '../../../ui/components/ProductCardsContainer';
import { IconCompleted } from '../Collection/style';


const Toj = ({
  heroImage, mediaIsTablet, mediaIsLaptop, getTojContent, mediaIsPhone,
  selected_toj: {
    id, title, description, materials, course_details, mandatory_survey_id,
    additional_survey_id, course_content, tutors = [], duration, slots, completed,
    cover: { url = ProceduresDefaultCover } = {}, tutee = [], survey_available
  },
  tojId, user_type, pushUrl, vocabulary, location
}) => {
  const { tutee_id } = queryString.parse(location.search);

  useEffect(() => {
    if (user_type === 'tutor') {
      getTojContent({ id: tojId, tutee_id });
    } else {
      getTojContent({ id: tojId });
    }
  }, [getTojContent, tojId, tutee_id, user_type]);

  const defaultTabs = [
    { label: 'Overview', value: 'overview' },
    { label: 'Materiali', value: 'materials' }
  ];

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || url} />
        <Container>
          <HeaderWrapper>
            <BackButton url={routes.integrationagenda.path} />
            <Arial type="newsDetailTitle" configuration={{ size: isMobile ? 35 : 50 }}>{title} {completed ? <IconWrapper><IconCompleted style={{ fontSize: 20 }} className="icon-completato" /></IconWrapper> : null}</Arial>
          </HeaderWrapper>
          <FooterWrapper style={{ bottom: mediaIsTablet ? 0 : '' }}>
            <Arial type="productCardDetail" vocabulary="tojName">{getValueVocabulary(vocabulary.tojName, 'tojName')} | {durationFormatHour(duration)}</Arial>
          </FooterWrapper>
        </Container>
      </HeaderContainer>
      <Container>
        <TabsContainer>
          <Tabs tabs={[...defaultTabs]} onChange={onChangeTab} selected={activeTab} tabType={Tabs.TYPE.PRIMARY} />
        </TabsContainer>
        {activeTab.value === 'overview' && (
          <OverviewContainer>
            <Row>
              <Col lg={mediaIsLaptop ? 12 : 8} md={12} sm={12}>
                <Text>
                  <SlotWrapper>
                    <Arial type="guidebookCategory" configuration={{ color: 'gray' }}>Slots</Arial>
                    <SlotContainer>
                      {(slots || []).map(slot => (
                        <Slot><Arial>Dal {moment(new Date(slot.startDateTimestamp)).format('DD/MM/YYYY')} dalle {moment(new Date(slot.startDateTimestamp)).format('HH:mm')} alle {moment(new Date(slot.endDateTimestamp)).format('HH:mm')}</Arial></Slot>
                      ))}
                    </SlotContainer>
                  </SlotWrapper>
                  <Arial type="tab">{description}</Arial>
                </Text>
                <AgendaTimeline timeline={course_details} />
                {course_content?.length > 0 && (
                  <>
                    <Title type="productCardTitle">Contenuti pensati per te</Title>
                    {mediaIsPhone || mediaIsTablet ? (<ProductCardsContainer productList={course_content} type="course_toj" />
                    ) : (
                      <TojCarouselContainer>
                        <ProductCardsContainer productList={course_content} type="course_toj" isAgenda />
                      </TojCarouselContainer>
                    )}

                  </>
                )}
              </Col>
              <Col lg={mediaIsLaptop ? 12 : 4} md={12} sm={12}>
                {mediaIsTablet || mediaIsLaptop ? (
                  <Container>
                    <BoxesContainer>

                      <Row>
                        <Col>
                          <InfoContainer>
                            <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                            <InfoDescription><Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>Lorem ipsum dolor sit amet</Arial></InfoDescription>
                          </InfoContainer>
                        </Col>
                        <Col>
                            <ShareResults onClick={() => { pushUrl(`${routes.toj.path}/${id}/survey${parseQueryParams({ mandatory_survey_id, additional_survey_id })}`); }} />
                        }
                        </Col>
                      </Row>
                      <Row>
                        <TeacherBox className="teacher-box" style={{ width: '300px' }} tutor={tutors.length ? tutors : tutee} />
                      </Row>


                    </BoxesContainer>
                  </Container>
                ) : (

                  <>
                    <TeacherBox tutor={tutors.length ? tutors : tutee} />
                    <InfoContainer>
                      <InfoTitle>
                        <Arial type="author" configuration={{ textTransform: 'none' }} vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial>
                      </InfoTitle>
                      <InfoDescription>
                        <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }} vocabulary="guidelinesDescription">
                          {getValueVocabulary(vocabulary.guidelinesDescription, 'guidelinesDescription')}
                        </Arial>
                      </InfoDescription>
                    </InfoContainer>
                            <ShareResults onClick={() => { pushUrl(`${routes.toj.path}/${id}/survey${parseQueryParams({ mandatory_survey_id, additional_survey_id })}`); }} disabled={!survey_available} />
                        }
                  </>
                )}
              </Col>
            </Row>

          </OverviewContainer>
        )}
        {activeTab.value === 'materials' && (
          <>
            <GuidebookListColumn md={12} sm={12} lg={12}>
              <RowGuidebook>
                {(materials || []).map((singleMaterials, i) => (
                  <GuidebookItemColumn key={i}>
                    <GuideboookCard guidebook={singleMaterials} />
                  </GuidebookItemColumn>
                ))}
              </RowGuidebook>
            </GuidebookListColumn>
          </>
        )}
      </Container>
    </>
  );
};


Toj.propTypes = {
  tojId: PropTypes.number.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,
  mediaIsPhone: PropTypes.bool.isRequired,
  // HOC (connect, dispatch)
  getTojContent: PropTypes.func.isRequired,
  // HOC (connect, state)
  selected_toj: PropTypes.object,
  location: PropTypes.object
};

export default connect(
  state => {
    const { selected_toj } = state.toj;
    const { data: { user_type } } = state.user;
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      selected_toj,
      user_type,
      vocabulary,
      location
    };
  },
  dispatch => ({
    getTojContent: (filters) => dispatch({ type: TOJ_CONTENT_GET._REQUEST, filters }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(Toj));
