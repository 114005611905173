import React from 'react';
import PropTypes from 'prop-types';

import CourseStatusContainer from './style';
import { CourseStates, getStateColor } from '../../../utils/course-state';
import Roboto from '../../typography/roboto';

const CourseStatus = ({ status }) => (
  <CourseStatusContainer background={status && getStateColor(status).primary} color={status && getStateColor(status).labelColor}>
    <Roboto type="status">{status && getStateColor(status).label}</Roboto>
  </CourseStatusContainer>
);

CourseStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(CourseStates).map(key => CourseStates[key]))
};

export default CourseStatus;
