import React from 'react';
import PropTypes from 'prop-types';
import Typeface, { TypefaceInterface } from './index';
import { FontBase } from '../../const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'intro',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: 1,
  letterSpacing: 'unset',
  size: FontBase
};

const types = {
  1: {
    ...baseConfig
  },
  title: {
    ...baseConfig,
    size: 30,
    lineHeight: 'normal'
  }
};

const Intro = ({ type, configuration, children }) => <Typeface configuration={{ ...types[type], ...configuration }}>{children}</Typeface>;

Intro.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface
};

Intro.defaultProps = {
  type: '1'
};

export default Intro;
