import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import YouTube from 'react-youtube';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  PillsContainer,
  PillContainer,
  PillTitle,
  PillCover,
  MainSection,
  PillElementDetails,
  Tag,
  TagContainer,
  TagWrapper,
  VideoContainer,
  PillsElementCounter,
  ActivityContainer,
  ActivityIcon,
  ActivityDescription,
  IconCompleted
} from './style';
import { BackButton, Button } from '../../../ui/atoms';
import routes from '../../../routes';
import {
  Comments, CourseDetailHeader, CourseDetailInfo, CourseDetailPill
} from '../../../ui/components';
import Gill from '../../../ui/typography/gill';
import {
  SELECTED_STC_ALL_GET, STC_ALL_COMMENTS_GET, STC_ALL_COMMENTS_SET,
  STC_ALL_NODE_ACTION, STC_ALL_PILL_SET_STATS, STC_ALL_PILLS_GET, STC_ALL_SHARE_PILL_COLLECTION
} from '../../redux/actions/stc_all';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { youtube_parser } from '../../../utils/common';

const pillElement = (pill, onClick, isActive = false, index) => {
  const {
    cover, title, blocked, stats
  } = pill;
  const { url = '' } = cover || {};
  return (
    <PillContainer onClick={!blocked && onClick} active={isActive} blocked={blocked}>
      <PillsElementCounter>
        {index + 1}
      </PillsElementCounter>
      <PillCover cover={url} />
      <PillElementDetails>
        <PillTitle type="collectionCourseTitle" title={title}>{title}</PillTitle>
        {(stats.status === 'completed') && <IconCompleted className="icon-completato" />}
      </PillElementDetails>
    </PillContainer>
  );
};

const STCAllPills = ({
  stcAllId, pillId, selectedStcAll, pills, comments,
  setSTCAllComments, getSelectedSTCAll, getSTCAllPills, getSTCAllComments,
  setLike, setDislike, setView, shareModal, setStatSTCAllPill, pushUrl,
  shareCollection, requestedURL, vocabulary, path_contents, testLink
}) => {
  const [pill, setPill] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedSTCAll(stcAllId);
    getSTCAllPills({ collectionId: stcAllId });
    //setView(collectionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stcAllId, setScormFullscreen, path_contents]);

  useEffect(() => {
    getSTCAllComments(pillId);
    setView(stcAllId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId]);

  useEffect(() => {
    setScormFullscreen(false);
    if (pills.length > 0) {
      const isCourseCompleted = [...pills].filter(item => (item.stats.status !== 'completed')).length <= 0;
      const selectedDefaultLO = [...pills].find((item) => item.id === Number(pillId));

      if (isCourseCompleted && !pillId) {
        //If all LOs are completed and there's no selected LO the first LO will be selected
        setPill(pills[0]);
        pushUrl(`${routes.stcAll.path}/${stcAllId}/${pills[0].id}`);
      } else if (isCourseCompleted && selectedDefaultLO) {
        //If all LOs are completed and there's a selected LO
        setPill(selectedDefaultLO);
      } else if (!isCourseCompleted && selectedDefaultLO && !selectedDefaultLO.blocked) {
        // If not all LOs are completed and there's selected LO that is also not blocked
        setPill(selectedDefaultLO);
      } else {
        // Select the first LO available if the conditions above are not meet
        const firstAvailableLO = [...pills].reduce((acc, val) => {
          if (!acc && val.stats.status !== 'completed' && !val.blocked) return val;
          return acc;
        }, null);
        if (firstAvailableLO?.id) {
          setPill(firstAvailableLO);
          pushUrl(`${routes.stcAll.path}/${stcAllId}/${firstAvailableLO.id}`);
        } else {
          setPill(pills[0]);
          pushUrl(`${routes.stcAll.path}/${stcAllId}/${pills[0].id}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId, pills]);

  const {
    category, duration, title: stcAllTitle, description: stcAllDescription
  } = selectedStcAll;

  const {
    description, tag, title
  } = pill;

  const percentage = ((pills.filter(item => item.stats.status === 'completed').length / pills.length) * 100).toFixed(0);

  const openPill = ({ id, stats: { status }, blocked }) => {
    //if (status !== 'completed') setStatSTCAllPill({ collectionId: stcAllId, pillId: id });
    pushUrl(`${routes.stcAll.path}/${stcAllId}/${id}`);
  };

  const handleNewCommentOnSubmit = (value) => {
    setSTCAllComments({
      id: pill?.id,
      subject: pill?.title,
      comment_body: value
    });
  };

  const windowOpen = (uri) => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.stcAll.path}/${stcAllId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  const onClickSetStatSTCAllPill = ({ id, stats: { status } }, url) => {
    if (status !== 'completed') setStatSTCAllPill({ collectionId: stcAllId, pillId: id });
    if (url) window.open(url, 'blank');
    pushUrl(`${routes.stcAll.path}/${stcAllId}`);
  };

  const youtubeOpts = {
    height: '315',
    width: '560',
    playerVars: {
      autoplay: 1
    }
  };

  console.log('requestedUrl', requestedURL?.pathname);

  return (
    <PillsContainer>
      <Container className="p-0">
        <BackButton />
        <CourseDetailHeader
          title={stcAllTitle}
          description={stcAllDescription}
          category={category}
          percentage={percentage}
          onClick={() => shareModal({ onClickConfirm: (value) => shareCollection({ receiver_user_id: value, node_id: stcAllId, configuration_type: 'pill_collection' }) })}
        />
        <MainSection>
          <Row>
            <Col sm={12} md={8} lg={9}>
              {pill ? (
                <>
                  <VideoContainer isIframe={pill.source_type === 'iframe'}>
                    {
                      pill.source_type === 'video' && <Player src={pill.video?.url} onEnded={() => onClickSetStatSTCAllPill(pill)} autoplay />
                    }
                    {
                      pill.source_type === 'iframe' && (
                      <div className="rwd-video">
                        <YouTube videoId={youtube_parser(pill.url_source.uri)} opts={youtubeOpts} onStateChange={({ data }) => (data === 0 ? onClickSetStatSTCAllPill(pill) : '')} />
                      </div>
                      )
                    }
                    {
                      pill.source_type === 'document' && (
                      <ActivityContainer>
                        <ActivityIcon className="icon-right" />
                        <ActivityDescription>
                          <Gill type="activityLinkDescription" vocabulary="activityDocumentDescription">{getValueVocabulary(vocabulary.activityDocumentDescription, 'activityDocumentDescription')}</Gill>
                          <Button
                            btnText={getValueVocabulary(vocabulary.btnActivityButton, 'btnActivityButton')}
                            onClick={() => onClickSetStatSTCAllPill(pill, pill.document.url)}
                            height={30}
                          />
                        </ActivityDescription>
                      </ActivityContainer>
                      )
                    }
                    {
                      pill.source_type === 'link' && (
                        <ActivityContainer>
                          <ActivityIcon className="icon-mappamondo" />
                          <ActivityDescription>
                            <Gill type="activityLinkDescription" vocabulary="activityLinkDescription">{getValueVocabulary(vocabulary.activityLinkDescription, 'activityLinkDescription')}</Gill>
                            <Button
                              btnText={getValueVocabulary(vocabulary.btnActivityButton, 'btnActivityButton')}
                              onClick={() => onClickSetStatSTCAllPill(pill, pill.url_source.uri)}
                              height={30}
                            />
                          </ActivityDescription>
                        </ActivityContainer>
                      )
                    }
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnOnClick={() => windowOpen(pill?.url_source?.uri)}
                    source={pill}
                    views={selectedStcAll?.views}
                    title={title}
                    description={description}
                    onClickLike={() => setLike(pill.id)}
                    onClickDislike={() => setDislike(pill.id)}
                  />
                  {tag?.length ? (
                    <TagContainer>
                      <Gill type="tagTitle">TAG</Gill>
                      <TagWrapper>
                        {tag.map(t => <Tag key={t.id}><Gill type="tagText">{t.label}</Gill></Tag>)}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments list={comments} onSubmit={handleNewCommentOnSubmit} />
                </>
              ) : null}
            </Col>
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={pills.length}
                durationPill={duration}
                vocabulary={vocabulary}
                isCompleted={selectedStcAll?.status === 'completed'}
                testLink={testLink}
              >
                {pills.map((c, index) => <React.Fragment key={`pill-${c.id}-${index}`}>{pillElement(c, () => openPill(c), c.id?.toString() === pillId?.toString(), index)}</React.Fragment>)}
              </CourseDetailPill>
            </Col>
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </PillsContainer>
  );
};

STCAllPills.propTypes = {
  stcAllId: PropTypes.string.isRequired,
  pillId: PropTypes.string,

  // HOC (connect, state)
  selectedStcAll: PropTypes.object,
  pills: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,

  // HOC (connect, dispatch)
  setSTCAllComments: PropTypes.func.isRequired,
  shareCollection: PropTypes.func.isRequired,
  getSelectedSTCAll: PropTypes.func.isRequired,
  getSTCAllPills: PropTypes.func.isRequired,
  getSTCAllComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  setStatSTCAllPill: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      selectedStcAll, pills, comments, testLink
    } = state.stc_all;
    const { requestedURL, vocabulary, path_contents } = state.app;
    return {
      selectedStcAll, pills, comments, requestedURL, vocabulary, path_contents, testLink
    };
  },
  dispatch => ({
    setSTCAllComments: (params) => dispatch({ type: STC_ALL_COMMENTS_SET._REQUEST, ...params }),
    shareCollection: (data) => dispatch({ type: STC_ALL_SHARE_PILL_COLLECTION._REQUEST, data }),
    getSelectedSTCAll: (id) => dispatch({ type: SELECTED_STC_ALL_GET._REQUEST, id }),
    getSTCAllPills: ({ collectionId }) => dispatch({ type: STC_ALL_PILLS_GET._REQUEST, collectionId }),
    getSTCAllComments: (id) => dispatch({ type: STC_ALL_COMMENTS_GET._REQUEST, id }),
    setLike: (nid) => dispatch({
      type: STC_ALL_NODE_ACTION._REQUEST, nid, action_type: 'like', node_type: 'pill_item'
    }),
    setDislike: (nid) => dispatch({
      type: STC_ALL_NODE_ACTION._REQUEST, nid, action_type: 'dislike', node_type: 'pill_item'
    }),
    setView: (nid) => dispatch({ type: STC_ALL_NODE_ACTION._REQUEST, nid, action_type: 'views' }),
    setStatSTCAllPill: ({ collectionId, pillId }) => dispatch({ type: STC_ALL_PILL_SET_STATS._REQUEST, collectionId, pillId }),
    shareModal: (data) => dispatch({ type: MODAL_OPEN, id: ShareModal.id, data }),
    pushUrl: url => dispatch(push(url))
  })
)(STCAllPills);
