const colors = theme => {
  let color = '';
  switch (theme) {
    case 'themeGreen':
      color = '#005256';
      break;
    case 'themeBlack':
      color = '#323232';
      break;
    case 'themeBlue':
      color = '#256ca5';
      break;
    case 'ThemeRed':
      color = '#DA291C';
      break;
    default:
      color = '#ffffff';
  }

  return {
    // Primary

    /* Main */
    primaryColor: color,
    primary: '#0C304E',
    primaryDark: '#3D597A',
    primaryMedium: '#6B86A9',
    primaryLight: '#C4CFDD',
    primaryLighter: '#F4F7FC',
    secondary: '#7CB342',
    secondaryDark: '#659533',
    secondaryMedium: '#9CCC65',
    secondaryLight: '#F5FAF0',
    primaryBlack: '#000',
    black: '#222222',
    primaryRed: '#DA291C',
    white: '#ffffff',
    body_bg: '#f2f5fa',
    brownGrey: '#989898',
    darkGrassGreen: '#268900',
    transparent: 'transparent',
    veryLightPink: '#e3e3e3',
    darkGreen: '#002325',
    lightGreen: 'rgba(0, 54, 57, 0.7);',
    lighterGreen: '#003639',
    lighterOpaGreen: '#003639b3',
    lightBorderGreen: '#255a5c',
    accentGreen: '#007176',
    black30: 'rgba(0, 0, 0, 0.3)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black40: 'rgba(0, 0, 0, 0.4)',
    black50: 'rgba(0, 0, 0, 0.5)',
    black05: 'rgba(34, 34, 34, 0.5)',
    black07: 'rgba(63, 63, 63, 0.7)',
    greenFluo: '#02b97f',
    greenFluoLighter: '#399EA3',
    greenFluoLight: 'rgb(34 110 86 / 50%)',
    white50: 'rgba(255, 255, 255, 0.5)',
    lightDarkerGreen: '#264345',
    lightGray: '#999999',
    darkerGray: '#4A4F53',
    border: '#C0C0C0',

    /* Secondary */
    armadillo: '#4A453B',
    orange: '#FC4C02',
    prune: '#9A3324',
    yellow: '#FFC107',
    amber: '#FFBE0A',
    orangeDark: '#F5A623',
    orangeDarker: '#E55D33',
    red: '#d0635c',
    redError: '#e30613',
    darkRed: '#660606',
    salem: '#077a42',
    japaneseLaurel: '#008800',
    greenLight: '#79bf69',
    azureDark: '#2196F3',
    azureLight: '#90CAF9',
    purple: '#9b006f',
    teaLeaf: '#009CA6',
    teaLeafLight: '#11b7c2',
    biscuit: '#D1CCBD',
    biscuit25: '#F3F2EE',
    biscuit50: '#F9F9F7',
    mustard: '#F2A900',
    mustardLight: 'rgba(242, 169, 0, 0.7)',

    /* Grey */
    mineShaft: '#3E3E3E',
    mineShaft2: '#232323',
    greyDark: '#222323',
    doveGray: '#323232',
    doveGray2: '#6f6f6f',
    silver: '#C8C8C8',
    greyMedium: '#cecece',
    greyLight: '#e8e8e8',
    greyLighter: '#959595',
    lightGrey: '#D8D8D8',
    gray: '#5c5c5c',
    gray2: '#7F7F7F',
    lighterGrey: '#f8f8f8',
    darkerGrey: '#3c3c3c',
    silverGrey: '#cccccc',
    santasGray: '#9ea0a5',
    hazeGray: '#e4e6e5',
    wildSand: '#F4F4F4',
    athensGray: '#E4E7EB',
    alto: '#DFDFDF',
    gallery: '#EEEEEE',

    /* Form */
    disabled: '#a0a8b0',

    /* Shadow */
    borderShadow: '0 0 10px 0 rgba(0,0,0,0.01), 0 5px 20px 0 rgba(0,0,0,0.08)',

    /* Main color gradient */
    mainColorGradient:
      'linear-gradient(40deg,rgba(218,41,28,1) 40%,rgba(118,23,6,1) 100%)',
    calendarGradient:
      'linear-gradient(137deg,rgba(218,41,28,1) 40%,rgba(118,23,6,1) 100%)'
  };
};

export default colors;
