import produce from 'immer';
import {
  PROCESSES_PROCEDURES_COMMENTS_GET,
  PROCESSES_PROCEDURES_COURSES_GET,
  PROCESSES_PROCEDURES_LIST_GET,
  SELECTED_PROCESSES_PROCEDURES_GET, SET_FILTERS_PROCESSES_PROCEDURES,
  SET_SELECTED_PROCESSES_PROCEDURES
} from '../actions/processesprocedures';
import {LOCATION_CHANGE} from "redux-first-history";
import routes from "../../../routes";

const initialState = {
  processes_procedures: {},
  selected_processes_procedures: {},
  scorms: [],
  comments: [],
  isLoadingData: false,
  selected_filters: {
    reload: true
  }
};

const processesProceduresTrainingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {

    case LOCATION_CHANGE: {
      const {payload} = action;
      if(payload.pathname.indexOf(routes.processesProcedures.path) < 0){
        draft.selected_filters = initialState.selected_filters;
      }
      break;
    }

    case PROCESSES_PROCEDURES_LIST_GET._SUCCESS: {
      const { response, processType } = action;
      const { from, courses } = response;
      const list = (!from || from <= 0) ? courses : [...state.processes_procedures[processType].courses, ...courses];
      draft.processes_procedures = {
        ...state.processes_procedures,
        [processType]: {
          ...response,
          courses: list
        }
      };
      break;
    }

    case PROCESSES_PROCEDURES_COURSES_GET._REQUEST: {
      draft.isLoadingData = true;
      break;
    }

    case PROCESSES_PROCEDURES_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      draft.isLoadingData = initialState.isLoadingData;
      break;
    }

    case SELECTED_PROCESSES_PROCEDURES_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_processes_procedures = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_PROCESSES_PROCEDURES: {
      const { selected } = action;
      draft.selected_processes_procedures = selected;
      break;
    }

    case PROCESSES_PROCEDURES_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case SET_FILTERS_PROCESSES_PROCEDURES: {
      const { filters } = action;
      draft.selected_filters = filters;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default processesProceduresTrainingReducer;
