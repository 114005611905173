import { createAsyncActionType } from './index';

export const STC_IMPROVED_LIST_GET = createAsyncActionType('stc_improved', 'STC_IMPROVED_LIST_GET');
export const SET_SELECTED_STC_IMPROVED = '@@stc_improved/SET_SELECTED_STC_IMPROVED';
export const SELECTED_STC_IMPROVED_GET = createAsyncActionType('stc_improved', 'SELECTED_STC_IMPROVED_GET');
export const STC_IMPROVED_PILLS_GET = createAsyncActionType('stc_improved', 'STC_IMPROVED_PILLS_GET');
export const STC_IMPROVED_NODE_ACTION = createAsyncActionType('stc_improved', 'STC_IMPROVED_NODE_ACTION');
export const STC_IMPROVED_COMMENTS_GET = createAsyncActionType('stc_improved', 'STC_IMPROVED_COMMENTS_GET');
export const STC_IMPROVED_COMMENTS_SET = createAsyncActionType('stc_improved', 'STC_IMPROVED_COMMENTS_SET');
export const STC_IMPROVED_PILL_SET_STATS = createAsyncActionType('stc_improved', 'STC_IMPROVED_PILL_SET_STATS');
export const STC_IMPROVED_SHARE_PILL_COLLECTION = createAsyncActionType('stc_improved', 'STC_IMPROVED_SHARE_PILL_COLLECTION');