import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CommentContainer, CommentsContainer, CommentsWrapper,
  CommentWrapper, TextArea, Timestamp, UserAvatar, Text, CommentBody,
  CommentInfo, CommentsHeader, MaxLength
} from './style';
import Arial from '../../typography/arial';
import formatTimestamp from '../../../utils/formatTimestamp';

const SurveyComments = ({
  list = [], onChange
}) => {
  const [newComment, setNewComment] = useState('');


  const handleOnchangeNewComment = ({ target: { value } }) => {
    setNewComment(value);

    if (onChange) onChange(value);
    
  };

  return (
    <CommentsContainer>
      <CommentsHeader>
        <CommentsWrapper>
          <TextArea  maxLength={250} onChange={handleOnchangeNewComment} value={newComment} placeholder="Scrivi un commento" />

            <MaxLength>
              <Arial type="newsDate">Max 250 caratteri</Arial>
            </MaxLength>
        </CommentsWrapper>
      </CommentsHeader>
      <CommentContainer>
        {list.map((item = {}, index) => (
          <CommentWrapper key={`comments${index}`}>
            <UserAvatar avatar={item?.user_picture} />
            <CommentBody>
              <CommentInfo>
                <Arial type="newsTitle">{`${item?.field_name} ${item?.field_surname}`}</Arial>
                <Timestamp>
                  <Arial type="newsDate">{formatTimestamp(item?.created)}</Arial>
                </Timestamp>
              </CommentInfo>
              <Text>
                <Arial type="guidebookCategory"><span dangerouslySetInnerHTML={{ __html: item?.comment_body }} /></Arial>
              </Text>
            </CommentBody>

          </CommentWrapper>
        ))}

      </CommentContainer>

    </CommentsContainer>
  );
};

SurveyComments.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired
};

export default SurveyComments;
