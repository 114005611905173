import { takeLatest, put, call } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  STATISTICS_ACTIVITIES_GET, STATISTICS_COURSES_PROGRESS_GET, STATISTICS_SCORES_GET,
  STATISTICS_PERFORMANCES_GET, STATISTICS_RECENT_COURSES_GET, STATISTICS_RESULTS_SET
} from '../actions/statistics';

import statistics from '../../api/statistics';

// stats/type=[courses, activities, performance, scores, recent_courses]

function* statisticsGet({ statType, needLoader = false, months }) {
  try {
    if (needLoader) yield put({ type: LOADER_OPEN });
    if (!statType) return;

    const { data: { data: response } = {} } = yield call(statistics.getStatistics, statType, { months });
    yield put({ type: STATISTICS_RESULTS_SET, response: { data: response, statType } });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > statistics > statisticsGet', err);
  } finally {
    if (needLoader) yield put({ type: LOADER_CLOSE });
  }
}

function* activitiesGetWatch() {
  yield takeLatest(STATISTICS_ACTIVITIES_GET, statisticsGet);
}

function* performanceGetWatch() {
  yield takeLatest(STATISTICS_PERFORMANCES_GET, statisticsGet);
}

function* scoresGetWatch() {
  yield takeLatest(STATISTICS_SCORES_GET, statisticsGet);
}

function* recentCoursesGetWatch() {
  yield takeLatest(STATISTICS_RECENT_COURSES_GET, statisticsGet);
}

function* coursesProgressGetWatch() {
  yield takeLatest(STATISTICS_COURSES_PROGRESS_GET, statisticsGet);
}

export default [
  activitiesGetWatch(),
  performanceGetWatch(),
  scoresGetWatch(),
  recentCoursesGetWatch(),
  coursesProgressGetWatch()
];
