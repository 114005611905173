import styled from 'styled-components';

const InfoCourseContainer = styled.div`
  width: 780px;
  height: 73px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.01);
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  position: relative;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

const TextElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 109px 0 20px;

  @media ${props => props.theme.device.mobileL} {
    padding: 20px;
  }
`;

export { InfoCourseContainer, TextElement, TextWrapper };
