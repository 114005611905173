import {
  takeLatest, put, call, select
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  COURSE_DETAILS_GET,
  RECENT_COURSES_GET,
  COURSES_TABLE_CONTENTS_GET,
  COURSE_DETAILS_SET,
  COURSE_FAVORITE_GET,
  COURSE_COMMENTS_GET,
  COURSE_COMMENTS_SET
} from '../actions/course';

import course from '../../api/course';
import { calcPagination } from '../../../utils/pagination';
import routes from '../../../routes';
import { shouldUseMock } from '../../../utils/common';

function* courseDetailsGet({ courseId }) {
  try {
    yield put({ type: LOADER_OPEN });

    if (!courseId) {
      return;
    }

    const { data: { data: { courses = [] } = {} } = {} } = yield call(course.getCourseDetails, courseId);

    const response = courses[courses.findIndex(c => c.id.toString() === courseId.toString())];
    if (response) {
      yield put({ type: COURSE_DETAILS_SET, response });
    } else if (!shouldUseMock()) {
      yield put(push(routes.home.path));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > course > courseDetailsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseDetailsGetWatch() {
  yield takeLatest(COURSE_DETAILS_GET, courseDetailsGet);
}

function* recentCoursesGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const response = yield call(course.getRecentCourses);
    yield put({
      type: RECENT_COURSES_GET._SUCCESS,
      response: response.data.courses
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > course > recentCoursesGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* recentCoursesGetWatch() {
  yield takeLatest(RECENT_COURSES_GET._REQUEST, recentCoursesGet);
}

function* tableCourseListGet({ itemPerPage = 10 }) {
  try {
    const { total_items: totalCount, count, from } = yield select(state => state.course.courses_table_contents || {});
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response } = {} } = yield call(course.getCoursesTableContents, pagination);

      yield put({
        type: COURSES_TABLE_CONTENTS_GET._SUCCESS, response
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > course > tableCourseListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* tableCourseListGetWatch() {
  yield takeLatest(COURSES_TABLE_CONTENTS_GET._REQUEST, tableCourseListGet);
}

function* courseFavoriteGet({ itemPerPage = 100 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { favorites: { total_items: totalCount, count, from } = {} } = yield select(state => state.course);
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const { data: { data: response } } = yield call(course.getFavoriteCourses, pagination);
      yield put({ type: COURSE_FAVORITE_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > news > getNewsFavorite', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseFavoriteGetWatch() {
  yield takeLatest(COURSE_FAVORITE_GET._REQUEST, courseFavoriteGet);
}

function* courseCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(course.setCourseComments, payload);
    yield put({ type: COURSE_COMMENTS_SET._SUCCESS });
    yield put({ type: COURSE_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > course > courseCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseCommentsSetWatch() {
  yield takeLatest(COURSE_COMMENTS_SET._REQUEST, courseCommentsSet);
}

function* courseCommentsGet({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: comments } } = yield call(course.getCourseComments, { id });
    yield put({ type: COURSE_COMMENTS_GET._SUCCESS, comments, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > course > courseCommentsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseCommentsGetWatch() {
  yield takeLatest(COURSE_COMMENTS_GET._REQUEST, courseCommentsGet);
}

export default [
  courseDetailsGetWatch(),
  recentCoursesGetWatch(),
  tableCourseListGetWatch(),
  courseFavoriteGetWatch(),
  courseCommentsSetWatch(),
  courseCommentsGetWatch()
];
