import produce from 'immer';
import {
  WHAT_CHANGE_COMMENTS_GET,
  WHAT_CHANGE_COURSES_GET,
  WHAT_CHANGE_GET,
  SELECTED_WHAT_CHANGE_GET,
  SET_SELECTED_WHAT_CHANGE
} from '../actions/what_change';

const initialState = {
  products: {},
  selected_product: {},
  scorms: [],
  comments: [],
  most_viewed: {},
  favorites: {},
  isLoadingData: false
};

const whatChangeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case WHAT_CHANGE_GET._SUCCESS: {
      const { response } = action;
      const { from, courses } = response;
      const list = (!from || from <= 0) ? courses : [...state.products.courses, ...courses];
      draft.products = {
        ...response,
        courses: list
      };
      break;
    }

    case WHAT_CHANGE_COURSES_GET._REQUEST: {
      draft.isLoadingData = true;
      break;
    }

    case WHAT_CHANGE_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      draft.isLoadingData = initialState.isLoadingData;
      break;
    }

    case SELECTED_WHAT_CHANGE_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_product = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_WHAT_CHANGE: {
      const { selected } = action;
      draft.selected_product = selected;
      break;
    }

    case WHAT_CHANGE_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default whatChangeReducer;
