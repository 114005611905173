import styled, { css, keyframes } from 'styled-components';
import { Col, Row } from '../../../ui/gridSystem';

const LoadEnter = keyframes`
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateX(0);
  }
`;

const GuidebookContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: ${props => props.theme.colors.transparent};
  position: relative;

  @media ${props => props.theme.device.tablet} {
    padding-top: 20px;
  }
`;

const CategoryColumn = styled(Col)`
  border-right: 1px solid ${props => props.theme.colors.white};
  @media ${props => props.theme.device.tablet} {
    border-right: 0;
  }
`;

const GuidebookListColumn = styled(Col)``;

const GuidebookItemColumn = styled.div`
  margin: 0 0 10px 20px;
  width: calc(50% - 20px);
  animation: ${LoadEnter} 0.3s;
  @media ${props => props.theme.device.tablet} {
    width: calc(50% - 30px);
  }
  @media ${props => props.theme.device.mobileL} {
    min-width: 100%;
    width: 100%;
  }
`;

const Category = styled.span`
  color: ${props => props.theme.colors.white};
  width: 100%;
  display: block;
  margin-bottom: 20px;
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      text-shadow: -0.06ex 0 white, 0.06ex 0 white;
    `};
`;

const RowGuidebook = styled(Row)`
  min-width: 100%;
  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
  }
`;

export {
  GuidebookContainer,
  Category,
  CategoryColumn,
  GuidebookListColumn,
  GuidebookItemColumn,
  RowGuidebook
};
