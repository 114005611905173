import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  InputContainer,
  Error,
  InputWrapper,
  Label,
  IconWrapper
} from './style';
import { RegexpType, validator } from '../../../utils/validator';
import Gill from '../../typography/gill';

const CustomInput = ({
  onBlur,
  onChange,
  placeholder,
  value,
  name,
  type,
  valid,
  readonly,
  autoFocus,
  disabled,
  min,
  max,
  error,
  label,
  icon,
  id,
  className,
  autoComplete
}) => {
  const [val, setVal] = useState(value);
  const [focus, setFocus] = useState(false);

  const toggleFocus = () => setFocus(s => !s);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const updateValue = ({ currentTarget: { value: newValue } = {} }) => {
    if (newValue.length <= 0 || !validator(RegexpType.SPACEONLY, newValue)) {
      setVal(newValue);
      if (onChange) onChange(newValue.trim());
    }
  };

  const handleOnBlur = () => {
    toggleFocus();
    if (onBlur) onBlur(val);
  };

  const getDefaultError = () => 'Errore: il campo non è valido';

  return (
    <InputWrapper>
      <InputContainer
        valid={readonly || valid}
        value={val}
        readonly={readonly}
        disabled={disabled}
        error={error}
        focus={focus}
      >
        {type === 'date' && label && (
          <Label>
            <Gill type="description">{label}</Gill>
          </Label>
        )}
        <Input
          id={id}
          className={className}
          autoFocus={autoFocus}
          type={type}
          onBlur={handleOnBlur}
          onFocus={toggleFocus}
          onChange={updateValue}
          placeholder={readonly ? '-' : placeholder}
          value={val}
          name={name}
          readonly={readonly}
          disabled={disabled}
          min={min}
          max={max}
          label={label}
          autoComplete={autoComplete}
        />
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </InputContainer>
      {!valid && error ? (
        <Error>
          <Gill type="error">
            {typeof error === 'string' ? error : getDefaultError()}
          </Gill>
        </Error>
      ) : null}
    </InputWrapper>
  );
};

CustomInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  Icon: PropTypes.node,
  autoComplete: PropTypes.string
};

CustomInput.defaultProps = {
  autoFocus: false,
  error: 'Il valore inserito non è valido',
  placeholder: '',
  readonly: false,
  type: 'text',
  valid: true,
  autoComplete: 'on'
};

export default CustomInput;
