import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 50%;
`;

const Chart = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${props => props.theme.device.tablet} {
    left: 40%;
    width: 240px;
  }
  
  @media ${props => props.theme.device.mobileL} {
    left: 45%;
    width: 260px;
  }
`;

const ProgressCourseContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    height: 125px;
    width: 125px;
    left: 40%;
  }

  @media ${props => props.theme.device.mobileL} {
    left: 45%;
  }
`;

const CourseNumber = styled.span`
  color: ${props => props.theme.colors.black};
`;

const CourseIcon = styled.span`
  font-size: 32px;
  color: ${props => props.theme.colors.black};
`;

const CourseActive = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.black};
  
  &:first-letter {
    text-transform: capitalize;
  }
`;

const ProgressBarContainer = styled.div`
  width: 50%;
  
  div:not(:last-child){
    .progress-content {
      border-bottom: 1px solid ${props => props.theme.colors.greyMedium};
    }
  }

  .progress-content {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }
`;

const NumberItem = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${props => props.theme.colors.black};
`;

const StateLabel = styled.div`
  margin-bottom: 8px;
  color: ${props => props.theme.colors.black};
  
  &:first-letter {
    text-transform: capitalize;
  }
`;

export {
  Container,
  ChartContainer,
  ProgressCourseContainer,
  Chart,
  CourseNumber,
  CourseIcon,
  CourseActive,
  ProgressBarContainer,
  NumberItem,
  StateLabel
};
