import { createAsyncActionType } from './index';

export const STC_COURSE_LIST_GET = createAsyncActionType(
  'stc_course',
  'STC_COURSE_LIST_GET'
);
export const SET_SELECTED_STC_COURSE = '@@stc_course/SET_SELECTED_STC_COURSE';
export const SELECTED_STC_COURSE_GET = createAsyncActionType(
  'stc_course',
  'SELECTED_STC_COURSE_GET'
);
export const STC_COURSE_PILLS_GET = createAsyncActionType(
  'stc_course',
  'STC_COURSE_PILLS_GET'
);
export const STC_COURSE_NODE_ACTION = createAsyncActionType(
  'stc_course',
  'STC_COURSE_NODE_ACTION'
);
export const STC_COURSE_COMMENTS_GET = createAsyncActionType(
  'stc_course',
  'STC_COURSE_COMMENTS_GET'
);
export const STC_COURSE_COMMENTS_SET = createAsyncActionType(
  'stc_course',
  'STC_COURSE_COMMENTS_SET'
);
export const STC_COURSE_PILL_SET_STATS = createAsyncActionType(
  'stc_course',
  'STC_COURSE_PILL_SET_STATS'
);
export const STC_COURSE_SHARE_PILL_COLLECTION = createAsyncActionType(
  'stc_course',
  'STC_COURSE_SHARE_PILL_COLLECTION'
);
export const PATH_DETAILS_GET = createAsyncActionType(
  'stc_course',
  'PATH_DETAILS_GET'
);
