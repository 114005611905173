import styled from 'styled-components';
import { EditorialCard } from '..';

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: ${props => props.theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin-bottom: 35px;
  transition: ${props => props.theme.constants.transitionBase};
  min-height: 80px;
  
  >div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const EditorialCardDetails = styled.div`
  position: relative;
`;

const Description = styled.div`
  min-height: 32px;
  margin-bottom: 10px;
  transition: ${props => props.theme.constants.transitionBase};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

const EditorialCardImage = styled.div`
  background-image: url(${props => (props.img)});
  background-color: ${props => props.theme.colors.black}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  height: 300px;
  padding: 40px 40px 25px;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  &:hover {
    transform: scale(0.98);
    opacity: 0.6;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: ${props => props.theme.constants.transitionBase};
    
    background-color: ${props => props.theme.colors.black40};
  }
`;

const EditorialCardContainer = styled.div`
  position: relative;
  padding: 10px;
  width: ${props => (props.type ? props.type === EditorialCard.TYPE.LARGE ? '67%' : '33%' : '100%')};
  ${Title} {
    max-width: ${props => (props.type === EditorialCard.TYPE.LARGE ? '400px' : '150px')};
  }
    
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  };

`;

export {
  EditorialCardContainer,
  EditorialCardDetails, Title, Description,
  EditorialCardImage
};
