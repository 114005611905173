import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  InfoContainer,
  StatisticsContainer,
  StatisticsInfoContainer,
  StatsWrapper,
  ChartWrapper
} from './style';
import {
  AccessStatistic,
  CommonCard,
  UserInfo,
  LineChart,
  Table
} from '../../../ui/components';
import { CourseProgress, CourseStatus, CustomSelect } from '../../../ui/atoms';
import genGraphConfig from '../../../utils/generate-graph-config';
import { Container } from '../../../ui/gridSystem';

import {
  STATISTICS_ACTIVITIES_GET,
  STATISTICS_COURSES_PROGRESS_GET,
  STATISTICS_PERFORMANCES_GET,
  STATISTICS_RECENT_COURSES_GET
} from '../../redux/actions/statistics';
import {
  STAT_TYPE,
  StatisticsActivitiesInterface,
  StatisticsCoursesInterface,
  StatisticsRecentCoursesInterface
} from '../../redux/reducers/statistics';
import { USER_INFO_GET } from '../../redux/actions';
import { UserDataInterface } from '../../redux/reducers/user';
import routes from '../../../routes';
import dateFormat from '../../../utils/date-format';
import { COURSE_DETAILS_SET } from '../../redux/actions/course';
import { getLabelByKey } from '../../../utils/dashboard';

const Dashboard = ({
  courses,
  activities,
  vocabulary,
  recent_courses,
  user,
  recentCoursesGet,
  coursesProgressGet,
  activitiesGet,
  pushUrl,
  getUserInfo
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserInfo();
    activitiesGet();
    recentCoursesGet();
    coursesProgressGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let activityGraph = {};
  if (activities) {
    activityGraph = genGraphConfig(activities.data, 'completed');
  }

  const [courseOptions, setCourseOptions] = useState([]);
  useEffect(() => {
    if (courses && Object.keys(courses)?.length > 0) {
      const opts = Object.keys(courses).map(k => ({
        label: getLabelByKey(k),
        value: k
      }));
      setCourseOptions(opts);
    }
  }, [courses]);

  const [selectedCourse, setSelectedCourse] = useState({});
  useEffect(() => {
    if (courses?.total) setSelectedCourse(courses?.total);
  }, [courses]);

  const handleOnchangeSelect = newValue => {
    const course = courses[newValue?.value];
    if (course && Object.keys(course)?.length > 0) setSelectedCourse(course);
  };

  const getDuration = time => {
    const hours = (time / 60).toFixed(0);
    const minutes = time % 60;
    if (hours > 0 && minutes > 0) {
      return `${hours > 1 ? `${hours} ore ` : `${hours} ora `}${minutes} min`;
    }
    if (hours > 0 && minutes === 0) {
      return `${hours > 1 ? `${hours} ore ` : `${hours} ora `}`;
    }
    return `${minutes} min`;
  };

  const getRedirectPath = ({ id, path }) => {
    pushUrl(`${routes.course.path}/${path}/${id}`);
  };

  const [dataset, setDataset] = useState(Table.createData([]));
  const newColumn = ({ title, field, cell }) => ({ title, field, cell });
  const cols = [
    newColumn({ title: 'Nome contenuto', field: 'title' }),
    newColumn({
      title: 'Stato',
      field: 'status',
      cell: ({ status }) => <CourseStatus status={status} />
    }),
    newColumn({
      title: 'Completamento',
      field: 'completion_date',
      cell: ({ completion_date }) =>
        completion_date
          ? moment(new Date(completion_date * 1000)).format('DD/MM/YYYY')
          : '-'
    }),
    newColumn({
      title: 'Tempo',
      field: 'duration',
      cell: ({ duration }) => getDuration(duration)
    })
  ];

  useEffect(() => {
    if (recent_courses?.data)
      setDataset(Table.createData(recent_courses.data || []));
  }, [recent_courses]);

  return (
    <Container className="p-0">
      <StatisticsContainer>
        <InfoContainer>
          <UserInfo />
          <AccessStatistic
            isLoading={!(user && user.id)}
            title="Ultima attività"
            description={
              user && user.last_accessed_time
                ? dateFormat(Number(user.last_accessed_time))
                : '-'
            }
            icon="icon-clock"
          />
          <AccessStatistic
            isLoading={!(user && user.id)}
            title="Ultimo login"
            description={
              user && user.last_login_time
                ? dateFormat(Number(user.last_login_time))
                : '-'
            }
          />
        </InfoContainer>
        <StatisticsInfoContainer>
          <CommonCard
            title="Avanzamento contenuti"
            isLoading={!courses}
            rightContent={
              <CustomSelect
                placeholder="Tutti"
                options={courseOptions}
                onChange={handleOnchangeSelect}
                isClearable
                // className="signup-select"
              />
            }
          >
            {courses && (
              <StatsWrapper>
                <CourseProgress data={selectedCourse} />
              </StatsWrapper>
            )}
          </CommonCard>
          <CommonCard
            noPadding
            isLoading={!activities}
            title="Attività"
            description="Numero di contenuti completati - Ultimi 12 mesi"
          >
            {activities && (
              <ChartWrapper>
                <LineChart config={activityGraph} />
              </ChartWrapper>
            )}
          </CommonCard>
          <CommonCard
            noPadding
            isLoading={!recent_courses}
            title={`I miei ${
              recent_courses ? recent_courses.data.length : 0
            } più recenti`}
          >
            {recent_courses?.data && (
              <Table
                columns={Table.createColumns(cols)}
                data={dataset}
                onRowClick={getRedirectPath}
              />
            )}
          </CommonCard>
        </StatisticsInfoContainer>
      </StatisticsContainer>
    </Container>
  );
};

Dashboard.propTypes = {
  // HOC (connect, state)
  courses: StatisticsCoursesInterface,
  activities: StatisticsActivitiesInterface,
  recent_courses: StatisticsRecentCoursesInterface,
  user: UserDataInterface,

  // HOC (connect, dispatch)
  activitiesGet: PropTypes.func.isRequired,
  recentCoursesGet: PropTypes.func.isRequired,
  coursesProgressGet: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  vocabulary: PropTypes.object
};

export default connect(
  state => {
    const {
      courses,
      activities,
      performance,
      scores,
      recent_courses
    } = state.statistics;
    const { vocabulary } = state.app;
    return {
      courses,
      activities,
      performance,
      scores,
      recent_courses,
      user: state.user.data,
      vocabulary
    };
  },
  dispatch => ({
    getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST }),
    activitiesGet: params =>
      dispatch({
        type: STATISTICS_ACTIVITIES_GET,
        params,
        statType: STAT_TYPE.ACTIVITIES,
        months: 12
      }),
    performanceGet: params =>
      dispatch({
        type: STATISTICS_PERFORMANCES_GET,
        params,
        statType: STAT_TYPE.PERFORMANCE
      }),
    recentCoursesGet: params =>
      dispatch({
        type: STATISTICS_RECENT_COURSES_GET,
        params,
        statType: STAT_TYPE.RECENT_COURSES
      }),
    coursesProgressGet: params =>
      dispatch({
        type: STATISTICS_COURSES_PROGRESS_GET,
        params,
        statType: STAT_TYPE.COURSES
      }),
    courseDetailsSet: collection =>
      dispatch({ type: COURSE_DETAILS_SET, collection }),
    pushUrl: url => dispatch(push(url))
  })
)(Dashboard);
