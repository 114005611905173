import React from 'react';
import PropTypes from 'prop-types';

import { DoughnutChart } from '../../components';
import { Col, Row } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  Container,
  ChartContainer,
  ProgressCourseContainer,
  Chart,
  CourseNumber,
  CourseIcon,
  CourseActive,
  ProgressBarContainer,
  NumberItem,
  StateLabel
} from './style';
import ProgressBar from '../ProgressBar';

import { getStateColor } from '../../../utils/course-state';

const chartConfig = data => {
  const dataset = Object.keys(data).reduce(
    (obj, key) => ({
      ...obj,
      [key]: data[key]
    }),
    {}
  );
  delete dataset.total_courses;
  delete dataset.statType;

  const colors = Object.keys(dataset).map(
    key => getStateColor(key) && getStateColor(key).primary
  );

  return {
    dataset,
    total_items: data.total_courses,
    config: {
      labels: Object.keys(dataset).map(key => getStateColor(key).label),
      datasets: [
        {
          data: Object.values(dataset),
          backgroundColor: colors,
          borderWidth: 0
        }
      ]
    }
  };
};

const CourseProgress = ({ data }) => {
  const formattedData = chartConfig(data);
  return (
    <Container>
      <ChartContainer>
        <ProgressCourseContainer>
          <CourseIcon className="icon-corsi" />
          <CourseNumber>
            <Arial type="newsTitle">{formattedData.total_items}</Arial>
          </CourseNumber>
          <CourseActive>
            <Arial type="newsDescription">totale contenuti</Arial>
          </CourseActive>
        </ProgressCourseContainer>
        <Chart>
          <DoughnutChart config={formattedData.config} />
        </Chart>
      </ChartContainer>

      <ProgressBarContainer>
        {(Object.keys(formattedData.dataset) || []).map(key => (
          <div key={key}>
            <StateLabel>
              <Arial type="description">{getStateColor(key).label}</Arial>
            </StateLabel>
            <Row className="no-gutters progress-content">
              <Col sm={2}>
                <NumberItem>{data[key]}</NumberItem>
              </Col>
              <Col sm={10}>
                <ProgressBar
                  percentage={Math.round(
                    (data[key] / formattedData.total_items) * 100
                  )}
                  state={key}
                  hasPercentage
                />
              </Col>
            </Row>
          </div>
        ))}
      </ProgressBarContainer>
    </Container>
  );
};

CourseProgress.propTypes = {
  data: PropTypes.object
};

CourseProgress.defaultProps = {
  data: {}
};

export default CourseProgress;
