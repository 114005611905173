import produce from 'immer';
import PropTypes from 'prop-types';

import { AUTH_LOGOUT, USER_INFO_GET } from '../actions';

// const USER_ROLES = {
//   ADMIN: 'ADMIN',
//   DEALER: 'DEALER'
// };

export const UserDataInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  surname: PropTypes.string,
  // fiscal_code: PropTypes.string.isRequired,
  username: PropTypes.string,
  mail: PropTypes.string,
  picture: PropTypes.string,
  group: PropTypes.string,
  last_accessed_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  last_login_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  created_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bad_dealer: PropTypes.oneOf([0, 1, '0', '1'])
});

const initialState = {
  data: {},
  isDealer: true
};

const userReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case USER_INFO_GET._SUCCESS: {
        // const { response: { data: response = { roles: [] } } = {} } = action;
        const { response = {} } = action;

        draft.data = response;
        // draft.isDealer = response && response.roles.length ? response.roles.includes(USER_ROLES.DEALER.toLowerCase()) : initialState.isDealer;
        break;
      }

      case AUTH_LOGOUT._SUCCESS: {
        draft.data = initialState.data;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default userReducer;
