import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

/*Note: The order of data.labels, data.datesets
must be in their corresponding information
-datasets is array of objects that contains label => string, data => array of values and backgrounColor => corresponding color
Sample: https://codepen.io/donnyburnside/pen/XgqLEX
*/
const LineChart = ({ datasets, labels, config }) => {

  const data = {
    /* labels,
    datasets: [{
      data: datasets,
      backgroundColor: 'rgba(20, 149, 198, 0.2)'
    }], */
    // labels: ['Gen', 'Feb', 'Mar', 'Apr', 'May', 'Giu', 'Lug', 'Ago', 'Sep'],
    ...config
  };

  const options = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false
  };

  return <Line data={data} options={options} />;
};

LineChart.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.object
};

export default LineChart;
