import React from 'react';

import PropTypes from 'prop-types';
import {
  EditorialContainer
} from './style';
import SwitcherEditorialContent from '../SwitcherEditorialContent';

const Editorial = ({
  content
}) => (
  <EditorialContainer>
    <SwitcherEditorialContent items={content} />
  </EditorialContainer>
);

Editorial.propTypes = {
  content: PropTypes.array.isRequired
};

export default Editorial;
