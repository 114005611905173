import moment from 'moment';

const formatTimestamp = (date) => {
  if (date) {
    const diffSeconds = moment(new Date()).diff(new Date(date), 'seconds');
    const diffMinutes = moment(new Date()).diff(new Date(date), 'minutes');
    const diffHours = moment(new Date()).diff(new Date(date), 'hours');
    const diffDays = moment(new Date()).diff(new Date(date), 'days');
    const diffMonths = moment(new Date()).diff(new Date(date), 'months');
    const diffYears = moment(new Date()).diff(new Date(date), 'years');

    if (diffYears > 0) {
      return `${diffYears} ${diffYears > 1 ? 'anni fa' : 'anno fa'}`;
    }
    if (diffMonths > 0) {
      return `${diffMonths} ${diffMonths > 1 ? 'mesi fa' : 'mese fa'}`;
    }
    if (diffDays > 0) {
      return `${diffDays} ${diffDays > 1 ? 'giorni fa' : 'giorno fa'}`;
    }
    if (diffHours > 0) {
      return `${diffHours} ${diffHours > 1 ? 'ore fa' : 'ora fa'}`;
    }
    if (diffMinutes > 0) {
      return `${diffMinutes} ${diffMinutes > 1 ? 'minuti fa' : 'minuto fa'}`;
    }
    if (diffSeconds > 0) {
      return `${diffSeconds} ${diffSeconds > 1 ? 'secondi fa' : 'secondo fa'}`;
    }
  }

  return '';
};

export default formatTimestamp;
