import styled from 'styled-components';

export const CalendarContainer = styled.div`
  padding-top: 40px;
  

  .fc {
    font-family: Gill;
    a {
      color: black;
    }
  }

  .fc-h-event {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid ${props => props.theme.colors.gray};
  }

  .fc .fc-toolbar-title {
    text-transform: capitalize;
  }
  .fc .fc-today-button {
    display: none;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-right: 15.25rem;
    margin-left: 0px;
  }
  .fc .fc-button-primary {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
  .fc-icon {
    color:black;
  }
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
    background-color: transparent;
  }
  .fc-direction-ltr .fc-button-group > .fc-button {
    background-color: transparent;
  }
  .fc .fc-view-harness {
    //background: ${props => props.theme.colors.biscuit25};
    border-radius: 4px;
    height: 430px !important;
    box-shadow: 1px 2px 17px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 2px 17px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 2px 17px 0px rgba(0,0,0,0.2);
  }
  .fc .fc-view-harness-active > .fc-view {
    padding: 0.5rem;
  }

  .fc .fc-scrollgrid {
    border: none;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }

  .fc .fc-daygrid-day-frame {
    border: 1px solid ${props => props.theme.colors.black};
    border-left: none;
    border-bottom: none;
    cursor: pointer;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 15px;
    font-size: 14px;
    font-weight: normal;
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 2px;
    .fc-daygrid-event-dot {
      display: none;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;

    .fc-daygrid-day-number {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 16px;
      text-align: center;
      padding: 0px;
      margin-top: 4px;
      font-weight: bold;
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.orange};
    }

    &.active-tile {
      background: ${props => props.theme.colors.biscuit25};
    }
  }
  .fc-direction-ltr .fc-daygrid-event {
    .fc-event-time {
      display: none;
    }
    .fc-event-title {
      font-size: 10px;
      font-weight: normal;
      text-overflow: ellipsis;
    }
  }

  .fc .fc-daygrid-day-number {
    margin-right: 5px;
  }

  .fc a {
    color: ${props => props.theme.colors.black};
  }

  .fc-daygrid-day.fc-day-sat {
    background-color: #ebebeb;
  }
  .fc-daygrid-day.fc-day-sun {
    background-color: #ebebeb;
  }

  .fc-daygrid-day .fc-day .fc-day-mon .fc-day-past .fc-day-other {
    opacity: 0.3;
  }

  .fc-daygrid-more-link {
    font-size: 10px;
  }

  .fc-daygrid-day.active-tile {
    background: ${props => props.theme.colors.biscuit25};
  }

  .fc-theme-standard .fc-popover {
    background: ${props => props.theme.colors.black};
  }

  .fc-toolbar-title {
    color: ${props => props.theme.colors.black};
  }

  @media ${props => props.theme.device.tablet} {
    .react-calendar {
      background: ${props => props.theme.colors.primaryColor};
      border: none;
      border-radius: 4px;

      .react-calendar__month-view__days__day--neighboringMonth {
        color: ${props => props.theme.colors.gray}!important;
      }

      button:not(:disabled),
      [type='button']:not(:disabled),
      [type='reset']:not(:disabled),
      [type='submit']:not(:disabled) {
        color: white;
      }
      .react-calendar__navigation {
        background: ${props => props.theme.colors.primaryColor};
        //padding-bottom: 1em;
        margin-bottom: 0px;

        .react-calendar__navigation__label {
          font-size: 26px;
          font-family: Arial;
          text-transform: capitalize;
        }
      }

      .react-calendar__navigation .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation .react-calendar__navigation__next2-button {
        display: none;
      }

      abbr[title],
      abbr[data-original-title] {
        text-decoration: none;
      }

      .react-calendar__month-view__weekdays {
        border-bottom: 1px solid ${props => props.theme.colors.gray};
      }

      .react-calendar__tile--now {
        background: white;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: ${props => props.theme.colors.greenFluo}!important;
      }

      .react-calendar__tile--now .react-calendar__tile--now:enabled:hover,
      .react-calendar__tile--now:enabled:focus {
        background: white !important;
      }

      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        background-color: transparent;
      }
      .react-calendar__month-view__weekdays__weekday {
        padding: 15px 12px 10px 12px;
      }

      .react-calendar__tile--active {
        background: rgba(255, 255, 255, 0.5) !important;
        border-radius: 50%;
      }

      .react-calendar__tile {
        text-align: center;
        height: 32px;
        margin-bottom: 10px;
        max-width: 32px !important;
        margin-left: 14px;
        padding: 0;
        position: relative;

        &.active {
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.brownGrey};
          }
        }
      }

      .react-calendar__tile--active {
        background: transparent;
      }

      .react-calendar__tile--active:enabled:hover,
      .react-calendar__tile--active:enabled:focus {
        background: transparent;
      }

      .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background-color: transparent;
      }

      .react-calendar__tile.active::after {
        left: 45%;
      }
    }
  }
`;

export const BoxContainer = styled.div`
  width: 580px;
  min-height: 430px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.1);
  //background: ${props => props.theme.colors.biscuit25};
  color:black;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-top: 100px;

  @media ${props => props.theme.device.mobileL} {
    box-shadow: none;
    background-color: transparent;
    width: auto;
  }

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const Header = styled.div`
  width: 70%;
  text-transform: capitalize;

  & > div:last-child {
    color: ${props => props.theme.colors.darkGray};
    margin-top: 2px;
    //text-transform: none;
  }

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
  }

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const Body = styled.div`
  padding: 20px;
`;

export const NoResultContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  margin: 15px 0 14px 0;
  padding: 15px 20px 3px 20px;
  align-items: center;
  min-height: 80px;

  > div:first-child {
    flex: 1 0 auto;
    padding-left: 20px;
  }

  & > button {
    white-space: nowrap;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    min-height: unset;
  }
`;
