import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import queryString from 'query-string';
import { Container } from '../../../ui/gridSystem';
import {
  AuthContainer,
  Background,
  FormContainer,
  InputWrapper,
  Subtitle,
  Title,
  LogoContainer,
  Error
} from './styles';
import { Button, Logo } from '../../../ui/atoms';
import heroImage from '../../../ui/assets/img/home_page.jpg';
import Gill from '../../../ui/typography/gill';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { AUTH_LOGIN } from '../../redux/actions';

const SSO_BASE_URL = {
  DEV: 'https://apidevubi.ispfdevelop.com/api/rest_api/sso/login',
  SIT: null,
  PRE_PROD: 'https://apippubi.ispfdevelop.com/api/rest_api/sso/login',
  PROD: 'https://api.isp4u.it/api/rest_api/sso/login',

  DEFAULT: 'http://localhost:3000/auth?status=ko&error_code=sso_error'
};

export const ssoRedirectLink = SSO_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || SSO_BASE_URL.DEFAULT;

const AuthSSO = ({ vocabulary, location, authLoginRequest }) => {
  sessionStorage.setItem('SSO', true);
  const { status, data, error_code } = queryString.parse(location.search);

  if (status === 'ok' && data) {
    const token = data;
    authLoginRequest(token);
  }

  const handleRedirectAuth = () => {
    window.location.href = ssoRedirectLink;
  };

  if (!((status === 'ok' && data) || (status === 'ko' && error_code))) {
    handleRedirectAuth();
  }

  return (
    <AuthContainer>
      <Background img={heroImage} />
      <Container>
        <Gill>
          <Title>
            <Gill type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.authTitle, 'authTitle')}
            </Gill>
          </Title>
          <Subtitle>
            <Gill type="descriptionHome" vocabulary="authDescription">
              {getValueVocabulary(
                vocabulary.authDescription,
                'authDescription'
              )}
            </Gill>
          </Subtitle>
        </Gill>
        <FormContainer>
          <LogoContainer>
            <Logo size={Logo.SIZE.LARGE} />
          </LogoContainer>
          {status === 'ko' && error_code && (
            <Error>
              <Gill type="descriptionHome" vocabulary="sso_error">
                {getValueVocabulary(vocabulary.sso_error, 'sso_error')}
              </Gill>
            </Error>
          )}
          <InputWrapper>
            <Button
              size={Button.SIZE.FULL}
              btnText="Riprova"
              onClick={handleRedirectAuth}
              height={40}
            />
          </InputWrapper>
        </FormContainer>
      </Container>
    </AuthContainer>
  );
};

AuthSSO.propTypes = {
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  authLoginRequest: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      vocabulary,
      location
    };
  },
  dispatch => ({
    authLoginRequest: token => dispatch({ type: AUTH_LOGIN._REQUEST, token, sso: true })
  })
)(AuthSSO);
