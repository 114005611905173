import styled from 'styled-components';

const EditorialQuoteContainer = styled.div`
  min-height: 468px;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Image = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  margin: 0 auto;
`;

const Quote = styled.div`
  margin: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuoteText = styled.div`
  width: 680px;
`;

const Author = styled.div`
  color: ${props => props.theme.colors.veryLightPink};
  margin-bottom: 10px;
`;

const Detail = styled.div`
  color: ${props => props.theme.colors.brownGrey};
`;

const IconQuote = styled.img`
  height: auto;
  width: 60px;
`;

export {
  EditorialQuoteContainer, Image, Quote, QuoteText, Author, Detail, IconQuote
};
