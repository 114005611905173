import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};

  .container-fluid.content-wrapper {
    @media ${props => props.theme.device.tablet} {
      padding: 0 20px;
    }
  }
`;

const CourseActivityContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  margin-bottom: 30px;
  
  @media ${props => props.theme.device.laptop} {
    padding: 30px 20px;
  }
  
  @media ${props => props.theme.device.tablet} {
    padding: 0px 20px 20px 20px;
  }
`;

export { Container, CourseActivityContainer };
