import produce from 'immer';
import {
  ROLE_TRAINING_COMMENTS_GET,
  ROLE_TRAINING_COURSES_GET,
  ROLE_TRAINING_LIST_GET,
  SELECTED_ROLE_TRAINING_GET, SET_FILTERS_ROLE_TRAINING,
  SET_SELECTED_ROLE_TRAINING
} from '../actions/roletraining';
import {LOCATION_CHANGE} from "redux-first-history";
import routes from "../../../routes";

const initialState = {
  role_training: {},
  selected_role_training: {},
  scorms: [],
  comments: [],
  isLoadingData: false,
  selected_filters: {
    reload: true
  }
};

const roleTrainingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {

    case LOCATION_CHANGE: {
      const {payload} = action;
      if(payload.pathname.indexOf(routes.roleTraining.path) < 0){
        draft.selected_filters = initialState.selected_filters;
      }
      break;
    }

    case ROLE_TRAINING_LIST_GET._SUCCESS: {
      const { response, roleTrainingType } = action;
      const { from, courses } = response;
      const list = (!from || from <= 0) ? courses : [...state.role_training[roleTrainingType].courses, ...courses];
      draft.role_training = {
        ...state.role_training,
        [roleTrainingType]: {
          ...response,
          courses: list
        }
      };
      break;
    }

    case ROLE_TRAINING_COURSES_GET._REQUEST: {
      draft.isLoadingData = true;
      break;
    }

    case ROLE_TRAINING_COURSES_GET._SUCCESS: {
      const { scorms } = action;
      draft.scorms = scorms;
      draft.isLoadingData = initialState.isLoadingData;
      break;
    }

    case SELECTED_ROLE_TRAINING_GET._SUCCESS: {
      const { response } = action;
      const { courses } = response;
      draft.selected_role_training = courses[0] ? courses[0] : null;
      break;
    }

    case SET_SELECTED_ROLE_TRAINING: {
      const { selected } = action;
      draft.selected_role_training = selected;
      break;
    }

    case ROLE_TRAINING_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }

    case SET_FILTERS_ROLE_TRAINING: {
      const { filters } = action;
      draft.selected_filters = filters;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default roleTrainingReducer;
