import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { push } from 'redux-first-history';
import { Table, TableCard } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import { CustomSelect, NoResult } from '../../../ui/atoms';
import {
  TitleHeader, HeaderContainer, Filters, NotificationWrapper,
  Title, Info, Description, Type, ReadAll
} from './style';
import Arial from '../../../ui/typography/arial';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { NOTIFICATION_GET, NOTIFICATION_READ } from '../../redux/actions/notifications';
import routes from '../../../routes';
import { Status } from '../../../ui/components/NavBar/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const tableItemLabels = {
  pill_collection: 'Raccolta di pillole',
  news: 'News',
  pill_item: 'Video Pillola',
  course: 'Corso',
  appointment: 'Appuntamento'
};

const Notification = ({
  notifications, types, mediaIsPhone, getNotification, readNotification,
  pushUrl, vocabulary
}) => {
  const [dataset, setDataset] = useState(Table.createData(notifications));
  const [selectedFilters, setSelectedFilters] = useState();

  useEffect(() => {
    setDataset(Table.createData(notifications || []));
  }, [notifications]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNotification({ type: selectedFilters?.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);


  const handleOnClickRow = ({
    id, node_id, type, category_root, status, date
  }) => {
    if (type === 'course' && category_root) {
      switch (category_root) {
        case 'cc_processi_e_procedure': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.processesProcedures.path}/${node_id}`);
          break;
        }
        case 'cc_prodotti_servizi': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.productsServices.path}/${node_id}`);
          break;
        }
        case 'cc_prodotti': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.products.path}/${node_id}`);
          break;
        }
        case 'cc_vivere_in_isp': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.lifeIsp.path}/${node_id}`);
          break;
        }
        case 'cc_cosa_cambia': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.whatChange.path}/${node_id}`);
          break;
        }
        case 'cc_formazione_ruolo': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.roleTraining.path}/${node_id}`);
          break;
        }
        case 'cc_processi_procedure': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.processesProcedures.path}/${node_id}`);
          break;
        }
        case 'cc_formazione_obbligatoria':
        default: {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.compulsarytraining.path}/${node_id}`);
          break;
        }
      }
    } else {
      switch (type) {
        case 'news': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.news.path}/${node_id}`);
          break;
        }
        case 'appointment': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.integrationagenda.path}?selectedDate=${date}`);
          break;
        }
        case 'pill_collection':
        default: {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.smartWorking.path}/${node_id}`);
          break;
        }
      }
    }
  };

  const newColumn = ({
    title, field, cell, width, sortable = true
  }) => ({
    title, field, cell, width, sortable
  });

  const cols = [
    newColumn({
      title: 'Tipologia', field: 'type', cell: (item) => <Info onClick={() => handleOnClickRow(item)}>{item.status === 'not_read' && <Status />}<Type notRead={item.status === 'not_read'}>{tableItemLabels[item.type]}</Type></Info>, width: '15%', sortable: false
    }),
    newColumn({
      title: 'Data', field: 'date', cell: (item) => <Info onClick={() => handleOnClickRow(item)}>{moment(new Date(item.date * 1000)).format('DD/MM/YYYY HH:mm')}</Info>, width: '15%'
    }),
    newColumn({
      title: 'Descrizione', field: 'title', width: '70%', sortable: false, cell: (item) => <Description onClick={() => handleOnClickRow(item)}><Title>{item.title}</Title><Info>{item.message}</Info></Description>
    })
  ];

  const handleReadAll = () => {
    readNotification('all');
  };

  return (
    <Container>
      <NotificationWrapper>
        <HeaderContainer>
          <TitleHeader>
            <Arial type="sectionTitle" vocabulary="notificationsTitle">{getValueVocabulary(vocabulary.notificationsTitle, 'notificationsTitle')}</Arial>
            <ReadAll onClick={() => handleReadAll()}>
              <Arial type="newsAttachment">Segna tutte come già lette</Arial>
            </ReadAll>
          </TitleHeader>
          <Filters>
            <Arial type="newsTitle">Filtra per tipologia</Arial>
            <CustomSelect
              isClearable
              options={types}
              onChange={newValue => setSelectedFilters(newValue)}
            />
          </Filters>
        </HeaderContainer>
        {notifications.length > 0 ? (<>{mediaIsPhone ? (<TableCard type="notifications" data={notifications} onClick={handleOnClickRow} />) : (<Table columns={Table.createColumns(cols)} data={dataset} onRowClick={handleOnClickRow} />)}</>) : (<NoResult text="Nessuna notifica presente" />)}
      </NotificationWrapper>
    </Container>
  );
};

Notification.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  //HOC (connect, state)
  notifications: PropTypes.array,
  types: PropTypes.array,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { notifications: { data: { notifications = [], types = [] } = [] } = {} } = state;
    const { vocabulary } = state.app;
    return {
      notifications,
      types,
      vocabulary
    };
  },
  dispatch => ({
    getNotification: (filters) => dispatch({ type: NOTIFICATION_GET._REQUEST, filters }),
    readNotification: (id) => dispatch({ type: NOTIFICATION_READ._REQUEST, id }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(Notification));
