import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  CourseContainer, InfoContainer, Title, SeeAll, HeaderContent
} from './style';
import { Collections } from '../index';
import routes from '../../../routes';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const CollectionsCardContainer = ({ pushUrl, title = 'Processi e procedure', vocabulary }) => (
  <CourseContainer>
    <Container className="p-0">
      <Row>
        <Col lg={12} sm={12}>
          <InfoContainer>
            <HeaderContent>
              <Title><Arial type="sectionTitle">{title}</Arial></Title>
              <SeeAll onClick={() => pushUrl(routes.procedures.path)}>
                <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                <span className="icon-arrow-right" />
              </SeeAll>
            </HeaderContent>
          </InfoContainer>
        </Col>
        <Col sm={12}>
          <Collections slider />
        </Col>
      </Row>
    </Container>
  </CourseContainer>
);

CollectionsCardContainer.propTypes = {
  title: PropTypes.string,
  //HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => ({
    vocabulary: state.app.vocabulary
  }),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(CollectionsCardContainer);
