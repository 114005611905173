import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../gridSystem';

import Arial from '../../typography/arial';

import {
  SmartWorkingContainer, InfoContainer, Title, SeeAll, HeaderContent
} from './style';
import { CustomCarousel } from '../index';
import { GeneralCard } from '../../atoms';
import routes from '../../../routes';
import { Settings } from '../Collections/settings';
import { SET_SELECTED_SMARTWORKING, SMARTWORKING_NODE_ACTION } from '../../../app/redux/actions/smartworking';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const SmartWorkingCarousel = ({
  pushUrl, smartWorking, setSelectedSmartWorking, setAsFavorite, vocabulary
}) => {
  const { items: list = [] } = smartWorking;

  const handleOnClickCard = (item) => {
    setSelectedSmartWorking(item);
    pushUrl(`${routes.smartWorking.path}/${item.id}`);
  };

  return (
    <SmartWorkingContainer>
      <Container className="p-0">
        <Row>
          <Col lg={12} sm={12}>
            <InfoContainer>
              <HeaderContent>
                <Title><Arial type="sectionTitle" vocabulary="smartWorkingTitle">{getValueVocabulary(vocabulary.smartWorkingTitle, 'smartWorkingTitle')}</Arial></Title>
                <SeeAll onClick={() => pushUrl(routes.smartWorking.path)}>
                  <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                  <span className="icon-arrow-right" />
                </SeeAll>
              </HeaderContent>
            </InfoContainer>
          </Col>
          <Col sm={12}>
            <CustomCarousel config={Settings}>
              {
                list.slice(0, 12).map(item => (
                  <GeneralCard
                    key={`smartworking-${item.id}`}
                    item={item}
                    onClickFavorite={() => setAsFavorite(item.id)}
                    onClickCard={() => handleOnClickCard(item)}
                    type={GeneralCard.TYPE.SMARTWORKING}
                  />
                ))
              }
            </CustomCarousel>
          </Col>
        </Row>
      </Container>
    </SmartWorkingContainer>
  );
};

SmartWorkingCarousel.propTypes = {
  // HOC (connect, state)
  smartWorking: PropTypes.object.isRequired,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  setSelectedSmartWorking: PropTypes.func.isRequired,
  setAsFavorite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { smartWorking } = state.smartworking;
    const { vocabulary } = state.app;
    return {
      smartWorking,
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    setAsFavorite: (nid) => dispatch({ type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'favourite' }),
    setSelectedSmartWorking: (selected) => dispatch({ type: SET_SELECTED_SMARTWORKING, selected })
  })
)(SmartWorkingCarousel);
