/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import {
  FootContainer, FootSection
} from './style';
import { Container } from '../../gridSystem';
import { Logo } from '../../atoms';
import AccentureLogo from '../../assets/img/accentureLogoWhite.png';


const Footer = ({ pushUrl }) => (
  <FootContainer>
    <Container className="content-wrapper">
      <FootSection>
        <Logo size={Logo.SIZE.LARGE} src={AccentureLogo} />
      </FootSection>
    </Container>
  </FootContainer>
);

Footer.propTypes = {

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(Footer);
