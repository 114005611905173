import {
  takeLatest, put, call, select, takeEvery
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import roletraining from '../../api/roletraining';
import {
  ROLE_TRAINING_COMMENTS_GET, ROLE_TRAINING_COMMENTS_SET,
  ROLE_TRAINING_COURSES_GET, ROLE_TRAINING_LIST_GET,
  ROLE_TRAINING_NODE_ACTION, SELECTED_ROLE_TRAINING_GET
} from '../actions/roletraining';
import { FAVORITE_LIST_GET } from '../actions/favorite';
import routes from '../../../routes';

function* roleTrainingListGet({
  itemPerPage = 24, filters = {}, subset = false, loader = true
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { role_training: { [filters.company_cluster]: { total_items: totalCount, courses = [] } = {} } = {} } = yield select(state => state.roletraining);
    const pagination = calcPagination(subset ? undefined : { from: courses.length , totalCount }, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { type: 'formazione_ruolo', ...filters, ...pagination, order_code: 'asc' };
      const { data: { data: response } } = yield call(roletraining.getRoleTrainingList, complete_filters);
      yield put({ type: ROLE_TRAINING_LIST_GET._SUCCESS, response, roleTrainingType: filters.company_cluster || 'all' });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > roletraining > roleTrainingListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* roleTrainingListGetWatch() {
  yield takeEvery(ROLE_TRAINING_LIST_GET._REQUEST, roleTrainingListGet);
}

function* setSelectedRoleTraining({ id, itemPerPage = 1, refresh = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    const { selected_role_training } = yield select(state => state.roletraining);
    if ((selected_role_training && Object.keys(selected_role_training).length <= 0 && pagination && !pagination.isLastPage) || refresh) {
      const complete_filters = { type: 'formazione_ruolo', id, ...pagination };
      const { data: { data: response } } = yield call(roletraining.getRoleTrainingList, complete_filters);
      yield put({ type: SELECTED_ROLE_TRAINING_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > roletraining > setSelectedRoleTraining', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setSelectedRoleTrainingWatch() {
  yield takeLatest(SELECTED_ROLE_TRAINING_GET._REQUEST, setSelectedRoleTraining);
}

function* roleTrainingCoursesGet({ collectionId, loader = true }) {
  if (collectionId) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: { scorms = [] } } = {} } = yield call(roletraining.getRoleTrainingCourses, { course_id: collectionId });
      yield put({ type: ROLE_TRAINING_COURSES_GET._SUCCESS, scorms });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > roletraining > roleTrainingCoursesGet', err);
      yield put(push(routes.home.path));
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
}

function* roleTrainingCoursesGetWatch() {
  yield takeLatest(ROLE_TRAINING_COURSES_GET._REQUEST, roleTrainingCoursesGet);
}

function* roleTrainingNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(roletraining.roleTrainingNodeAction, payload);
      yield put({ type: ROLE_TRAINING_NODE_ACTION._SUCCESS });
      switch (action_type) {
        case 'favourite':
        {
          yield put({ type: ROLE_TRAINING_LIST_GET._REQUEST, loader: false });
          yield put({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100, loader: false });
          break;
        }
        case 'views':
        {
          yield put({ type: ROLE_TRAINING_LIST_GET._REQUEST, loader: false });
          break;
        }
        default:
        {
          if (nid && action_type !== 'favourite') {
            const { selected_role_training } = yield select(state => state.roletraining);
            if (selected_role_training.id) yield put({ type: ROLE_TRAINING_COURSES_GET._REQUEST, collectionId: selected_role_training.id, loader: false });
          } else {
            yield put({ type: ROLE_TRAINING_LIST_GET._REQUEST, loader: false });
          } break;
        }
      }
    } catch (err) {
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > roletraining > roleTrainingNodeAction', err);
    }
  }
}

function* roleTrainingNodeActionWatch() {
  yield takeLatest(ROLE_TRAINING_NODE_ACTION._REQUEST, roleTrainingNodeAction);
}

function* roleTrainingCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(roletraining.getRoleTrainingComments, { id });
      yield put({ type: ROLE_TRAINING_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > roletraining > roleTrainingCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* roleTrainingCommentsGetWatch() {
  yield takeLatest(ROLE_TRAINING_COMMENTS_GET._REQUEST, roleTrainingCommentsGet);
}

function* roleTrainingCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'field_comments' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }]
    };
    yield call(roletraining.setRoleTrainingComments, payload);
    yield put({ type: ROLE_TRAINING_COMMENTS_SET._SUCCESS });
    yield put({ type: ROLE_TRAINING_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > roletraining > roleTrainingCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* roleTrainingCommentsSetWatch() {
  yield takeLatest(ROLE_TRAINING_COMMENTS_SET._REQUEST, roleTrainingCommentsSet);
}

export default [
  roleTrainingListGetWatch(),
  setSelectedRoleTrainingWatch(),
  roleTrainingCoursesGetWatch(),
  roleTrainingNodeActionWatch(),
  roleTrainingCommentsGetWatch(),
  roleTrainingCommentsSetWatch()
];