import {
  call,
  delay,
  put,
  select,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';

import {
  APP_CONFIGURATION_GET,
  APP_STORE_RELOAD,
  FAQ_GET,
  LOADER_CLOSE,
  LOADER_OPEN,
  PATHS_MAPPING_GET,
  USER_INFO_GET,
  VOCABULARY_GET
} from '../actions';
import app from '../../api/app';
//import { NOTIFICATION_GET } from '../actions/notifications';

function* appConfigGet() {
  yield delay(250);
  try {
    yield put({ type: LOADER_OPEN });
    const actualConfig = yield select(state => state.app.appConfig);

    if (!(actualConfig && actualConfig.homeHeroImage)) {
      const { data: response } = yield call(app.getConfig);
      yield put({ type: APP_CONFIGURATION_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > appConfigGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* appConfigGetWatch() {
  yield takeLatest(APP_CONFIGURATION_GET._REQUEST, appConfigGet);
}

function* appReload() {
  try {
    yield put({ type: LOADER_OPEN });
    yield put({ type: VOCABULARY_GET._REQUEST });
    const { authState: { isLogged } = {} } = yield select(state => state.app);
    if (isLogged) {
      yield put({ type: USER_INFO_GET._REQUEST });
      yield put({ type: VOCABULARY_GET._REQUEST, name: 'path_contents' });
      yield put({ type: VOCABULARY_GET._REQUEST, name: 'path_users' });
    }
    //yield put({ type: NOTIFICATION_GET._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > appReload', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* appReloadWatch() {
  yield takeLatest(APP_STORE_RELOAD, appReload);
}

function* faqGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: { categories, items } = {} } = {} } = yield call(
      app.getFaq
    );
    yield put({ type: FAQ_GET._SUCCESS, categories, items });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > faqGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* faqGetWatch() {
  yield takeLatest(FAQ_GET._REQUEST, faqGet);
}

function* vocabularyGet({ name }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data: vocabulary = {} } = {}
    } = yield call(app.getVocabulary, { name });
    yield put({ type: VOCABULARY_GET._SUCCESS, vocabulary, name });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > vocabularyGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* vocabularyGetWatch() {
  yield takeEvery(VOCABULARY_GET._REQUEST, vocabularyGet);
}

function* pathsMappingGet({ pid }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data } = {} } = yield call(app.pathsMappingGet, pid);
    yield put({ type: PATHS_MAPPING_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > pathsMappingGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* pathsMappingGetWatch() {
  yield takeEvery(PATHS_MAPPING_GET._REQUEST, pathsMappingGet);
}

export default [
  appConfigGetWatch(),
  appReloadWatch(),
  faqGetWatch(),
  vocabularyGetWatch(),
  pathsMappingGetWatch()
];
