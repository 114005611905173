import styled from 'styled-components';
import { Col, Row } from '../../gridSystem';

const EditorialContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  padding-bottom: 100px;
`;

const Title = styled(Col)`
  color: ${props => props.theme.colors.white};
  padding-left: 0;
`;

const Subtitle = styled(Col)`
  color: ${props => props.theme.colors.veryLightPink};
  padding: 0;
`;

const Iframe = styled.iframe`
  min-height: 380px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
`;

const EditorialHeader = styled(Row)`
  margin: 22px 0 60px;
`;

const Content = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 40px;
`;

const NewsDetailAttachment = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 30px;
`;

const Icon = styled.span`
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

export {
  EditorialContainer, Title, Iframe, EditorialHeader, Content,
  NewsDetailAttachment, Icon, Subtitle, Image
};
