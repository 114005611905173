import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBar } from '../../atoms';
import {
  HeaderContainer,
  Category,
  ProgressContainer,
  ProgressWrapper,
  Social,
  IconsCompletation,
  TitleShare,
  SocialShare,
  IconViews, Info
} from './style';
import Arial from '../../typography/arial';
import Gill from '../../typography/gill';

const CourseDetailHeader = ({
  title, category, percentage, onClick, eyeIcon, views, durationPill, categoryColor, description
}) => (
  <HeaderContainer>
    {category && (
    <Category categoryColor={categoryColor}>
      <Arial type="categoryPill">{category}</Arial>
    </Category>
    )}
    <TitleShare>
      <Info>
        <Gill type="collectionHeaderTitle">{title}</Gill>
        {description && <Gill type="collectionHeaderDescription">{description}</Gill>}
      </Info>
      <IconsCompletation>
        {eyeIcon && (
          <SocialShare>
            {/*   {onClick &&
              <Share onClick={onClick}>
                <IconShare className="icon-share" />
              </Share>
            } */}

            <Social>
              <IconViews className="icon-visualizzazioni" />{views}
            </Social>
          </SocialShare>
        )}
        <ProgressContainer>
          <ProgressWrapper>
            <Arial type="progressInfo">Completamento</Arial>
            <Arial type="progressInfo">{percentage}%</Arial>
          </ProgressWrapper>
          <ProgressBar percentage={percentage} />
        </ProgressContainer>
      </IconsCompletation>
    </TitleShare>
  </HeaderContainer>
);

CourseDetailHeader.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  category: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CourseDetailHeader;
