import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer
} from '../Modal.style';
import {
  ModalBodyContainer, Video, CloseModal, Source, Track, Title, Subtitle
} from './style';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';

const modalId = 'WelcomeModal';

const WelcomeModal = ({ onClose, modal_data }) => (
  <Modal id={modalId}>
    <Container>
      <ModalGlobalContainer
        onMouseDown={stopEvent}
        onClick={stopEvent}
        width="1080"
        height="560"
      >
        <CloseModal onClick={onClose} className="icon-close" />
        <ModalBodyContainer>
          <Title><Arial type="welcomeModalTitle">{modal_data?.title}</Arial></Title>
          <Subtitle><Arial type="welcomeModalSubtitle">{modal_data?.subtitle}</Arial></Subtitle>
          <Video autoPlay controls>
            <Source src={modal_data?.video?.url} type="video/mp4" />
            <Track src={modal_data?.video?.url} kind="captions" />
          </Video>
        </ModalBodyContainer>
      </ModalGlobalContainer>
    </Container>
  </Modal>
);

WelcomeModal.propTypes = {
  // HOC (connect, state)
  modal_data: PropTypes.object,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

WelcomeModal.id = modalId;

export default connect(
  state => {
    const { data: modal_data } = state.modal;
    return {
      modal_data
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(WelcomeModal);
