import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Spinner } from '../Loader';

import { Container, Row, Col } from '../../gridSystem';

import Roboto from '../../typography/roboto';
import Intro from '../../typography/intro';

import {
  CourseContainer, InfoContainer, Title,
  Subtitle, TitleContent,
  SubtitleContent, CourseCompleted
} from './style';

import { HOME_COURSES_GET } from '../../../app/redux/actions/home';
import Collections from '../Collections';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const CourseCardContainer = ({
  loadingCourses, vocabulary
}) => (
  <CourseContainer>
    <Container className="p-0">
      <Row className="no-gutters">
        <Col sm={12}>
          <InfoContainer>
            <TitleContent>
              <Title><Intro type="title" configuration={{ size: 20 }}>Le tue collection</Intro></Title>
            </TitleContent>
            <SubtitleContent>
              <Subtitle><Roboto type="description" /></Subtitle>
              <CourseCompleted><Roboto type="label">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Roboto></CourseCompleted>
            </SubtitleContent>
          </InfoContainer>
        </Col>
        <Col sm={12}>
          {loadingCourses ? (<Spinner />) : (
            <Collections slider />
          )}
        </Col>
      </Row>
    </Container>
  </CourseContainer>
);

CourseCardContainer.propTypes = {
  loadingCourses: PropTypes.bool,
  vocabulary: PropTypes.object
};

CourseCardContainer.defaultProps = {
  loadingCourses: false
};

export default connect(
  state => ({
    vocabulary: state.app.vocabulary
  }),
  dispatch => ({
    getCourses: () => dispatch({ type: HOME_COURSES_GET._REQUEST })
  })
)(CourseCardContainer);
