/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';

import {
  SearchContainer,
  ResultContainer,
  SectionTitle,
  Icon,
  SearchWord,
  TabsContainer,
  TotalCount,
  TotalCountLabel,
  TotalCountNumber,
  ItemsContainer,
  CardContainer
} from './style';
import { Col, Container } from '../../../ui/gridSystem';
import routes from '../../../routes';
import { Tabs, GeneralCard, NoResult } from '../../../ui/atoms';
import {
  Title,
  HeaderContent,
  InfoContainer
} from '../../../ui/components/ProductCardsContainer/style';
import Arial from '../../../ui/typography/arial';
import { defaultTabsValue } from '../../../utils/common';
import { STC_CONNECTED_NODE_ACTION } from '../../redux/actions/stc_connected';
import { STC_COURSE_NODE_ACTION } from '../../redux/actions/stc_course';
import { SEARCH_STC_COURSES_LIST_GET } from '../../redux/actions/search';
import { getUrl } from '../../../utils/subnavbar';

const Search = ({
  search_word,
  pushUrl,
  vocabulary,
  searchAllCourses,
  setAsFavoriteSTCCourse,
  pathMapping,
  allResults
}) => {
  useEffect(() => {
    if (search_word.length <= 0) {
      pushUrl(routes.home.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathMapping?.length > 0) {
      const path_ids = pathMapping.map(p => p?.path_tid);
      searchAllCourses({ path_ids, title: search_word });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathMapping, search_word]);

  const defaultTabs = defaultTabsValue(vocabulary);
  const [tabs, setTabs] = useState(defaultTabs);
  const [selectedTab, setSelectedTab] = useState(defaultTabs[0]);

  const handleOnchangeTab = value => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (pathMapping?.length > 0 && Object.keys(allResults)?.length > 0) {
      setSelectedTab(defaultTabs[0]);
      const newTabs = Object.keys(allResults)?.map(k => ({
        label: pathMapping.find(p => p?.path_tid === k)?.path_name,
        value: k,
        items: allResults[k]?.total_items || '0'
      }));
      setTabs([...defaultTabs, ...newTabs]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResults, pathMapping]);

  const onClickCardSTCCourse = (item, pathName) => {
    pushUrl(`${getUrl(pathName)}/${item.id}`);
  };

  return (
    <SearchContainer>
      <Container className="p-0">
        <SectionTitle>
          <Icon className="icon-search" />{' '}
          <>
            {Object.values(allResults).reduce(
              (acc, results) => (acc += (results?.items?.length || 0)),
              0
            )}
          </>{' '}
          Risultati per: <SearchWord>{search_word}</SearchWord>
        </SectionTitle>
        <TabsContainer>
          <Tabs
            tabType={Tabs.TYPE.PRIMARY}
            tabs={[...tabs]}
            onChange={handleOnchangeTab}
            selected={selectedTab}
          />
        </TabsContainer>
        {(Object.keys(allResults) || []).map(k => {
          const list = allResults[k]?.items;
          const tabName = pathMapping.find(p => p?.path_tid === k)?.path_name;
          return (
            <div key={`search_result-${k}`}>
              {(selectedTab.value === 'all' || selectedTab.value === k) && (
                <>
                  {list?.length > 0 && (
                    <ResultContainer>
                      <Col lg={12} sm={12}>
                        <InfoContainer>
                          <HeaderContent>
                            <Title>
                              <Arial type="sectionTitle" vocabulary="stcCourse">
                                {tabName}
                              </Arial>
                            </Title>
                          </HeaderContent>
                        </InfoContainer>
                        <TotalCount>
                          <TotalCountLabel>Risultati: </TotalCountLabel>
                          <TotalCountNumber>{list?.length}</TotalCountNumber>
                        </TotalCount>
                      </Col>
                      <Col lg={12} sm={12}>
                        <ItemsContainer listLength={list?.length}>
                          {(list || []).map(item => (
                            <CardContainer key={`stccourse-${item.id}`}>
                              <GeneralCard
                                key={`stc_course-${item.id}`}
                                item={item}
                                onClickFavorite={() => setAsFavoriteSTCCourse(item.id)
                                }
                                onClickCard={() => onClickCardSTCCourse(item, tabName)
                                }
                                type={GeneralCard.TYPE.SMARTWORKING}
                              />
                            </CardContainer>
                          ))}
                        </ItemsContainer>
                      </Col>
                    </ResultContainer>
                  )}
                </>
              )}
            </div>
          );
        })}

        {tabs?.length <= 1 && <NoResult />}
      </Container>
    </SearchContainer>
  );
};

Search.propTypes = {
  search_word: PropTypes.string,

  // HOC (connect, state)
  products: PropTypes.object,
  compulsarytraining: PropTypes.object,
  collections: PropTypes.object,
  news: PropTypes.object,
  vocabulary: PropTypes.object,
  lifeisp: PropTypes.object,
  productsservices: PropTypes.object,
  pathMapping: PropTypes.array,

  // HOC (connect, dispatch)
  searchAllCourses: PropTypes.func.isRequired,
  setAsFavoriteSTCCourse: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { search_word, allResults } = state.search;
    const { vocabulary, paths_mapping: pathMapping } = state.app;
    const {
      data: { user_type }
    } = state.user;

    return {
      search_word,
      vocabulary,
      user_type,
      allResults,
      pathMapping
    };
  },
  dispatch => ({
    searchAllCourses: ({ path_ids, title }) => dispatch({
      type: SEARCH_STC_COURSES_LIST_GET._REQUEST,
      itemPerPage: 30,
      title,
      path_ids
    }),
    setAsFavoriteSTCCourse: nid => dispatch({
      type: STC_COURSE_NODE_ACTION._REQUEST,
      nid,
      action_type: 'favourite'
    }),
    setAsFavoriteSTCConnected: nid => dispatch({
      type: STC_CONNECTED_NODE_ACTION._REQUEST,
      nid,
      action_type: 'favourite'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(Search);
