import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';

import {
  LOADER_OPEN,
  LOADER_CLOSE,
  APP_REQUEST_URL_RESET,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  APP_MENU_TOGGLE,
  APP_CONFIGURATION_GET,
  APP_REQUEST_URL_SET,
  AUTH_RESET_SET,
  APP_STORE_RELOAD,
  FAQ_GET,
  VOCABULARY_GET,
  APP_MENU_NOTIFICATION_TOGGLE,
  APP_NAVIGATION_TOGGLE,
  AUTH_RESET_PASSWORD,
  SIGNUP_FORM_GET,
  GO_TO_STEP,
  SAVE_CREDENTIALS,
  SIGNUP_PATHS_GET,
  SIGNUP_PATH_SAVE,
  SIGNUP_RESET,
  SIGNUP_CHECK_EMAIL,
  AUTH_VALIDATE_OTP,
  STORE_CREDENTIALS,
  PATHS_MAPPING_GET
} from '../actions';

const initialState = {
  loader: {
    instances: 0,
    isSpinning: true
  },
  authState: {
    isLogged: !!localStorage.getItem('X-CSRF-Token'),
    sso: false,
    error: null,
    errorCode: null,
    errorCounter: 0
  },
  requestedURL: null,
  menuMobileOpen: false,
  notificationMenuMobileOpen: false,
  navigationMobile: false,
  appConfig: {
    homeHeroImage: null,
    faqUrl: null
  },
  currentPath: undefined,
  backPath: undefined,
  faq: {
    categories: [],
    items: []
  },
  vocabulary: {},
  path_users: [],
  path_contents: [],
  paths_mapping: [],
  signup: {
    step: 1,
    mailIsInvalid: 0,
    credentials: {},
    paths: [],
    selectedPath: null,
    selectedPathLabel: null,
    signupForm: []
  },
  otp: {
    otpToken: null,
    messageCounter: 0
  }
};

export const AUTH_ERROR = {
  MISSING_PARAMS: 'missing params'
};

const ls = localStorage;

const appReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      draft.menuMobileOpen = initialState.menuMobileOpen;
      draft.notificationMenuMobileOpen = initialState.notificationMenuMobileOpen;
      const { payload } = action;
      const { pathname } = payload;
      draft.navigationMobile = false;
      draft.currentPath = pathname;
      const paths_array = ls.getItem('paths_array') || '';

      // trick to avoid redirect to wrong pages (course without pill_id)
      if (pathname.includes('/course') && pathname.split('/').length === 4) {
        break;
      }

      // set new_path = current puthname
      ls.setItem('new_path', pathname);
      const new_paths_array = paths_array ? paths_array.split(';') : [];
      // if last item of new_paths_array = pathname means that a redirect to same route has occurred ==> it can be ignored and not pushed to new_paths_array
      if (new_paths_array[new_paths_array.length - 1] === pathname) {
        break;
      }

      // else push the new route to new_paths_array
      new_paths_array.push(pathname);
      // just save last two routes
      if (new_paths_array?.length > 2) new_paths_array.shift();
      ls.setItem('paths_array', new_paths_array.join(';'));

      break;
    }

    case APP_STORE_RELOAD: {
      draft.authState.isLogged = !!localStorage.getItem('X-CSRF-Token');
      draft.authState.sso = !!JSON.parse(localStorage.getItem('SSO'));
      break;
    }

    case APP_REQUEST_URL_SET:
      draft.requestedURL = action.requestedURL || action.payload;
      break;

    case APP_MENU_TOGGLE:
      draft.menuMobileOpen = !state.menuMobileOpen;
      break;

    case APP_MENU_NOTIFICATION_TOGGLE:
      draft.notificationMenuMobileOpen = !state.notificationMenuMobileOpen;
      break;

    case APP_NAVIGATION_TOGGLE:
      //draft.notificationMenuMobileOpen = initialState.notificationMenuMobileOpen;
      draft.menuMobileOpen = initialState.menuMobileOpen;
      draft.navigationMobile = !state.navigationMobile;
      break;

    case APP_REQUEST_URL_RESET:
      draft.requestedURL = initialState.requestedURL;
      break;

    case LOADER_OPEN: {
      const i = state.loader.instances > 0 ? state.loader.instances + 1 : 1;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;

      break;
    }
    case LOADER_CLOSE: {
      const i = state.loader.instances >= 1 ? state.loader.instances - 1 : 0;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;

      break;
    }
    case AUTH_VALIDATE_OTP._SUCCESS:
    case AUTH_RESET_PASSWORD._SUCCESS:
    case AUTH_LOGIN._SUCCESS: {
      const { token, sso = false } = action;
      localStorage.setItem('X-CSRF-Token', token);
      localStorage.setItem('SSO', sso);
      draft.authState.isLogged = true;
      draft.authState.sso = sso;
      draft.authState.error = initialState.authState.error;

      break;
    }

    case AUTH_RESET_SET: {
      const { params, token } = action;
      draft.authState = {
        ...state,
        ...params
      };
      sessionStorage.setItem('TMP-X-CSRF-Token', token);
      break;
    }

    case AUTH_LOGOUT._REQUEST:
      sessionStorage.setItem('isLogout', true);
      break;

    case AUTH_LOGOUT._SUCCESS: {
      draft.authState.isLogged = false;
      draft.authState.error = initialState.authState.error;
      localStorage.clear();
      break;
    }

    case AUTH_LOGIN._ERROR: {
      const { data = {} } = action.response;

      if (data.message) {
        draft.authState.error = {
          ...state.authState.error,
          message: data.message
        };
      } else {
        draft.authState.error = {
          ...state.authState.error,
          message: 'Unexpected error'
        };
      }

      draft.authState.isLogged = false;
      localStorage.removeItem('X-CSRF-Token');
      localStorage.removeItem('SSO');
      break;
    }

    case STORE_CREDENTIALS: {
      draft.otp.message = action?.message;
      draft.otp.messageCounter = state.otp?.messageCounter + 1;
      if (action?.otpToken) draft.otp.otpToken = action?.otpToken;
      if (action?.params?.username) {
        draft.otp.username = action.params?.username;
      }
      break;
    }

    case AUTH_VALIDATE_OTP._ERROR: {
      const { response, errorCode } = action;
      console.log('error', response);
      draft.authState.error = response;
      draft.authState.errorCode = errorCode;
      draft.authState.errorCounter = state.authState.errorCounter + 1;
      break;
    }

    case APP_CONFIGURATION_GET._SUCCESS: {
      const { data: { homepage = {}, site = {} } = {} } = action.response;

      draft.appConfig = {
        ...state.appConfig,
        homeHeroImage: homepage['hero-image'],
        faqUrl: site.faq
      };

      break;
    }

    case FAQ_GET._SUCCESS: {
      const { categories, items } = action;
      draft.faq = { categories, items };
      break;
    }

    case GO_TO_STEP: {
      draft.signup.step = action.step;
      break;
    }
    case SIGNUP_CHECK_EMAIL._SUCCESS: {
      if (!action?.data?.email_available) {
        draft.signup.mailIsInvalid = state.signup.mailIsInvalid + 1;
      } else {
        draft.signup.mailIsInvalid = 0;
        draft.signup.step = 2;
      }
      break;
    }
    case SAVE_CREDENTIALS: {
      draft.signup.credentials = action.credentials;
      break;
    }

    case SIGNUP_PATHS_GET._SUCCESS: {
      const { data } = action;
      draft.signup.paths = data?.paths || [];
      break;
    }

    case SIGNUP_PATH_SAVE: {
      draft.signup.selectedPath = action.path?.id;
      draft.signup.selectedPathLabel = action.path?.label;
      break;
    }

    case SIGNUP_FORM_GET._SUCCESS: {
      const { data } = action;
      draft.signup.signupForm = data;
      break;
    }

    case SIGNUP_RESET: {
      draft.signup = { ...initialState.signup };
      break;
    }

    case VOCABULARY_GET._SUCCESS: {
      const { vocabulary = {}, name } = action;
      switch (name) {
        case 'path_contents':
          draft[name] = Object.keys(vocabulary).reduce((acc, key) => {
            const children = vocabulary[key].children
              ? Object.keys(vocabulary[key].children).reduce(
                (a, v) => [...a, { ...vocabulary[key].children[v], id: v }],
                []
              )
              : [];
            return [...acc, { id: key, ...vocabulary[key], children }];
          }, []);
          break;
        case 'path_users':
          draft[name] = vocabulary;
          break;
        default:
          draft.vocabulary = vocabulary;
          break;
      }
      break;
    }

    case PATHS_MAPPING_GET._SUCCESS: {
      draft.paths_mapping = action?.data;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default appReducer;
