import styled from 'styled-components';
import Button from './index';

const ButtonContainer = styled.button`
  width: 100%;
  max-width: ${props =>
    props.size === Button.SIZE.DEFAULT ? '248px' : 'unset'};
  height: ${props => (props.height ? `${props.height}px` : '50px')};
  border-radius: 4px;
  background-color: ${props =>
    props.type === Button.TYPE.PRIMARY
      ? props.theme.colors.primaryColor
      : props.type === Button.TYPE.THIRD
      ? 'rgba(255, 255, 255, 0.3)'
      : props.theme.colors.white};
  color: ${props =>
    props.type === Button.TYPE.PRIMARY
      ? props.theme.colors.white
      : props.type === Button.TYPE.THIRD
      ? props.theme.colors.white
      : props.theme.colors.primaryColor};
  margin: 20px 0;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  border: 1px solid
    ${props =>
      props.type === Button.TYPE.PRIMARY
        ? props.theme.colors.primaryColor
        : props.theme.colors.border};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: ${props => (props.disabled ? 0.6 : 0.8)};
  }

  transition: ${props => props.theme.constants.transitionBase};
  text-transform: uppercase;
`;

const Date = styled.div`
  margin-top: 3px;
`;

export { ButtonContainer, Date };
