import React from 'react';
import PropTypes from 'prop-types';
import Typeface, { TypefaceInterface } from './index';
import { FontBase } from '../../const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  size: FontBase
};

const types = {
  1: {
    ...baseConfig
  },
  2: {
    ...baseConfig,
    fontStyle: 'italic'
  },
  3: {
    ...baseConfig,
    fontWeight: 400
  },
  4: {
    ...baseConfig,
    fontWeight: 500
  },
  5: {
    ...baseConfig,
    fontWeight: 700
  },
  6: {
    ...baseConfig,
    fontWeight: 900
  },
  cardTitle: {
    ...baseConfig,
    fontWeight: 700,
    size: 14,
    lineHeight: 1.14,
    letterSpacing: 'normal'
  },
  cardDescription: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  label: {
    ...baseConfig,
    size: 14,
    fontWeight: 500,
    lineHeight: 'normal'
  },
  introSubtitle: {
    ...baseConfig,
    lineHeight: 1.25,
    letterSpacing: 'normal'
  },
  description: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.29
  },
  status: {
    ...baseConfig,
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textTransform: 'uppercase'
  },
  required: {
    ...baseConfig,
    size: 10,
    fontWeight: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textTransform: 'uppercase'
  },
  infoTitle: {
    ...baseConfig,
    size: 14,
    fontWeight: 'bold',
    lineHeight: 1.13,
    letterSpacing: 'normal'
  },
  courseInfoLabel: {
    ...baseConfig,
    size: 14,
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  courseInfoDetails: {
    ...baseConfig,
    size: 14,
    fontWeight: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal'
  },
  infoLabel: {
    ...baseConfig,
    size: 14,
    fontStyle: 'italic',
    lineHeight: 1.14
  },
  infoDescription: {
    ...baseConfig,
    size: 12,
    fontStyle: 'italic',
    lineHeight: 1.33
  },
  normalText: {
    ...baseConfig,
    size: 14,
    lineHeight: 'normal'
  },
  boldText: {
    ...baseConfig,
    size: 22,
    fontWeight: 'bold',
    lineHeight: 'normal'
  },
  backButton: {
    ...baseConfig,
    size: 12,
    fontWeight: 500,
    lineHeight: 1.33
  },
  menuText: {
    ...baseConfig,
    size: 15.6,
    lineHeight: 1.41
  },
  itemListMobile: {
    ...baseConfig,
    size: 16,
    fontWeight: 'bold'
  },
  footer: {
    ...baseConfig,
    size: 10,
    textAlign: 'center'
  }
};

const Roboto = ({ type, configuration, children, style }) => (
  <Typeface style={style} configuration={{ ...types[type], ...configuration }}>
    {children}
  </Typeface>
);

Roboto.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface
};

Roboto.defaultProps = {
  type: '1'
};

export default Roboto;
