import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import { ModalGlobalContainer, ModalWrapper } from '../Modal.style';
import {
  Button,
  Checkbox,
  CircleImage,
  CustomInput,
  FilterPill
} from '../../../atoms';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import {
  ButtonsContainer,
  Colleague,
  ColleaguesContainer,
  InvitationsContainer,
  Invite,
  ShareModalContainer,
  Title,
  IconClose,
  ColleagueHeader,
  Email,
  Collaborate
} from './style';
import Arial from '../../../typography/arial';
import { newForm, newFormField } from '../../../../utils/form';
import { RegexpType, validator } from '../../../../utils/validator';
import { SEARCH_COLLEAGUE } from '../../../../app/redux/actions/search';

const modalId = 'ShareModal';

const initialForm = newForm([
  newFormField({
    field: 'user',
    required: true,
    type: RegexpType.STRING,
    valid: true
  })
]);

const ShareModal = ({
  colleagues,
  searchColleague,
  onClose,
  modalData,
  userID,
  searchColleagueReset
}) => {
  const [form, setForm] = useState(initialForm);
  const [invitations, setInvitations] = useState([]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = form[field];
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value: newValue,
        valid:
          typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true
      }
    });
    if (newValue.length >= 3) {
      searchColleague(newValue);
    } else if (colleagues.length > 0) {
      searchColleagueReset();
    }
  };

  const handleOnClose = () => {
    setForm(initialForm);
    setInvitations([]);
    searchColleagueReset();
    onClose();
  };

  const isChecked = ({ id }) =>
    !!invitations.find(({ id: ID }) => ID.toString() === id?.toString());

  const colleagueToggle = (colleague = {}) => {
    if (isChecked(colleague)) {
      setInvitations(
        invitations.filter(
          ({ id }) => id?.toString() !== colleague?.id?.toString()
        )
      );
    } else {
      setInvitations([...invitations, colleague]);
    }
  };

  const onClickIconClose = invite => {
    setInvitations(
      invitations.filter(({ id }) => id?.toString() !== invite?.id?.toString())
    );
  };

  const handleOnConfirm = () => {
    const idInvitations = invitations
      .map(invitation => invitation.id)
      .toString()
      .replace(/,/g, ';');
    if (modalData?.onClickConfirm) modalData.onClickConfirm(idInvitations);
    handleOnClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <IconClose className="icon-close" onClick={handleOnClose} />
            <ShareModalContainer>
              <Title>Condividi</Title>
              <CustomInput
                placeholder="Cerca collega..."
                onChange={newValue => handleOnChange('user', newValue)}
                value={form?.user?.value}
                valid={form?.user?.valid}
              />
              <ColleaguesContainer>
                {colleagues.length > 0 && (
                  <ColleagueHeader>
                    <Arial type="quoteDetail">Collaboratore</Arial>
                    <Arial type="quoteDetail">email id</Arial>
                  </ColleagueHeader>
                )}
                {(colleagues || [])
                  .filter(colleague => colleague.id !== userID)
                  .map(colleague => (
                    <Colleague
                      onClick={() => colleagueToggle(colleague)}
                      key={colleague?.id}
                    >
                      <Checkbox
                        checked={isChecked(colleague)}
                        name={colleague.mail}
                      />
                      <CircleImage
                        src={colleague?.picture}
                        name={`${colleague?.name} ${colleague?.surname}`}
                      />
                      <Arial>
                        <Collaborate>
                          {colleague?.name}&nbsp;{colleague?.surname}
                        </Collaborate>
                      </Arial>
                      <Arial type="newsDescription">
                        <Email>{colleague?.mail}</Email>
                      </Arial>
                    </Colleague>
                  ))}
              </ColleaguesContainer>
              <InvitationsContainer>
                {(invitations || []).map(invite => (
                  <Invite key={invite?.id}>
                    <FilterPill
                      label={`${invite?.name} ${invite?.surname}`}
                      value={invite}
                      onRemove={() => onClickIconClose(invite)}
                    />
                  </Invite>
                ))}
              </InvitationsContainer>
              <ButtonsContainer>
                <Button
                  type={Button.TYPE.SECONDARY}
                  onClick={handleOnClose}
                  btnText="Annulla"
                />
                <Button
                  disabled={!invitations.length}
                  onClick={() => handleOnConfirm()}
                  btnText="Condividi"
                />
              </ButtonsContainer>
            </ShareModalContainer>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ShareModal.propTypes = {
  // HOC (connect, state)
  colleagues: PropTypes.arrayOf(PropTypes.object),
  modalData: PropTypes.object,
  userID: PropTypes.number,
  // HOC (connect, dispatch)
  searchColleague: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  searchColleagueReset: PropTypes.func.isRequired
};

ShareModal.id = modalId;

export default connect(
  state => {
    const { colleagueList: colleagues = [] } = state.search;
    const { data: modalData = {} } = state.modal;
    const { data: { id: userID } = {} } = state.user;
    return { colleagues, modalData, userID };
  },
  dispatch => ({
    searchColleague: user =>
      dispatch({ type: SEARCH_COLLEAGUE._REQUEST, user }),
    searchColleagueReset: () =>
      dispatch({ type: SEARCH_COLLEAGUE._SUCCESS, users: [] }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ShareModal);
