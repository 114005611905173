import styled from 'styled-components';
import {
  NavBarHeight,
  NavBarHeightMobile,
  SubNavBarHeight,
  SubNavBarHeightMobile,
  FooterHeight,
  FooterHeightMobile
} from '../../../const';

export const PageContainer = styled.div`
  min-height: calc(
    100vh - ${NavBarHeight}px - ${FooterHeight}px - ${SubNavBarHeight}px
  );
  margin-top: calc(${NavBarHeight}px + ${SubNavBarHeight}px);
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    min-height: calc(
      100vh - ${NavBarHeightMobile}px - ${SubNavBarHeightMobile}px -
        ${FooterHeightMobile}px
    );
    margin-top: 110px;
  }
`;
