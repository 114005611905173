import styled from 'styled-components';
import Row from '../../../ui/gridSystem/Row';

const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: ${props => props.theme.colors.transparent};
  color: ${props => props.theme.colors.black};
`;

const ResultContainer = styled(Row)`
  margin-top: 40px;
  color: ${props => props.theme.colors.black};

  .info-container {
    margin-bottom: 7px;
  }
`;

const SectionTitle = styled.div`
  font-size: 18px;
  padding-top: 50px;
  text-align: center;
  color: ${props => props.theme.colors.black};
`;

const SearchWord = styled.span`
  font-weight: 900;
`;

const Icon = styled.span`
  font-size: 18px;
  color: black;
`;

const TabsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  // Filter Tabs on two row and center alignment
  > div {
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      margin-bottom: 10px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    text-align: center;
  }
`;

const TotalCount = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
`;

const TotalCountLabel = styled.span`
  font-style: italic;
  color: ${props => props.theme.colors.black};
`;

const TotalCountNumber = styled.span`
  font-style: bold;
  color: ${props => props.theme.colors.black};
`;

const ItemsContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

const CardContainer = styled.div`
  min-width: fit-content;

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export {
  SearchContainer,
  ResultContainer,
  SectionTitle,
  Icon,
  SearchWord,
  TabsContainer,
  TotalCount,
  TotalCountLabel,
  TotalCountNumber,
  ItemsContainer,
  CardContainer
};
