import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';
import {
  SEARCH_COACHEE,
  SEARCH_COLLEAGUE,
  SEARCH_LIST_GET,
  SEARCH_STC_COURSES_LIST_GET,
  SEARCH_WORD_SET
} from '../actions/search';

const initialState = {
  search_list: [],
  search_word: '',
  colleagueList: [],
  coachees: [],
  allResults: {}
};

const searchReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        draft.colleagueList = initialState.colleagueList;
        break;
      }

      case SEARCH_LIST_GET._SUCCESS:
        draft.search_list = action.response;
        break;

      case SEARCH_WORD_SET:
        draft.search_word = action.response;
        break;

      case SEARCH_COLLEAGUE._SUCCESS: {
        const { users = [] } = action;
        draft.colleagueList = users;

        break;
      }

      case SEARCH_COACHEE._SUCCESS: {
        const { coachees = [] } = action;
        draft.coachees = coachees;
        break;
      }

      case SEARCH_STC_COURSES_LIST_GET._SUCCESS: {
        draft.allResults = action.response;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default searchReducer;
