import { createSelector } from 'reselect';

const selectCourse = state => state.course.courses;
const selectLesson = state => state.lesson.lessons;
const selectPill = state => state.home.pillsData;

export const selectCurrentPill = createSelector(
  selectPill,
  (_, pillId) => pillId,
  (pillsData, pillId) => {
    if (pillsData.pills && pillsData.pills.length) {
      const pill = pillsData.pills.findIndex(p => Number(p.id) === Number(pillId));
      if (pill !== -1) return pillsData.pills[pill];
    }

    return null;
  }
);

export const selectCurrentCourse = createSelector(
  selectCourse,
  (_, courseId) => courseId,
  (courses, courseId) => courses[courseId]
);

export const selectCurrentLesson = createSelector(
  selectLesson,
  (_, lessonId) => lessonId,
  (lessons, lessonId) => lessons[lessonId] || {}
);

export const selectCurrentScorm = createSelector(
  selectLesson,
  (_, { lessonId, scormId }) => ({ lessonId, scormId }),
  (lessons, { lessonId, scormId }) => {
    const res = lessons[lessonId] || {};
    if (res.scorms && res.scorms.length) {
      const scorm = res.scorms.findIndex(sco => Number(sco.node_scorm_id) === Number(scormId));
      if (scorm !== -1) return res.scorms[scorm];
    }

    return null;
  }
);
