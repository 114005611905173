import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 100vh;
    margin-bottom: -293px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 311px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -72px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 78%;
    top: -165px;
  }
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;

export const Time = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const FooterWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;

  & > button {
    height: 40px;
    width: 180px;
  }

  @media ${props => props.theme.device.tabletL} {
    position: absolute;
    right: 46px;
    width: 206px;
  }
`;

export const OverviewContainer = styled.div``;

export const Text = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
`;

export const SkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;

  & > div {
    margin-right: 10px;
    color: ${props => props.theme.colors.veryLightPink};
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 180px;
    height: 40px;
  }
`;
