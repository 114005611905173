import styled, { css } from 'styled-components';

const Input = styled.input`
  background-color: ${props => props.theme.colors.white};
  border: none;
  width: ${props => (props.type === 'date' && props.label ? 'auto' : '100%')};
  height: 100%;

  :focus {
    outline: none;
  }

  font-family: ${({ theme }) => theme.fontset.gill};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-overflow: ellipsis;
  color: ${props => (props.type === 'date'
    ? props.theme.colors.white
    : props.theme.colors.black)};
  position: ${props => (props.type === 'date' && props.label ? 'absolute' : 'none')};
  top: ${props => (props.type === 'date' && props.label ? '0' : 'none')};
  right: ${props => (props.type === 'date' && props.label ? '10px' : 'none')};
  max-width: ${props => (props.type === 'date' && props.label ? '160px' : 'none')};

  ::placeholder {
    font-weight: 500;
    font-style: italic;
    color: ${props => props.theme.colors.greyDark};
    opacity: 50%;
    text-transform: none;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: 500;
    color: ${props => props.theme.colors.greyDark};
    opacity: 50%;
    text-transform: none;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 500;
    color: ${props => props.theme.colors.greyDark};
    opacity: 50%;
    text-transform: none;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ disabled }) => disabled
    && css`
      cursor: not-allowed;
    `}

  ${({ readonly }) => readonly
    && css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${({ type }) => type === 'fiscalCode'
      && css`
        text-transform: uppercase;
        &::placeholder {
          text-transform: none;
        }
      `}
`;

const InputContainer = styled.div`
  position: relative;
  min-width: 65px;
  height: 38px;
  border: 1px solid
    ${({ valid, value, theme }) => (valid || !value ? theme.colors.border : theme.colors.orangeDarker)};
  background-color: ${props => props.theme.colors.white};
  padding: 0 10px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: no-wrap;

  ${Input} {
    flex: 1 0 auto;
    width: initial;
  }

  @media ${props => props.theme.device.mobileL} {
    //padding: 0 0 0 5px;
    min-width: 80px;
  }

  ${({ disabled, readonly }) => disabled
    && !readonly
    && css`
      cursor: not-allowed;
      opacity: 0.6;
    `};

  ${({ readonly, theme }) => readonly
    && css`
      pointer-events: none;
      border: none;
      background-color: ${theme.colors.custom.transparent};
      padding: 10px 0;
    `};

  ${({ error, theme, valid }) => error
    && !valid
    && css`
      border: 1px solid ${theme.colors.primaryColor};
    `};
`;

const SpanIcon = styled.span`
  position: absolute;
  width: 50px;
  height: 40px;
  right: 0;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  background-color: ${props => (props.hasValue ? props.theme.colors.orangeDarker : 'transparent')};
  color: ${props => (props.hasValue ? props.theme.colors.white : props.theme.colors.white)};
  cursor: pointer;
  font-size: 30px;
`;

const ValidInput = styled.span`
  position: absolute;
  font-size: 35px;
  font-weight: 700;
  top: 50%;
  right: 5px;
  color: ${({ theme }) => theme.colors.green};
  transform: translate(0, -50%);
`;

export const Error = styled.span`
  color: ${props => props.theme.colors.primaryColor};
  display: block;
  margin-top: 5px;
  padding-left: 10px;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.greyMedium};
  position: absolute;
  top: 13px;

  & > div {
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media ${props => props.theme.device.tablet} {
      max-width: 300px;
    }

    @media ${props => props.theme.device.mobileM} {
      max-width: 130px;
    }

    @media ${props => props.theme.device.mobileS} {
      max-width: 100px;
    }
  }
`;

export const IconWrapper = styled.div`
  padding: 9px;
  color: ${props => props.theme.colors.white};
  max-height: 100%;
  margin-right: -10px;
  position: relative;

  @media ${props => props.theme.device.tabletL} {
    min-width: 40px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    background-color: ${props => props.theme.colors.white50};
    left: 0;
    top: 10%;
  }
`;

export {
  Input, InputContainer, SpanIcon, ValidInput
};
