// import { combineReducers } from "redux";
import app from './app';
import collections from './collections';
import home from './home';
import course from './course';
import user from './user';
import statistics from './statistics';
import certification from './certification';
import search from './search';
import lesson from './lesson';
import news from './news';
import guidebook from './guidebook';
import product from './product';
import modal from './modal';
import editorial from './editorial';
import notifications from './notification';
import smartworking from './smartworking';
import compulsarytraining from './compulsarytraining';
import mostviewed from './mostviewed';
import favorite from './favorite';
import lifeIsp from './lifeIsp';
import rubricchange from './rubricchange';
import agenda from './agenda';
import toj from './toj';
import survey from './survey';
import whatChange from './whatChange';
import roletraining from './roletraining';
import processesprocedures from './processesprocedures';
import coachcoachee from './coachcoachee';
import productsservices from './productsservices';
import stc_all from './stc_all';
import stc_connected from './stc_connected';
import stc_course from './stc_course';
import stc_improved from './stc_improved';

export default {
  app,
  collections,
  home,
  course,
  user,
  statistics,
  certification,
  search,
  lesson,
  news,
  guidebook,
  product,
  modal,
  editorial,
  notifications,
  smartworking,
  compulsarytraining,
  mostviewed,
  favorite,
  lifeIsp,
  rubricchange,
  agenda,
  toj,
  survey,
  whatChange,
  roletraining,
  processesprocedures,
  coachcoachee,
  productsservices,
  stc_all,
  stc_connected,
  stc_course,
  stc_improved
};
