import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Container, Checkbox, Label, MenuIcon
} from './style';

const MenuButton = ({ handleOnClick, navigationMobile }) => (
  <Container>
    <Checkbox checked={navigationMobile} onChange={() => {}} />
    <Label onClick={() => handleOnClick()}>
      <MenuIcon>&nbsp;</MenuIcon>
    </Label>
  </Container>
);

MenuButton.propTypes = {
  navigationMobile: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func
};


export default connect(
  state => ({
    navigationMobile: state.app.navigationMobile
  })
)(MenuButton);
