import styled from 'styled-components';

export const CustomCarouselContainer = styled.div`
  .slick-slider {
    .slick-list {
      padding: 0 20px 20px;
      .slick-track {
        margin: 0;
        margin-left: -20px;
      }
    }

    .slick-next,
    .slick-prev {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.13);
      border: solid 3.2px ${props => props.theme.colors.orange};
      background-color: ${props => props.theme.colors.primaryColor};
      z-index: ${props => props.theme.zIndex.box};

      &:before {
        content: '\\e929';
        font-family: 's4b';
        font-weight: bold;
        font-size: 15px;
        width: 16px;
        height: 16px;
        margin: auto;
      }

      &.slick-disabled {
        display: none !important;
      }

      &:hover,
      &:active,
      &:focus {
        color: none;
        outline: none;
        background-color: ${props => props.theme.colors.orange};

        &::before {
          opacity: 0.8;
        }
      }
    }

    .slick-prev {
      left: -16px;
      top: 50%;
      &::before {
        display: block;
        transform: rotate(180deg);
      }
    }

    .slick-next {
      right: -10px;
      top: 50%;
    }

    .slick-dots .slick-active button:before {
      font-size: 8px;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;
