/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import moment from 'moment';

import {
  SEARCH_WORD_SET,
  SEARCH_LIST_GET
} from '../../../app/redux/actions/search';
import {
  APP_CONFIGURATION_GET,
  APP_MENU_NOTIFICATION_TOGGLE,
  APP_MENU_TOGGLE,
  AUTH_LOGOUT,
  APP_NAVIGATION_TOGGLE,
  MODAL_OPEN
} from '../../../app/redux/actions';

import {
  NavBarContainer,
  BackHomeWrapper,
  LoginWrapper,
  IconWrapper,
  ContentWrapper,
  SearchContainer,
  SidebarMenu,
  MenuElement,
  Notifications,
  NotificationHeader,
  NotificationLabel,
  NotificationContent,
  NotificationDate,
  NotificationDescription,
  SeeAll,
  Status,
  IconMenu,
  NotificationMenu,
  Icon,
  InfoDescription,
  CircleName,
  NameContainer,
  MobileIconContainer,
  NameContainerClickable,
  LogoMobileContainer,
  NoResultContainer,
  LogoContainerDesktop
} from './style';

import { Logo, MenuButton, SearchBar, NoResult } from '../../atoms';
import Menu from '../Menu';
import routes from '../../../routes';
import { Container, Row } from '../../gridSystem';
import compose from '../../../utils/compose';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';
import Arial from '../../typography/arial';
import Gill from '../../typography/gill';
import {
  NOTIFICATION_GET,
  NOTIFICATION_READ
} from '../../../app/redux/actions/notifications';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
// import Arial from '../../typography/arial';
import CoachContactsModal from '../Modals/CoachContactsModal';
import STCLogo from '../../assets/img/STCLogo.png';
import Home from '../../assets/img/home.png';

const menuElements = [
  {
    title: 'Dashboard',
    icon: 'icon-dashboard',
    url: routes.dashboard.path
  },
  {
    title: 'Logout',
    icon: 'icon-logout',
    url: routes.logout.path
  }
];

const menuElement = ({ title, icon, url }, redirect) => (
  <MenuElement onClick={() => redirect(url)}>
    <span className={icon} />
    {title}
  </MenuElement>
);

const getInitials = name => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
};

const NavBar = ({
  menuMobileOpen,
  notificationMenuMobileOpen,
  setSearchWord,
  startLogout,
  pushUrl,
  menuToggle,
  notificationMenuToggle,
  navigationMobileToogle,
  navigationMobile,
  getNotification,
  getPlatformConf,
  mediaIsPhone,
  mediaIsTablet,
  mediaIsLaptop,
  mediaIsDesktop,
  notifications,
  readNotification,
  vocabulary,
  userName,
  isAuthSSO,
  user_type,
  coachContactsModal
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);

  useEffect(() => {
    getPlatformConf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = useState('');
  const handleOnChangeSearch = value => setSearch(value);

  const handleOnSubmitSearch = e => {
    e.preventDefault();
    if (search.length > 0) {
      setSearch('');
      setSearchWord(search);
      pushUrl(routes.search.path);
      //getSearchWord(search);
    }
  };

  const changeLocation = url => {
    menuToggle();
    notificationMenuToggle();
    pushUrl(url);
  };

  const logout = () => {
    startLogout();
  };

  const toggleMenu = () => {
    //const open = isOpen !== undefined ? isOpen : menuOpen;
    setMenuOpen(!menuOpen);
    setNotificationOpen(false);
    //toggleHandlerBodyCloseMenu(open);
  };

  const handleMenuClick = url => {
    toggleMenu(false);
    setNotificationOpen(false);
    if (url === routes.logout.path) {
      logout();
    } else {
      pushUrl(url);
    }
  };

  const toggleNotification = () => {
    setMenuOpen(false);
    setNotificationOpen(!notificationOpen);
    notificationMenuToggle();
    if (menuMobileOpen) menuToggle();
    if (navigationMobile) navigationMobileToogle();
  };

  const handleOnClickSeeAll = () => {
    toggleNotification();
    pushUrl(routes.notifications.path);
  };

  const toggleMenuNotification = () => {
    setMenuOpen(false);
    setNotificationMenuOpen(!notificationMenuOpen);
    //toggleHandlerBodyCloseMenu(!notificationMenuOpen);
  };

  const handleOnClickNotifcation = ({
    id,
    node_id,
    type,
    category_root,
    status,
    date
  }) => {
    toggleNotification();
    if (type === 'course' && category_root) {
      switch (category_root) {
        case 'cc_processi_e_procedure': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.processesProcedures.path}/${node_id}`);
          break;
        }
        case 'cc_prodotti': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.products.path}/${node_id}`);
          break;
        }
        case 'cc_vivere_in_isp': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.lifeIsp.path}/${node_id}`);
          break;
        }
        case 'cc_cosa_cambia': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.whatChange.path}/${node_id}`);
          break;
        }
        case 'cc_formazione_ruolo': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.roleTraining.path}/${node_id}`);
          break;
        }
        case 'cc_processi_procedure': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.processesProcedures.path}/${node_id}`);
          break;
        }
        case 'cc_prodotti_servizi': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.productsServices.path}/${node_id}`);
          break;
        }
        case 'cc_formazione_obbligatoria':
        default: {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.compulsarytraining.path}/${node_id}`);
          break;
        }
      }
    } else {
      switch (type) {
        case 'news': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.news.path}/${node_id}`);
          break;
        }
        case 'appointment': {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.integrationagenda.path}?selectedDate=${date}`);
          break;
        }
        case 'pill_collection':
        default: {
          if (status === 'not_read') readNotification(id);
          pushUrl(`${routes.smartWorking.path}/${node_id}`);
          break;
        }
      }
    }
  };

  const handleReadAll = () => {
    toggleNotification();
    readNotification('all');
  };

  const handleNavigationMobileToggle = () => {
    if (notificationMenuMobileOpen) notificationMenuToggle();
    navigationMobileToogle();
  };

  const handleMenuToggle = () => {
    if (navigationMobile) navigationMobileToogle();
    if (notificationMenuMobileOpen) notificationMenuToggle();
    menuToggle();
  };

  return (
    <NavBarContainer>
      {mediaIsPhone ? (
        <>
          <Container>
            <Row className="first-nav-row">
              <LogoMobileContainer>
                <Logo
                  src={STCLogo}
                  size={Logo.SIZE.SMALL}
                  onClick={() => {
                    changeLocation(routes.home.path);
                    setNotificationOpen(false);
                  }}
                />
              </LogoMobileContainer>
              <MobileIconContainer>
                {/* <IconWrapperPeople onClick={coachContactsModal}>
                  <div>
                    <span className="icon icon-assistance-hr" />
                    <Arial type="titlePeople">Contatti</Arial>
                  </div>
                </IconWrapperPeople> */}

                <CircleName onClick={handleMenuToggle} menuOpen={menuOpen}>
                  <span className="initials">
                    <Gill>
                      {getInitials(`${userName.name} ${userName.surname}`)}
                    </Gill>
                  </span>
                </CircleName>
                <Menu
                  callback={handleMenuClick}
                  menuList={
                    isAuthSSO
                      ? menuElements.filter(
                          menuItem => menuItem.title !== 'Logout'
                        )
                      : menuElements
                  }
                  customClass={`menu-mobile ${menuMobileOpen ? 'open' : ''}`}
                />
                <IconWrapper
                  onClick={() => changeLocation(routes.integrationagenda.path)}
                >
                  <span className="icon-calendar" />
                </IconWrapper>
                <IconWrapper
                  notificationOpen={!notificationOpen}
                  onClick={() => {
                    setNotificationOpen(false);
                    changeLocation(routes.favorites.path);
                  }}
                >
                  <span className="icon-heart-empty" />
                </IconWrapper>
                <BackHomeWrapper
                  title="Home"
                  onClick={() => changeLocation(routes.home.path)}
                >
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={Home} />
                </BackHomeWrapper>
              </MobileIconContainer>
            </Row>

            <Row className="second-nav-row">
              <SearchContainer>
                <SearchBar
                  placeholder={getValueVocabulary(vocabulary.search, 'search')}
                  mobile
                  value={search}
                  onChange={handleOnChangeSearch}
                  onSubmit={handleOnSubmitSearch}
                  onClick={() => setOpenMobileSearch(!openMobileSearch)}
                />
              </SearchContainer>
              <MenuButton handleOnClick={handleNavigationMobileToggle} />
            </Row>
            <CoachContactsModal />
          </Container>
        </>
      ) : (
        <Container className="p-0">
          <ContentWrapper className="container-fluid p-0">
            {(mediaIsDesktop || mediaIsLaptop || true) && (
              <LogoContainerDesktop>
                <Logo
                  src={STCLogo}
                  size={mediaIsTablet ? Logo.SIZE.SMALL : Logo.SIZE.MEDIUM}
                  onClick={() => changeLocation(routes.home.path)}
                />
              </LogoContainerDesktop>
            )}
            <SearchContainer style={{ left: mediaIsLaptop ? '11.5rem' : '' }}>
              <SearchBar
                placeholder={getValueVocabulary(vocabulary.search, 'search')}
                mobile
                value={search}
                onChange={handleOnChangeSearch}
                onSubmit={handleOnSubmitSearch}
                onClick={() => setOpenMobileSearch(!openMobileSearch)}
              />
            </SearchContainer>
            <div style={{ display: 'flex' }}>
              <div style={{ position: 'relative' }}>
                <NameContainer>
                  <NameContainerClickable
                    onClick={toggleMenu}
                    menuOpen={menuOpen}
                  >
                    <CircleName>
                      <span className="initials">
                        <Gill>
                          {getInitials(`${userName.name} ${userName.surname}`)}
                        </Gill>
                      </span>
                    </CircleName>
                    <div>
                      <Gill type="newsTitle">{userName.name}</Gill>
                    </div>
                  </NameContainerClickable>
                </NameContainer>
                <SidebarMenu isOpen={menuOpen}>
                  <>
                    {menuElements
                      .filter(menuItem =>
                        isAuthSSO ? menuItem.title !== 'Logout' : true
                      )
                      .map(e => (
                        <React.Fragment key={e.icon}>
                          {menuElement(e, handleMenuClick)}
                        </React.Fragment>
                      ))}
                  </>
                </SidebarMenu>
              </div>
              <LoginWrapper>
                {/* <IconWrapperPeople onClick={(coachContactsModal)}>
                  <div>
                    <span className="icon icon-assistance-hr" />
                    <Arial type="titlePeople">Contatti</Arial>
                  </div>
                </IconWrapperPeople> */}
                {notificationOpen && (
                  <Notifications
                    className={`notification-menu-mobile ${
                      notificationMenuMobileOpen ? 'open' : ''
                    }`}
                  >
                    <NotificationHeader>
                      <Arial
                        type="sectionTitle"
                        vocabulary="notificationsTitle"
                      >
                        {getValueVocabulary(
                          vocabulary.notificationsTitle,
                          'notificationsTitle'
                        )}
                      </Arial>
                      <IconMenu onClick={toggleMenuNotification}>
                        <span className="icon-menu" />
                      </IconMenu>
                      {notificationMenuOpen && (
                        <NotificationMenu onClick={() => handleReadAll()}>
                          <Icon className="icon-check" />
                          <Arial type="button">
                            Segna tutte come già lette
                          </Arial>
                        </NotificationMenu>
                      )}
                    </NotificationHeader>
                    {notifications?.notifications.length > 0 ? (
                      <NotificationContent>
                        {(notifications?.notifications
                          ? [...notifications?.notifications]
                          : []
                        )
                          ?.splice(0, 5)
                          .map(itemNotification => (
                            <NotificationDescription
                              onClick={() =>
                                handleOnClickNotifcation(itemNotification)
                              }
                            >
                              {itemNotification.status === 'not_read' && (
                                <Status />
                              )}
                              <InfoDescription
                                notRead={itemNotification.status === 'not_read'}
                              >
                                <NotificationLabel>
                                  <Arial type="newsDescription">
                                    {itemNotification?.title}
                                  </Arial>
                                  <Arial type="newsDescription">
                                    {itemNotification?.message}
                                  </Arial>
                                </NotificationLabel>
                                <NotificationDate>
                                  <Arial type="newsDescription">
                                    {moment(
                                      new Date(itemNotification?.date * 1000)
                                    ).format('DD/MM/YYYY HH:mm')}
                                  </Arial>
                                </NotificationDate>
                              </InfoDescription>
                            </NotificationDescription>
                          ))}
                      </NotificationContent>
                    ) : (
                      <NoResultContainer>
                        <NoResult text="Nessuna notifica presente" />
                      </NoResultContainer>
                    )}
                    <SeeAll onClick={() => handleOnClickSeeAll()}>
                      <Arial type="newsAttachment" vocabulary="newsShowAll">
                        {getValueVocabulary(
                          vocabulary.newsShowAll,
                          'newsShowAll'
                        )}
                      </Arial>
                      <span className="icon-arrow-right" />
                    </SeeAll>
                  </Notifications>
                )}
                <IconWrapper
                  onClick={() => changeLocation(routes.integrationagenda.path)}
                >
                  <span className="icon-calendar" />
                  <Arial type="titlePeople" vocabulary="favorites">
                    {getValueVocabulary(vocabulary.integrationAgenda, 'Agenda')}
                  </Arial>
                </IconWrapper>
                <IconWrapper
                  onClick={() => changeLocation(routes.favorites.path)}
                >
                  <span className="icon-heart-empty" />
                  <Arial type="titlePeople" vocabulary="favorites">
                    {getValueVocabulary(vocabulary.favorites, 'Preferiti')}
                  </Arial>
                </IconWrapper>
                <BackHomeWrapper
                  title="Home"
                  onClick={() => changeLocation(routes.home.path)}
                >
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={Home} />
                </BackHomeWrapper>
              </LoginWrapper>
            </div>
          </ContentWrapper>
          <CoachContactsModal />
        </Container>
      )}
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsDesktop: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,

  // HOC (connect, state)
  menuMobileOpen: PropTypes.bool.isRequired,
  isAuthSSO: PropTypes.bool.isRequired,
  notificationMenuMobileOpen: PropTypes.bool.isRequired,
  notifications: PropTypes.object,
  vocabulary: PropTypes.object,
  userName: PropTypes.object,

  // HOC (connect, dispatch)
  setSearchWord: PropTypes.func,
  startLogout: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  menuToggle: PropTypes.func.isRequired,
  notificationMenuToggle: PropTypes.func.isRequired,
  navigationMobileToogle: PropTypes.func.isRequired,
  getPlatformConf: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  coachContactsModal: PropTypes.func.isRequired
};

const composed = compose(
  connect(
    state => ({
      menuMobileOpen: state.app.menuMobileOpen,
      notificationMenuMobileOpen: state.app.notificationMenuMobileOpen,
      notifications: state.notifications.data,
      vocabulary: state.app.vocabulary,
      navigationMobile: state.app.navigationMobile,
      userName: state.user.data,
      user_type: state.user.data.user_type,
      isAuthSSO: state.app.authState.sso
    }),
    dispatch => ({
      setSearchWord: payload =>
        dispatch({ type: SEARCH_WORD_SET, response: payload }),
      getSearchWord: payload =>
        dispatch({ type: SEARCH_LIST_GET._REQUEST, response: payload }),
      startLogout: () => dispatch({ type: AUTH_LOGOUT._REQUEST }),
      pushUrl: url => dispatch(push(url)),
      menuToggle: () => dispatch({ type: APP_MENU_TOGGLE }),
      notificationMenuToggle: () =>
        dispatch({ type: APP_MENU_NOTIFICATION_TOGGLE }),
      navigationMobileToogle: () => dispatch({ type: APP_NAVIGATION_TOGGLE }),
      getPlatformConf: () => dispatch({ type: APP_CONFIGURATION_GET._REQUEST }),
      getNotification: () => dispatch({ type: NOTIFICATION_GET._REQUEST }),
      readNotification: id =>
        dispatch({ type: NOTIFICATION_READ._REQUEST, id }),
      coachContactsModal: () =>
        dispatch({ type: MODAL_OPEN, id: CoachContactsModal.id })
    })
  ),
  withMediaQueries
)(NavBar);
export default composed;
