import styled from 'styled-components';
import { Container } from '../../../ui/gridSystem';

const ButtonsWrapperHeight = 130;

export const Steps = styled.div`
  padding: 20px 0;
  background-color: ${props => props.theme.colors.primaryColor};
`;

export const StepsContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & span {
    font-size: 24px;
  }
`;

export const SignupContainer = styled.div`
  padding-top: 40px;
  color: ${props => props.theme.colors.black};
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: bold;

  @media ${props => props.theme.device.mobileL} {
    font-size: 24px;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 40px;
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 25px;
  }
`;

export const Body = styled.div`
  padding-bottom: calc(${ButtonsWrapperHeight}px + 54px);
`;

export const Disclaimer = styled.div`
  position: fixed;
  bottom: ${ButtonsWrapperHeight}px;
  left: 0;
  background-color: ${props => props.theme.colors.biscuit50};
  padding-top: 6px;
  width: 100vw;
  font-style: italic;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.doveGray};
  height: ${ButtonsWrapperHeight}px;
  padding: 0 22px;
`;
