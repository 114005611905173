import styled from 'styled-components';
import {
  CollectionContainer,
  CourseContainer,
  CourseElementCounter,
  CourseElementDetails,
  CourseTitle,
  Cover
} from '../Collection/style';

export const PillsContainer = styled(CollectionContainer)`
  color: ${props => props.theme.colors.black};

  .pdf {
    height: 100%;
    width: 100%;

    @media ${props => props.theme.device.tablet} {
      overflow: auto;
    }
  }
`;

export const MainSection = styled.div`
  margin-top: 20px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.isIframe ? 'transparent' : `${props.theme.colors.biscuit25}`};
  min-height: ${props => (props.isIframe ? 'auto' : '550px')};
  width: 100%;
  margin-bottom: 13px;

  .video-react .video-react-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-react-progress-control.video-react-control {
    pointer-events: none;
  }

  .rwd-video {
    height: 0;
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
  }
  .rwd-video iframe,
  .rwd-video object,
  .rwd-video embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const TagContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 20px 0;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const TagWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;

  span:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Tag = styled.span`
  background-color: ${props => props.theme.colors.greyDark};
  height: 28px;
  border-radius: 14px;
  color: ${props => props.theme.colors.veryLightPink};
  padding: 6px 10px;
`;

export const PillsListContainer = styled.div``;

export const PillContainer = styled(CourseContainer)``;

export const PillsElementCounter = styled(CourseElementCounter)``;

export const PillCover = styled(Cover)``;

export const PillElementDetails = styled(CourseElementDetails)``;

export const PillTitle = styled(CourseTitle)`
  font-size: 12px;
  > div.tooltip {
    display: inline;
    position: relative;

    .tooltip:hover:after {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      background: #444;
      border-radius: 8px;
      color: #fff;
      content: attr(title);
      margin: -82px auto 0;
      font-size: 16px;
      padding: 13px;
      width: 220px;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: #444 transparent;
      border-width: 12px 6px 0 6px;
      content: '';
      left: 45%;
      bottom: 30px;
      position: absolute;
    }
  }
`;

export const Iframe = styled.iframe`
  min-height: 550px;
  height: auto;
  height: calc(${props => props.frameHeight} + 30px);
  overflow: hidden;
  width: 100%;
`;
