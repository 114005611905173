import styled from 'styled-components';
import background from '../../../ui/assets/img/survey.jpg'


export const QuestionContainer = styled.div`
    width: 100%;
    padding-right: 22px;
    padding-left: 22px;
    margin-right: auto;
    margin-left: auto;
    position: sticky;
    top: 200px;


    @media ${props => props.theme.device.tablet} {
      position: unset;
    }

`;


export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;
  margin-bottom: -40px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.laptop} {
    padding: 32px 22px 0 23px;
    height: 300px;
    margin-bottom: -67px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 100vh;
    margin-bottom: -380px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 311px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -102px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${background});

  @media ${props => props.theme.device.tablet} {
    height: 81%;
    top: -112px;
    
  }
  
  @media ${props => props.theme.device.mobileL} {
    height: 80vh;
    top: -243px;
  }
  
`;

export const Category = styled.div`
padding: 3px 10px;
border-radius: 12px;
background-color: ${props => props.theme.colors.redError};
margin-bottom: 10px;
text-transform: uppercase;
width: fit-content;
margin-left:10px;
display:table;
`;

export const ExpireDate = styled.div`
padding-bottom: 20px;
display: flex;
align-items: baseline;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
`;


export const Question = styled.div`
padding-bottom: 20px;
max-width: 780px;
border-bottom: 1px solid #5c5c5c;
margin-bottom: 20px;
width:100%;

@media ${props => props.theme.device.tablet} {
  padding: 0 20px 0px 20px;

}

& > label {
  margin-bottom: 20px;
}


`;

export const Title = styled.div`
  margin-bottom:20px;
`;

export const StarRating = styled.div`
display:flex;
flex-direction: row-reverse;
font-size:1.5em;
justify-content:space-around;
padding:0 .2em;
text-align:center;
width: 250px;



 input {
display:none;
}

label {
color:#ccc;
cursor:pointer;
}

:checked ~ label {
color:#f90;
}

 label:hover,
 label:hover ~ label {
color:#fc0;
}

`;
