import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import { Document, Page } from 'react-pdf';

import { push } from 'redux-first-history';
import YouTube from 'react-youtube';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  PillsContainer,
  PillContainer,
  PillTitle,
  PillCover,
  PillElementDetails,
  Tag,
  TagContainer,
  TagWrapper,
  VideoContainer,
  PillsElementCounter,
  MainSection
} from './style';
import { BackButton, Button } from '../../../ui/atoms';
import routes from '../../../routes';
import {
  CourseDetailHeader,
  CourseDetailInfo,
  CourseDetailPill
} from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import {
  SELECTED_STC_COURSE_GET,
  // STC_COURSE_COMMENTS_GET,
  // STC_COURSE_COMMENTS_SET,
  STC_COURSE_NODE_ACTION,
  STC_COURSE_PILL_SET_STATS,
  STC_COURSE_PILLS_GET,
  STC_COURSE_SHARE_PILL_COLLECTION
} from '../../redux/actions/stc_course';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import {
  ActivityContainer,
  ActivityDescription,
  ActivityIcon,
  IconCompleted,
  PrevPage,
  NextPage
} from '../STCAllPills/style';
import Gill from '../../../ui/typography/gill';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { youtube_parser } from '../../../utils/common';
import { PILLS_RESET } from '../../redux/actions/course';

const pillElement = (pill, onClick, isActive = false, index) => {
  const {
    cover, title, blocked, stats
  } = pill;
  const { url = '' } = cover || {};
  return (
    <PillContainer
      onClick={!blocked && onClick}
      active={isActive}
      blocked={blocked}
    >
      <PillsElementCounter>{index + 1}</PillsElementCounter>
      <PillCover cover={url} />
      <PillElementDetails>
        <PillTitle type="collectionCourseTitle" title={title}>
          {title}
        </PillTitle>
        {stats.status === 'completed' && (
          <IconCompleted className="icon-completato" />
        )}
      </PillElementDetails>
    </PillContainer>
  );
};

const CourseDetails = ({
  courseName,
  courseId,
  pillId,
  selectedStcCourse,
  pills,
  getSelectedSTCCourse,
  getSTCCoursePills,
  setLike,
  setDislike,
  setView,
  shareModal,
  setStatSTCCoursePill,
  pushUrl,
  shareCollection,
  vocabulary,
  path_contents,
  testLink,
  nextCourse,
  prevCourse,
  pillsReset
}) => {
  const [pill, setPill] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [scormFullscreen, setScormFullscreen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoadSuccess, setPDFLoadSuccess] = useState(false);
  const [pdfTotalPages, setPDFTotalPage] = useState(0);

  useEffect(() => () => {
    pillsReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseName, courseId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedSTCCourse(courseId);
    getSTCCoursePills({ collectionId: courseId });
    //setView(collectionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, setScormFullscreen, path_contents]);

  useEffect(() => {
    // getSTCCourseComments(pillId);
    setView(courseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId]);

  useEffect(() => {
    setScormFullscreen(false);
    if (pills.length > 0) {
      const isCourseCompleted = [...pills].filter(item => item.stats.status !== 'completed').length
        <= 0;
      const selectedDefaultLO = [...pills].find(
        item => item.id === Number(pillId)
      );

      if (isCourseCompleted && !pillId) {
        //If all LOs are completed and there's no selected LO the first LO will be selected
        setPill(pills[0]);
        pushUrl(
          `${routes.course.path}/${courseName}/${courseId}/${pills[0].id}`
        );
      } else if (isCourseCompleted && selectedDefaultLO) {
        //If all LOs are completed and there's a selected LO
        setPill(selectedDefaultLO);
      } else if (
        !isCourseCompleted
        && selectedDefaultLO
        && !selectedDefaultLO.blocked
      ) {
        // If not all LOs are completed and there's selected LO that is also not blocked
        setPill(selectedDefaultLO);
      } else {
        // Select the first LO available if the conditions above are not meet
        const firstAvailableLO = [...pills].reduce((acc, val) => {
          if (!acc && val.stats.status !== 'completed' && !val.blocked) { return val; }
          return acc;
        }, null);
        if (firstAvailableLO?.id) {
          setPill(firstAvailableLO);
          pushUrl(
            `${routes.course.path}/${courseName}/${courseId}/${firstAvailableLO.id}`
          );
        } else {
          setPill(pills[0]);
          pushUrl(
            `${routes.course.path}/${courseName}/${courseId}/${pills[0].id}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId, pills]);

  const {
    category,
    duration,
    title: stcCourseTitle,
    description: stcCourseDescription
  } = selectedStcCourse;
  const { description, tag, title } = pill;

  const percentage = (
    (pills.filter(item => item.stats.status === 'completed').length
      / pills.length)
    * 100
  ).toFixed(0);

  const openPill = ({ id, stats: { status }, blocked }) => {
    //if (status !== 'completed') setStatSTCCoursePill({ collectionId: courseId, pillId: id });
    pushUrl(`${routes.course.path}/${courseName}/${courseId}/${id}`);
  };

  // const handleNewCommentOnSubmit = (value) => {
  //   setSTCCourseComments({
  //     id: pill?.id,
  //     subject: pill?.title,
  //     comment_body: value
  //   });
  // };

  const windowOpen = uri => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.course.path}/${courseName}/${courseId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  const onClickSetStatSTCCoursePill = ({ id, stats: { status } }, url) => {
    if (status !== 'completed') { setStatSTCCoursePill({ collectionId: courseId, pillId: id }); }
    if (url) window.open(url, 'blank');
    setPageNumber(1);
    setPDFLoadSuccess(false);
    setPDFTotalPage(0);
    pushUrl(`${routes.course.path}/${courseName}/${courseId}`);
  };

  const youtubeOpts = {
    height: '315',
    width: '560',
    playerVars: {
      autoplay: 1
    }
  };

  return (
    <PillsContainer>
      <Container className="p-0">
        <BackButton onClick={pillsReset} />
        <CourseDetailHeader
          title={stcCourseTitle}
          description={stcCourseDescription}
          category={category}
          percentage={percentage}
          onClick={() => shareModal({
            onClickConfirm: value => shareCollection({
              receiver_user_id: value,
              node_id: courseId,
              configuration_type: 'pill_collection'
            })
          })
          }
        />
        <MainSection>
          <Row>
            <Col sm={12} md={8} lg={9}>
              {pill ? (
                <>
                  <VideoContainer isIframe={pill.source_type === 'iframe'}>
                    {pill.source_type === 'video' && (
                      <Player
                        src={pill.video?.url}
                        onEnded={() => onClickSetStatSTCCoursePill(pill)}
                        autoplay
                      />
                    )}
                    {pill.source_type === 'iframe' && (
                      <div className="rwd-video">
                        <YouTube
                          videoId={youtube_parser(pill.url_source.uri)}
                          opts={youtubeOpts}
                          onStateChange={({ data }) => (data === 0 ? onClickSetStatSTCCoursePill(pill) : '')
                          }
                        />
                      </div>
                    )}
                    {pill.source_type === 'document'
                      && pill?.document?.url.indexOf('.pdf') < 0 && (
                        <ActivityContainer>
                          <ActivityIcon className="icon-right" />
                          <ActivityDescription>
                            <Gill
                              type="activityLinkDescription"
                              vocabulary="activityDocumentDescription"
                            >
                              {getValueVocabulary(
                                vocabulary.activityDocumentDescription,
                                'activityDocumentDescription'
                              )}
                            </Gill>
                            <Button
                              btnText={getValueVocabulary(
                                vocabulary.btnActivityButton,
                                'btnActivityButton'
                              )}
                              onClick={() => onClickSetStatSTCCoursePill(
                                pill,
                                pill.document.url
                              )
                              }
                              height={30}
                            />
                          </ActivityDescription>
                        </ActivityContainer>
                    )}
                    {pill.source_type === 'document'
                      && pill?.document?.url.indexOf('.pdf') > 0 && (
                        <ActivityContainer>
                          {pdfLoadSuccess && pageNumber !== 1 && (
                            <PrevPage
                              className="icon-left"
                              onClick={() => setPageNumber(pageNumber - 1)}
                            />
                          )}
                          <Document
                            file={{ url: pill?.document?.url }}
                            className="pdf"
                            error={getValueVocabulary(
                              vocabulary.pdfLoadError,
                              'pdfLoadError'
                            )}
                            loading={getValueVocabulary(
                              vocabulary.pdfLoading,
                              'pdfLoading'
                            )}
                            onLoadError={e => console.log('error ', e)}
                            onLoadProgress={({ loaded, total }) => console.log(
                              `Loading a document: ${(loaded / total) * 100}%`
                            )
                            }
                            onLoadSuccess={pdf => {
                              setPDFLoadSuccess(true);
                              setPDFTotalPage(pdf.numPages);
                              console.log(
                                `Loaded a file with ${pdf.numPages} pages!`
                              );
                            }}
                          >
                            <Page pageNumber={pageNumber} />
                          </Document>
                          {pdfLoadSuccess
                            && (pageNumber !== pdfTotalPages
                              || (pageNumber === pdfTotalPages
                                && pill.stats.status !== 'completed')) && (
                                <NextPage
                                  className="icon-arrow-right"
                                  onClick={() => (pageNumber !== pdfTotalPages
                                    ? setPageNumber(pageNumber + 1)
                                    : onClickSetStatSTCCoursePill(pill))
                                }
                                />
                          )}
                        </ActivityContainer>
                    )}
                    {pill.source_type === 'link' && (
                      <ActivityContainer>
                        <ActivityIcon className="icon-mappamondo" />
                        <ActivityDescription>
                          <Gill
                            type="activityLinkDescription"
                            vocabulary="activityLinkDescription"
                          >
                            {getValueVocabulary(
                              vocabulary.activityLinkDescription,
                              'activityLinkDescription'
                            )}
                          </Gill>
                          <Button
                            btnText={getValueVocabulary(
                              vocabulary.btnActivityButton,
                              'btnActivityButton'
                            )}
                            onClick={() => onClickSetStatSTCCoursePill(
                              pill,
                              pill.url_source.uri
                            )
                            }
                            height={30}
                          />
                        </ActivityDescription>
                      </ActivityContainer>
                    )}
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnOnClick={() => windowOpen(pill?.url_source?.uri)}
                    source={pill}
                    views={selectedStcCourse?.views}
                    title={title}
                    description={description}
                    onClickLike={() => setLike(pill.id)}
                    onClickDislike={() => setDislike(pill.id)}
                  />
                  {tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {tag.map(t => (
                          <Tag key={t.id}>
                            <Arial type="tagText">{t.label}</Arial>
                          </Tag>
                        ))}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  {/* <Comments list={comments} onSubmit={handleNewCommentOnSubmit} /> */}
                </>
              ) : null}
            </Col>
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={pills.length}
                durationPill={duration}
                vocabulary={vocabulary}
                isCompleted={selectedStcCourse?.status === 'completed'}
                testLink={testLink}
                nextCourse={nextCourse}
                prevCourse={prevCourse}
                courseName={courseName}
                pushUrl={pushUrl}
                pillsReset={pillsReset}
              >
                {pills.map((c, index) => (
                  <React.Fragment key={`pill-${c.id}`}>
                    {pillElement(
                      c,
                      () => openPill(c),
                      c.id?.toString() === pillId?.toString(),
                      index
                    )}
                  </React.Fragment>
                ))}
              </CourseDetailPill>
            </Col>
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </PillsContainer>
  );
};

CourseDetails.propTypes = {
  courseId: PropTypes.string.isRequired,
  pillId: PropTypes.string,

  // HOC (connect, state)
  selectedStcCourse: PropTypes.object,
  pills: PropTypes.arrayOf(PropTypes.object),
  nextCourse: PropTypes.object,
  prevCourse: PropTypes.object,
  // comments: PropTypes.array,

  // HOC (connect, dispatch)
  shareCollection: PropTypes.func.isRequired,
  getSelectedSTCCourse: PropTypes.func.isRequired,
  getSTCCoursePills: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  setStatSTCCoursePill: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  pillsReset: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      selectedStcCourse,
      pills,
      comments,
      testLink,
      nextCourse,
      prevCourse
    } = state.stc_course;
    const { requestedURL, vocabulary, path_contents } = state.app;
    return {
      selectedStcCourse,
      pills,
      comments,
      requestedURL,
      vocabulary,
      path_contents,
      testLink,
      nextCourse,
      prevCourse
    };
  },
  dispatch => ({
    // setSTCCourseComments: (params) => dispatch({ type: STC_COURSE_COMMENTS_SET._REQUEST, ...params }),
    shareCollection: data => dispatch({ type: STC_COURSE_SHARE_PILL_COLLECTION._REQUEST, data }),
    getSelectedSTCCourse: id => dispatch({ type: SELECTED_STC_COURSE_GET._REQUEST, id }),
    getSTCCoursePills: ({ collectionId }) => dispatch({ type: STC_COURSE_PILLS_GET._REQUEST, collectionId }),
    // getSTCCourseComments: (id) => dispatch({ type: STC_COURSE_COMMENTS_GET._REQUEST, id }),
    setLike: nid => dispatch({
      type: STC_COURSE_NODE_ACTION._REQUEST,
      nid,
      action_type: 'like',
      node_type: 'pill_item'
    }),
    setDislike: nid => dispatch({
      type: STC_COURSE_NODE_ACTION._REQUEST,
      nid,
      action_type: 'dislike',
      node_type: 'pill_item'
    }),
    setView: nid => dispatch({
      type: STC_COURSE_NODE_ACTION._REQUEST,
      nid,
      action_type: 'views'
    }),
    setStatSTCCoursePill: ({ collectionId, pillId }) => dispatch({
      type: STC_COURSE_PILL_SET_STATS._REQUEST,
      collectionId,
      pillId
    }),
    shareModal: data => dispatch({ type: MODAL_OPEN, id: ShareModal.id, data }),
    pushUrl: url => dispatch(push(url)),
    pillsReset: () => dispatch({ type: PILLS_RESET })
  })
)(CourseDetails);
