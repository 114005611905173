import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../gridSystem';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer, ModalWrapper
} from '../Modal.style';

import Arial from '../../../typography/arial';
import {
  Title, IconClose, IconError, ErrorContent, ShareModalContainer, ButtonsContainer
} from './style';

import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Button } from '../../../atoms';

const modalId = 'ErrorModal';

const ErrorModal = ({ onClose, modal_data: { title = '', message = '' } = {} }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent} height="370">
          <IconClose className="icon-close" onClick={onClose} />
          <ShareModalContainer>
            <Title>{title}</Title>
            <ErrorContent>
              <IconError className="icon-error" />
              <Arial type="editorialImageText">{message}</Arial>
            </ErrorContent>
            <ButtonsContainer>
              <Button type={Button.TYPE.SECONDARY} onClick={onClose} btnText="Annulla" height={40} />
              <Button btnText="Riprova" onClick={onClose} height={40} />
            </ButtonsContainer>
          </ShareModalContainer>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

ErrorModal.propTypes = {
  // HOC (connect, state)
  modal_data: PropTypes.object,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};
ErrorModal.id = modalId;

export default connect(
  state => {
    const { data: modal_data } = state.modal;
    return {
      modal_data
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ErrorModal);
