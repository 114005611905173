import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  ProductCoursesContainer, Iframe, MainSection, Tag, TagContainer,
  ProductCourseContainer, ProductCourseElementCounter, ProductCourseCover,
  ProductCourseElementDetails, ProductCourseTitle, TagWrapper, VideoContainer
} from './style';
import { BackButton } from '../../../ui/atoms';
import routes from '../../../routes';
import {
  Comments, CourseDetailHeader,
  CourseDetailInfo, CourseDetailPill
} from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import {
  PRODUCT_COURSES_GET, PRODUCTS_COMMENTS_GET,
  PRODUCTS_COMMENTS_SET, PRODUCTS_NODE_ACTION,
  SELECTED_PRODUCT_GET
} from '../../redux/actions/product';
import { CourseElementCompleted, IconCompleted } from '../Collection/style';
import { Frame } from '../CompulsaryTrainingCourses/style';

const courseElement = ({
  cover, title, blocked, stats
} = {}, onClick, isActive = false, index) => (
  <ProductCourseContainer onClick={onClick} active={isActive} blocked={blocked}>
    <ProductCourseElementCounter>
      {index + 1}
    </ProductCourseElementCounter>
    <ProductCourseCover cover={cover} />
    <ProductCourseElementDetails>
      <ProductCourseTitle title={title} type="collectionCourseTitle">{title}</ProductCourseTitle>
    </ProductCourseElementDetails>
    <CourseElementCompleted>
      {stats?.status === 'completed' && <IconCompleted className="icon-completato" />}
    </CourseElementCompleted>
  </ProductCourseContainer>
);

const ProductDetail = ({
  collectionId, scormId, selected_product, scorms,
  comments, getSelectedProduct, getProductCourses,
  setProductComments, getProductComments, setLike,
  setDislike, setViews, pushUrl, requestedURL
}) => {
  const [scorm, setScorm] = useState({});
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setViews(collectionId);
    getSelectedProduct(collectionId);
    getProductCourses({ collectionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, setScormFullscreen]);

  useEffect(() => {
    if (scormId) getProductComments(scormId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scormId]);

  useEffect(() => {
    if ((scorms?.length && !scormId) || (scormId && scorms?.length && !scorms?.find(({ node_scorm_id } = {}) => node_scorm_id?.toString() === scormId?.toString()))) {
      const selectedScorm = scorms?.find(({ stats } = {}) => !(stats.status === 'completed'));
      selectedScorm ? pushUrl(`${routes.products.path}/${collectionId}/${selectedScorm?.node_scorm_id}`) : pushUrl(`${routes.products.path}/${collectionId}/${scorms[0]?.node_scorm_id}`);
      setScorm(selectedScorm || scorms[0]);
    } else if (scorms?.length && scormId) {
      let selectedScorm = scorms?.find(({ node_scorm_id, stats } = {}) => node_scorm_id?.toString() === scormId?.toString());
      selectedScorm = selectedScorm || scorms?.filter(({ stats } = {}) => !(stats.status === 'completed'));
      setScorm(selectedScorm);
    }
    setScormFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scorms, scormId]);

  const {
    description, tag, title
  } = scorm;

  const percentage = ((scorms.filter(item => item.stats.status === 'completed').length / scorms.length) * 100).toFixed(0);

  const openCourse = ({ node_scorm_id, blocked }) => {
    if (!blocked) {
      pushUrl(`${routes.products.path}/${collectionId}/${node_scorm_id}`);
      getProductCourses({ collectionId });
    }
  };

  const handleNewCommentOnSubmit = (value) => {
    setProductComments({
      id: scorm?.node_scorm_id,
      subject: scorm?.title,
      comment_body: value
    });
  };

  // const onClickNext = () => {
  //   getProductCourses({ collectionId });
  //   pushUrl(`${routes.products.path}/${collectionId}`);
  // };

  const windowOpen = (uri) => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.products.path}/${collectionId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  const { title: productTitle, category } = selected_product;

  return (
    <ProductCoursesContainer>
      <Container className="p-0">
        {requestedURL === '/integration-agenda' ? (
          <BackButton url={routes.integrationagenda.path} />
        ) : (
          <BackButton url={routes.products.path} />
        )}
        <CourseDetailHeader
          title={productTitle}
          category={category}
          percentage={percentage}
        />
        <MainSection>
          <Row>
            <Col sm={12} md={8} lg={9}>
              {scorm ? (
                <>
                  <VideoContainer>
                    {scormFullscreen
                      ? (<Frame><Arial type="editorialCardText">Aperto in modalità fullscreen</Arial></Frame>)
                      : (
                        <Iframe
                          // frameHeight={frameHeight}
                          title={scorm?.title}
                          src={scorm?.iframe_uri}
                          id="scorm_frame"
                        />
                      )
                    }
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnSource={scorm?.iframe_uri}
                    btnOnClick={() => windowOpen(scorm?.iframe_uri)}
                    source={scorm}
                    views={selected_product?.views}
                    title={title}
                    description={description}
                    onClickLike={() => setLike(scorm.node_scorm_id)}
                    onClickDislike={() => setDislike(scorm.node_scorm_id)}
                  />
                  {tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {tag.map(t => <Tag key={t.id}><Arial type="tagText">{t.label}</Arial></Tag>)}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments list={comments} onSubmit={handleNewCommentOnSubmit} />
                </>
              ) : null}
            </Col>
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={scorms.length}
                durationPill={selected_product?.duration}
              >
                {scorms.map((c, index) => <React.Fragment key={`scorm-${c.id}`}>{courseElement(c, () => openCourse(c), c.id?.toString() === scormId?.toString(), index)}</React.Fragment>)}
              </CourseDetailPill>
            </Col>
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </ProductCoursesContainer>
  );
};

ProductDetail.propTypes = {
  collectionId: PropTypes.string.isRequired,
  scormId: PropTypes.string,

  // HOC (connect, state)
  selected_product: PropTypes.object,
  scorms: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,

  // HOC (connect, dispatch)

  getSelectedProduct: PropTypes.func.isRequired,
  getProductCourses: PropTypes.func.isRequired,
  setProductComments: PropTypes.func.isRequired,
  getProductComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setViews: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  requestedURL: PropTypes.string
};

export default connect(
  state => {
    const { selected_product, scorms, comments } = state.product;
    const { requestedURL } = state.app;
    return {
      selected_product, scorms, comments, requestedURL
    };
  },
  dispatch => ({
    getSelectedProduct: (id) => dispatch({ type: SELECTED_PRODUCT_GET._REQUEST, id }),
    getProductCourses: ({ collectionId }) => dispatch({ type: PRODUCT_COURSES_GET._REQUEST, collectionId }),
    setProductComments: (params) => dispatch({ type: PRODUCTS_COMMENTS_SET._REQUEST, ...params }),
    getProductComments: (id) => dispatch({ type: PRODUCTS_COMMENTS_GET._REQUEST, id }),
    setLike: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'like', node_type: 'scorm'
    }),
    setDislike: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'dislike', node_type: 'scorm'
    }),
    setViews: (nid) => dispatch({
      type: PRODUCTS_NODE_ACTION._REQUEST, nid, action_type: 'views', node_type: 'course'
    }),
    shareModal: () => dispatch({ type: MODAL_OPEN, id: ShareModal.id }),
    pushUrl: url => dispatch(push(url))
  })
)(ProductDetail);
