import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  PillsContainer, PillContainer, PillTitle, PillCover,
  Iframe, MainSection, PillElementDetails, Tag,
  TagContainer, TagWrapper, VideoContainer, PillsElementCounter
} from './style';
import { BackButton } from '../../../ui/atoms';
import routes from '../../../routes';
import { Comments, CourseDetailHeader, CourseDetailInfo, CourseDetailPill } from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import {
  SELECTED_SMARTWORKING_GET, SMARTWORKING_COMMENTS_GET, SMARTWORKING_COMMENTS_SET,
  SMARTWORKING_NODE_ACTION, SMARTWORKING_PILL_SET_STATS, SMARTWORKING_PILLS_GET, SMARTWORKING_SHARE_PILL_COLLECTION
} from '../../redux/actions/smartworking';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import { Frame } from '../CompulsaryTrainingCourses/style';

const pillElement = ({
  cover: { url = '' }, title, blocked
} = {}, onClick, isActive = false, index) => (
  <PillContainer onClick={!blocked && onClick} active={isActive} blocked={blocked}>
    <PillsElementCounter>
      {index + 1}
    </PillsElementCounter>
    <PillCover cover={url} />
    <PillElementDetails>
      <PillTitle type="collectionCourseTitle" title={title}>{title}</PillTitle>
    </PillElementDetails>
  </PillContainer>
);

const SmartWorkingPills = ({
  smartWorkingId, pillId, selectedSmartWorking, pills, comments,
  setSmartWorkingComments, getSelectedSmartWorking, getSmartWorkingPills, getSmartWorkingComments,
  setLike, setDislike, setView, shareModal, setStatSmartWorkingPill, pushUrl, shareCollection, requestedURL
}) => {
  const [pill, setPill] = useState({});
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedSmartWorking(smartWorkingId);
    getSmartWorkingPills({ collectionId: smartWorkingId });
    //setView(collectionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartWorkingId, setScormFullscreen]);

  useEffect(() => {
    getSmartWorkingComments(pillId);
    setView(smartWorkingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId]);

  useEffect(() => {
    if ((pills?.length && !pillId) || (pillId && pills?.length && !pills?.find(({ id } = {}) => id?.toString() === pillId?.toString()))) {
      const { id, stats: { status } } = pills[0];
      if (status !== 'completed') setStatSmartWorkingPill({ collectionId: smartWorkingId, pillId: id });
      pushUrl(`${routes.smartWorking.path}/${smartWorkingId}/${id}`);
    } else if (pills?.length && pillId) {
      const selectedPill = pills?.find(({ id } = {}) => id?.toString() === pillId?.toString());
      setPill(selectedPill);
      const { id, stats: { status } } = pills[0];
      if (status !== 'completed') setStatSmartWorkingPill({ collectionId: smartWorkingId, pillId: id });
    }
    setScormFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId, pills]);

  const {
    category, duration, title: smartWorkingTile
  } = selectedSmartWorking;
  const {
    description, tag, title
  } = pill;

  const percentage = ((pills.filter(item => item.stats.status === 'completed').length / pills.length) * 100).toFixed(0);

  const openPill = ({ id, stats: { status }, blocked }) => {
    if (!blocked) {
      if (status !== 'completed') setStatSmartWorkingPill({ collectionId: smartWorkingId, pillId: id });
      pushUrl(`${routes.smartWorking.path}/${smartWorkingId}/${id}`);
    }
  };

  const handleNewCommentOnSubmit = (value) => {
    setSmartWorkingComments({
      id: pill?.id,
      subject: pill?.title,
      comment_body: value
    });
  };

  const windowOpen = (uri) => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );
    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.smartWorking.path}/${smartWorkingId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  return (
    <PillsContainer>
      <Container className="p-0">
        {requestedURL?.pathname === '/integration-agenda' ? (
          <BackButton url={`${routes.integrationagenda.path}${requestedURL?.search}`} />
        ) : (
          <BackButton url={routes.smartWorking.path} />
        )}
        <CourseDetailHeader
          title={smartWorkingTile}
          category={category}
          percentage={percentage}
          onClick={() => shareModal({ onClickConfirm: (value) => shareCollection({ receiver_user_id: value, node_id: smartWorkingId, configuration_type: 'pill_collection' }) })}
        />
        <MainSection>
          <Row>
            <Col sm={12} md={8} lg={9}>
              {pill ? (
                <>
                  <VideoContainer>
                    {scormFullscreen ? (
                      <Frame>
                        <Arial type="editorialCardText">
                          Aperto in modalità fullscreen
                        </Arial>
                      </Frame>
                    ) : (
                      <Iframe
                        // frameHeight={frameHeight}
                        title={title}
                        src={pill?.url_source?.uri}
                        id="scorm_frame"
                      // onLoad={adjustHeight}
                      />
                    )}
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnSource={pill?.url_source?.uri}
                    btnOnClick={() => windowOpen(pill?.url_source?.uri)}
                    source={pill}
                    views={selectedSmartWorking?.views}
                    title={title}
                    description={description}
                    onClickLike={() => setLike(pill.id)}
                    onClickDislike={() => setDislike(pill.id)}
                  />
                  {tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {tag.map(t => <Tag key={t.id}><Arial type="tagText">{t.label}</Arial></Tag>)}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments list={comments} onSubmit={handleNewCommentOnSubmit} />
                </>
              ) : null}
            </Col>
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={pills.length}
                durationPill={duration}
              >
                {pills.map((c, index) => <React.Fragment key={`pill-${c.id}`}>{pillElement(c, () => openPill(c), c.id?.toString() === pillId?.toString(), index)}</React.Fragment>)}
              </CourseDetailPill>
            </Col>
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </PillsContainer>
  );
};

SmartWorkingPills.propTypes = {
  smartWorkingId: PropTypes.string.isRequired,
  pillId: PropTypes.string,

  // HOC (connect, state)
  selectedSmartWorking: PropTypes.object,
  pills: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,

  // HOC (connect, dispatch)
  setSmartWorkingComments: PropTypes.func.isRequired,
  shareCollection: PropTypes.func.isRequired,
  getSelectedSmartWorking: PropTypes.func.isRequired,
  getSmartWorkingPills: PropTypes.func.isRequired,
  getSmartWorkingComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  setStatSmartWorkingPill: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { selectedSmartWorking, pills, comments } = state.smartworking;
    const { requestedURL } = state.app;
    return {
      selectedSmartWorking, pills, comments, requestedURL
    };
  },
  dispatch => ({
    setSmartWorkingComments: (params) => dispatch({ type: SMARTWORKING_COMMENTS_SET._REQUEST, ...params }),
    shareCollection: (data) => dispatch({ type: SMARTWORKING_SHARE_PILL_COLLECTION._REQUEST, data }),
    getSelectedSmartWorking: (id) => dispatch({ type: SELECTED_SMARTWORKING_GET._REQUEST, id }),
    getSmartWorkingPills: ({ collectionId }) => dispatch({ type: SMARTWORKING_PILLS_GET._REQUEST, collectionId }),
    getSmartWorkingComments: (id) => dispatch({ type: SMARTWORKING_COMMENTS_GET._REQUEST, id }),
    setLike: (nid) => dispatch({
      type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'like', node_type: 'pill_item'
    }),
    setDislike: (nid) => dispatch({
      type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'dislike', node_type: 'pill_item'
    }),
    setView: (nid) => dispatch({ type: SMARTWORKING_NODE_ACTION._REQUEST, nid, action_type: 'views' }),
    setStatSmartWorkingPill: ({ collectionId, pillId }) => dispatch({ type: SMARTWORKING_PILL_SET_STATS._REQUEST, collectionId, pillId }),
    shareModal: (data) => dispatch({ type: MODAL_OPEN, id: ShareModal.id, data }),
    pushUrl: url => dispatch(push(url))
  })
)(SmartWorkingPills);
