import styled from 'styled-components';

export const GeneralCardContainer = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: 260px;
  height: 276px;
  border-radius: 4px;
  margin: auto;
  padding: 20px;  
  transition: ${props => props.theme.constants.transitionBase};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 290px;
    margin-bottom: 15px;
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.3)};
    transition: ${props => props.theme.constants.transitionBase};
  }
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    &:before {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
    }
  }
`;

export const AdditionalInfo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: ${props => props.theme.colors.white};
`;

export const IconWrapper = styled.div`
position: absolute;
top: 15px;
right: 20px;
`;


export const InformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 0;
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;

& > button {
  width: 166px;
  height: 40px;
}
`;
