import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Collections } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import {
  ProceduresContainer, Mask, Description, Title,
  FilterContainer, CustomSelectContainer, FilterPillsContainer, FilterLabel
  // SeeMore
} from './style';
import { CustomSelect, FilterPill } from '../../../ui/atoms';
import Arial from '../../../ui/typography/arial';
import { COLLECTION_LIST_GET } from '../../redux/actions/collections';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const defaultAreaMestiere = [
  //{ label: 'Tutti', value: 'all' }
];

const Procedures = ({
  getCollection, filters: { categories = [] }, vocabulary
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const onChangeFilter = (newFilters) => {
    if (newFilters.length) {
      getCollection({ category_id: [...newFilters.map(item => item.value)].toString().replace(/,/g, ';') });
    } else {
      getCollection();
    }
    setSelectedFilters(newFilters);
  };

  const removeFilter = (item) => {
    const newFilters = (selectedFilters || []).filter(filter => filter.value !== item);
    getCollection({ category_id: [...newFilters.map(filter => filter.value)].toString().replace(/,/g, ';') });
    setSelectedFilters(newFilters);
  };

  return (
    <Container>
      <ProceduresContainer>
        <Mask>
          <Title>
            <Arial type="comingSoonTitle">Coming soon!</Arial>
          </Title>
          <Description>
            <Arial type="EditorialGridTitle" configuration={{ fontWeight: 'normal' }}>Pillole formative digitali per supportarti nell'apprendimento dei processi e delle procedure</Arial>
          </Description>
        </Mask>
        <Container className="p-0">
          <>
            <FilterLabel><Arial type="filterLabel" vocabulary="proceduresFilterLabel">{getValueVocabulary(vocabulary.proceduresFilterLabel, 'proceduresFilterLabel')}</Arial></FilterLabel>
            <FilterContainer>
              <CustomSelectContainer>
                <CustomSelect placeholder="Tutti" options={[...defaultAreaMestiere, ...categories.map(({ id: value, name: label }) => ({ label, value }))]} onChange={newValue => onChangeFilter(newValue || [])} selected={selectedFilters} isMulti />
              </CustomSelectContainer>
              <FilterPillsContainer>
                {(selectedFilters || []).map(filter => <FilterPill key={`filter-${filter.value}`} onRemove={removeFilter} value={filter.value} label={filter.label} />)}
              </FilterPillsContainer>
            </FilterContainer>
          </>
          <Collections selectedFilters={selectedFilters} />
          {/*{courses.length > 0 && (*/}
          {/*<SeeMore>*/}
          {/*  <Arial type="scrollUp">Mostra Altri</Arial>*/}
          {/*</SeeMore>*/}
          {/*)}*/}
        </Container>
      </ProceduresContainer>
    </Container>
  );
};

Procedures.propTypes = {
  // HOC (connect, state)
  courses: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.object)
  }),
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getCollection: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { collection: { courses = [], filters = {} } } = state.collections;
    const { vocabulary } = state.app;
    return {
      heroImage,
      courses,
      filters,
      vocabulary
    };
  },
  dispatch => ({
    getCollection: (filters) => dispatch({
      type: COLLECTION_LIST_GET._REQUEST, filters, itemPerPage: 100, subset: true
    })
  })
)(Procedures);
