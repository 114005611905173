import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Description } from './style';

const VideoSection = ({ title, description }) => (
  <Container>
    <div className="section-content">
      <div className="section-text">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <div className="section-video" />
    </div>
  </Container>
);

VideoSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

VideoSection.defaultProps = {
  title: '',
  description: ''
};

export default VideoSection;
