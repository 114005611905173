import styled from 'styled-components';

const CourseInProgressContainer = styled.div`
  width: 100%;
  min-height: 305px;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};

  display: flex;
  flex-wrap: wrap;
`;

const HeaderContainer = styled.div`
  position: relative;
  padding-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.primaryColor};
  opacity: 0.9;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    top: -9px;
    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 20px;
    height: 420px;

    @media ${props => props.theme.device.mobileL} {
      height: fit-content;
    }
  }
`;

const Background = styled.div`
  height: 70%;
  min-height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  filter: blur(2px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 50%;
    top: -31px;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.white};
    opacity: 0.6;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 75px 0 0;
  position: relative;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 33px;
    width: 100%;
  }
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    padding-top: 40px;
    margin-bottom: 0;
    width: 100%;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.black};
  margin-bottom: 10px;
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.black};

  @media ${props => props.theme.device.tablet} {
    font-size: 16px;
    width: 75%;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 30px;
  }
`;

const DescriptionSection = styled.div`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  > div {
    width: 48%;
  }

  ul {
    list-style: unset;
    padding-left: 20px;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 24px;
  }
  p {
    margin-bottom: 1rem;
  }

  @media ${props => props.theme.device.tablet} {
    font-size: 16px;
    width: 75%;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:first-child {
      margin-bottom: 20px;
    }
  }
`;

const HighlightsNewsContainer = styled.div`
  width: 50%;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }
`;

const Banner = styled.div`
  padding: 25px 0;

  @media ${props => props.theme.device.mobileL} {
    &:first-child {
      padding: 100px 0px 50px 0px;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const BannerRubrica = styled.div`
  padding: 25px 0;
  background-color: #222222;
  @media ${props => props.theme.device.mobileL} {
    &:first-child {
      padding: 100px 0px 50px 0px;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const BannerContainer = styled.div`
  ${Banner} {
    background-color: ${props => props.theme.colors.biscuit25};

    &:nth-child(odd) {
      background-color: ${props => props.theme.colors.biscuit50};
    }
    /* &:nth-child(2),
    &:nth-child(6) {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.darkGreen, 0.1)};
    }
    &:nth-child(4),
    &:nth-child(8) {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.darkGreen, 0.1)};
    } */
  }
`;

const Text = styled.span``;

export {
  Banner,
  BannerContainer,
  CourseInProgressContainer,
  Title,
  Subtitle,
  HeaderContainer,
  TitleWrapper,
  Background,
  HighlightsNewsContainer,
  Text,
  BannerRubrica,
  DescriptionSection
};
