import styled from 'styled-components';

const Container = styled.div`
  display: block;
`;

const TableCardContainer = styled.div`
  width: 100%;
`;

const Card = styled.div`
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.lightGreen};
  margin-bottom: 10px;
  cursor: pointer;
  
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 50px;
  }
`;

const CardContent = styled.div`
  width: 100%;

  &.name,
  &.title {
    width: 100%;
  }

  &.courses {
    &.name {
      order: 1;
    }
    &.code {
      order: 2;
    }
    &.status {
      order: 3;
    }
    &.subscriptiondate {
      order: 4;
    }
    &.deadline {
      order: 5;
    }
    &.completiondate {
      order: 6;
    }
    &.credits {
      order: 7;
    }
    &.time {
      order: 8;
    }
    &.score {
      order: 9;
    }
  }
  &.certification {
    @media ${props => props.theme.device.mobileL} {
      &.title {
        order: 1;
      }
      &.code {
        order: 3;
      }
      &.description {
        order: 2;
        width: 100%;
      }
      &.startdate {
        order: 4;
      }
      &.deadline {
        order: 5;
      }
      &.renew {
        order: 6;
      }
    }
  }
`;

const CardHead = styled.div`
  color: ${props => props.theme.colors.primary.white};
  background-color: ${props => props.theme.colors.primary.red};
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 11px 14px;
  text-align: start;
`;

const CardItem = styled.div`
  color: ${props => props.theme.colors.gray};
  
  &.has-icon {
    div {
      display: flex;
      align-items: center;
      
      .icon-badge {
        margin-left: 5px;
      }
    }
  }
`;

const CourseStatusContainer = styled.div`
  width: max-content;
`;

export const Description = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 11px 14px;

`;

export {
  Container,
  CourseStatusContainer,
  Card,
  CardContent,
  CardHead,
  CardItem,
  TableCardContainer
};
