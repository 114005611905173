const MainTitle = 'Banca 5';

const ENV_BASE_PATH = {
  DEV: null,
  SIT: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};

const basePath =
  ENV_BASE_PATH[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const baseRoutes = {
  authSSO: {
    path: `${basePath}auth`,
    title: `Auth - ${MainTitle}`
  },
  authOTP: {
    path: `${basePath}auth/otp`,
    title: `Auth OTP - ${MainTitle}`
  },
  auth: {
    path: `${basePath}auth/manual`,
    title: `Auth - ${MainTitle}`
  },
  resetAuth: {
    path: `${basePath}reset-auth`,
    title: `Reset Auth - ${MainTitle}`
  },
  signup: {
    path: `${basePath}signup`,
    title: `Signup - ${MainTitle}`
  },
  home: {
    path: `${basePath}`,
    title: `Home - ${MainTitle}`
  },
  dashboard: {
    path: `${basePath}dashboard`,
    title: `Dashboard - ${MainTitle}`
  },
  mission: {
    path: `${basePath}mission`,
    title: `${MainTitle}`
  },
  values: {
    path: `${basePath}values`,
    title: `${MainTitle}`
  },
  organization: {
    path: `${basePath}organization`,
    title: `${MainTitle}`
  },
  maintenance: {
    path: `${basePath}maintenance`,
    title: `${MainTitle}`
  },
  leadership: {
    path: `${basePath}organization`,
    title: `Il gruppo - ${MainTitle}`
  },
  procedures: {
    path: `${basePath}procedures`,
    title: `Procedure - ${MainTitle}`
  },
  news: {
    path: `${basePath}news`,
    title: `News - ${MainTitle}`
  },
  products: {
    path: `${basePath}products`,
    title: `Products - ${MainTitle}`
  },
  lifeIsp: {
    path: `${basePath}life-isp`,
    title: `LifeISP - ${MainTitle}`
  },
  whatChange: {
    path: `${basePath}what-change`,
    title: `WhatChange - ${MainTitle}`
  },
  compulsarytraining: {
    path: `${basePath}compulsary-training`,
    title: `Obbligatory - ${MainTitle}`
  },
  /* notifications: {
    path: `${basePath}notifications`,
    title: `Notifications - ${MainTitle}`
  }, */
  favorites: {
    path: `${basePath}favorites`,
    title: `Favorites - ${MainTitle}`
  },
  mostViewed: {
    path: `${basePath}mostViewed`,
    title: `MostViewed - ${MainTitle}`
  },
  guidebook: {
    path: `${basePath}guidebook`,
    title: `Manuali - ${MainTitle}`
  },
  integrationagenda: {
    path: `${basePath}integration-agenda`,
    title: `Agenda   - ${MainTitle}`
  },
  integrationagendadetails: {
    path: `${basePath}integration-agenda/details`,
    title: `Agenda  - ${MainTitle}`
  },
  toj: {
    path: `${basePath}toj`,
    title: `Toj  - ${MainTitle}`
  },
  survey: {
    path: `${basePath}survey`,
    title: `Survey  - ${MainTitle}`
  },
  surveydetails: {
    path: `${basePath}survey/details`,
    title: `Survey - ${MainTitle}`
  },
  faq: {
    path: `${basePath}learning-tips`,
    title: `Learning Tips - ${MainTitle}`
  },
  classrooms: {
    path: `${basePath}classrooms`,
    title: `Classrooms - ${MainTitle}`
  },
  badges: {
    path: `${basePath}badges`,
    title: `Badges - ${MainTitle}`
  },
  collection: {
    path: `${basePath}collection`,
    title: `Collection - ${MainTitle}`
  },
  smartWorking: {
    path: `${basePath}smart-working`,
    title: `Smart Working - ${MainTitle}`
  },
  course: {
    path: `${basePath}course`,
    title: `Course - ${MainTitle}`
  },
  stcAll: {
    path: `${basePath}stc-all`,
    title: `all-course - ${MainTitle}`
  },
  stcImproved: {
    path: `${basePath}improved`,
    title: `Improved - ${MainTitle}`
  },
  stcConnected: {
    path: `${basePath}connected`,
    title: `Connected - ${MainTitle}`
  },
  rubricChange: {
    path: `${basePath}rubrica`,
    title: `Rubrica del cambiamento - ${MainTitle}`
  },
  social: {
    path: `${basePath}social`,
    title: `Social - ${MainTitle}`
  },
  webinar: {
    path: `${basePath}webinar`,
    title: `Webinar - ${MainTitle}`
  },
  lessons: {
    path: `${basePath}lessons`,
    title: `Lessons - ${MainTitle}`
  },
  pill: {
    path: `${basePath}pill`,
    title: `Pill - ${MainTitle}`
  },
  search: {
    path: `${basePath}search`,
    title: `Search - ${MainTitle}`
  },
  listPills: {
    path: `${basePath}list-pills`,
    title: `Pills - ${MainTitle}`
  },
  /*productsAndServices: {
    path: `${basePath}products-and-services`,
    title: `Products and Services - ${MainTitle}`
  },
  commercialGap: {
    path: `${basePath}commercial-gap`,
    title: `Commercial Gap - ${MainTitle}`
  },*/
  notFound: {
    path: `${basePath}404`,
    title: 'Not Found'
  },
  logout: {
    path: `${basePath}logout`,
    title: 'Logout'
  },
  processesProcedures: {
    path: `${basePath}processes-and-procedures`,
    title: 'Process and Procedures'
  },
  productsServices: {
    path: `${basePath}products-and-services`,
    title: 'Products and Services'
  },
  roleTraining: {
    path: `${basePath}role-training`,
    title: 'Role Training'
  }
};

//dashboard sections
const routes = {
  ...baseRoutes,
  statistics: {
    path: `${baseRoutes.dashboard.path}/statistics`,
    title: `Dashboard - ${MainTitle}`
  },
  additionalInfo: {
    path: `${baseRoutes.dashboard.path}/additional-info`,
    title: `Additional Informations - ${MainTitle}`
  },
  courses: {
    path: `${baseRoutes.dashboard.path}/courses`,
    title: `Courses - ${MainTitle}`
  },
  certification: {
    path: `${baseRoutes.dashboard.path}/certification`,
    title: `Certification - ${MainTitle}`
  }
};

export default routes;
