import React from 'react';
import PropTypes from 'prop-types';

import { DownloadLinkContainer, Icon, Link } from './style';
import Roboto from '../../typography/roboto';
import { apiHost } from '../../../app/api/config';

const DownloadLink = ({ url }) => {
  const linkUrl = `${apiHost}${url}`.replace('/api/api/', '/api/');
  linkUrl.replace('//', '/');

  return (
    <DownloadLinkContainer>
      <Link href={linkUrl} target="_blank">
        <Roboto type="status">Scarica</Roboto>
        <Icon className="icon-arrow-right ml-1" />
      </Link>
    </DownloadLinkContainer>
  );
};

DownloadLink.propTypes = {
  url: PropTypes.string.isRequired
};

export default DownloadLink;
