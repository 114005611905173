import { delay, takeLatest, put, call, select } from 'redux-saga/effects';

import {
  LOADER_CLOSE,
  LOADER_OPEN,
  PATHS_MAPPING_GET,
  USER_INFO_GET
} from '../actions';

import user from '../../api/user';

function* userInfoGet() {
  yield delay(250);
  const { data } = yield select(state => state.user);
  if (!(data && data.name)) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: { data: response } = {} } = yield call(user.getInfo);
      yield put({ type: USER_INFO_GET._SUCCESS, response });

      if (response?.path) {
        yield put({
          type: PATHS_MAPPING_GET._REQUEST,
          pid: response.path?.tid
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > user > userInfoGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* userInfoGetWatch() {
  yield takeLatest(USER_INFO_GET._REQUEST, userInfoGet);
}

export default [userInfoGetWatch()];
