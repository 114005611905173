import {
  takeLatest, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { TOJ_CONTENT_GET, TOJ_LIST_GET } from '../actions/toj';
import toj from '../../api/toj';

function* getTojContent({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(toj.getTojContent, filters);
    yield put({ type: TOJ_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_CONTENT_GET._ERROR, error });
    console.log('sagas > toj > getTojContent', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTojContentWatch() {
  yield takeLatest(TOJ_CONTENT_GET._REQUEST, getTojContent);
}

function* getTojList({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(toj.getTojList, filters);
    yield put({ type: TOJ_LIST_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_LIST_GET._ERROR, error });
    console.log('sagas > toj > getTojList', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTojListWatch() {
  yield takeLatest(TOJ_LIST_GET._REQUEST, getTojList);
}

export default [
  getTojContentWatch(),
  getTojListWatch()
];
