import moment from 'moment';

const dateFormat = (date, type, format) => {
  if (format) {
    return moment(date * 1000)
      .format(format);
  }

  switch (type) {
    case 'short':
      return moment(date * 1000)
        .format('DD/MM/YYYY');
    case 'news':
      return moment(date * 1000)
        .format('DD MMMM YYYY');
    default:
      return moment(date * 1000)
        .format('DD/MM/YYYY - HH:mm');
  }
};

export default dateFormat;
