import {
  takeLatest, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import mostviewed from '../../api/mostviewed';
import {
  MOST_VIEWED_LIST_GET, MOST_VIEWED_NODE_ACTION
} from '../actions/mostviewed';


function* mostviewedListGet({ itemPerPage = 24, filters = {}, loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const pagination = calcPagination(undefined, itemPerPage);
    if (pagination && !pagination.isLastPage) {
      const complete_filters = { ...filters, ...pagination };
      const { data: { data: response } } = yield call(mostviewed.getMostViewedList, complete_filters);
      yield put({ type: MOST_VIEWED_LIST_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > mostviewed > mostviewedListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* mostviewedListGetWatch() {
  yield takeLatest(MOST_VIEWED_LIST_GET._REQUEST, mostviewedListGet);
}

function* mostviewedNodeAction({ nid, action_type, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        action_type,
        node_type
      };
      yield call(mostviewed.mostViewedNodeAction, payload);
      yield put({ type: MOST_VIEWED_NODE_ACTION._SUCCESS });
      yield put({ type: MOST_VIEWED_LIST_GET._REQUEST, loader: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > mostviewed > mostviewedNodeAction', err);
    }
  }
}

function* mostviewedNodeActionWatch() {
  yield takeLatest(MOST_VIEWED_NODE_ACTION._REQUEST, mostviewedNodeAction);
}

export default [
  mostviewedListGetWatch(),
  mostviewedNodeActionWatch()
];
