import styled from 'styled-components';

const EditorialPageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 101px;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    padding-top: 20px;
  }
`;


export { EditorialPageContainer };
