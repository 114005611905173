import styled from 'styled-components';
import { HeaderContent, InfoContainer } from '../CollectionsCardContainer/style';

export const ContentsCatalogHeaderContent = styled(HeaderContent)``;
export const ContentsCatalogInfoContainer = styled(InfoContainer)``;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
  margin-bottom: 10px;
`;

export const ContentsCatalogContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  > div:not(:last-child) {
    margin-right: 15px;
  }
  
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    
    > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;
