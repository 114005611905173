import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  SEARCH_COACHEE,
  SEARCH_COLLEAGUE,
  SEARCH_LIST_GET,
  SEARCH_STC_COURSES_LIST_GET
} from '../actions/search';

import search from '../../api/search';
import stc_course from '../../api/stc_course';
import routes from '../../../routes';

function* searchListGet() {
  try {
    yield put({ type: LOADER_OPEN });

    const search_word = yield select(state => state.search.search_word || '');
    const filters = {
      title: search_word
    };

    const response = yield call(search.getSearchList, filters);
    const { courses } = response.data.data;
    yield put({ type: SEARCH_LIST_GET._SUCCESS, response: courses });
    yield put(push(routes.search.path));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > search > searchListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* searchListGetWatch() {
  yield takeLatest(SEARCH_LIST_GET._REQUEST, searchListGet);
}

function* searchColleagues({ user: mail }) {
  try {
    if (mail) {
      const {
        data: { user_type: role }
      } = yield select(state => state.user || '');
      const filter = {
        mail,
        role,
        skip: 0,
        take: 25
      };
      const { data: { data: { items: users = [] } = {} } = {} } = yield call(
        search.getColleaguesList,
        filter
      );
      yield put({ type: SEARCH_COLLEAGUE._SUCCESS, users });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > search > searchListGet', err);
  }
}

function* searchColleaguesWatch() {
  yield takeLatest(SEARCH_COLLEAGUE._REQUEST, searchColleagues);
}

function* searchCoachee({ user: mail }) {
  try {
    const filters = {
      mail,
      role: 'student',
      skip: 0,
      take: 25
    };
    const { data: { data: { items: coachees = [] } = {} } = {} } = yield call(
      search.getCoachee,
      filters
    );
    yield put({ type: SEARCH_COACHEE._SUCCESS, coachees });
  } catch (error) {
    yield put({ type: SEARCH_COACHEE._ERROR, error });
    console.log('sagas > coachee > searchCoachee', error);
  }
}

function* searchCoacheeWatch() {
  yield takeLatest(SEARCH_COACHEE._REQUEST, searchCoachee);
}

function* courseListGet({ path_ids, itemPerPage, title }) {
  try {
    yield put({ type: LOADER_OPEN });
    const response = {};
    for (const p of path_ids) {
      const filters = {
        path: p,
        items_per_page: itemPerPage,
        title
      };
      const {
        data: { data }
      } = yield call(stc_course.getStcCourseList, filters);
      if (data?.total_items > 0) response[p] = data;
    }
    yield put({ type: SEARCH_STC_COURSES_LIST_GET._SUCCESS, response });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > search > courseListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* courseListGetWatch() {
  yield takeLatest(SEARCH_STC_COURSES_LIST_GET._REQUEST, courseListGet);
}

export default [
  searchListGetWatch(),
  searchColleaguesWatch(),
  searchCoacheeWatch(),
  courseListGetWatch()
];
