import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Editorial } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import { BrandGroupContainer } from './style';
import { EDITORIAL_GET } from '../../redux/actions/editorial';

const EditorialPage = ({ getEditorialPage, editorial }) => {
  let editorialPage = {};
  const location = useLocation();
  const alias = location.pathname.replace('/', '');
  const { [alias]: page } = editorial;

  useEffect(() => {
    window.scrollTo(0, 0);
    getEditorialPage(alias);
  }, [alias, getEditorialPage]);

  if (page) {
    const articles = [];
    editorialPage = Object.keys(page).reduce((acc, key) => {
      const { type } = page[key];
      switch (type) {
        case 'article':
          return {
            ...acc,
            [type]: page[key].data
          };
        case 'body':
        case 'images':
          return {
            ...acc,
            [type]: page[key].data[type]
          };
        case 'file_article':
          articles.push({
            type: page[key].data.media_position,
            data: {
              ...{ article: page[key].data.article[0].data },
              ...{ file: page[key].data.file[0].data }
            }
          });
          return {
            ...acc,
            paragraph: [...articles]
          };
        default:
          return {
            ...acc,
            [type]: page[key].data
          };
      }
    }, {});
  }
  return (
    <Container className="p-0">
      <BrandGroupContainer>
        <Editorial
          content={editorialPage}
        />
      </BrandGroupContainer>
    </Container>
  );
};

EditorialPage.propTypes = {
  // HOC (connect, state)
  editorial: PropTypes.object,
  // HOC (connect, dispatch)
  getEditorialPage: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    editorial: state.editorial
  }),
  dispatch => ({
    getEditorialPage: alias => dispatch({ type: EDITORIAL_GET._REQUEST, alias })
  })
)(EditorialPage);
