import styled, { css } from 'styled-components';

export const CollectionContainer = styled.div`
  color: ${props => props.theme.colors.white};
`;

export const HeaderContainer = styled.div`
  background-color: ${props => props.theme.colors.greyDark};
  color: ${props => props.theme.colors.black};
  padding: 20px 53px 25px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 4px;

  @media ${props => props.theme.device.mobileL} {
    border-radius: 0;
  }
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 75%;
`;

export const ProgressContainer = styled.div`
  width: 25%;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }
`;

export const ProgressWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  div:last-child {
    text-align: right;
  }
`;

export const Category = styled.div`
  padding: 3px 10px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.orange};
  margin-bottom: 10px;
  text-transform: uppercase;
  width: fit-content;
  display: table;
`;

export const MainSection = styled.div`
  margin-top: 20px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  background-color: ${props =>
    props.theme.mixin.rgba(props.theme.colors.black, 0.4)};
  min-height: 550px;
  width: 100%;
`;

export const VideoInfo = styled.div`
  color: ${props => props.theme.colors.white};
  // border-bottom: 1px solid ${props => props.theme.colors.brownGrey};
`;

export const VideoInfoWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const VideoSocialWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  div:first-child {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      margin-right: 10px;
    }
  }
`;

export const TagContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 20px 0;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const TagWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;

  span:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Tag = styled.span`
  background-color: ${props => props.theme.colors.greyDark};
  height: 28px;
  border-radius: 14px;
  color: ${props => props.theme.colors.black};
  padding: 6px 10px;
`;

export const CoursesContainer = styled.div``;

export const CoursesHeader = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;

  font-size: 12px;

  span {
    span {
      margin-right: 6px;
    }
  }
`;

export const CoursesHeaderClock = styled.span`
  font-size: 16px;
  margin-right: 5px;
  margin-left: auto;
`;

export const CoursesHeaderLength = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > div {
    width: fit-content;
    width: auto;
  }

  > div:first-child {
    margin-right: 10px;
  }
`;

export const CoursesHeaderDuration = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;

  > div {
    width: fit-content;
  }
`;

export const CoursesList = styled.div`
  height: 100%;
  max-height: 480px;
  width: 100%;
  -ms-overflow-style: scrollbar; /* IE and Edge */
  scrollbar-width: scrollbar; /* Firefox */
  scrollbar-base-color: #5c5c5c;
  scrollbar-face-color: #5c5c5c;
  scrollbar-3dlight-color: #5c5c5c;
  scrollbar-highlight-color: #5c5c5c;
  scrollbar-track-color: #323232;
  scrollbar-arrow-color: #323232;
  scrollbar-shadow-color: #5c5c5c;
  scrollbar-dark-shadow-color: #5c5c5c;

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  overflow-y: scroll;
`;

export const IconCompleted = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.darkGrassGreen};
  background: white;
  overflow: hidden;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.white};
  border: 0.5px solid ${props => props.theme.colors.white};
`;

export const Icon = styled.span``;

export const CourseContainer = styled.div`
  align-items: center;
  background-color: ${props =>
    props.active
      ? props.theme.colors.biscuit25
      : props.theme.mixin.rgba(props.theme.colors.biscuit25, 0.5)};
  /* border-radius: 10px; */
  cursor: ${props => (props.blocked ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px 10px 10px 5px;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  opacity: ${props => (props.blocked ? 0.4 : 1)};

  &:after {
    content: '';
    border-bottom: 1px solid ${props => props.theme.colors.black};
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${props =>
    !props.blocked &&
    css`
      &:hover {
        background-color: ${props.theme.colors.biscuit25};
      }
    `};
`;

export const CourseElementCounter = styled.div`
  margin-right: 5px;
  font-size: 12px;
  width: 14px;
  text-align: center;
  color: ${props => props.theme.colors.brownGrey};
`;

export const CourseElementCompleted = styled.div``;

export const Cover = styled.div`
  background-image: url(${props => props.cover});
  background-size: cover;
  border-radius: 4px;
  min-height: 60px;
  min-width: 60px;
`;

export const CourseElementDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const CourseTitle = styled.div`
  color: ${props => props.theme.colors.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
  font-size: 12px;
  white-space: initial;
  text-overflow: ellipsis;
  max-width: 140px;

  > div.tooltip {
    display: inline;
    position: relative;

    .tooltip:hover:after {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      background: #444;
      border-radius: 8px;
      color: #fff;
      content: attr(title);
      margin: -82px auto 0;
      font-size: 16px;
      padding: 13px;
      width: 220px;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: #444 transparent;
      border-width: 12px 6px 0 6px;
      content: '';
      left: 45%;
      bottom: 30px;
      position: absolute;
    }
  }
`;

export const CourseDuration = styled.span`
  font-size: 8px;
`;

export const CourseElementHeart = styled.span`
  margin-left: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > button {
    width: 100px;
    margin-left: 10px;
  }
`;

export const Iframe = styled.iframe`
  min-height: 550px;
  height: auto;
  height: calc(${props => props.frameHeight} + 30px);
  overflow: hidden;
  width: 100%;
`;

export const Social = styled.div`
  color: ${props =>
    props.isActive
      ? props.theme.colors.azureDark
      : props.theme.colors.brownGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    font-size: 20px;
    margin-right: 9px;
  }
  div,
  span {
    cursor: pointer;
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div,
    span {
      opacity: 0.6;
    }
  }
`;

export const Share = styled.div`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    margin-right: 9px;
    font-size: 20px;
  }
  div,
  span {
    cursor: pointer;
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div,
    span {
      color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
    }
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 18px;
`;

export const Like = styled.div`
  > div {
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
`;
