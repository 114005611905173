import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { Button } from '../../atoms';

import {
  VideoInfo,
  VideoInfoWrapper,
  VideoSocialWrapper,
  Social,
  Description,
  Like,
  ButtonContainer,
  DownloadPDF
} from './style';
import Gill from '../../typography/gill';

const CourseDetailInfo = ({
  btnText, btnSource, btnOnClick, source,
  title, description, onClickLike, onClickDislike,
  btnTextNext, btnSourceNext, btnOnClickNext
}) => (
  <VideoInfo>
    <ButtonContainer>
      {btnSource && (
      <Button
        btnText={btnText}
        onClick={btnOnClick}
        height={20}
      />
      )}
      {btnSourceNext && (
        <Button
          btnText={btnTextNext}
          onClick={btnOnClickNext}
          height={20}
        />
      )}
    </ButtonContainer>
    <VideoInfoWrapper>
      <Gill type="sectionTitle">{title}</Gill>
      {source.source_type === 'document' && (
        <DownloadPDF href={source.document.url} target="_blank">
          <span className="icon-download"></span>Download PDF
        </DownloadPDF>
      )}
      {/* <Social>
        <span className="icon-visualizzazioni" />{views}
      </Social> */}
    </VideoInfoWrapper>
    <Description>
      <Gill type="description14">{description}</Gill>
    </Description>
    <VideoSocialWrapper>
      <Like>
        <Social isActive={source?.user_actions?.like}>
          <span className="icon-like" onClick={onClickLike} />
          <Gill type="like">
            {source?.stats?.like}
          </Gill>
        </Social>
        <Social isActive={source?.user_actions?.dislike}>
          <span className="icon-nonutile" onClick={onClickDislike} />
          <Gill type="like">
            {source?.stats?.dislike}
          </Gill>
        </Social>
      </Like>
    </VideoSocialWrapper>
  </VideoInfo>
);

CourseDetailInfo.propTypes = {
  btnText: PropTypes.string,
  btnSource: PropTypes.string,
  btnOnClick: PropTypes.func,
  btnTextNext: PropTypes.string,
  btnSourceNext: PropTypes.string,
  btnOnClickNext: PropTypes.func,
  source: PropTypes.object,
  views: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  onClickLike: PropTypes.func,
  onClickDislike: PropTypes.func
};

export default CourseDetailInfo;
