const FontBase = 16;
const NavBarHeight = 70;
const NavBarHeightMobile = 80;
const SubNavBarHeight = 60;
const SubNavBarHeightMobile = 60;
const FooterHeight = 0;
const FooterHeightMobile = 0;
const ButtonGoToTop = 80;

const ENV_PORTAL_REDIRECT = {
  DEV: null,
  SIT: null,
  PRE_PROD: null,
  PROD: 'https://portale.banca5.com',

  DEFAULT: 'https://portale.collaudo.banca5.com:2443'
};

const ENV_BO_REDIRECT = {
  DEV: 'https://devb5.ispfdevelop.com/',
  SIT: 'https://apisitb5.ispfdevelop.com/',
  PRE_PROD: 'https://apidemob5.ispfdevelop.com/',
  PROD: 'https://apielearning.banca5.com/',

  DEFAULT: 'https://apielearning.banca5.com/'
};

const PortalHomepage = ENV_PORTAL_REDIRECT[process.env.REACT_APP_DEPLOY_ENV] || ENV_PORTAL_REDIRECT.DEFAULT;
const BOHomepage = ENV_BO_REDIRECT[process.env.REACT_APP_DEPLOY_ENV] || ENV_BO_REDIRECT.DEFAULT;

export {
  PortalHomepage, BOHomepage, FontBase, NavBarHeight, NavBarHeightMobile, SubNavBarHeight, SubNavBarHeightMobile, FooterHeight, FooterHeightMobile, ButtonGoToTop
};
