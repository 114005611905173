import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorialImageRight, EditorialImageLeft
} from '..';
import { Video, Body, BodyDouble } from '../../atoms';

let counterSwitch = 0;

const SwitcherEditorialContent = ({ items }) => {
  const switcher = (item) => {
    counterSwitch += 1;
    const { type, data } = item;
    switch (type) {
      case 'double_body':
        return <BodyDouble key={`editorialcontent-${counterSwitch}`} content={data} />;
      case 'body':
        return <Body key={`editorialcontent-${counterSwitch}`} content={data?.body} />;
      case 'video_text_overlay':
        return (
          <Video
            key={`video-${counterSwitch}`}
            videoUrl={data.file ? data.file[0]?.data?.video?.url : null}
            videoTitle={data.article ? data?.article[0].data?.title : null}
            videoSubTitle={data.article ? data?.article[0].data?.body : null}
            imageUrl={data.file ? data.file[0]?.data?.image?.url : null}
          />
        );
      case 'file_article':
        return data.media_position === 'Left'
          ? <EditorialImageLeft key={`editorialcontent-${counterSwitch}`} content={data} />
          : <EditorialImageRight key={`editorialcontent-${counterSwitch}`} content={data} />;
      default:
        return <></>;
    }
  };
  return (
    <>
      {items.map((item) => (
        switcher(item)
      ))}
    </>
  );
};

SwitcherEditorialContent.propTypes = {
  items: PropTypes.array.isRequired
};

export default SwitcherEditorialContent;
