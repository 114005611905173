import React from 'react';
import PropTypes from 'prop-types';

import { ButtonContainer, Date } from './style';
import Gill from '../../typography/gill';

const Button = ({
  btnText, onClick, disabled, type, size, height, date
}) => (
  <ButtonContainer onClick={onClick} disabled={disabled} type={type} size={size} height={height}>
    <Gill type="button">{btnText}</Gill>
    {type === 'THIRD' && (
      <Date><Gill type="productCardDetail">{date}</Gill></Date>
    )}
  </ButtonContainer>
);

Button.TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  THIRD: 'THIRD'
};

Button.SIZE = {
  DEFAULT: 'DEFAULT',
  FULL: 'FULL'
};

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(Button.SIZE)),
  type: PropTypes.oneOf(Object.keys(Button.TYPE)),
  height: PropTypes.number
};

Button.defaultProps = {
  size: Button.SIZE.DEFAULT,
  type: Button.TYPE.PRIMARY
};

export default Button;
