import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import { CardContainer, MostViewedContainer } from './style';
import { ResultContainer, Title } from '../News/style';
import Arial from '../../../ui/typography/arial';
import routes from '../../../routes';
import { GeneralCard, NoResult } from '../../../ui/atoms';
import { MOST_VIEWED_LIST_GET } from '../../redux/actions/mostviewed';
import { SMARTWORKING_NODE_ACTION } from '../../redux/actions/smartworking';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const MostViewed = ({
  mostviewed,
  getMostViewed,
  setAsFavoriteSmartWorking,
  pushUrl,
  vocabulary
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getMostViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRedirectPath = ({ path, id }) => {
    pushUrl(`${routes.course.path}/${path}/${id}`);
  };

  return (
    <>
      <MostViewedContainer>
        <Container className="p-0">
          <Title>
            <Arial type="newsTitle" vocabulary="mostViewedTitle">
              {getValueVocabulary(
                vocabulary.mostViewedTitle,
                'mostViewedTitle'
              )}
            </Arial>
          </Title>
          <ResultContainer>
            {(mostviewed || []).length > 0 ? (
              mostviewed.map(item => (
                <CardContainer sm={12} md={6} lg={3} key={item.id}>
                  <GeneralCard
                    key={`product-${item._id}`}
                    item={item}
                    onClickCard={() => getRedirectPath(item)}
                    onClickFavorite={() => () =>
                      setAsFavoriteSmartWorking(item.id)}
                    type={GeneralCard.TYPE.SMARTWORKING}
                    colorTag={item.category_color}
                  />
                </CardContainer>
              ))
            ) : (
              <NoResult />
            )}
          </ResultContainer>
        </Container>
      </MostViewedContainer>
    </>
  );
};

MostViewed.propTypes = {
  // HOC (connect, state)
  mostviewed: PropTypes.array,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  getMostViewed: PropTypes.func.isRequired,
  setAsFavoriteSmartWorking: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { items: mostviewed = [] } = state.mostviewed;
    return { mostviewed, vocabulary };
  },
  dispatch => ({
    getMostViewed: () =>
      dispatch({ type: MOST_VIEWED_LIST_GET._REQUEST, itemPerPage: 100 }),
    setAsFavoriteSmartWorking: nid =>
      dispatch({
        type: SMARTWORKING_NODE_ACTION._REQUEST,
        nid,
        action_type: 'favourite',
        node_type: 'pill_collection'
      }),
    pushUrl: url => dispatch(push(url))
  })
)(MostViewed);
