import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CustomCarouselContainer } from './style';

const CustomCarousel = ({
  children, config, onSlideChange, isAgenda
}) => (
  <CustomCarouselContainer isAgenda={isAgenda}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading*/}
    <Slider {...config} afterChange={onSlideChange}>{children}</Slider>
  </CustomCarouselContainer>
);

CustomCarousel.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({}),
  onSlideChange: PropTypes.func
};

export default CustomCarousel;
