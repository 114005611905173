import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  ProductsContainer, CardContainer, TabsContainer, ProductsFilterContainer,
  ProductsFilterLabel, ProductsCustomSelectContainer, ProductsFilterPillsContainer,
  Background, HeaderContainer, SeeMore
} from './style';
import {
  ResultContainer
} from '../News/style';
import routes from '../../../routes';
import {
  CustomSelect, FilterPill, GeneralCard, Tabs, NoResult
} from '../../../ui/atoms';
import ProceduresDefaultCover from '../../../ui/assets/img/lifeIsp.jpg';
import Arial from '../../../ui/typography/arial';
import { getTagColor } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import {
  LIFE_ISP_GET,
  LIFE_ISP_NODE_ACTION,
  SET_SELECTED_FILTERS_LIFE_ISP,
  SET_SELECTED_LIFE_ISP
} from '../../redux/actions/life_isp';
import { withMediaQueries } from '../../hoc/withMediaQueries';

const defaultFamiglia = [
  //{ label: 'Tutti', value: 'all' }
];

const LifeISP = ({
  productsList, heroImage, getProducts, vocabulary,
  setSelectedProduct, setAsFavoriteProduct, pushUrl,
  mediaIsTablet, mediaIsLaptop, setSelectedProductFilters
}) => {
  const { products: { courses: list = [], filters: { categories = [] } = {}, total_items } = {}, selected_filters } = productsList;

  useEffect(() => {
    window.scrollTo(0, 0);
    if(Object.keys(selected_filters).length <= 0) {
      getProducts({},true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts]);

  const defaultTabs = [
    { label: 'Tutti', value: 'all' }
  ];

  const [activeTab, setActiveTab] = useState(selected_filters?.activeTab || defaultTabs[0]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState(selected_filters?.filters || []);
  const [selectedFilters, setSelectedFilters] = useState(selected_filters?.selectedFilters || []);
  const [getProductFilters, setGetProductFilters] = useState([]);

  useEffect(() => {
    setProducts(list);
    if (activeTab.value === 'all') {
      const payload = [...categories].filter(category => !!category.parent);
      setFilters(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList]);

  const onChangeTab = (tab) => {
    if (tab.value === 'all') {
      const payload = [...categories].filter(category => !!category.parent);
      setFilters(payload);
      getProducts({}, true);
    } else {
      const payload = [...categories].filter(category => category.parent === tab.value);
      setFilters(payload);
      const filter = { category_id: [tab.value, ...payload.map(item => item.id)].toString().replace(/,/g, ';') };
      setGetProductFilters(filter);
      getProducts(filter, true);
    }
    setSelectedFilters([]);
    setActiveTab(tab);
    setSelectedProductFilters({activeTab: tab, filters});
  };

  const onChangeFilter = (newFilters) => {
    if (newFilters.length) {
      const filter = { category_id: [...newFilters.map(item => item.value)].toString().replace(/,/g, ';') }
      setGetProductFilters(filter);
      getProducts(filter, true);
    } else {
      onChangeTab(activeTab);
    }
    setSelectedFilters(newFilters);
    setSelectedProductFilters({activeTab, selectedFilters: newFilters, filters});
  };

  const removeFilter = (item) => {
    const newFilters = (selectedFilters || []).filter(filter => filter.value !== item);
    if (newFilters.length) {
      const filter = { category_id: [...newFilters.map(filter => filter.value)].toString().replace(/,/g, ';') };
      setGetProductFilters(filter);
      getProducts(filter, true);
      setSelectedFilters(newFilters);
      setSelectedProductFilters({activeTab, selectedFilters: newFilters, filters});
    } else {
      onChangeTab(activeTab);
    }
  };

  const handleOnClickCard = (item) => {
    setSelectedProduct(item);
    pushUrl(`${routes.lifeIsp.path}/${item.id}`);
  };

  const onClickSeeMore = () => {
    getProducts(getProductFilters);
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage || ProceduresDefaultCover} />
      </HeaderContainer>
      <ProductsContainer>
        <Container className="p-0">
          <TabsContainer>
            <Tabs tabs={[...defaultTabs, ...categories.filter(category => !category.parent).map(({ id: value, name: label }) => ({ label, value }))]} onChange={onChangeTab} selected={activeTab} />
          </TabsContainer>
          <>
            <ProductsFilterLabel><Arial type="filterLabel" vocabulary="productsFilterLabel">{getValueVocabulary(vocabulary.productsFilterLabel, 'productsFilterLabel')}</Arial></ProductsFilterLabel>
            <ProductsFilterContainer>
              <ProductsCustomSelectContainer>
                <CustomSelect
                  options={[...defaultFamiglia, ...filters.map(({ id: value, name: label }) => ({ label, value }))]}
                  onChange={newValue => onChangeFilter(newValue || [])}
                  selected={selectedFilters}
                  placeholder={selectedFilters.length ? 'Seleziona' : 'Tutti'}
                  isMulti
                />
              </ProductsCustomSelectContainer>
              <ProductsFilterPillsContainer>
                {(selectedFilters || []).map(filter => <FilterPill onRemove={removeFilter} value={filter.value} label={filter.label} />)}
              </ProductsFilterPillsContainer>
            </ProductsFilterContainer>
          </>
          <ResultContainer>
            {(products || []).length > 0 ? (products.map((product) => (
              <CardContainer sm={12} md={6} lg={mediaIsTablet || mediaIsLaptop ? 4 : 3} key={product.id}>
                <GeneralCard
                  key={`product-${product.id}`}
                  item={product}
                  onClickCard={() => handleOnClickCard(product)}
                  onClickFavorite={() => setAsFavoriteProduct(product.id)}
                  type={GeneralCard.TYPE.PRODUCT}
                  colorTag={getTagColor(categories, product.category_id)}
                />
              </CardContainer>
            ))) : (<NoResult />)}
          </ResultContainer>
          {list.length < total_items && <SeeMore onClick={onClickSeeMore}>Mostra altri</SeeMore>}
        </Container>
      </ProductsContainer>
    </>
  );
};

LifeISP.propTypes = {
  // MediaQueries
  mediaIsTablet: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  productsList: PropTypes.object,
  heroImage: PropTypes.string,
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  getProducts: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setAsFavoriteProduct: PropTypes.func.isRequired,
  setSelectedProductFilters: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const productsList = state.lifeIsp;
    const { homeHeroImage: heroImage } = state.app.appConfig;
    const { vocabulary } = state.app;

    return {
      productsList,
      heroImage,
      vocabulary
    };
  },
  dispatch => ({
    getProducts: (filters, subset = false) => dispatch({
      type: LIFE_ISP_GET._REQUEST, filters, itemPerPage: 12, subset
    }),
    setSelectedProduct: (selected) => dispatch({ type: SET_SELECTED_LIFE_ISP, selected }),
    setSelectedProductFilters: (filters) => dispatch({ type: SET_SELECTED_FILTERS_LIFE_ISP, filters }),
    setAsFavoriteProduct: (nid) => dispatch({
      type: LIFE_ISP_NODE_ACTION._REQUEST, nid, action_type: 'favourite', node_type: 'course'
    }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(LifeISP));

