import React from 'react';
import PropTypes from 'prop-types';

import { SeeAllContainer } from './style';
import Arial from '../../typography/arial';

const SeeAll = ({
  label, onClick
}) => (
  <SeeAllContainer onClick={onClick}>
    <Arial type="newsAttachment">{label}</Arial>
    <span className="icon-arrow-right" />
  </SeeAllContainer>
);

SeeAll.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default SeeAll;
