import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  ProductCoursesContainer, Iframe, MainSection, Tag,
  TagContainer, ProductCourseContainer, TagWrapper,
  VideoContainer, ProductCourseElementCounter, ProductCourseCover,
  ProductCourseElementDetails, ProductCourseTitle, PdfContainer
} from './style';
import { BackButton } from '../../../ui/atoms';
import routes from '../../../routes';
import {
  Comments, CourseDetailHeader,
  CourseDetailInfo, CourseDetailPill, GuideboookCard
} from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import ShareModal from '../../../ui/components/Modals/ShareModal';
import { MODAL_OPEN } from '../../redux/actions';
import {
  CourseElementCompleted, IconCompleted
} from '../Collection/style';
import { Frame } from '../CompulsaryTrainingCourses/style';
import {
  LIFE_ISP_COMMENTS_GET,
  LIFE_ISP_COMMENTS_SET,
  LIFE_ISP_COURSES_GET, LIFE_ISP_NODE_ACTION,
  SELECTED_LIFE_ISP_GET
} from '../../redux/actions/life_isp';
import { getTagColor } from '../../../utils/common';
import { GuidebookItemColumn, GuidebookListColumn, RowGuidebook } from '../Guidebook/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const courseElement = ({
  cover, title, blocked, stats
} = {}, onClick, isActive = false, index) => (
  <ProductCourseContainer onClick={onClick} active={isActive} blocked={blocked}>
    <ProductCourseElementCounter>
      {index + 1}
    </ProductCourseElementCounter>
    <ProductCourseCover cover={cover} />
    <ProductCourseElementDetails>
      <ProductCourseTitle title={title} type="collectionCourseTitle">{title}</ProductCourseTitle>
    </ProductCourseElementDetails>
    <CourseElementCompleted>
      {stats?.status === 'completed' && <IconCompleted className="icon-completato" />}
    </CourseElementCompleted>
  </ProductCourseContainer>
);

const LifeISPDetail = ({
  collectionId, scormId, selected_product, scorms,
  comments, getSelectedLifeIsp, getLifeIspCourses,
  setLifeIspComments, getLifeIspComments, setLike, getProductCourses,
  setDislike, setViews, shareModal, pushUrl, requestedURL, isLoadingData,
  categories, vocabulary
}) => {
  const [scorm, setScorm] = useState({});
  const [scormFullscreen, setScormFullscreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setViews(collectionId);
    getSelectedLifeIsp(collectionId);
    getLifeIspCourses({ collectionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, setScormFullscreen]);

  useEffect(() => {
    if (scormId) getLifeIspComments(scormId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scormId]);

  useEffect(() => {
    if (!isLoadingData) {
      if ((scorms?.length && !scormId) || (scormId && scorms?.length && !scorms?.find(({ node_scorm_id } = {}) => node_scorm_id?.toString() === scormId?.toString()))) {
        const selectedScorm = scorms?.find(({ stats } = {}) => !(stats.status === 'completed'));
        selectedScorm ? pushUrl(`${routes.lifeIsp.path}/${collectionId}/${selectedScorm?.node_scorm_id}`) : pushUrl(`${routes.lifeIsp.path}/${collectionId}/${scorms[0]?.node_scorm_id}`);
        setScorm(selectedScorm || scorms[0]);
      } else if (scorms?.length && scormId) {
        let selectedScorm = scorms?.find(({ node_scorm_id, stats } = {}) => node_scorm_id?.toString() === scormId?.toString());
        selectedScorm = selectedScorm || scorms?.filter(({ stats } = {}) => !(stats.status === 'completed'));
        setScorm(selectedScorm);
      }
    }
    setScormFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scorms, scormId, isLoadingData]);

  const {
    description, tag, title
  } = scorm;

  //const percentage = ((scorms.filter(item => item.stats.status === 'completed').length / scorms.length) * 100).toFixed(0);

  const openCourse = ({ node_scorm_id, blocked }) => {
    if (!blocked) {
      pushUrl(`${routes.lifeIsp.path}/${collectionId}/${node_scorm_id}`);
      getLifeIspCourses({ collectionId });
    }
  };

  const handleNewCommentOnSubmit = (value) => {
    setLifeIspComments({
      id: scorm?.node_scorm_id,
      subject: scorm?.title,
      comment_body: value
    });
  };

  const onClickNext = () => {
    getSelectedLifeIsp(collectionId, true);
    getLifeIspCourses({ collectionId });
    pushUrl(`${routes.lifeIsp.path}/${collectionId}`);
  };

  const windowOpen = (uri) => {
    setScormFullscreen(true);
    const scormWindow = window.open(
      uri,
      '',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`
    );

    setInterval(() => {
      if (scormWindow.closed) {
        pushUrl(`${routes.lifeIsp.path}/${collectionId}`);
        window.location.reload();
        clearInterval();
      }
    }, 500);
  };

  return (
    <ProductCoursesContainer>
      <Container className="p-0">
        {requestedURL?.pathname === '/integration-agenda' ? (
          <BackButton url={`${routes.integrationagenda.path}${requestedURL?.search}`} />
        ) : (
          <BackButton url={routes.lifeIsp.path} />
          )}
        <CourseDetailHeader
          title={selected_product?.title}
          category={selected_product?.category}
          categoryColor={getTagColor(categories, selected_product?.category_id)}
          percentage={selected_product?.percentage}
          eyeIcon={scorms.length <= 1}
          views={selected_product?.views}
          durationPill={selected_product?.duration}
        />
        <MainSection>
          <Row>
            <Col sm={12} md={scorms.length > 1 ? 8 : 12} lg={scorms.length > 1 ? 9 : 12}>
              {scorm ? (
                <>
                  <VideoContainer>
                    {scormFullscreen
                      ? (<Frame><Arial type="editorialCardText">Aperto in modalità fullscreen</Arial></Frame>)
                      : (
                        <Iframe
                          // frameHeight={frameHeight}
                          title={scorm?.title}
                          src={scorm?.iframe_uri}
                          id="scorm_frame"
                        />
                      )
                    }
                  </VideoContainer>
                  <CourseDetailInfo
                    btnText="Fullscreen"
                    btnSource={scorm?.iframe_uri}
                    btnOnClick={() => windowOpen(scorm?.iframe_uri)}
                    source={scorm}
                    title={title}
                    description={description}
                    onClickLike={() => setLike(scorm.node_scorm_id)}
                    onClickDislike={() => setDislike(scorm.node_scorm_id)}

                    btnTextNext="Registra completamento"
                    btnSourceNext={scorms.length > 0}
                    btnOnClickNext={onClickNext}
                  />
                  {scorm?.files?.length > 0 && scorm?.stats?.status === 'completed'
                  && (
                    <PdfContainer>
                      <Arial type="newsTitle" vocabulary="pdfScorm">{getValueVocabulary(vocabulary.pdfScorm, 'pdfScorm')}</Arial>
                      <GuidebookListColumn md={12} sm={12} lg={12}>
                        <RowGuidebook>
                          {(scorm?.files || []).map((singleMaterials, i) => (
                            <GuidebookItemColumn key={i}>
                              <GuideboookCard guidebook={singleMaterials} />
                            </GuidebookItemColumn>
                          ))}
                        </RowGuidebook>
                      </GuidebookListColumn>
                    </PdfContainer>
                  )
                  }
                  {tag?.length ? (
                    <TagContainer>
                      <Arial type="tagTitle">TAG</Arial>
                      <TagWrapper>
                        {tag.map(t => <Tag key={t.id}><Arial type="tagText">{t.label}</Arial></Tag>)}
                      </TagWrapper>
                    </TagContainer>
                  ) : null}
                  <Comments list={comments} onSubmit={handleNewCommentOnSubmit} />
                </>
              ) : null}
            </Col>
            {scorms?.length > 1 && (
            <Col sm={12} md={4} lg={3}>
              <CourseDetailPill
                length={scorms.length}
                durationPill={selected_product?.duration}
                views={selected_product?.views}
              >
                {scorms.map((c, index) => <React.Fragment key={`scorm-${c.id}`}>{courseElement(c, () => openCourse(c), c.id?.toString() === scormId?.toString(), index)}</React.Fragment>)}
              </CourseDetailPill>
            </Col>
            )}
          </Row>
        </MainSection>
        <ShareModal />
      </Container>
    </ProductCoursesContainer>
  );
};

LifeISPDetail.propTypes = {
  collectionId: PropTypes.string.isRequired,
  scormId: PropTypes.string,

  // HOC (connect, state)
  selected_product: PropTypes.object,
  scorms: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.array,
  isLoadingData: PropTypes.bool,

  // HOC (connect, dispatch)

  getSelectedLifeIsp: PropTypes.func.isRequired,
  getLifeIspCourses: PropTypes.func.isRequired,
  setLifeIspComments: PropTypes.func.isRequired,
  getLifeIspComments: PropTypes.func.isRequired,
  setLike: PropTypes.func.isRequired,
  setDislike: PropTypes.func.isRequired,
  setViews: PropTypes.func.isRequired,
  shareModal: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      selected_product, scorms, comments, isLoadingData, products: { filters: { categories = [] } = {} } = {}
    } = state.lifeIsp;
    const { requestedURL, vocabulary } = state.app;
    return {
      selected_product, scorms, comments, requestedURL, isLoadingData, categories, vocabulary
    };
  },
  dispatch => ({
    getSelectedLifeIsp: (id, refresh = false) => dispatch({ type: SELECTED_LIFE_ISP_GET._REQUEST, id, refresh }),
    getLifeIspCourses: ({ collectionId }) => dispatch({ type: LIFE_ISP_COURSES_GET._REQUEST, collectionId }),
    setLifeIspComments: (params) => dispatch({ type: LIFE_ISP_COMMENTS_SET._REQUEST, ...params }),
    getLifeIspComments: (id) => dispatch({ type: LIFE_ISP_COMMENTS_GET._REQUEST, id }),
    setLike: (nid) => dispatch({
      type: LIFE_ISP_NODE_ACTION._REQUEST, nid, action_type: 'like', node_type: 'scorm'
    }),
    setDislike: (nid) => dispatch({
      type: LIFE_ISP_NODE_ACTION._REQUEST, nid, action_type: 'dislike', node_type: 'scorm'
    }),
    setViews: (nid) => dispatch({
      type: LIFE_ISP_NODE_ACTION._REQUEST, nid, action_type: 'views', node_type: 'course'
    }),
    shareModal: () => dispatch({ type: MODAL_OPEN, id: ShareModal.id }),
    pushUrl: url => dispatch(push(url))
  })
)(LifeISPDetail);
