import { createAsyncActionType } from './index';

export const COLLECTIONS_GET = createAsyncActionType(
  'collections',
  'COLLECTIONS_GET'
);

export const COURSES_GET = createAsyncActionType(
  'collections',
  'COURSES_GET'
);

export const COURSE_GET = createAsyncActionType(
  'collections',
  'COURSE_GET'
);

export const LESSONS_GET = createAsyncActionType(
  'collections',
  'LESSONS_GET'
);

export const COLLECTION_LIST_GET = createAsyncActionType('collection', 'COLLECTION_LIST_GET');
export const SELECTED_COLLECTION_GET = createAsyncActionType('collection', 'SELECTED_COLLECTION_GET');
export const SET_SELECTED_COLLECTION = '@@collection/SET_SELECTED_COLLECTION';
export const COLLECTION_COURSES_GET = createAsyncActionType('collection', 'COLLECTION_COURSES_GET');

export const COLLECTION_NODE_ACTION = createAsyncActionType('collection', 'COLLECTION_NODE_ACTION');
export const COLLECTION_COMMENTS_GET = createAsyncActionType('collection', 'COLLECTION_COMMENTS_GET');
export const COLLECTION_COMMENTS_SET = createAsyncActionType('collection', 'COLLECTION_COMMENTS_SET');
