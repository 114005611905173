import React from 'react';
import PropTypes from 'prop-types';

import {
  EditorialGridContainer, LightBox, ColumnRight, BoxText, ColumnLeft,
  ContainerVideo, BoxTextTitle, RowBottom, BoxTextDescription
} from './style';
import { Row, Col } from '../../gridSystem';
import Arial from '../../typography/arial';
import { VideoWithModal } from '../../atoms';

const EditorialGrid = ({
  file, text, article1, article2
}) => (
  <EditorialGridContainer className="p-0">
    <Row className="no-gutters">
      <ColumnLeft md={8} sm={12}>
        <RowBottom className="no-gutters">
          {text && (
          <LightBox md={11}>
            <Arial type="EditorialAboutText">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Arial>
          </LightBox>
          )}
          {file
          && (
          <ContainerVideo md={6}>
            <VideoWithModal
              image={file?.image?.url}
              video={file?.video?.url}
            />
          </ContainerVideo>
          )}
          {article2 && (
            <Col md={6}>
              <BoxText>
                <BoxTextTitle>
                  <Arial type="EditorialGridTitle">{article2?.title}</Arial>
                </BoxTextTitle>
                <BoxTextDescription>
                  <Arial type="EditorialGridText"><div dangerouslySetInnerHTML={{ __html: article2?.body }} /></Arial>
                </BoxTextDescription>
              </BoxText>
            </Col>
          )}
        </RowBottom>
      </ColumnLeft>
      {article1
        && (
        <ColumnRight md={4} sm={12}>
          <BoxText>
            <BoxTextTitle>
              <Arial type="EditorialGridTitle">{article1.title}</Arial>
            </BoxTextTitle>
            <BoxTextDescription>
              <Arial type="EditorialGridText"><div dangerouslySetInnerHTML={{ __html: article1?.body }} /></Arial>
            </BoxTextDescription>
          </BoxText>
        </ColumnRight>
        )
      }
    </Row>
  </EditorialGridContainer>
);

EditorialGrid.propTypes = {
  file: PropTypes.object,
  text: PropTypes.string,
  article1: PropTypes.object,
  article2: PropTypes.object
};

export default EditorialGrid;
