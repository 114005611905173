import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 300px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;
    height: 300px;
    
    .mobile-no-padding-right {
      padding-right: 0;
    }
    
    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 420px;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  
  @media ${props => props.theme.device.mobileL} {
    height: 50%;
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(${props => props.theme.colors.transparent}, ${props => props.theme.colors.doveGray});
  }
`;

export const SmartWorkingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;

export const ResultContainer = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  width: 25%;
  padding-top: 20px;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
