import { createAsyncActionType } from './index';

export const CERTIFICATION_TABLE_CONTENTS_GET = createAsyncActionType(
  'certification',
  'CERTIFICATION_TABLE_CONTENTS_GET'
);

export const CERTIFICATION_COURSE_GET = createAsyncActionType(
  'certification',
  'CERTIFICATION_COURSE_GET'
);
