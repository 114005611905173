import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  GeneralCardContainer, ButtonContainer, AdditionalInfo,
  InformationContainer, Title, IconWrapper
} from './style';
import Arial from '../../typography/arial';
import Button from '../Button';
import { Description } from '../../components/AccessStatistic/style';
import durationFormatHour from '../../../utils/duration-format-hour';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { IconCompleted } from '../../../app/pages/Collection/style';


const AgendaCard = ({
  pushUrl, agenda: {
    cover, title, date, description, time, duration, completed, slot
  }, btnText, btnType, onClick, vocabulary
}) => {
  const { url: image } = cover || {};

  return (
    <GeneralCardContainer onClick={onClick} cover={image}>
      <AdditionalInfo>
        <Arial type="productCardDetail">{moment(new Date(slot.startDateTimestamp)).format('DD MMMM')} <br /> Ore {moment(new Date(slot.startDateTimestamp)).format('HH:mm')} - {moment(new Date(slot.endDateTimestamp)).format('HH:mm')}</Arial>
      </AdditionalInfo>
      {completed ? (
        <IconWrapper>
          <IconCompleted className="icon-completato" />
        </IconWrapper>
      ) : null
        }
      <InformationContainer>
        <Title><Arial type="collectionCardTitle">{title}</Arial></Title>
        <Description><Arial type="addInfoGeneralCard" vocabulary="tojName">{getValueVocabulary(vocabulary.tojName, 'tojName')} | {durationFormatHour(duration)}</Arial>
        </Description>
        <ButtonContainer>
          <Button btnText={btnText} disabled type={btnType} />
        </ButtonContainer>
      </InformationContainer>
    </GeneralCardContainer>
  );
};


AgendaCard.propTypes = {
  pushUrl: PropTypes.func
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(AgendaCard);
