import React, {
  useEffect //, useState
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { Container } from '../../../ui/gridSystem';
import {
  FavoritesContainer,
  CardContainer //, TabsContainer
} from './style';
import { ResultContainer, Title } from '../News/style';
import Arial from '../../../ui/typography/arial';
import routes from '../../../routes';
import {
  GeneralCard,
  NoResult //, Tabs
} from '../../../ui/atoms';
import { FAVORITE_LIST_GET } from '../../redux/actions/favorite';
import { SMARTWORKING_NODE_ACTION } from '../../redux/actions/smartworking';

const Favorites = ({
  favorites,
  getFavorites,
  setAsFavoriteSmartWorking,
  pushUrl
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFavorites]);

  const getRedirectPath = ({ path, id }) => {
    pushUrl(`${routes.course.path}/${path}/${id}`);
  };

  return (
    <>
      <FavoritesContainer>
        <Container className="p-0">
          <Title>
            <Arial type="newsTitle">Preferiti</Arial>
          </Title>
          <ResultContainer>
            {(favorites || []).length > 0 ? (
              favorites.map(item => (
                <CardContainer sm={12} md={6} lg={3} key={item.id}>
                  <GeneralCard
                    key={`product-${item._id}`}
                    item={item}
                    onClickCard={() => getRedirectPath(item)}
                    onClickFavorite={() => setAsFavoriteSmartWorking(item.id)}
                    type={GeneralCard.TYPE.SMARTWORKING}
                    colorTag={item.category_color}
                  />
                </CardContainer>
              ))
            ) : (
              <NoResult />
            )}
          </ResultContainer>
        </Container>
      </FavoritesContainer>
    </>
  );
};

Favorites.propTypes = {
  // HOC (connect, state)
  favorites: PropTypes.array,
  // HOC (connect, dispatch)
  getFavorites: PropTypes.func.isRequired,
  setAsFavoriteSmartWorking: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { items: favorites = [] } = state.favorite;
    return { favorites };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getFavorites: () =>
      dispatch({ type: FAVORITE_LIST_GET._REQUEST, itemPerPage: 100 }),
    setAsFavoriteSmartWorking: nid =>
      dispatch({
        type: SMARTWORKING_NODE_ACTION._REQUEST,
        nid,
        action_type: 'favourite',
        node_type: 'pill_collection'
      })
  })
)(Favorites);
